/* tslint:disable */
/* eslint-disable */
/**
 * Consolidate Kanban API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    BoardDto,
    BoardDtoFromJSON,
    BoardDtoToJSON,
    BoardListDto,
    BoardListDtoFromJSON,
    BoardListDtoToJSON,
    Command,
    CommandFromJSON,
    CommandToJSON,
    Command2,
    Command2FromJSON,
    Command2ToJSON,
    Command3,
    Command3FromJSON,
    Command3ToJSON,
    Command4,
    Command4FromJSON,
    Command4ToJSON,
    Command5,
    Command5FromJSON,
    Command5ToJSON,
    Command6,
    Command6FromJSON,
    Command6ToJSON,
    Command7,
    Command7FromJSON,
    Command7ToJSON,
    Result,
    ResultFromJSON,
    ResultToJSON,
} from '../models';

export interface KanbanAddCardRequest {
    command4: Command4;
}

export interface KanbanAddColumnRequest {
    command2: Command2;
}

export interface KanbanCreateBoardRequest {
    command: Command;
}

export interface KanbanDeleteBoardRequest {
    boardId: number;
}

export interface KanbanEditColumnRequest {
    command7: Command7;
}

export interface KanbanGetBoardRequest {
    id: number;
}

export interface KanbanMoveCardRequest {
    command3: Command3;
}

export interface KanbanRemoveCardRequest {
    command5: Command5;
}

export interface KanbanRemoveColumnRequest {
    command6: Command6;
}

/**
 * 
 */
export class KanbanApi extends runtime.BaseAPI {

    /**
     */
    async kanbanAddCardRaw(requestParameters: KanbanAddCardRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.command4 === null || requestParameters.command4 === undefined) {
            throw new runtime.RequiredError('command4','Required parameter requestParameters.command4 was null or undefined when calling kanbanAddCard.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("oauth2", []);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/api/v1/Kanban/AddCard`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: Command4ToJSON(requestParameters.command4),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async kanbanAddCard(command4: Command4): Promise<void> {
        await this.kanbanAddCardRaw({ command4: command4 });
    }

    /**
     */
    async kanbanAddColumnRaw(requestParameters: KanbanAddColumnRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.command2 === null || requestParameters.command2 === undefined) {
            throw new runtime.RequiredError('command2','Required parameter requestParameters.command2 was null or undefined when calling kanbanAddColumn.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("oauth2", []);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/api/v1/Kanban/AddColumn`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: Command2ToJSON(requestParameters.command2),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async kanbanAddColumn(command2: Command2): Promise<void> {
        await this.kanbanAddColumnRaw({ command2: command2 });
    }

    /**
     */
    async kanbanCreateBoardRaw(requestParameters: KanbanCreateBoardRequest): Promise<runtime.ApiResponse<Result>> {
        if (requestParameters.command === null || requestParameters.command === undefined) {
            throw new runtime.RequiredError('command','Required parameter requestParameters.command was null or undefined when calling kanbanCreateBoard.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("oauth2", []);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/api/v1/Kanban`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CommandToJSON(requestParameters.command),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ResultFromJSON(jsonValue));
    }

    /**
     */
    async kanbanCreateBoard(command: Command): Promise<Result> {
        const response = await this.kanbanCreateBoardRaw({ command: command });
        return await response.value();
    }

    /**
     */
    async kanbanDeleteBoardRaw(requestParameters: KanbanDeleteBoardRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.boardId === null || requestParameters.boardId === undefined) {
            throw new runtime.RequiredError('boardId','Required parameter requestParameters.boardId was null or undefined when calling kanbanDeleteBoard.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("oauth2", []);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/api/v1/Kanban/{boardId}`.replace(`{${"boardId"}}`, encodeURIComponent(String(requestParameters.boardId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async kanbanDeleteBoard(boardId: number): Promise<void> {
        await this.kanbanDeleteBoardRaw({ boardId: boardId });
    }

    /**
     */
    async kanbanEditColumnRaw(requestParameters: KanbanEditColumnRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.command7 === null || requestParameters.command7 === undefined) {
            throw new runtime.RequiredError('command7','Required parameter requestParameters.command7 was null or undefined when calling kanbanEditColumn.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("oauth2", []);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/api/v1/Kanban/EditColumn`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: Command7ToJSON(requestParameters.command7),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async kanbanEditColumn(command7: Command7): Promise<void> {
        await this.kanbanEditColumnRaw({ command7: command7 });
    }

    /**
     */
    async kanbanGetBoardRaw(requestParameters: KanbanGetBoardRequest): Promise<runtime.ApiResponse<BoardDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling kanbanGetBoard.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("oauth2", []);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/api/v1/Kanban/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => BoardDtoFromJSON(jsonValue));
    }

    /**
     */
    async kanbanGetBoard(id: number): Promise<BoardDto> {
        const response = await this.kanbanGetBoardRaw({ id: id });
        return await response.value();
    }

    /**
     */
    async kanbanGetBoardsRaw(): Promise<runtime.ApiResponse<Array<BoardListDto>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("oauth2", []);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/api/v1/Kanban`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(BoardListDtoFromJSON));
    }

    /**
     */
    async kanbanGetBoards(): Promise<Array<BoardListDto>> {
        const response = await this.kanbanGetBoardsRaw();
        return await response.value();
    }

    /**
     */
    async kanbanMoveCardRaw(requestParameters: KanbanMoveCardRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.command3 === null || requestParameters.command3 === undefined) {
            throw new runtime.RequiredError('command3','Required parameter requestParameters.command3 was null or undefined when calling kanbanMoveCard.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("oauth2", []);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/api/v1/Kanban/MoveCard`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: Command3ToJSON(requestParameters.command3),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async kanbanMoveCard(command3: Command3): Promise<void> {
        await this.kanbanMoveCardRaw({ command3: command3 });
    }

    /**
     */
    async kanbanRemoveCardRaw(requestParameters: KanbanRemoveCardRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.command5 === null || requestParameters.command5 === undefined) {
            throw new runtime.RequiredError('command5','Required parameter requestParameters.command5 was null or undefined when calling kanbanRemoveCard.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("oauth2", []);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/api/v1/Kanban/RemoveCard`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: Command5ToJSON(requestParameters.command5),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async kanbanRemoveCard(command5: Command5): Promise<void> {
        await this.kanbanRemoveCardRaw({ command5: command5 });
    }

    /**
     */
    async kanbanRemoveColumnRaw(requestParameters: KanbanRemoveColumnRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.command6 === null || requestParameters.command6 === undefined) {
            throw new runtime.RequiredError('command6','Required parameter requestParameters.command6 was null or undefined when calling kanbanRemoveColumn.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("oauth2", []);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/api/v1/Kanban/RemoveColumn`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: Command6ToJSON(requestParameters.command6),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async kanbanRemoveColumn(command6: Command6): Promise<void> {
        await this.kanbanRemoveColumnRaw({ command6: command6 });
    }

}
