










































































































































import ActivityDetailFacade from '../../../../domain/application/ActivityDetailFacade';
import Vue, { PropType } from 'vue';
import { Activity, Attendee, AttendeeType } from '../../../../domain';
import ContactGroup from '../../shared/ContactGroup.vue';
import ActivityContactGroup from './ActivityContactGroup.vue';
import AttendeeContactGroup from './AttendeeContactGroup.vue';
import { GetAktMemberDefault } from '../../../../../../../libs/shared/data-access-legacy-api/src';
import EmployeeService from '../../../../domain/application/EmployeeService';
import { ensureMinApiVersion } from 'apps/web/src/utils/versioning';

export default Vue.extend({
  components: {
    ContactGroup,
    ActivityContactGroup,
    AttendeeContactGroup,
  },
  props: {
    data: { type: Object as PropType<Activity>, required: true },
  },
  methods: {
    showAcceptionsAndCancellations() {
      ActivityDetailFacade.showAcceptionsAndCancellationsOfInstance();
    },
    getUserDisplay(username: string): string {
      const user = EmployeeService.find(username);

      if (user) {
        return `${user.lastName} ${user.firstName} (${user.userName})`;
      }

      return username;
    },
    chooseICC() {
      ActivityDetailFacade.sendCCs();
    },
    removeAttendee(item: Attendee): void {
      ActivityDetailFacade.removeAttendee(item);
    },
    addAttendeeInternal(): void {
      ActivityDetailFacade.addAttendee(AttendeeType.Internal);
    },
    addAttendeeExternal(): void {
      ActivityDetailFacade.addAttendee(AttendeeType.External);
    },
    removeContact(item: GetAktMemberDefault): void {
      ActivityDetailFacade.removeContact(item);
    },
    addContact(): void {
      ActivityDetailFacade.addContact();
    },
    addClient(): void {
      if (ensureMinApiVersion('5.2022')) {
        ActivityDetailFacade.addClient();
      }
    },
    removeClient(item: GetAktMemberDefault): void {
      if (ensureMinApiVersion('5.2022')) {
        ActivityDetailFacade.removeClient(item);
      }
    },
  },
});
