

















































import Vue from 'vue';

import { translate } from '@consolidate/shared/util-translations';
import CsButton from 'libs/shared/ui-components/src/lib/components/CsButton/CsButton.vue';
import SearchBar from '../../components/SearchBar.vue';
import ActivityList from '../../components/activity/list/ActivityList.vue';
import { ActivityListItem } from '../../domain';
import ActivitySearchFacade, {
  SearchType,
} from '../../domain/application/ActivitySearchFacade';
import SettingsFacade from '../../domain/application/SettingsFacade';
import ModalService, {
  AdvancedSearchOptions,
} from '../../logic/services/ModalService';
import { ensureMinApiVersion } from '../../utils/versioning';

export default Vue.extend({
  name: 'PoolMaster',
  metaInfo: {
    titleTemplate: `${translate('ACTIVITIES')}  %s`,
  },
  components: {
    SearchBar,
    ActivityList,
    CsButton,
  },
  data: () => ({
    searched: false,
    collapsed: true,
    advancedOptions: undefined as AdvancedSearchOptions | undefined,
  }),
  created() {
    if (ActivitySearchFacade.type !== SearchType.Search) {
      ActivitySearchFacade.clear();
    }
  },
  computed: {
    searchTerm: {
      get() {
        return ActivitySearchFacade.value
          ? ActivitySearchFacade.value.toLocaleString()
          : '';
      },
      set(newTerm: string) {
        ActivitySearchFacade.value = newTerm;
      },
    },
    isLoading(): boolean {
      return ActivitySearchFacade.loading;
    },
    loadError(): boolean {
      return ActivitySearchFacade.loadError;
    },
    items(): ActivityListItem[] {
      return ActivitySearchFacade.filteredItems;
    },
    total(): string {
      return ActivitySearchFacade.total;
    },
    lastCreated(): ActivityListItem[] {
      return ActivitySearchFacade.lastCreated;
    },
  },
  methods: {
    async searchClicked() {
      this.searched = false;

      let term = this.searchTerm;
      if (this.advancedOptions && !term) {
        term = '*';
      }

      await ActivitySearchFacade.loadFor(
        SearchType.Search,
        term,
        this.advancedOptions
      );
      if (term) this.searched = true;
    },
    loadMore() {
      ActivitySearchFacade.loadMore();
    },
    openModal() {
      if (!ensureMinApiVersion('5.2063')) return;

      // if elasticsearch is used the index needs to be updated
      if (
        SettingsFacade.settings.elasticIndexVersion &&
        SettingsFacade.settings.elasticIndexVersion < 2
      ) {
        ModalService.error(
          this.$t('ELASTIC_OUTDATED'),
          undefined,
          this.$t('OUTDATED_NOT_AVAILABLE'),
          false
        );
        return;
      }

      ModalService.advancedSearch({
        initialOptions: this.advancedOptions,
        callback: {
          onYes: async (data) => {
            this.advancedOptions = { ...data };
            this.searchClicked();
          },
        },
      });
    },
  },
});
