











import { AuthService } from '@consolidate/shared/util-auth';
import Vue from 'vue';
import EmployeeService from '../../../domain/application/EmployeeService';
import { User } from '../../../domain';

export default Vue.extend({
  name: 'ModalCalendarFrom',
  props: {
    callback: {
      type: Object,
      default() {
        return {
          onYes: null,
        };
      },
    },
  },
  data: () => ({
    searchInput: '',
  }),
  computed: {
    users() {
      return EmployeeService.users.filter((x) =>
        x.fullName.toLowerCase().includes(this.searchInput.toLowerCase())
      );
    },
    myUserName() {
      return AuthService.getUser()?.uid;
    },
  },
  methods: {
    async handleYesCallback(item: User) {
      const { onYes } = this.callback;

      if (onYes) {
        onYes({
          userName: item.userName,
          fullName: item.fullName,
          selectedMySelf: item.userName === this.myUserName,
        });

        this.$emit('close');
      }
    },
  },
});
