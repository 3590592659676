import { GetthumbpicturesResponse } from '@consolidate/shared/data-access-legacy-api';
import { Activity, ActivityFactory, ActivityTechnology } from '../../../domain';
import { ensureMinApiVersion } from '../../../utils/versioning';
import Client from '../../api/Client';
import ConsolidateBaseQuery from '../ConsolidateBaseQuery';

interface Payload {
  id: number;
}

class GetActivityQuery extends ConsolidateBaseQuery<Activity, Payload> {
  constructor(payload: Payload) {
    super(payload);
  }

  async execute() {
    const { id } = this._payload;

    // Await this request as we need to know if there are attachments
    const actDetail = await new Client().api.getAkt(id, {
      getmembers: true,
      getcustom: true,
    });

    const atts = actDetail.attachments ? actDetail.attachments : [];
    const attIds = atts.map((a: any) => a.attid).join(',');
    const attachments = attIds
      ? await new Client().api.getThumbpictures(attIds)
      : ({ data: { items: [] } } as GetthumbpicturesResponse);

    let meeting = undefined;
    if (
      actDetail.type === ActivityTechnology.Appointment &&
      ensureMinApiVersion('5.2100', true)
    ) {
      meeting = await new Client().activities.activitiesGetOnlineMeeting(id);
    }

    // Send that stuff concurrently
    const activity = ActivityFactory.getFromConsolidate(
      actDetail,
      attachments,
      meeting
    );

    return activity;
  }
}
export default GetActivityQuery;
