/* tslint:disable */
/* eslint-disable */
/**
 * Consolidate App API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 1 = First
 * 2 = Second
 * 3 = Third
 * 4 = Fourth
 * 5 = Last
 * @export
 * @enum {string}
 */
export enum WeekOfMonth {
    NUMBER_1 = 1,
    NUMBER_2 = 2,
    NUMBER_3 = 3,
    NUMBER_4 = 4,
    NUMBER_5 = 5
}

export function WeekOfMonthFromJSON(json: any): WeekOfMonth {
    return WeekOfMonthFromJSONTyped(json, false);
}

export function WeekOfMonthFromJSONTyped(json: any, ignoreDiscriminator: boolean): WeekOfMonth {
    return json as WeekOfMonth;
}

export function WeekOfMonthToJSON(value?: WeekOfMonth | null): any {
    return value as any;
}

