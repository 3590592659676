















































































import Vue from 'vue';
import { getLang } from '@consolidate/shared/util-translations';
import RootModal from './components/modal/RootModal.vue';
import SidebarMenu from './components/sidebar/SidebarMenu.vue';
import CreateButton from './components/navigation/CreateButton.vue';
import { AuthService } from '@consolidate/shared/util-auth';
import update from './mixins/update';
import ConnectivityService from './logic/services/ConnectivityService';
import InitializationService from './logic/services/InitializationService';
import OverviewFacade from './domain/application/OverviewFacade';

export default Vue.extend({
  name: 'App',
  components: {
    RootModal,
    SidebarMenu,
    CreateButton,
  },
  mixins: [update],
  data: () => ({
    drawer: false,
  }),
  computed: {
    online() {
      return ConnectivityService.online;
    },
    isUserLoggedIn(): boolean {
      return AuthService.isLoggedIn();
    },
    notificationsCount() {
      const count = OverviewFacade.notificationsCount;
      if (count && count > 999) return '🔥';
      return count;
    },
    isLoading() {
      return InitializationService.state.isLoading;
    },
  },
  mounted() {
    // set html lang attribute
    const lang = getLang();
    if (lang) {
      document.querySelector('html')?.setAttribute('lang', lang.split('-')[0]);
    }
  },
  methods: {
    goToInbox(): void {
      this.$router.push({ name: 'inbox' });
    },
    goToOverview(): void {
      this.$router.push({ name: 'overview' });
    },
  },
});
