import { WorkflowCancelProcessRequest } from '@consolidate/shared/data-access-legacy-api';
import { NotificationService } from '@consolidate/shared/ui-components';
import { translate } from '@consolidate/shared/util-translations';
import Client from '../../api/Client';
import ConsolidateBaseCommand from '../baseCommands/ConsolidateBaseCommand';

class WorkflowCancelCommand extends ConsolidateBaseCommand<WorkflowCancelProcessRequest> {
  constructor(payload: WorkflowCancelProcessRequest) {
    super('WorkflowCancelCommand', payload);
  }

  async execute() {
    try {
      const value = await new Client().api.workflowCancelProcess(this.payload);

      const success = value._return === '1';

      return this.result(success);
    } catch (error) {
      if (this.isRetryable(error)) {
        return this.save();
      }
      return this.notOk();
    }
  }

  protected onError() {
    NotificationService.showError({
      message: translate('COULDNT_CHANGE_ACTIVITY'),
    });
  }
}

export default WorkflowCancelCommand;
