<template>
  <form class="modal-activity-postpone" @submit.prevent="handleYesCallback">
    <p v-show="error" class="error--text" v-text="error" />

    <cs-datetime-picker
      :label="$t('DATE_INTERNAL')"
      v-model="newDate"
      @input="resetError"
      type="date"
    />

    <BaseModalControls
      class="margin-top"
      :blur="blurButtons"
      :loading="isLoading"
      @no="$emit('close')"
    >
      <template #yes>
        <span v-text="$t('POSTPONE')" />
      </template>
    </BaseModalControls>
  </form>
</template>

<script>
import BaseModalControls from '../partials/BaseModalControls.vue';

export default {
  name: 'ModalActivityPostpone',
  components: {
    BaseModalControls,
  },
  props: {
    dueDate: { type: String, required: true },
    callback: {
      type: Object,
      default: () => ({
        onYes: { type: Function, default: null },
      }),
    },
  },
  data: () => ({
    error: '',
    newDate: '',
    isLoading: false,
    blurButtons: false,
  }),
  created() {
    this.newDate = this.dueDate;
  },
  methods: {
    async handleYesCallback() {
      // Just check if the format is valid
      const isPatternValid = /\d{4}-\d{2}-\d{2}/.test(this.newDate);
      if (!isPatternValid) {
        this.error = `${this.$t('INVALID_DATE_FORMAT')}.`;
      }

      if (this.newDate === this.dueDate) {
        this.error = `${this.$t('DATE_NOT_CHANGED')}.`;
      }
      if (this.error) return;

      const { onYes } = this.callback;
      if (onYes) {
        this.isLoading = true;
        await onYes({ dueDateTimeUtc: this.newDate });
        this.isLoading = false;
      }

      this.$emit('close');
    },
    resetError() {
      this.error = '';
    },
  },
};
</script>
