import RemoveFavoritesBaseCommand from '../baseCommands/RemoveFavoriteBaseCommand';

class RemoveProjectFromFavoritesCommand extends RemoveFavoritesBaseCommand {
  constructor({ id }: { id: number }) {
    super('RemoveProjectFromFavoritesCommand', { type: 3, id });
  }

  async execute() {
    return super.executeInternal();
  }
}
export default RemoveProjectFromFavoritesCommand;
