import { NotificationService } from '@consolidate/shared/ui-components';
import { translate } from '@consolidate/shared/util-translations';
import Client from '../../api/Client';
import ConsolidateBaseCommand from '../baseCommands/ConsolidateBaseCommand';

interface Payload {
  actId: number;
  receivers: string[];
  comment: string;
}

export default class SendCcCommand extends ConsolidateBaseCommand<Payload> {
  constructor(payload: Payload) {
    super('SendCcCommand', payload);
  }

  async execute() {
    try {
      const { actId, receivers, comment } = this.payload;

      const data = await new Client().api.addCc({
        aktid: actId,
        internalCCs: this.toConsolidateCSV(receivers),
        cCcomment: comment,
      });

      const success = data?._return === 'TRUE';

      return this.result(success);
    } catch (error) {
      if (this.isRetryable(error)) {
        return this.save();
      }
      return this.notOk();
    }
  }

  protected onSuccess() {
    NotificationService.showSuccess({
      message: translate('ICC_SEND_SUCCESS'),
    });
  }

  protected onError() {
    NotificationService.showError({
      message: translate('ICC_SEND_ERROR'),
    });
  }
}
