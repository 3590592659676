import Client from '../../api/Client';
import ConsolidateBaseCommand from './ConsolidateBaseCommand';

interface Payload {
  type: number;
  id: number | string;
}

abstract class RemoveFavoriteBaseCommand extends ConsolidateBaseCommand<Payload> {
  constructor(type: string, payload: Payload) {
    super(type, payload);
  }
  async executeInternal() {
    try {
      const data = await new Client().api.removeFavorite({
        id: this.payload.id.toString(),
        type: this.payload.type,
      });
      const result = data?._return === 'TRUE';

      return this.result(result);
    } catch (error) {
      if (this.isRetryable(error)) {
        return this.save();
      }
      return this.notOk();
    }
  }
}

export default RemoveFavoriteBaseCommand;
