import Client from '../../api/Client';
import ConsolidateBaseCommand from '../baseCommands/ConsolidateBaseCommand';

interface Payload {
  actId: number;
  confidentiality: number;
}

class SetActivityConfidentialityCommand extends ConsolidateBaseCommand<Payload> {
  constructor(payload: Payload) {
    super('SetActivityConfidentialityCommand', payload);
  }

  async execute() {
    try {
      const { actId, confidentiality } = this.payload;

      const dto = {
        aktid: actId,
        secret: confidentiality,
      };
      const data = await new Client().api.setAktSecretGroup(dto);
      const success = data?._return === 'TRUE';
      return this.result(success);
    } catch (error) {
      if (this.isRetryable(error)) {
        return this.save();
      }
      return this.notOk();
    }
  }
}

export default SetActivityConfidentialityCommand;
