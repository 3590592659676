




















import Vue from 'vue';
import QueueService from '../../domain/application/QueueService';
import ModalService from '../../logic/services/ModalService';

export default Vue.extend({
  computed: {
    items() {
      return QueueService.all;
    },
  },
  methods: {
    removeFromQueue(item: any) {
      ModalService.choice({
        title: this.$t('DELETE_COMMAND'),
        callback: {
          onYes: () => QueueService.delete(item.id),
        },
        labels: {
          yes: this.$t('DELETE'),
        },
      });
    },
  },
});
