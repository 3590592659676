import AddToFavoritesBaseCommand from '../baseCommands/AddToFavoritesBaseCommand';

class AddProjectToFavoritesCommand extends AddToFavoritesBaseCommand {
  constructor({ id }: { id: number }) {
    super('AddProjectToFavoritesCommand', { type: 3, id });
  }

  async execute() {
    return super.executeInternal();
  }
}

export default AddProjectToFavoritesCommand;
