/* tslint:disable */
/* eslint-disable */
/**
 * Consolidate App API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface QuarantinedMailDto
 */
export interface QuarantinedMailDto {
    /**
     * 
     * @type {string}
     * @memberof QuarantinedMailDto
     */
    messageId: string;
    /**
     * 
     * @type {string}
     * @memberof QuarantinedMailDto
     */
    senderMailAddress: string;
    /**
     * 
     * @type {string}
     * @memberof QuarantinedMailDto
     */
    subject: string;
    /**
     * 
     * @type {string}
     * @memberof QuarantinedMailDto
     */
    bodyPreview: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof QuarantinedMailDto
     */
    recipients: Array<string>;
    /**
     * 
     * @type {Date}
     * @memberof QuarantinedMailDto
     */
    receivedDate: Date;
}

export function QuarantinedMailDtoFromJSON(json: any): QuarantinedMailDto {
    return QuarantinedMailDtoFromJSONTyped(json, false);
}

export function QuarantinedMailDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): QuarantinedMailDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'messageId': json['messageId'],
        'senderMailAddress': json['senderMailAddress'],
        'subject': json['subject'],
        'bodyPreview': json['bodyPreview'],
        'recipients': json['recipients'],
        'receivedDate': (new Date(json['receivedDate'])),
    };
}

export function QuarantinedMailDtoToJSON(value?: QuarantinedMailDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'messageId': value.messageId,
        'senderMailAddress': value.senderMailAddress,
        'subject': value.subject,
        'bodyPreview': value.bodyPreview,
        'recipients': value.recipients,
        'receivedDate': (value.receivedDate.toISOString()),
    };
}


