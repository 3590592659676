




























































import Vue from 'vue';
import InboxFacade from '../../domain/application/InboxFacade';
import ActivityList from '../../components/activity/list/ActivityList.vue';

import { ActivityListItem } from '../../domain';
import { filterMixin } from '../../mixins/filterMixin';
import { translate } from '@consolidate/shared/util-translations';

export default Vue.extend({
  name: 'InboxMaster',
  metaInfo: {
    titleTemplate: `${translate('INBOX')}  %s`,
  },
  components: {
    ActivityList,
  },
  mixins: [filterMixin(InboxFacade)],
  data: () => ({
    selectedTab: 0,
  }),
  computed: {
    routePath(): string {
      return this.$route.path;
    },
    inboxData(): ActivityListItem[] {
      return InboxFacade.filteredItems;
    },
    spamData(): ActivityListItem[] {
      return InboxFacade.filteredSpamItems;
    },
    isLoading(): boolean {
      return InboxFacade.loading;
    },
    tab(): string | undefined {
      return this.$route?.params?.tab;
    },
    loadError(): boolean {
      return InboxFacade.loadError;
    },
  },
  mounted() {
    InboxFacade.load();
  },
  watch: {
    routePath() {
      (this.$refs.tabs as any).callSlider();
    },
    tab: {
      immediate: true,
      handler() {
        if (this.tab) {
          this.selectedTab = this.tab === 'INBOX' ? 0 : 1;
        }
      },
    },
  },
  methods: {
    reload() {
      InboxFacade.load(true);
    },
  },
});
