var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('activity-form-base',_vm._g(_vm._b({ref:"base",attrs:{"subjectCaption":_vm.$t('TOPIC')},scopedSlots:_vm._u([{key:"header_after",fn:function(ref){
var data = ref.data;
return [_c('v-row',[_c('v-col',[_c('attendees-auto-complete',{ref:"attendees",attrs:{"value":_vm.getParticipants(data),"label":_vm.$t('ATTENDEES_INTERNAL_EXTERNAL')},on:{"input":function($event){return _vm.setParticipants($event, data)}}})],1)],1),(!data.recurrence)?_c('v-row',[_c('v-col',[_c('cs-datetime-picker',{attrs:{"label":_vm.$t('START_DATE_TIME'),"type":data.appointmentIsAllDay ? 'date' : 'datetime',"required":""},on:{"done":function($event){return _vm.onStartEntered(data, _vm.$refs.endDate)}},model:{value:(data.appointmentStartDateTimeUtc),callback:function ($$v) {_vm.$set(data, "appointmentStartDateTimeUtc", $$v)},expression:"data.appointmentStartDateTimeUtc"}})],1),_c('v-col',[_c('cs-datetime-picker',{ref:"endDate",attrs:{"label":_vm.$t('END_DATE_TIME'),"type":data.appointmentIsAllDay ? 'date' : 'datetime',"rules":[
            function (val) { return _vm.validateEndDate(
                data.appointmentStartDateTimeUtc,
                data.appointmentEndDateTimeUtc,
                data.appointmentIsAllDay
              ); } ],"required":""},model:{value:(data.appointmentEndDateTimeUtc),callback:function ($$v) {_vm.$set(data, "appointmentEndDateTimeUtc", $$v)},expression:"data.appointmentEndDateTimeUtc"}})],1)],1):(!data.appointmentIsAllDay)?_c('v-row',[_c('v-col',[_c('cs-datetime-picker',{attrs:{"value":_vm.getStartTime(data),"label":_vm.$t('START_DATE_TIME'),"type":"time","required":""},on:{"input":function (t) { return _vm.setStartTime(data, t); },"done":function($event){return _vm.onStartEntered(data, _vm.$refs.endTime)}}})],1),_c('v-col',[_c('cs-datetime-picker',{ref:"endTime",attrs:{"value":_vm.getEndTime(data),"label":_vm.$t('END_DATE_TIME'),"type":"time","rules":[
            function (val) { return _vm.validateEndDate(
                data.appointmentStartDateTimeUtc,
                data.appointmentEndDateTimeUtc,
                data.appointmentIsAllDay
              ); } ],"required":""},on:{"input":function (t) { return _vm.setEndTime(data, t); }}})],1)],1):_vm._e(),_c('v-row',{attrs:{"align":"center"}},[_c('v-col',[_c('cs-checkbox',{attrs:{"label":_vm.$t('ALL_DAY')},model:{value:(data.appointmentIsAllDay),callback:function ($$v) {_vm.$set(data, "appointmentIsAllDay", $$v)},expression:"data.appointmentIsAllDay"}})],1),_c('v-col',[_c('v-switch',{attrs:{"label":data.appointmentIsBooked ? _vm.$t('BOOKED') : _vm.$t('AVAILABLE')},model:{value:(data.appointmentIsBooked),callback:function ($$v) {_vm.$set(data, "appointmentIsBooked", $$v)},expression:"data.appointmentIsBooked"}})],1),_c('v-col',{attrs:{"cols":"auto"}},[_c('cs-button',{attrs:{"fab":"","small":"","outlined":data.recurrence === undefined,"color":data.recurrence !== undefined ? 'primary' : null},on:{"click":function($event){return _vm.addRecurrence(data)}}},[_c('cs-icon',{attrs:{"icon":"Repetitive"}})],1)],1)],1)]}},{key:"contacts",fn:function(ref){
          var data = ref.data;
return [_c('contact-group',{attrs:{"label":_vm.$t('ATTENDEES_INTERNAL'),"subtitle":_vm.$t('ATTENDEES_ASSIGNED')},on:{"add":_vm.focusAttendees},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('cs-icon',{attrs:{"icon":"ConfirmationFixed"}})]},proxy:true}],null,true),model:{value:(data.appointmentInternalParticipants),callback:function ($$v) {_vm.$set(data, "appointmentInternalParticipants", $$v)},expression:"data.appointmentInternalParticipants"}}),_c('contact-group',{staticClass:"mt-5",attrs:{"label":_vm.$t('ATTENDEES_EXTERNAL'),"display-subtitle":_vm.getSubtitle},on:{"add":_vm.focusAttendees},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('cs-icon',{attrs:{"icon":"ConfirmationFixed"}})]},proxy:true}],null,true),model:{value:(data.appointmentExternalParticipants),callback:function ($$v) {_vm.$set(data, "appointmentExternalParticipants", $$v)},expression:"data.appointmentExternalParticipants"}})]}}])},'activity-form-base',_vm.$attrs,false),_vm.$listeners))}
var staticRenderFns = []

export { render, staticRenderFns }