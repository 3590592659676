




import { ActivityType } from '../../../domain';
import Vue, { PropType } from 'vue';
import ActivityTypeChooser from '../../activity/ActivityTypeChooser.vue';

export default Vue.extend({
  components: { ActivityTypeChooser },
  name: 'ModalActivityChooseActType',
  props: {
    currentType: { type: Object as PropType<ActivityType> },
    callback: {
      type: Object as PropType<{
        onYes?: (type: ActivityType) => void;
      }>,
    },
  },
  methods: {
    async select(type: ActivityType) {
      const { onYes } = this.callback;

      if (onYes) {
        await onYes(type);
      }

      this.$emit('close');
    },
  },
});
