







































import { translate } from '@consolidate/shared/util-translations';
import Vue from 'vue';
import ActivityList from '../../components/activity/list/ActivityList.vue';
import ProjectActivitiesFacade from '../../domain/application/ProjectActivitiesFacade';

export default Vue.extend({
  name: 'ProjectActivities',
  metaInfo() {
    const name: string = this.title ?? '';
    const path = this.$route.path;

    if (path.includes('activities/')) {
      return {
        titleTemplate: `${name} %s`,
      };
    } else {
      return {
        title: translate('ACTIVITIES'),
      };
    }
  },
  components: {
    ActivityList,
  },
  data: () => ({
    searchOpen: false,
    searchValue: '',
  }),
  computed: {
    searchActive() {
      return !!ProjectActivitiesFacade.search;
    },
    items() {
      return ProjectActivitiesFacade.items;
    },
    total() {
      return ProjectActivitiesFacade.total;
    },
    title(): string | undefined {
      return ProjectActivitiesFacade.projectName;
    },
    isLoading(): boolean {
      return ProjectActivitiesFacade.loading;
    },
    loadError(): boolean {
      return ProjectActivitiesFacade.loadError;
    },
    id(): number {
      return +this.$route.params.typeId;
    },
  },
  created() {
    this.searchValue = ProjectActivitiesFacade.search;
  },
  watch: {
    id: {
      handler() {
        this.load();
      },
      immediate: true,
    },
  },
  methods: {
    load() {
      if (!this.id) return;
      if (this.id === ProjectActivitiesFacade.projectId) return;

      ProjectActivitiesFacade.setProject(this.id);
    },
    search() {
      ProjectActivitiesFacade.setSearch(this.searchValue);
    },
    loadMore() {
      ProjectActivitiesFacade.loadMore();
    },
    reload() {
      ProjectActivitiesFacade.load();
    },
  },
});
