



































import Vue, { PropType } from 'vue';
import VueUploadComponent from 'vue-upload-component';
import { ActivityTypeDocsAllowed } from '../../domain';
import { FileWithBlob } from '../../utils/attachment';
import { formatBytes } from '../../utils/file';
import AttachmentListItem from '../list/AttachmentListItem.vue';

export default Vue.extend({
  name: 'FileInput',
  components: {
    VueUploadComponent,
    AttachmentListItem,
  },
  props: {
    value: { type: Array as PropType<FileWithBlob[]>, default: () => [] }, // Array of Attachment model's
    canRemove: { type: Boolean, default: true },
    canAdd: { type: Number as PropType<ActivityTypeDocsAllowed> },
  },
  data: () => ({
    files: [] as FileWithBlob[],
    id: `file-input-${Math.round(Math.random() * 999999)}`,
  }),
  computed: {
    canUpload(): boolean {
      if (this.canAdd === ActivityTypeDocsAllowed.None) {
        return false;
      }

      if (this.canAdd === ActivityTypeDocsAllowed.Single) {
        return this.files.length === 0;
      }

      return true;
    },
  },
  created() {
    // Reassign files on init (should there be any)
    if (this.value.length) {
      this.files = this.value;
    }
  },
  methods: {
    inputFilter(newFile: FileWithBlob) {
      // Create a blob field
      newFile.blob = '';
      let URL = window.URL || window.webkitURL;
      if (URL && URL.createObjectURL) {
        newFile.blob = URL.createObjectURL(newFile.file);
      }
    },
    handleInput() {
      this.$emit('input', this.files);
    },
    removeFile(id: string) {
      this.files = this.files.filter((file) => file.id !== id);
      this.handleInput();
    },
    formatSize(size: number) {
      return formatBytes(size);
    },
  },
});
