

















import Vue, { PropType } from 'vue';
import { User } from '../../../domain';
import EmployeeService from '../../../domain/application/EmployeeService';
import ButtonBar from '../../button/ButtonBar.vue';

export default Vue.extend({
  name: 'ModalConfirmSubstitute',
  components: {
    ButtonBar,
  },
  props: {
    user: {
      type: Object as PropType<User>,
    },
    callback: {
      type: Object,
      default() {
        return {
          onYes: null,
        };
      },
    },
  },
  computed: {
    substitute() {
      if (!this.user.substituteUser) return null;

      const u = EmployeeService.users.find(
        (x) => x.userName === this.user.substituteUser
      );

      if (!u) return this.user.substituteUser;

      return `${u.fullName} (${u.userName})`;
    },
  },
  methods: {
    async confirm(userName: string) {
      const { onYes } = this.callback;

      this.$emit('close');
      if (onYes) {
        await onYes({
          userName: userName,
        });
      }
    },
  },
});
