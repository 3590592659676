




















import { AppointmentConfirmationStatus, Attendee } from 'apps/web/src/domain';
import { formatDateTime } from 'apps/web/src/utils/date';
import Vue, { PropType } from 'vue';
import { Nested, nestItems } from './nestingHelper';

import ContactGroup from '../../shared/ContactGroup.vue';
import { AuthService } from '@consolidate/shared/util-auth';
import { Permission } from 'libs/shared/util-auth/src/lib/models/permission';

export default Vue.extend({
  name: 'AttendeeContactGroup',
  components: {
    ContactGroup,
  },
  props: {
    value: { type: Array as PropType<Attendee[]>, default: () => [] },
    editable: { type: Boolean, default: true },
    showLabel: { type: Boolean, default: true },
    isRecurring: { type: Boolean, default: false },
    label: { type: String },
    nesting: { type: Boolean, default: true },
  },
  computed: {
    nestedData(): Attendee[] | Nested<Attendee>[] {
      if (!this.nesting) return this.value;

      return nestItems(this.value);
    },
    count(): number {
      if (!this.nesting) this.nestedData.length;

      return (
        (this.nestedData as Nested<Attendee>[])?.filter((x) => !x.skipCount)
          .length ?? 0
      );
    },
  },
  methods: {
    getAttendeeDisplay(item: Attendee): string {
      if (item.userName) {
        return `${item.name} (${item.userName})`;
      }

      return item.name;
    },
    getIcon(item: Attendee): string {
      if (item.anr && !item.cid) {
        // Addresses can not confirm, decline or be invited.
        return 'Address';
      }

      switch (item.confirmationStatus) {
        case AppointmentConfirmationStatus.NoConfirmationNeeded:
          return 'ConfirmationFixed';
        case AppointmentConfirmationStatus.Outstanding:
          return 'ConfirmationPending';
        case AppointmentConfirmationStatus.Confirmed:
          return 'ConfirmationConfirmed';
        case AppointmentConfirmationStatus.Declined:
          return 'ConfirmationDeclined';
        case AppointmentConfirmationStatus.Tentative:
          return 'ConfirmationTentative';
      }
    },
    hasExclusionDate(item: Attendee) {
      return !!item.exclusionDates;
    },
    getAttendeeDisplaySubtitle(item: Attendee): string {
      if (!item.userName) {
        // for externals we need to know the Email address that has received the invitation
        return item.email ?? '';
      }

      let status;
      switch (item.confirmationStatus) {
        case AppointmentConfirmationStatus.Confirmed:
          if (this.isRecurring) {
            status = this.hasExclusionDate(item)
              ? this.$t('ATTENDANCE_CONFIRMED_SOME')
              : this.$t('ATTENDANCE_CONFIRMED_ALL');
          } else {
            status = this.$t('ATTENDANCE_CONFIRMED');
          }

          break;
        case AppointmentConfirmationStatus.Declined:
          if (this.isRecurring) {
            status = this.$t('ATTENDANCE_DECLINED_ALL');
          } else {
            status = this.$t('ATTENDANCE_DECLINED');
          }
          break;
        case AppointmentConfirmationStatus.Tentative:
          status = this.$t('ATTENDANCE_TENTATIVE');
          break;
        case AppointmentConfirmationStatus.NoConfirmationNeeded:
          if (this.isRecurring) {
            status = this.hasExclusionDate(item)
              ? this.$t('ATTENDANCE_CONFIRMED_SOME')
              : this.$t('ATTENDANCE_CONFIRMED_ALL');
          }

          break;
        default:
          return '';
      }

      if (item.confirmationDate)
        return `${status}: ${formatDateTime(item.confirmationDate)}`;

      return status;
    },
    getAttendeeLink(item: Attendee) {
      const user = AuthService.getUser();
      if (item.cid && user?.permissions.includes(Permission.ViewPersonList)) {
        return {
          name: 'contact-detail',
          params: { typeId: item.cid },
        };
      }
      if (item.anr && user?.permissions.includes(Permission.ViewAddressList)) {
        return {
          name: 'address-detail',
          params: { typeId: item.anr },
        };
      }
    },
    canRemoveAttendee(item: Nested<Attendee>) {
      if (item.userName) {
        return item.confirmationStatus !== 3;
      }
      if (item.skipCount) {
        return false;
      }
      return true;
    },
    add() {
      this.$emit('add');
    },
    remove(index: number) {
      this.$emit('remove', this.nestedData[index]);
      this.$emit('input', [
        ...this.nestedData.slice(0, index),
        ...this.nestedData.slice(index + 1),
      ]);
    },
  },
});
