<template>
  <div class="d-flex justify-center">
    <v-progress-circular indeterminate color="primary" />
  </div>
</template>

<script>
export default {
  name: 'LoadingIndicator',
  props: {
    small: { type: Boolean, default: false },
  },
};
</script>
