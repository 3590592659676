import { normalizeColor } from '@consolidate/shared/ui-components';
import { AuthService } from '@consolidate/shared/util-auth';
import Client from '../../logic/api/Client';
import { resolveIconId } from '../../utils/activity/icon';
import { isConnectionError } from '../../utils/network';
import { CalendarItem, CalendarItemType } from '../entitites';
import { CalendarItemModel } from '../infrastructure/+state/models';
import store from '../infrastructure/+state/store';
import ActivityEvents from './ActivityEvents';
import { ListFacade } from './ListFacade';

class CalendarFacade extends ListFacade<CalendarItemModel, CalendarItem> {
  constructor() {
    super();

    ActivityEvents.on('activityCreated', this.load.bind(this));
  }

  protected entity = CalendarItemModel.entity;

  protected map(item: CalendarItemModel): CalendarItem {
    return {
      uid: item.id,
      allDay: item.allDay,
      end: new Date(item.end),
      start: new Date(item.start),
      eventEnd: new Date(item.eventEnd),
      eventStart: new Date(item.eventStart),
      icon: item.icon,
      iconColor: item.iconColor,
      isAppointment: item.type === CalendarItemType.APPOINTMENT,
      title: item.subject,
      type: item.type,
      activityId: item.aktid,
      user: item.user,
      isRecurring: item.isRecurring,
      blocktype: item.blocktype,
      status: item.status,
    };
  }

  private get hash(): number {
    return store.state.entities[this.model.entity].hash;
  }

  private set hash(val: number) {
    this.model.commit((state) => {
      state.hash = val;
    });
  }

  public load() {
    return this.loadItems(this.currentUserId);
  }

  protected async getItems(): Promise<Record<string, unknown>[] | undefined> {
    throw new Error('Not implemented');
  }

  private async getItemsForUser(
    username: string,
    start: string,
    end: string
  ): Promise<Record<string, unknown>[] | undefined> {
    const resp = await new Client().api.getUserTimelineRaw({
      inlineObject: {
        fromdate: start,
        todate: end,
      },
      id: username,
      hash: this.hash,
    });
    const hash = +(resp.raw.headers.get('hash') ?? '0');
    if (hash === this.hash) {
      return undefined;
    }
    this.hash = hash;

    const { items } = await resp.value();
    if (items) {
      return items.map((x) => {
        return {
          id: x.id + '_' + username,
          type: x.type,
          start: x.start,
          end: x.end,
          eventStart: x.eventstart,
          eventEnd: x.eventend,
          allDay: x.noStartTime && x.noEndTime,
          subject: x.subject,
          backupIcon: resolveIconId(x.icon),
          backupIconColor: normalizeColor(x.backcolor),
          user: username,
          aktid: x.aktid,
          isRecurring: x.isrecurring,
          blocktype: x.blocktype,
          status: x.status,
        };
      });
    }
  }

  public async loadItems(
    username: string,
    start?: string,
    end?: string,
    force = false
  ) {
    if (force) {
      this.model.commit((state) => {
        state.hash = undefined;
      });
    }

    CalendarItemModel.commit((state) => {
      state.loading = true;
    });

    const now = new Date();
    const nowYear = now.getFullYear();
    const nowMonth = now.getMonth();

    if (!start) start = new Date(nowYear, nowMonth, 1).toISODateString();
    if (!end) end = new Date(nowYear, nowMonth + 1, 0).toISODateString();

    let loadError = false;
    try {
      const items = await this.getItemsForUser(username, start, end);
      if (items !== undefined) {
        const searchStart = new Date(start);
        const searchEnd = new Date(end);
        searchStart.setHours(0, 0, 0, 0);
        searchEnd.setHours(24, 0, 0, 0);

        CalendarItemModel.delete(
          (x) =>
            x.user === username &&
            new Date(x.end) >= searchStart &&
            new Date(x.start) <= searchEnd
        );

        if (items) {
          CalendarItemModel.insertOrUpdate({
            data: items,
          });
        }
      }
    } catch (error) {
      loadError = isConnectionError(error);
    }

    CalendarItemModel.commit((state) => {
      state.loading = false;

      if (username !== this.currentUserId) {
        // Only show offline indicator for other users, current user just displays what is available
        state.loadError = loadError;
      }
    });

    return !loadError;
  }

  protected filterProperties: (keyof CalendarItem)[] = [];

  private get currentUserId() {
    const user = AuthService.getUser()?.uid;
    if (!user) throw new Error('User not set');

    return user;
  }

  public get itemsOfToday(): CalendarItem[] {
    const start = new Date(new Date().setHours(0, 0, 0, 0));
    const end = new Date(new Date().setHours(23, 59, 59, 0));
    return this.getItemsByUser(this.currentUserId).filter(
      (x) =>
        (x.allDay && x.isAppointment ? x.end > start : x.end >= start) &&
        x.start <= end
    );
  }

  public getItemsByUser(user: string) {
    return this.items.filter((x) => x.user === user);
  }
}

export default new CalendarFacade();
