/* tslint:disable */
/* eslint-disable */
/**
 * Consolidate App API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    EditAdditionalDataDto2,
    EditAdditionalDataDto2FromJSON,
    EditAdditionalDataDto2ToJSON,
    ObjectDto,
    ObjectDtoFromJSON,
    ObjectDtoToJSON,
    PaginatedListResultOfObjectListItemDto,
    PaginatedListResultOfObjectListItemDtoFromJSON,
    PaginatedListResultOfObjectListItemDtoToJSON,
} from '../models';

export interface ObjectsEditAdditionalDataRequest {
    id: number;
    editAdditionalDataDto2: EditAdditionalDataDto2;
}

export interface ObjectsGetObjectRequest {
    id: number;
}

export interface ObjectsGetObjectsRequest {
    skip?: number;
    take?: number;
    searchString?: string | null;
}

/**
 * 
 */
export class ObjectsApi extends runtime.BaseAPI {

    /**
     */
    async objectsEditAdditionalDataRaw(requestParameters: ObjectsEditAdditionalDataRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling objectsEditAdditionalData.');
        }

        if (requestParameters.editAdditionalDataDto2 === null || requestParameters.editAdditionalDataDto2 === undefined) {
            throw new runtime.RequiredError('editAdditionalDataDto2','Required parameter requestParameters.editAdditionalDataDto2 was null or undefined when calling objectsEditAdditionalData.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("oauth2", []);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/app/v1/Objects/{id}/additionalData`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: EditAdditionalDataDto2ToJSON(requestParameters.editAdditionalDataDto2),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async objectsEditAdditionalData(id: number, editAdditionalDataDto2: EditAdditionalDataDto2): Promise<void> {
        await this.objectsEditAdditionalDataRaw({ id: id, editAdditionalDataDto2: editAdditionalDataDto2 });
    }

    /**
     */
    async objectsGetObjectRaw(requestParameters: ObjectsGetObjectRequest): Promise<runtime.ApiResponse<ObjectDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling objectsGetObject.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("oauth2", []);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/app/v1/Objects/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ObjectDtoFromJSON(jsonValue));
    }

    /**
     */
    async objectsGetObject(id: number): Promise<ObjectDto> {
        const response = await this.objectsGetObjectRaw({ id: id });
        return await response.value();
    }

    /**
     */
    async objectsGetObjectsRaw(requestParameters: ObjectsGetObjectsRequest): Promise<runtime.ApiResponse<PaginatedListResultOfObjectListItemDto>> {
        const queryParameters: any = {};

        if (requestParameters.skip !== undefined) {
            queryParameters['Skip'] = requestParameters.skip;
        }

        if (requestParameters.take !== undefined) {
            queryParameters['Take'] = requestParameters.take;
        }

        if (requestParameters.searchString !== undefined) {
            queryParameters['SearchString'] = requestParameters.searchString;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("oauth2", []);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/app/v1/Objects`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => PaginatedListResultOfObjectListItemDtoFromJSON(jsonValue));
    }

    /**
     */
    async objectsGetObjects(skip?: number, take?: number, searchString?: string | null): Promise<PaginatedListResultOfObjectListItemDto> {
        const response = await this.objectsGetObjectsRaw({ skip: skip, take: take, searchString: searchString });
        return await response.value();
    }

}
