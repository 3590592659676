import { Model } from '@vuex-orm/core';
import {
  ActivityTechnology,
  ActivityType,
  ActivityTypeDocsAllowed,
  ActivityTypeWorkflow,
  AdditionalData,
} from '../../../entitites';

export class ActivityTypeModel extends Model implements ActivityType {
  static entity = 'activityTypes';

  id!: number;
  name!: string;
  technology!: ActivityTechnology;
  icon!: string;
  color!: string;
  allowdocs!: ActivityTypeDocsAllowed;
  allowobjects!: boolean;
  defaultanswer!: boolean;
  defaultanswerall!: boolean;
  defaultforward!: boolean;
  active!: boolean;
  hideOnMobile!: boolean;
  workflows?: ActivityTypeWorkflow[];
  secretgroup!: number;
  additionalData?: AdditionalData;

  static fields() {
    return {
      id: this.number(0),
      name: this.string(''),
      technology: this.string(''),
      icon: this.string(''),
      color: this.string(''),
      allowdocs: this.number(0),
      allowobjects: this.boolean(null),
      defaultanswer: this.boolean(false),
      defaultanswerall: this.boolean(false),
      defaultforward: this.boolean(false),
      active: this.boolean(true),
      hideOnMobile: this.boolean(false),
      workflows: this.attr(null).nullable(),
      secretgroup: this.number(0),
      additionalData: this.attr(null).nullable(),
    };
  }

  static state() {
    return {
      latestFive: [],
    };
  }
}
