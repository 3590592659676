import { NotificationService } from '@consolidate/shared/ui-components';
import { translate } from '@consolidate/shared/util-translations';
import Client from '../../api/Client';
import ConsolidateBaseCommand from '../baseCommands/ConsolidateBaseCommand';

interface Payload {
  signature: string;
}

class SetSignatureCommand extends ConsolidateBaseCommand<Payload, string> {
  constructor(payload: Payload) {
    super('SetSignatureCommand', payload);
  }

  async execute() {
    const { signature } = this.payload;
    try {
      const apisuccess = await new Client().api.setSignature({
        text: signature,
      });

      const success = apisuccess._return === 'TRUE';

      return this.result(success, signature);
    } catch (error) {
      if (this.isRetryable(error)) {
        return this.save();
      }
      return this.notOk();
    }
  }

  protected onError() {
    NotificationService.showError({
      message: translate('COULD_NOT_SAVE_SIGNATURE'),
    });
  }
}

export default SetSignatureCommand;
