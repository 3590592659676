<template>
  <div class="overview-tile rounded-lg" :style="{ 'border-color': color }">
    <div class="icon">
      <cs-icon :icon="icon" :color="color" :animate="isLoading" />
    </div>
    <div class="count" v-text="count" />
    <div class="name" v-text="title" />
  </div>
</template>

<script>
export default {
  name: 'OverviewTile',
  props: {
    title: { type: String, default: '' },
    count: { type: Number },
    icon: { type: String },
    color: { type: String },
    isLoading: { type: Boolean, default: false },
  },
};
</script>

<style scoped>
.overview-tile {
  display: grid;
  padding: 1rem;
  border: 0.125rem solid;
  grid-template-areas:
    'icon count'
    'title title';
}

.name {
  padding-top: 0.75rem;
  font-size: 0.875rem;
  grid-area: title;
}

.icon {
  grid-area: icon;
}

.count {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  text-align: right;
  font-size: 1.375rem;
  line-height: 1.375rem;
  grid-area: count;
}
</style>
