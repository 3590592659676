import {
  GetAktMemberDefault,
  GetAktResponseAppointmentAllOfMembersReservations,
  GetAktResponseBaseObjects,
  RecurrenceInfo,
} from '@consolidate/shared/data-access-legacy-api';
import {
  ActivityTechnology,
  AdditionalData,
  Attachment,
  Attendee,
  Confidentiality,
  DelegationInfo,
  OnlineMeeting,
  ProjectInfo,
  RightsObject,
  WorkflowStep,
} from '../../../entitites';
import { ActivityModel } from './activityModel';

export class ActivityDetailModel extends ActivityModel {
  static entity = 'activityDetails';
  static baseEntity = ActivityModel.entity;

  // detail
  allowobjects?: boolean;
  latestInternalCCs!: string[];
  previousActivity?: number;
  hasFollowingActivities!: number;
  specializedType!: ActivityTechnology;
  attachments!: Attachment[];
  alarmDateTimeUtc?: string;
  project?: ProjectInfo;
  confidentiality!: Confidentiality;
  rights!: RightsObject;
  delegated!: false | DelegationInfo;
  workflow!: WorkflowStep | null;
  createdBy!: string;
  creationDate!: string;
  additionalData?: AdditionalData;
  clients?: GetAktMemberDefault[];
  addressList?: GetAktMemberDefault[];
  objects?: GetAktResponseBaseObjects[];
  // Email
  from?: GetAktMemberDefault;
  to?: GetAktMemberDefault[];
  cc?: GetAktMemberDefault[];
  bcc?: GetAktMemberDefault[];
  // Appointment
  startDateTimeUtc?: string;
  endDateTimeUtc?: string;
  alarmTime?: number;
  isAllDay?: boolean;
  shouldSendReply?: boolean;
  organizer?: string;
  attendeesInternal?: Attendee[];
  attendeesExternal?: Attendee[];
  reservations?: GetAktResponseAppointmentAllOfMembersReservations[];
  recurrence?: RecurrenceInfo;
  onlineMeeting?: OnlineMeeting | null;
  // Document
  contacts?: GetAktMemberDefault[];

  static fields() {
    return {
      ...super.fields(),

      // detail
      allowobjects: this.boolean(null).nullable(),
      latestInternalCCs: this.attr([]),
      previousActivity: this.number(null).nullable(),
      hasFollowingActivities: this.number(0),
      specializedType: this.string(''),
      attachments: this.attr([]),
      alarmDateTimeUtc: this.string(null).nullable(),
      alarmTime: this.number(null).nullable(),
      project: this.attr(null).nullable(),
      confidentiality: this.attr({}),
      rights: this.attr({}),
      delegated: this.attr(false),
      workflow: this.attr(null).nullable(),
      createdBy: this.string(''),
      creationDate: this.attr(null).nullable(),
      additionalData: this.attr(null).nullable(),
      clients: this.attr(null).nullable(),
      addressList: this.attr(null).nullable(),
      objects: this.attr(null).nullable(),
      // Email
      from: this.attr(null).nullable(),
      to: this.attr(null).nullable(),
      cc: this.attr(null).nullable(),
      bcc: this.attr(null).nullable(),
      // Appointment
      startDateTimeUtc: this.string(null).nullable(),
      endDateTimeUtc: this.string(null).nullable(),
      isAllDay: this.boolean(null).nullable(),
      shouldSendReply: this.boolean(null).nullable(),
      organizer: this.string(null).nullable(),
      attendeesInternal: this.attr(null).nullable(),
      attendeesExternal: this.attr(null).nullable(),
      reservations: this.attr(null).nullable(),
      recurrence: this.attr(null).nullable(),
      onlineMeeting: this.attr(null).nullable(),
      // Document
      contacts: this.attr(null).nullable(),
    };
  }

  static state() {
    return {
      ...super.state(),
      loadingDetail: false,
      detailId: null as number | null,
      canEditContact: false,
      loadError: false,
    };
  }
}
