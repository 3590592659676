














import Vue from 'vue';
import ActivityDetailFacade from '../../../domain/application/ActivityDetailFacade';

import ActivityToolbar from './partials/ActivityToolbar.vue';
import LoadingIndicator from '../../loading/LoadingIndicator.vue';
import ActivityDetailTemplate from './ActivityDetailTemplate.vue';
import ActivityEvents from '../../../domain/application/ActivityEvents';

export default Vue.extend({
  name: 'ActivityDetail',
  metaInfo() {
    const act = ActivityDetailFacade.Activity;
    const path = this.$route.path;

    if (path.includes('activity/')) {
      return {
        title: act?.subject ? `${act.subject}` : `...`,
      };
    }
    return {
      title: act?.subject ? `| ${act.subject}` : '| ...',
    };
  },
  components: {
    LoadingIndicator,
    ActivityToolbar,
    ActivityDetailTemplate,
  },
  computed: {
    isLoading() {
      return ActivityDetailFacade.loading;
    },
    loadError() {
      return ActivityDetailFacade.loadError;
    },
    data() {
      return ActivityDetailFacade.Activity;
    },
    id(): number {
      return +(this.$route.params.id ?? this.$route.params.typeId);
    },
  },
  watch: {
    id() {
      this.fetchData();
    },
  },
  created(): void {
    this.fetchData();
    ActivityEvents.on('activityChanged', this.activityChanged);
  },
  destroyed(): void {
    ActivityEvents.off('activityChanged', this.activityChanged);
  },
  methods: {
    fetchData() {
      if (!this.id) return;

      const skipReload = !!this.$route.meta?.skipReload;
      if (skipReload && this.id === ActivityDetailFacade.id) return;

      ActivityDetailFacade.loadActivity(this.id);
    },
    activityChanged(id: number) {
      if (id !== this.id) return;

      ActivityDetailFacade.loadActivity(this.id);
    },
  },
});
