











































































































































































































































































import {
  GetAktMemberDefault,
  GetAktResponseBaseObjects,
} from '@consolidate/shared/data-access-legacy-api';
import { AuthService } from '@consolidate/shared/util-auth';
import { translate } from '@consolidate/shared/util-translations';
import Vue, { PropType } from 'vue';
import {
  ACTIVITY_PRIORITY_LIST,
  ACTIVITY_TODO_TYPE_DONE,
  ACTIVITY_TODO_TYPE_LIST,
  Activity,
  ActivityTechnology,
  ActivityTodoType,
  ActivityType,
  AdditionalDataEntry,
  Attachment,
  ContactOrAddress,
} from '../../../../domain';
import ActivityService from '../../../../domain/application/ActivityService';
import ConfidentialitiesService from '../../../../domain/application/ConfidentialitiesService';
import EmployeeService from '../../../../domain/application/EmployeeService';
import SettingsFacade from '../../../../domain/application/SettingsFacade';
import { AddActivityCommandPayload } from '../../../../logic/commands/activity/AddActivityCommand';
import ModalService, {
  ActivityCreateMode,
} from '../../../../logic/services/ModalService';
import {
  FileWithBlob,
  ensureFileSize,
  fileToBase64,
} from '../../../../utils/attachment';
import { isAktMemberDefault } from '../../../../utils/checkType';
import { getContactSubtitle } from '../../../../utils/contact';
import { ensureMinApiVersion } from '../../../../utils/versioning';
import FileInput from '../../../input/FileInput.vue';
import AttachmentListItem from '../../../list/AttachmentListItem.vue';
import ObjectAutoComplete from '../../../object/ObjectAutoComplete.vue';
import ProjectAutoComplete from '../../../project/ProjectAutoComplete.vue';
import ContactGroup from '../../shared/ContactGroup.vue';

export default Vue.extend({
  components: {
    ProjectAutoComplete,
    FileInput,
    AttachmentListItem,
    ContactGroup,
    ObjectAutoComplete,
  },
  props: {
    type: { type: String as PropType<ActivityTechnology>, required: true },
    mode: {
      type: String as PropType<ActivityCreateMode>,
      required: true,
    },
    actType: { type: Object as PropType<ActivityType>, required: true },
    previousActivity: { type: Object as PropType<Activity>, required: false },
    headerKey: { type: String },
    documentsCaption: { type: String, default: () => translate('DOCUMENTS') },
    saveCaption: { type: String, default: () => translate('SAVE') },
    bodyCaption: { type: String, default: () => translate('NOTES') },
    subjectCaption: { type: String, default: () => translate('SUBJECT') },
    prefilledValues: { type: Object as PropType<AddActivityCommandPayload> },
  },
  data: () => ({
    isFormValid: false,
    collapsed: false,
    loading: false,
    tab: 0,

    data: {} as AddActivityCommandPayload,

    selectedAttachments: [] as Attachment[],
    selectedObjects: [] as GetAktResponseBaseObjects[],

    initialTodoType: undefined as ActivityTodoType | undefined,
    todoTypeList: ACTIVITY_TODO_TYPE_LIST,
    priorityList: ACTIVITY_PRIORITY_LIST,

    files: [] as FileWithBlob[],
  }),
  computed: {
    objectManagerText(): string {
      switch (SettingsFacade.settings.objectManagerStrategy) {
        case 1:
          return translate('OBJECT_MANAGER_TEXT1');
        case 2:
          return translate('OBJECT_MANAGER_TEXT2');
        default:
          return '';
      }
    },
    objectLocationText(): string {
      switch (SettingsFacade.settings.objectLocationStrategy) {
        case 1:
          return translate('OBJECT_LOCATION_TEXT1');
        case 2:
          return translate('OBJECT_LOCATION_TEXT2');
        default:
          return '';
      }
    },
    confidentialities() {
      return ConfidentialitiesService.items;
    },
    isForwardReplyMode(): boolean {
      return this.mode !== ActivityCreateMode.Create;
    },
    attachmentCount() {
      return (
        (this.data.attachments?.length ?? 0) +
        this.files.length +
        this.selectedAttachments.length
      );
    },
    invalidAdditionalData(): Record<string, boolean> {
      return (
        this.editableAdditionalData?.reduce((acc, definition) => {
          const fields = this.data.additionalData[definition.key];

          acc[definition.key] = definition.fields.some((fieldDefinition) => {
            if (!fieldDefinition.required) return false;

            const value = fields?.find(
              (field) => field.key === fieldDefinition.key
            )?.value;

            return value === null || value === undefined;
          });

          return acc;
        }, {} as Record<string, boolean>) ?? {}
      );
    },
    editableAdditionalData() {
      return this.data.actType.additionalData?.filter((form) =>
        form.fields.some((field) => field.editable)
      );
    },
    currentActType() {
      return this.data.actType;
    },
  },
  async created() {
    this.data = {
      technology: this.type,
      responsible: AuthService.getUser()?.uid ?? '',
      todoType: ACTIVITY_TODO_TYPE_LIST[0],
      subject: '',
      actType: this.actType,
      priority: ACTIVITY_PRIORITY_LIST[1],
      dueDateTimeUtc:
        this.type === ActivityTechnology.Document
          ? new Date().toISODateString()
          : undefined,

      previousActivityId: this.previousActivity?.id,
      project: this.previousActivity?.project,

      // these initializations are required for vue to perform correct change detection (https://v3.vuejs.org/guide/change-detection.html)
      clients: this.previousActivity?.clients ?? [],
      internalCCs: [],
      emailExternalCCs: [],
      emailReceivers: [],
      body: '',
      appointmentInternalParticipants: [],
      appointmentExternalParticipants: [],
      documentContacts: [],
      appointmentIsBooked: undefined,
      recurrence: undefined,
      appointmentEndDateTimeUtc: undefined,
      appointmentStartDateTimeUtc: undefined,
      additionalData: {},
      mailFrom: null,
    };
    const currentUserId = AuthService.getUser()?.uid;

    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    this.data.confidentiality = ConfidentialitiesService.find(
      this.data.actType.secretgroup
    )!;

    if (this.previousActivity && this.isForwardReplyMode) {
      this.data.sendHistory = true;

      switch (this.mode) {
        case ActivityCreateMode.Reply:
        case ActivityCreateMode.ReplyAll:
          this.data.subject = `AW: ${this.previousActivity.subject}`;
          break;
        case ActivityCreateMode.Forward:
          this.data.subject = `FWD: ${this.previousActivity.subject}`;
          this.selectedAttachments = this.previousActivity.attachments ?? [];
          break;
      }

      if (this.previousActivity.specializedType === ActivityTechnology.Email) {
        const receivers: GetAktMemberDefault[] = [];
        const ccs: GetAktMemberDefault[] = [];

        if (this.mode === ActivityCreateMode.Reply) {
          receivers.push(this.previousActivity.from);
        } else if (this.mode === ActivityCreateMode.ReplyAll) {
          if (this.previousActivity.from)
            receivers.push(this.previousActivity.from);

          if (this.previousActivity.to)
            receivers.push(...this.previousActivity.to);

          if (this.previousActivity.cc) ccs.push(...this.previousActivity.cc);
          if (this.previousActivity.bcc) ccs.push(...this.previousActivity.bcc);
        }

        this.data.emailReceivers = receivers
          .filter((x) => x.uid !== currentUserId)
          .map((x) => x.mailaddress)
          .filter((x) => !!x) as string[];

        this.data.emailExternalCCs = ccs
          .filter((x) => x.uid !== currentUserId)
          .map((x) => x.mailaddress)
          .filter((x) => !!x) as string[];
      }
    }

    if (this.prefilledValues) {
      this.data = { ...this.data, ...this.prefilledValues };
    }

    if (this.type === ActivityTechnology.Email) {
      const signature = SettingsFacade.settings.signature;

      // webservice before 5.2.30 did not have filled signature, fall back to text instead
      if (signature.text && !signature.filledsignature) {
        this.data.body = this.data.body + signature.text;
      } else if (signature.filledsignature) {
        this.data.body = this.data.body + signature.filledsignature;
      }

      if (signature.filleddisclaimer) {
        this.data.body = this.data.body + signature.filleddisclaimer;
      }
    } else if (this.type === ActivityTechnology.Appointment) {
      const me = EmployeeService.find(AuthService.getUser()?.uid);
      if (me) {
        this.data.appointmentInternalParticipants = [me];
        this.data.appointmentIsBooked = true;
      }
    }

    // folgeaktivität
    if (this.mode === ActivityCreateMode.Create && this.previousActivity) {
      this.data.subject = this.previousActivity.subject;

      if (
        this.previousActivity.specializedType === ActivityTechnology.Appointment
      ) {
        const attendeesExternal = this.previousActivity.attendeesExternal;

        switch (this.type) {
          case ActivityTechnology.Appointment:
            this.data.appointmentInternalParticipants =
              this.previousActivity.attendeesInternal;
            this.data.appointmentExternalParticipants = attendeesExternal;
            break;
          case ActivityTechnology.Document:
            this.data.documentContacts = attendeesExternal;
            break;
          case ActivityTechnology.Email:
            this.data.emailReceivers = attendeesExternal.filter(
              (x) => x.cid || x.email
            );
            break;
        }
      }

      if (
        this.previousActivity.specializedType === ActivityTechnology.Document
      ) {
        const contacts = this.previousActivity.contacts;

        switch (this.type) {
          case ActivityTechnology.Document:
            this.data.documentContacts = contacts;
            break;
          case ActivityTechnology.Appointment:
            this.data.appointmentExternalParticipants = contacts;
            break;
          case ActivityTechnology.Email:
            this.data.emailReceivers = contacts;
            break;
        }
      }

      if (this.previousActivity.specializedType === ActivityTechnology.Email) {
        const emailReceivers = [
          ...this.previousActivity.to,
          ...this.previousActivity.cc,
          this.previousActivity.from,
        ].filter((x) => x.uid !== currentUserId);

        switch (this.type) {
          case ActivityTechnology.Email:
            this.data.emailReceivers = emailReceivers;
            break;
          case ActivityTechnology.Appointment:
            this.data.appointmentExternalParticipants = emailReceivers;
            break;
          case ActivityTechnology.Document:
            this.data.documentContacts = emailReceivers;
            break;
        }
      }

      if (this.previousActivity) {
        if (!this.previousActivity.confidentiality) {
          // user has no access to confidentiality group
          await ModalService.choiceAsync({
            title: this.$t('CONFIDENTIALITY_RESET'),
            subtitle: this.$t('CONFIDENTIALITY_RESET_TEXT'),
          });
        } else if (
          this.previousActivity.confidentiality.id !==
          this.data.confidentiality?.id
        ) {
          if (
            await ModalService.choiceAsync({
              title: this.$t('CONFIDENTIALITY_KEEP'),
              subtitle: this.$t('CONFIDENTIALITY_KEEP_TEXT'),
              canCancel: true,
              labels: {
                yes: this.$t('YES'),
                no: this.$t('NO'),
              },
            })
          ) {
            this.data.confidentiality = this.previousActivity.confidentiality;
          }
        }
      }
    }
  },
  watch: {
    files() {
      if (!ensureFileSize(this.files, this.data.attachments)) {
        this.files.pop();
      }
    },
    currentActType(newValue, oldValue) {
      if (newValue != oldValue) {
        this.data.additionalData =
          this.data.actType.additionalData
            ?.filter((additionalData) =>
              additionalData.fields.some((field) => field.editable)
            )
            .reduce((acc, form) => {
              acc[form.key] = form.fields;
              return acc;
            }, {} as Record<string, AdditionalDataEntry[]>) ?? {};
      }
    },
  },
  methods: {
    removeAttachment(index: number) {
      this.data.attachments?.splice(index, 1);
    },
    removeSelectedAttachment(index: number) {
      this.selectedAttachments.splice(index, 1);
    },
    expand() {
      this.collapsed = false;
    },
    closeModal() {
      this.$emit('close', false);
    },
    chooseActType() {
      ModalService.activityChooseActType({
        subject: this.data.subject,
        currentType: this.data.actType,
        callback: {
          onYes: async (actType) => {
            this.data.actType = actType;
          },
        },
      });
    },
    chooseResponsible() {
      ModalService.activityDelegate({
        context: 'create',
        subject: this.data.subject,
        callback: {
          onYes: async ({ userName, comment }) => {
            this.data.responsible = userName;
            this.data.transferComment = comment;
          },
        },
      });
    },
    getUserDisplay(username: string): string {
      const user = EmployeeService.find(username);

      if (user) {
        return `${user.lastName} ${user.firstName} (${user.userName})`;
      }

      return username;
    },
    chooseICC() {
      ModalService.activitySendCC({
        isNew: true,
        latestInternalCCs: this.data.internalCCs ?? [],
        initialComment: this.data.internalCCsComment,
        callback: {
          onYes: async ({ receivers, comment }) => {
            this.data.internalCCs = receivers;
            this.data.internalCCsComment = comment;
          },
        },
      });
    },
    addClient() {
      if (ensureMinApiVersion('5.2017')) {
        ModalService.addContact(
          {
            onYes: async ({ contact }) => {
              if (!this.data.clients) {
                this.data.clients = [];
              }

              if (Array.isArray(contact)) {
                this.data.clients = [...this.data.clients, ...contact];
              } else {
                this.data.clients.push(contact);
              }
              return true;
            },
          },
          this.$t('ADD_CLIENT'),
          true
        );
      }
    },
    getSubtitle(item: ContactOrAddress) {
      return getContactSubtitle(item);
    },
    getMemberIcon(item: ContactOrAddress | GetAktMemberDefault) {
      if (isAktMemberDefault(item)) {
        return item.cid ? 'GenderNeutral' : 'Address';
      }

      if (item.type === 'CONTACT') return 'GenderNeutral';
      if (item.type === 'ADDRESS') return 'Address';
    },
    async toggleAktCompleted() {
      if (this.data.todoType?.id !== ACTIVITY_TODO_TYPE_DONE.id) {
        this.initialTodoType = this.data.todoType;
        this.data.todoType = ACTIVITY_TODO_TYPE_DONE;
      } else {
        this.data.todoType = this.initialTodoType ?? ACTIVITY_TODO_TYPE_LIST[0];
      }
    },
    validate() {
      (this.$refs.submitFormRef as any).validate();
    },
    async openHistoryModal() {
      if (!this.previousActivity) return;

      const body = await ActivityService.getActivityComment(
        this.previousActivity.id
      );

      ModalService.activityHistory({
        subject: this.previousActivity.subject,
        actMailText: body ?? '',
      });
    },
    async submitForm() {
      this.validate();
      if (!this.isFormValid) return;

      this.loading = true;

      const blobs = this.files.map((f) => fileToBase64(f.blob));
      const encodedStuff = await Promise.all(blobs);

      if (this.data.actType.allowdocs) {
        this.data.selectedAttachments = this.selectedAttachments.map(
          (x) => x.id
        );

        this.data.attachments = [
          ...(this.data.attachments ?? []),
          ...this.files.map((f, index) => {
            return {
              name: f.name,
              base64: encodedStuff[index],
            };
          }),
        ];
      } else {
        this.data.attachments = undefined;
      }

      if (this.data.actType.allowobjects) {
        this.data.objects = this.selectedObjects.map((x) => x.id);
      } else {
        this.data.objects = undefined;
      }

      const success = await ActivityService.createActivity(this.data);

      if (success) {
        this.$emit('close', false);
      }
      this.loading = false;
    },
    edit(formKey: string) {
      const fields = this.data.additionalData[formKey];
      if (fields) {
        ModalService.editAdditionalData({
          fields: fields,
          callback: (fields) => {
            // use Vue.$set because change detection cant see entries being added to a record
            this.$set(this.data.additionalData, formKey, fields);
            return Promise.resolve();
          },
        });
      }
    },
  },
});
