/* eslint-disable no-restricted-syntax */
<template>
  <div class="hours-to-work">
    <h2>Worked hours</h2>
    <p>
      When remainder is negative, it means you need to work harder! Positive means => 🍺
      Example:
      <pre>10:24-12:20, 13:05-19:30</pre>
    </p>
    <cs-input
      v-model="input"
      @blur="save"
    />
    <div class="">
      Percentage: 
      <cs-button class="ml-2" @click="percentage = 100">100%</cs-button>
      <cs-button class="ml-2" @click="percentage = 80">80%</cs-button>
      <cs-button class="ml-2" @click="percentage = 60">60%</cs-button>
      
      <v-slider
        v-model="percentage"
        step="5"
        thumb-label
      />
    </div>
    Result: {{ result | getYoutubeLikeToDisplay }} <br>
    Remainder:{{ remainder | getYoutubeLikeToDisplay }}
  </div>
</template>

<script>

export default {
  filters: {
    getYoutubeLikeToDisplay(millisec) {
      let negative = false;
      if (millisec < 0) {
        negative = true;
        // eslint-disable-next-line no-param-reassign
        millisec *= -1;
      }
      let seconds = (millisec / 1000).toFixed(0);
      let minutes = Math.floor(seconds / 60);
      let hours = '';
      if (minutes > 59) {
        hours = Math.floor(minutes / 60);
        hours = (hours >= 10) ? hours : `0${hours}`;
        minutes -= (hours * 60);
        minutes = (minutes >= 10) ? minutes : `0${minutes}`;
      }

      seconds = Math.floor(seconds % 60);
      seconds = (seconds >= 10) ? seconds : `0${seconds}`;
      if (hours !== '') {
        return `${negative ? '-' : ''}${hours}:${minutes}:${seconds}`;
      }
      return `${negative ? '-' : ''}${minutes}:${seconds}`;
    },
  },
  data() {
    return {
      input: '',
      totalWorkingMinutes: 7 * 60 + 42,
      percentage: 100
    };
  },
  computed: {
    result() {
      let totalTime = 0;
      const timePairs = this.input.split(',').filter(Boolean);
      const intermediateResults = timePairs.map((r) => r.split('-'));
      // eslint-disable-next-line no-restricted-syntax
      for (const pair of intermediateResults) {
        if (pair.length !== 2) break;
        const from = new Date(`2000-01-01T${pair[0].trim()}`);
        const to = new Date(`2000-01-01T${pair[1].trim()}`);
        const calculatedTotal = to - from;
        const penaltyCount = Math.floor(calculatedTotal / 10800000);
        totalTime += calculatedTotal;

        if (penaltyCount > 0) {
          totalTime -= (penaltyCount * 360000);
        }
      }
      return totalTime;
    },
    remainder() {
      return this.result - (this.totalWorkingMinutes * this.percentage / 100 * 60000);
    },
  },
  methods: {
    save() {
      this.input = this.input.replace(/\(00:00\)/gi, ', ');
      this.input = this.input.replace('__:__', new Date().toLocaleTimeString('de', { hour: '2-digit', minute: '2-digit', timeZone: 'Europe/Vienna' }));
    },
  },

};
</script>
