import ActivityEvents from '../../../domain/application/ActivityEvents';
import Client from '../../api/Client';
import ConsolidateBaseCommand from '../baseCommands/ConsolidateBaseCommand';

interface Payload {
  actId: number;
  exceptions: Date[];
  deleteExceptions: boolean;
}

class RemoveRecurrencesFromChainCommand extends ConsolidateBaseCommand<
  Payload,
  number[]
> {
  constructor(payload: Payload) {
    super('RemoveRecurrencesFromChainCommand ', payload);
  }

  async execute() {
    try {
      const result =
        await new Client().activities.activitiesRemoveRecurrencesFromChain(
          this.payload.actId,
          {
            exceptions: this.payload.exceptions.map((x) =>
              x.toISODateString()
            ) as any, // backend expects only dates and the generated code serializes as datetime
            deleteExceptions: this.payload.deleteExceptions,
          }
        );

      return this.ok(result.activityIds);
    } catch (error) {
      console.error(error);
      if (this.isRetryable(error)) {
        return this.save();
      }
      return this.notOk();
    }
  }

  protected async onSuccess() {
    ActivityEvents.emit('appointmentDateChanged', this.payload.actId);
    ActivityEvents.emit('activityChanged', this.payload.actId);
  }
}

export default RemoveRecurrencesFromChainCommand;
