import { AuthService } from '@consolidate/shared/util-auth';
import * as signalR from '@microsoft/signalr';
import Vue from 'vue';
import { Client } from '../api/Client';
import { BoardDto, BoardListDto } from '../api/gen';

interface Position {
  column: number;
  index: number;
}

class BoardsFacade {
  private activityHandler?: (id: number) => void;
  private connection?: signalR.HubConnection;
  private state = Vue.observable({
    boards: [] as BoardListDto[],
    board: null as BoardDto | null,
    lastUpdate: null as Date | null,
  });

  public get boards(): BoardListDto[] {
    return this.state.boards;
  }

  public get board(): BoardDto | null {
    return this.state.board;
  }

  public get lastUpdate(): Date | null {
    return this.state.lastUpdate;
  }

  public async loadBoards(): Promise<void> {
    this.state.boards = await new Client().api.kanbanGetBoards();
  }

  public async load(id: number): Promise<void> {
    if (this.board?.id != id) this.state.board = null;
    this.connectForUpdates();

    const board = await new Client().api.kanbanGetBoard(id);
    this.state.board = Vue.observable(board);
    this.state.lastUpdate = new Date();
  }

  private connectForUpdates() {
    if (this.connection) return;

    const url = AuthService.getWebServiceUrl();
    if (!url) throw new Error('url not set');

    this.connection = new signalR.HubConnectionBuilder()
      .withUrl(url + '/kanban', {
        accessTokenFactory: () =>
          AuthService.getAccessToken()?.split(' ')[1] ?? '',
      })
      .withAutomaticReconnect()
      .build();

    this.connection.start().then(() => {
      this.connection?.on('boardChanged', this.boardUpdated.bind(this));
    });
  }

  private boardUpdated(id: number) {
    if (id === this.board?.id) {
      this.load(id);
    }
  }

  public async move(
    oldPosition: Position,
    newPosition: Position
  ): Promise<void> {
    if (!this.board) return;

    // remove from oldPosition
    const card = this.getColumn(oldPosition.column).cards.splice(
      oldPosition.index,
      1
    )[0];

    // insert into newPosition
    const column = this.getColumn(newPosition.column);
    column.cards.splice(newPosition.index, 0, card);

    await new Client().api.kanbanMoveCard({
      boardId: this.board.id,
      cardId: card.id,
      destinationColumn: column.id,
      afterCard: newPosition.index
        ? column.cards[newPosition.index - 1].id
        : null,
    });
  }

  public async addCard(activityId: number) {
    if (!this.board) return;

    await new Client().api.kanbanAddCard({
      boardId: this.board.id,
      activityId: activityId,
    });
  }

  public async removeCard(id: number) {
    if (!this.board) return;

    await new Client().api.kanbanRemoveCard({
      boardId: this.board.id,
      activityId: id,
    });
  }

  public async addBoard(title: string) {
    const result = await new Client().api.kanbanCreateBoard({
      title: title,
    });

    this.boards.push({ id: result.id, title });
  }

  public async deleteBoard(id: number) {
    this.state.boards = this.boards.filter((x) => x.id != id);
    await new Client().api.kanbanDeleteBoard(id);
  }

  public async addColumn(title: string) {
    if (!this.board) return;

    await new Client().api.kanbanAddColumn({
      boardId: this.board.id,
      title: title,
    });
  }

  public async removeColumn(id: number) {
    if (!this.board) return;

    await new Client().api.kanbanRemoveColumn({
      boardId: this.board.id,
      columnId: id,
    });
  }

  public async editColumn(id: number, title: string) {
    if (!this.board) return;

    await new Client().api.kanbanEditColumn({
      boardId: this.board.id,
      columnId: id,
      title: title,
    });
  }

  public openActivity(id: number) {
    if (this.activityHandler) {
      this.activityHandler(id);
    }
  }

  public setActivityHandler(handler: (id: number) => void) {
    this.activityHandler = handler;
  }

  private getColumn(id: number) {
    const col = this.board?.columns.find((x) => x.id === id);
    if (!col) throw new Error('column not found');

    return col;
  }
}

export default new BoardsFacade();
