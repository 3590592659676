import {
  GetApiInfoResponse,
  GetSettingsResponse,
  GetSignatureResponse,
} from '@consolidate/shared/data-access-legacy-api';
import { vuetify } from '@consolidate/shared/ui-components';
import * as Sentry from '@sentry/capacitor';
import { Module } from 'vuex';
import { Settings, Theme } from '../../../entitites';
import store from '../store';

const mediaQueryList = window.matchMedia('(prefers-color-scheme: dark)');
if (mediaQueryList.addEventListener) {
  mediaQueryList.addEventListener('change', async () => {
    store.commit('setTheme', store.state.settings.theme);
  });
}

export const settingsModule: Module<Settings, any> = {
  state: {
    EMAIL_AUTHENTICITY: true,
    theme: 'auto',
    apiStatus: '',
    apiVersion: '',
    elasticIndexVersion: null,
    signature: {
      text: '',
    },
    objectManagerStrategy: 0,
    objectLocationStrategy: 0,
    mailFromAddresses: null,
  },

  mutations: {
    setSignature(state, payload: GetSignatureResponse) {
      state.signature = payload;
    },

    setTheme(state, payload: Theme) {
      const prefersDarkScheme = mediaQueryList.matches ?? false;

      vuetify.framework.theme.dark =
        payload === 'dark' || (payload === 'auto' && prefersDarkScheme);
      state.theme = payload;
    },

    setSettings(state, payload: GetSettingsResponse) {
      state.EMAIL_AUTHENTICITY =
        payload.allowcommentincomingmail === false &&
        payload.allowcommentoutgoingmail === false;

      state.objectManagerStrategy = payload.activityobjectmanagerstrategy ?? 0;
      state.objectLocationStrategy =
        payload.activityobjectlocationstrategy ?? 0;

      state.elasticIndexVersion = payload.elasticindexversion ?? null;

      if (payload.mailaddress) {
        state.mailFromAddresses = {
          primary: payload.mailaddress,
          aliases: payload.mailalias ?? [],
        };
      }
    },

    setApiInfo(state, payload: GetApiInfoResponse) {
      state.apiStatus = payload.status;
      state.apiVersion = payload.version;

      Sentry.setTag('webservice.version', payload.version);
    },
  },
};
