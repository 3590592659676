import ActivityService from '../../../domain/application/ActivityService';
import Client from '../../api/Client';
import ConsolidateBaseCommand from '../baseCommands/ConsolidateBaseCommand';

interface Payload {
  actId: number;
  comment: string;
}

export default class AddCommentToActivityCommand extends ConsolidateBaseCommand<Payload> {
  constructor(payload: Payload) {
    super('AddCommentToActivityCommand', payload);
  }

  async execute() {
    try {
      const { actId, comment } = this.payload;
      const data = await new Client().api.commentActivity({
        aktid: actId,
        comment,
      });
      const newComment = data.replace(/\\"/g, '"') ?? '';

      ActivityService.storeActivityComment(actId, newComment);

      return this.ok();
    } catch (error) {
      if (this.isRetryable(error)) {
        return this.save();
      }
      return this.notOk();
    }
  }
}
