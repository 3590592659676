var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('activity-form-base',_vm._g(_vm._b({ref:"base",attrs:{"documentsCaption":_vm.$t('ATTACHMENTS'),"saveCaption":_vm.$t('SEND_EMAIL'),"bodyCaption":_vm.$t('MAILTEXT')},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var data = ref.data;
return [_c('v-row',{attrs:{"dense":"","align":"center"}},[(_vm.mailFromAddresses.length > 0)?_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('cs-select',{attrs:{"label":_vm.$t('MAIL_FROM'),"items":_vm.mailFromAddresses,"item-text":"email","item-value":"value"},model:{value:(data.mailFrom),callback:function ($$v) {_vm.$set(data, "mailFrom", $$v)},expression:"data.mailFrom"}})],1):_vm._e(),_c('v-col',[_c('contact-auto-complete',{ref:"receivers",attrs:{"label":_vm.$t('TO'),"multiple":true,"allowCustomEmail":true,"allowWithoutEmail":false,"item-subtitle":"email","rules":[_vm.validateEmails]},model:{value:(data.emailReceivers),callback:function ($$v) {_vm.$set(data, "emailReceivers", $$v)},expression:"data.emailReceivers"}})],1),(!_vm.ccVisible)?_c('v-col',{attrs:{"cols":"auto"}},[_c('cs-button',{attrs:{"color":"primary","outlined":""},on:{"click":function($event){_vm.ccVisible = true}}},[_vm._v(_vm._s(_vm.$t('SHOW_CC')))])],1):_vm._e()],1),(_vm.ccVisible)?_c('v-row',{attrs:{"dense":""}},[_c('v-col',[_c('contact-auto-complete',{ref:"externalCC",attrs:{"label":_vm.$t('CC'),"multiple":true,"allowCustomEmail":true,"allowWithoutEmail":false,"item-subtitle":"email"},model:{value:(data.emailExternalCCs),callback:function ($$v) {_vm.$set(data, "emailExternalCCs", $$v)},expression:"data.emailExternalCCs"}})],1)],1):_vm._e()]}},{key:"contacts",fn:function(ref){
var data = ref.data;
return [_c('contact-group',{attrs:{"label":_vm.$t('RECIPIENT'),"display":_vm.getContactDisplay,"display-subtitle":"email"},on:{"add":_vm.focusReceivers},scopedSlots:_vm._u([{key:"icon",fn:function(ref){
var item = ref.item;
return [_c('cs-icon',{attrs:{"icon":_vm.getMemberIcon(item)}})]}}],null,true),model:{value:(data.emailReceivers),callback:function ($$v) {_vm.$set(data, "emailReceivers", $$v)},expression:"data.emailReceivers"}}),_c('contact-group',{staticClass:"mt-5",attrs:{"label":_vm.$t('CC'),"display":_vm.getContactDisplay,"display-subtitle":"email"},on:{"add":_vm.focusCC},scopedSlots:_vm._u([{key:"icon",fn:function(ref){
var item = ref.item;
return [_c('cs-icon',{attrs:{"icon":_vm.getMemberIcon(item)}})]}}],null,true),model:{value:(data.emailExternalCCs),callback:function ($$v) {_vm.$set(data, "emailExternalCCs", $$v)},expression:"data.emailExternalCCs"}})]}}])},'activity-form-base',_vm.$attrs,false),_vm.$listeners))}
var staticRenderFns = []

export { render, staticRenderFns }