import { NotificationService } from '@consolidate/shared/ui-components';
import { translate } from '@consolidate/shared/util-translations';
import Client from '../../api/Client';
import ConsolidateBaseCommand from '../baseCommands/ConsolidateBaseCommand';

interface Payload {
  aktids: number[];
  folderid: number;
}

class RemoveActivitiesFromFolderCommand extends ConsolidateBaseCommand<Payload> {
  constructor(payload: Payload) {
    super('RemoveActivityFromFolderCommand', payload);
  }

  async execute() {
    try {
      const { aktids, folderid } = this.payload;

      const aktRemovedFromFolder = await new Client().api.removeAktsFromFolder({
        aktids: this.toConsolidateCSV(aktids),
        folderid: folderid,
      });

      return this.result(!!aktRemovedFromFolder);
    } catch (error) {
      if (this.isRetryable(error)) {
        return this.save();
      }
      return this.notOk();
    }
  }

  protected onError() {
    NotificationService.showError({
      message: translate('ACT_REMOVE_FROM_FOLDER_ERROR'),
    });
  }
}

export default RemoveActivitiesFromFolderCommand;
