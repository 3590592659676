import AddToFavoritesBaseCommand from '../baseCommands/AddToFavoritesBaseCommand';

class AddAddressToFavoritesCommand extends AddToFavoritesBaseCommand {
  constructor({ id }: { id: string }) {
    super('AddAddressToFavoritesCommand', { type: 2, id });
  }

  async execute() {
    return super.executeInternal();
  }
}
export default AddAddressToFavoritesCommand;
