


















import Vue from 'vue';
import { translate } from '@consolidate/shared/util-translations';
import SearchBar from '../SearchBar.vue';

export default Vue.extend({
  components: {
    SearchBar,
  },
  props: {
    value: { type: String },
    open: { type: Boolean },
    icon: { type: String, default: 'Filter' },
    label: { type: String, default: translate('Filter') },
    count: { type: [String, Number] },
  },
  methods: {
    setFilter(val: string) {
      this.$emit('input', val);
    },
  },
  watch: {
    open() {
      if (this.open) {
        this.$nextTick().then(() => {
          if (this.$refs.input) (this.$refs.input as any).focus();
        });
      }
    },
  },
});
