import RemoveFavoritesBaseCommand from '../baseCommands/RemoveFavoriteBaseCommand';

class RemoveAddressFromFavoritesCommand extends RemoveFavoritesBaseCommand {
  constructor({ id }: { id: string }) {
    super('RemoveAddressFromFavoritesCommand', { type: 2, id });
  }

  async execute() {
    return super.executeInternal();
  }
}
export default RemoveAddressFromFavoritesCommand;
