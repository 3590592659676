import Vue from 'vue';
import { ListFacade } from '../domain/application/ListFacade';
import { ListModel } from '../domain/infrastructure/+state/models';

export function filterMixin<
  T extends ListModel,
  TListItem extends Record<string, any>
>(facade: ListFacade<T, TListItem>) {
  return Vue.extend({
    data: () => ({
      filterOpen: false,
    }),
    computed: {
      filterString: {
        get() {
          return facade.filterString;
        },
        set(val: string) {
          return facade.setFilter(val);
        },
      },
      filterActive(): boolean {
        return facade.filterActive;
      },
    },
  });
}
