import Client from '../../logic/api/Client';
import { ObjectListItemDto } from '../../logic/api/gen';
import { minApiVersion } from '../../utils/versioning';
import { ObjectModel } from '../infrastructure/+state/models';
import store from '../infrastructure/+state/store';
import { ServerFilteringListFacade } from './ServerFilteringListFacade';

class ObjectsFacade extends ServerFilteringListFacade<
  ObjectModel,
  ObjectListItemDto
> {
  protected entity = ObjectModel.entity;

  protected map(item: ObjectModel): ObjectListItemDto {
    return {
      ...item,
    };
  }

  @minApiVersion('5.2012')
  protected async getItems(
    offset = 0
  ): Promise<Record<string, unknown>[] | undefined> {
    const response = await this.findItems(this.filterString, offset);

    ObjectModel.commit((state) => {
      state.hasMore = response.hasMoreItems;
      state.total = response.totalItems;
    });

    return response.items.map((x) => ({
      ...ObjectModel.find(x.id),
      ...x,
    }));
  }

  protected filterProperties: (keyof ObjectListItemDto)[] = ['description'];

  public get hasMore(): boolean {
    return store.state.entities[ObjectModel.entity].hasMore;
  }

  public get total(): number {
    return store.state.entities[ObjectModel.entity].total;
  }

  @minApiVersion('5.2012')
  public async findItems(searchString: string, skip = 0, take = 30) {
    const response = await new Client().objects.objectsGetObjects(
      skip,
      take,
      searchString
    );

    return response;
  }

  public async loadMore() {
    if (!this.hasMore) return;

    ObjectModel.commit((state) => {
      state.loading = true;
    });

    try {
      const items = await this.getItems(this.items.length);

      if (items) {
        ObjectModel.insertOrUpdate({
          data: items,
        });
      }
    } catch {
      // when offline, dont load more items
    }

    ObjectModel.commit((state) => {
      state.loading = false;
    });
  }
}

export default new ObjectsFacade();
