import { NotificationService } from '@consolidate/shared/ui-components';
import { translate } from '@consolidate/shared/util-translations';
import { AdditionalDataEntry } from '../../../domain';
import ActivityDetailFacade from '../../../domain/application/ActivityDetailFacade';
import { preprocessFields } from '../../../utils/additionalData';
import Client from '../../api/Client';
import ConsolidateBaseCommand from '../baseCommands/ConsolidateBaseCommand';

interface Payload {
  activityId: number;
  form: string;
  fields: AdditionalDataEntry[];
}

class EditActivityAdditionalDataCommand extends ConsolidateBaseCommand<Payload> {
  constructor(payload: Payload) {
    super('EditActivityAdditionalDataCommand', payload);
  }

  async execute() {
    try {
      const { activityId, form, fields } = this.payload;
      await new Client().activities.activitiesEditAdditionalData(activityId, {
        form: form,
        fields: preprocessFields(fields),
      });
      return this.ok();
    } catch (error) {
      if (this.isRetryable(error)) {
        return this.save();
      }
      return this.notOk();
    }
  }

  protected onError() {
    NotificationService.showError({
      message: translate('ERROR_OCCURRED'),
    });

    ActivityDetailFacade.loadActivity(this.payload.activityId);
  }
}

export default EditActivityAdditionalDataCommand;
