




















































































































































































































import { translate } from '@consolidate/shared/util-translations';
import Vue, { PropType } from 'vue';
import { RRule, Frequency, Options, ByWeekday } from 'rrule';
import { NotificationService } from '@consolidate/shared/ui-components';
import WeekdaySelector from '../../shared/WeekdaySelector.vue';

export default Vue.extend({
  name: 'ModalRecurringAppointment',
  components: {
    WeekdaySelector,
  },
  props: {
    callback: {
      type: Object as PropType<{
        onYes?: (rule?: RRule) => void | Promise<void>;
      }>,
    },
    currentStartDate: {
      type: String as PropType<string>,
    },
    currentRecurrence: {
      type: Object as PropType<RRule>,
    },
    canEditStartDate: {
      type: Boolean,
    },
    canRemove: {
      type: Boolean,
    },
  },
  data: () => ({
    options: {} as Partial<Options>,

    isValid: false,
    isLoading: false,

    dateOrRepetition: 2,
    dayOrWeekOfMonth: 1,

    endWithDateOrSpecifiedRepiticion: [
      { text: translate('ON'), value: 1 },
      { text: translate('AFTER'), value: 2 },
    ],
    freqOptions: [
      { text: translate('REPEAT_DAY'), value: 3 },
      { text: translate('REPEAT_WEEK'), value: 2 },
      { text: translate('REPEAT_MONTH'), value: 1 },
      { text: translate('REPEAT_YEAR'), value: 0 },
    ],
    daysOfWeek: [
      { text: translate('MONDAY'), value: RRule.MO.weekday },
      { text: translate('TUESDAY'), value: RRule.TU.weekday },
      { text: translate('WEDNESDAY'), value: RRule.WE.weekday },
      { text: translate('THURSDAY'), value: RRule.TH.weekday },
      { text: translate('FRIDAY'), value: RRule.FR.weekday },
      { text: translate('SATURDAY'), value: RRule.SA.weekday },
      { text: translate('SUNDAY'), value: RRule.SU.weekday },
    ],
    weekOfMonthOptions: [
      { text: translate('FIRST'), value: 1 },
      { text: translate('SECOND'), value: 2 },
      { text: translate('THIRD'), value: 3 },
      { text: translate('FOURTH'), value: 4 },
      { text: translate('LAST'), value: -1 },
    ],
    months: [
      { text: translate('JANUARY'), value: 1 },
      { text: translate('FEBRUARY'), value: 2 },
      { text: translate('MARCH'), value: 3 },
      { text: translate('APRIL'), value: 4 },
      { text: translate('MAY'), value: 5 },
      { text: translate('JUNE'), value: 6 },
      { text: translate('JULY'), value: 7 },
      { text: translate('AUGUST'), value: 8 },
      { text: translate('SEPTEMBER'), value: 9 },
      { text: translate('OCTOBER'), value: 10 },
      { text: translate('NOVEMBER'), value: 11 },
      { text: translate('DECEMBER'), value: 12 },
    ],
  }),
  computed: {
    sanitizedOptions(): Partial<Options> {
      if (this.options.freq === undefined) return {};

      return {
        ...this.options,
        until: this.dateOrRepetition === 1 ? this.options.until : null,
        count: this.dateOrRepetition === 2 ? this.options.count : null,

        byweekday:
          this.options.freq === Frequency.WEEKLY ||
          (this.options.freq <= Frequency.MONTHLY &&
            this.dayOrWeekOfMonth === 2)
            ? this.options.byweekday
            : null,
        bymonthday:
          this.options.freq <= Frequency.MONTHLY && this.dayOrWeekOfMonth === 1
            ? this.options.bymonthday
            : null,
        bysetpos:
          this.options.freq <= Frequency.MONTHLY && this.dayOrWeekOfMonth === 2
            ? this.options.bysetpos
            : null,

        bymonth:
          this.options.freq === Frequency.YEARLY ? this.options.bymonth : null,
      };
    },
    weekday: {
      get(): ByWeekday | undefined {
        if (
          Array.isArray(this.options.byweekday) &&
          this.options.byweekday.length > 0
        ) {
          return this.options.byweekday[0];
        }

        return undefined;
      },
      set(val: ByWeekday) {
        if (val !== undefined) {
          this.options.byweekday = [val];
        } else {
          this.options.byweekday = [];
        }
      },
    },
    startDate: {
      get(): string {
        return this.options.dtstart?.toISOString() as string;
      },
      set(value: string) {
        this.options.dtstart = new Date(value);
      },
    },
    endDate: {
      get(): string {
        return this.options.until?.toISOString() ?? '';
      },
      set(value: string) {
        this.options.until = new Date(value);
      },
    },
  },
  created() {
    // get current date and total number of days in the month
    const now = new Date();

    if (this.currentRecurrence) {
      this.options = {
        ...this.currentRecurrence.options,
        bymonthday: this.currentRecurrence.options.bymonthday
          ? this.currentRecurrence.options.bymonthday[0]
          : null,
        bysetpos: this.currentRecurrence.options.bysetpos
          ? this.currentRecurrence.options.bysetpos[0]
          : null,
        bymonth: this.currentRecurrence.options.bymonth
          ? this.currentRecurrence.options.bymonth[0]
          : null,
      };

      this.dateOrRepetition = this.options.count ? 2 : 1;
      this.dayOrWeekOfMonth = this.options.bymonthday ? 1 : 2;
    } else {
      const date = this.currentStartDate
        ? new Date(this.currentStartDate)
        : now;

      this.options = {
        freq: Frequency.DAILY,
        interval: 1,
        dtstart: date,
        until: date,
        count: 1,
        byweekday: [(date.getDay() + 6) % 7],
        bymonthday: date.getDate(),
        bymonth: date.getMonth() + 1,
      };
    }
  },
  methods: {
    currentMonth(): Date {
      let tempDate = new Date();
      tempDate.setDate(1);
      return tempDate;
    },
    currentYear(): Date {
      let tempDate = new Date(this.startDate as string);
      tempDate.setMonth(0);
      tempDate.setDate(1);

      return tempDate;
    },
    validateEndDate(startDate: string, endDate: string) {
      const start = new Date(startDate);
      const end = new Date(endDate);
      return end > start || this.$t('END_BEFORE_START');
    },
    validate() {
      (this.$refs.formRef as any).validate();
    },
    async handleYesCallback() {
      this.validate();
      if (!this.isValid) return;

      const noWeekdaySelected =
        this.options.byweekday === null ||
        (this.options.byweekday as ByWeekday[])?.length === 0;
      if (
        noWeekdaySelected &&
        (this.options.freq === Frequency.WEEKLY ||
          (this.options.freq === Frequency.MONTHLY &&
            this.dayOrWeekOfMonth === 2))
      ) {
        NotificationService.showError({
          message: translate('SELECT_DAY'),
        });
        return;
      }

      const rule = new RRule(this.sanitizedOptions);
      const { onYes } = this.callback;

      if (rule.all().length === 0) {
        NotificationService.showError({
          message: translate('APPOINTMENT_REPETITION_NO_OCCURRENCE'),
        });
        return;
      }
      rule.options.dtstart = rule.all()[0];

      this.isLoading = true;
      if (onYes) {
        await onYes(rule);
      }
      this.isLoading = false;
      this.$emit('close');
    },
    handleRemoveCallback() {
      const { onYes } = this.callback;
      if (onYes) {
        onYes(undefined);
      }
      this.$emit('close');
    },
  },
});
