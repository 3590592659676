


























































































import { translate } from '@consolidate/shared/util-translations';
import Vue from 'vue';
import { JunkmailItem } from '../../domain';
import JunkmailsFacade from '../../domain/application/JunkmailsFacade';
import ModalService from '../../logic/services/ModalService';
import { formatDate } from '../../utils/date';
import { capitalize } from '../../utils/string';

export default Vue.extend({
  name: 'JunkmailMaster',
  metaInfo: {
    titleTemplate: `${translate('JUNKMAIL')}  %s`,
  },
  data() {
    return {
      selectedItems: [] as JunkmailItem[],
    };
  },
  computed: {
    items() {
      return JunkmailsFacade.filteredItems;
    },
    isLoading() {
      return JunkmailsFacade.loading;
    },
    loadError(): boolean {
      return JunkmailsFacade.loadError;
    },
  },
  mounted() {
    JunkmailsFacade.load();
  },
  methods: {
    reload() {
      JunkmailsFacade.load();
    },
    toggleSelection(item: JunkmailItem) {
      const index = this.selectedItems.indexOf(item);
      if (index > -1) {
        this.selectedItems.splice(index, 1);
      } else {
        this.selectedItems.push(item);
      }
    },
    clearSelection() {
      this.selectedItems = [];
    },
    formattedContact(item: JunkmailItem): string {
      return `${capitalize(this.$t('FROM'))}: ${item.from}`;
    },
    formattedDate(item: JunkmailItem): string {
      return formatDate(item.date);
    },
    deleteJunkmails() {
      return new Promise<boolean>((resolve) => {
        ModalService.choice({
          title: translate('DELETE_JUNKMAILS'),
          subtitle: translate('DELETE_JUNKMAILS_TEXT', {
            count: this.selectedItems.length,
          }),
          labels: { yes: translate('DELETE') },
          canCancel: true,
          callback: {
            onYes: async () => {
              const ids = this.selectedItems.map((x) => x.id);
              this.items = this.items.filter((x) => !ids.includes(x.id));
              this.clearSelection();

              JunkmailsFacade.deleteJunkmails(ids);

              resolve(true);
            },
            onNo: () => {
              this.clearSelection();
            },
          },
        });
      });
    },
    downloadJunkmails() {
      const ids = this.selectedItems.map((x) => x.id);

      this.clearSelection();
      JunkmailsFacade.downloadJunkmails(ids);
    },
    selectAll() {
      this.selectedItems = [...this.items];
    },
  },
});
