import Client from '../../api/Client';
import ConsolidateBaseCommand from '../baseCommands/ConsolidateBaseCommand';

interface Payload {
  actId: number;
}

class MarkActivityAsSeenCommand extends ConsolidateBaseCommand<Payload> {
  constructor(payload: Payload) {
    super('MarkActivityAsSeenCommand', payload);
  }

  async execute() {
    try {
      const { actId } = this.payload;
      const data = await new Client().api.setAktSeen(actId);
      const success = data?._return === 'TRUE';

      return this.result(success);
    } catch (error) {
      if (this.isRetryable(error)) {
        return this.save();
      }
      return this.notOk();
    }
  }
}

export default MarkActivityAsSeenCommand;
