import { JunkmailItem } from '../../..';
import { ListModel } from './listModel';

export class JunkmailModel extends ListModel implements JunkmailItem {
  static entity = 'junkmails';

  id!: string;
  from!: string;
  subject!: string;
  body!: string;
  date!: string;
  recipients!: string[];

  static fields() {
    return {
      id: this.string(''),
      from: this.string(''),
      subject: this.string(''),
      body: this.string(''),
      date: this.string(''),
      recipients: this.attr([]),
    };
  }
}
