import { AuthService } from '@consolidate/shared/util-auth';
import * as Sentry from '@sentry/capacitor';

class LogoutService {
  private preLogoutTasks: (() => Promise<void>)[] = [];

  public registerPreLogoutTask(task: () => Promise<void>) {
    this.preLogoutTasks.push(task);
  }

  public async logout() {
    for (const task of this.preLogoutTasks) {
      try {
        await task();
      } catch (error) {
        Sentry.captureException(error);
      }
    }

    await AuthService.logout();
  }
}

export default new LogoutService();
