import { Browser } from '@capacitor/browser';

class CapacitorNavigator {
  public prepare() {
    return Promise.resolve(this);
  }

  public navigate(params: { url?: string }) {
    if (!params || !params.url) {
      return Promise.reject(new Error('No url provided'));
    }

    return Browser.open({
      presentationStyle: 'popover',
      url: params.url,
      windowName: '_self',
    });
  }

  public close(): void {
    Browser.close();
  }

  public get url() {
    return window.location.href;
  }
}

export const capacitorNavigator = new CapacitorNavigator();
