







































































































































import Vue from 'vue';
import { Attendee, ContactOrAddress, User } from '../../../domain';
import { AddActivityCommandPayload } from '../../../logic/commands/activity/AddActivityCommand';
import ActivityFormBase from './partials/ActivityFormBase.vue';
import AttendeesAutoComplete from './partials/AttendeesAutoComplete.vue';
import ContactGroup from '../shared/ContactGroup.vue';
import { GetAktMemberDefault } from '@consolidate/shared/data-access-legacy-api';
import {
  isAktMemberDefault,
  isAttendee,
  isUser,
} from '../../../utils/checkType';
import ModalService from '../../../logic/services/ModalService';
import { ensureMinApiVersion } from '../../../utils/versioning';
import { getContactSubtitle } from '../../../utils/contact';

export default Vue.extend({
  name: 'ActivityFormAppointment',
  components: {
    ActivityFormBase,
    AttendeesAutoComplete,
    ContactGroup,
  },
  methods: {
    getStartTime(data: AddActivityCommandPayload): string {
      return new Date(
        data.appointmentStartDateTimeUtc ?? ''
      ).toShortTimeString();
    },
    setStartTime(data: AddActivityCommandPayload, time: string) {
      const current = new Date(data.appointmentStartDateTimeUtc ?? '');
      current.setHours(+time.split(':')[0], +time.split(':')[1]);
      data.appointmentStartDateTimeUtc = current.toISOString();
    },
    getEndTime(data: AddActivityCommandPayload): string {
      return new Date(data.appointmentEndDateTimeUtc ?? '').toShortTimeString();
    },
    setEndTime(data: AddActivityCommandPayload, time: string) {
      const current = new Date(data.appointmentEndDateTimeUtc ?? '');
      current.setHours(+time.split(':')[0], +time.split(':')[1]);
      data.appointmentEndDateTimeUtc = current.toISOString();
    },
    onStartEntered(
      data: AddActivityCommandPayload,
      next: HTMLInputElement
    ): void {
      // update enddatetime if not set or before startdatetime
      if (data.appointmentStartDateTimeUtc) {
        const start = new Date(data.appointmentStartDateTimeUtc);
        const end = data.appointmentEndDateTimeUtc
          ? new Date(data.appointmentEndDateTimeUtc)
          : null;

        if (!end || start > end) {
          const newEnd = start;
          newEnd.setHours(newEnd.getHours() + 1);
          data.appointmentEndDateTimeUtc = newEnd.toISOString();
        }
      }

      next.focus();
    },
    getSubtitle(
      item: GetAktMemberDefault | ContactOrAddress | User | Attendee
    ): string | undefined {
      if (isAttendee(item)) {
        if (item.userName) {
          return item.userName;
        }

        return;
      }

      if (isUser(item)) {
        return item.userName;
      }

      if (isAktMemberDefault(item)) return;

      return getContactSubtitle(item);
    },
    addRecurrence(data: AddActivityCommandPayload) {
      if (ensureMinApiVersion('5.2014')) {
        ModalService.addRecurrence(
          data.appointmentStartDateTimeUtc ?? '',
          data.recurrence ?? undefined,
          {
            onYes: (rule) => {
              data.recurrence = rule;

              if (rule) {
                data.appointmentStartDateTimeUtc =
                  rule.options.dtstart.toISOString();

                const endDate = new Date(
                  data.appointmentEndDateTimeUtc ??
                    data.appointmentStartDateTimeUtc
                );

                endDate.setFullYear(
                  rule.options.dtstart.getFullYear(),
                  rule.options.dtstart.getMonth(),
                  rule.options.dtstart.getDate()
                );

                data.appointmentEndDateTimeUtc = endDate.toISOString();
              }
            },
          }
        );
      }
    },
    async focusAttendees() {
      (this.$refs.base as any).expand();
      await this.$nextTick();
      (this.$refs.attendees as any).focus();
    },
    validateEndDate(startDate: string, endDate: string, allDay: boolean) {
      const start = new Date(startDate);
      const end = new Date(endDate);
      return (
        (allDay ? end >= start : end > start) || this.$t('END_BEFORE_START')
      );
    },
    getParticipants(data: AddActivityCommandPayload) {
      return [
        ...(data.appointmentInternalParticipants || []),
        ...(data.appointmentExternalParticipants || []),
      ];
    },
    setParticipants(
      value: (Attendee | ContactOrAddress | User | GetAktMemberDefault)[],
      data: AddActivityCommandPayload
    ) {
      data.appointmentInternalParticipants = value.filter(
        (x): x is Attendee | User =>
          isUser(x) || (isAttendee(x) && x.userName !== '')
      );
      data.appointmentExternalParticipants = value.filter(
        (x) => !isUser(x) && !(isAttendee(x) && x.userName !== '')
      );
    },
  },
});
