import { getLang, translate } from '@consolidate/shared/util-translations';

const currentLocale = getLang();

export const dayFormat: Intl.DateTimeFormatOptions = {
  weekday: 'long',
};

export const dateFormat: Intl.DateTimeFormatOptions = {
  day: '2-digit',
  month: '2-digit',
  year: 'numeric',
};

export const fullDateFormat: Intl.DateTimeFormatOptions = {
  ...dateFormat,
  year: 'numeric',
};

export const timeFormat: Intl.DateTimeFormatOptions = {
  hour: '2-digit',
  minute: '2-digit',
};

export const fullDateTimeFormat: Intl.DateTimeFormatOptions = {
  ...fullDateFormat,
  ...timeFormat,
};

/**
 * Because Consi (or more our WebService) can't handle proper timezoning
 * and UTC handling, everything is in local time :)
 * So here is dat one single point of access to that bullshittery so we
 * have to only change it once (IF and WHEN they can handle UTC)
 *
 * NOTE: DB 01.07.2020: I know it works pretty accurate in DE, AT, CH regions
 * but when days/months are overflowing (e.g. due to +/- 12h TZ difference) it's going
 * to fuck things up. Pretty sure. But what was this UTC thingy again? /shrug
 *
 * @param utcDateTimeString DateTime String in ISO format
 * @returns  Local dateTime in YYYY-MM-DDTHH:mm:ss format
 */
export function getConsiDateTimeFromUTCString(
  utcDateTimeString: string
): string {
  /**
   * Borrowed boring code from here, kudos to them
   * https://usefulangle.com/post/30/javascript-get-date-time-with-offset-hours-minutes
   */

  // Extract offset and readd to minutes
  const rightNow = new Date(utcDateTimeString);
  const currentYear = rightNow.getFullYear();
  let currentMonth: string | number = rightNow.getMonth() + 1; // Zero based :)
  let currentHours: string | number = rightNow.getHours();
  let currentDay: string | number = rightNow.getDate();
  let currentMinutes: string | number = rightNow.getMinutes();
  let currentSeconds: string | number = rightNow.getSeconds();

  // Add 0 before date, month, hrs, mins or secs if they are less than 0
  currentMonth = currentMonth < 10 ? `0${currentMonth}` : currentMonth;
  currentDay = currentDay < 10 ? `0${currentDay}` : currentDay;
  currentHours = currentHours < 10 ? `0${currentHours}` : currentHours;
  currentMinutes = currentMinutes < 10 ? `0${currentMinutes}` : currentMinutes;
  currentSeconds = currentSeconds < 10 ? `0${currentSeconds}` : currentSeconds;

  // Current datetime
  // String such as 2016-07-16T19:20:30
  return `${currentYear}-${currentMonth}-${currentDay}T${currentHours}:${currentMinutes}:${currentSeconds}`;
}

/**
 * Checks if the provided date is today's date
 * @param {string} dateToCheck ISO date string
 * @returns {boolean} true if the provided date is today's date
 */
export function isToday(dateToCheck: string) {
  const now = new Date();

  const date = new Date(dateToCheck);
  const sameYear = now.getFullYear() === date.getFullYear();
  const sameMonth = now.getMonth() === date.getMonth();
  return now.getDate() === date.getDate() && sameMonth && sameYear;
}

/**
 * Checks if both of the dates share the same day
 *
 * @returns {boolean} true if the provided dates are at the same day
 */
export function areTheSameDay(
  date1: Date | string,
  date2: Date | string
): boolean {
  if (typeof date1 === 'string') date1 = new Date(date1);
  if (typeof date2 === 'string') date2 = new Date(date2);

  const sameYear = date1.getFullYear() === date2.getFullYear();
  const sameMonth = date1.getMonth() === date2.getMonth();
  const sameDay = date1.getDate() === date2.getDate();
  return sameDay && sameMonth && sameYear;
}

/**
 * Return the weekday (Mon / Mo, Thu / Di, ...) from an UTC string, respecting the
 * given locale
 *
 * @param {String} utcString  - The date to extract the weekday from
 * @param {String} locale     - Overridable, defaults to current browser lang
 */
export function getWeekDayFromISOString(
  utcString = '',
  locale = currentLocale
) {
  return new Date(utcString).toLocaleDateString(locale, { weekday: 'short' });
}

export function getLocaleDateFromISOString(
  dateString: string,
  locale = currentLocale
) {
  if (typeof dateString !== 'string') return '';

  // Day of week (Mon, Tue, Wed, ...)
  const day = getWeekDayFromISOString(dateString, locale);

  // The actual date
  const date = new Date(dateString).toLocaleDateString(locale, fullDateFormat);

  return `${day} ${date}`;
}

export function getLocaleTimeFromISOString(
  timeString: string,
  locale = currentLocale
) {
  if (typeof timeString !== 'string') return '';
  return new Date(timeString).toLocaleTimeString(locale, timeFormat);
}

export function getLocaleDateTimeFromISOString(
  dateTimeString: string,
  locale = currentLocale
) {
  if (typeof dateTimeString !== 'string') return '';
  return new Date(dateTimeString)
    .toLocaleString(locale, {
      ...fullDateFormat,
      ...timeFormat,
    })
    .replace(/,/g, '');
}

/**
 * Returns the week number of the year
 *
 * @param {Date} date
 * @returns {Integer} weekNumberOfYear
 *
 * @example
 * getWeekNumberOfYearFromDate(new Date()); on 28.02.2020 returns 9
 */
export function getWeekNumberOfYearFromDate(date: Date) {
  const d = new Date(
    Date.UTC(date.getFullYear(), date.getMonth(), date.getDate())
  );
  const dayNum = d.getUTCDay() || 7;
  d.setUTCDate(d.getUTCDate() + 4 - dayNum);
  const yearStart = new Date(Date.UTC(d.getUTCFullYear(), 0, 1));
  return Math.ceil(((d.getTime() - yearStart.getTime()) / 86400000 + 1) / 7);
}

/**
 * Formats DateTimes to following Gless dates (based on de locale):
 * - Tomorrow
 * - Today
 * - Yesterday
 * - Monday, Tuesday, whatever (if it is in the same week)
 * - 18.01 (Year ommitted if it's the same as the current one)
 * - 20.12.2019 (if nothing of the above is applicable)
 * @param {String} dateTimeString ISO string
 * @param {String} locale Override, defaults to the current locale
 */
export function formatDate(
  dateTimeString?: string,
  locale = currentLocale
): string {
  if (!dateTimeString || dateTimeString === '') return '';

  const now = new Date();
  const date = new Date(dateTimeString);
  const sameYear = now.getFullYear() === date.getFullYear();
  const sameMonth = now.getMonth() === date.getMonth();

  const isTomorrow =
    now.getDate() + 1 === date.getDate() && sameMonth && sameYear;
  if (isTomorrow) {
    return translate('TOMORROW');
  }

  if (isToday(dateTimeString)) {
    return translate('TODAY');
  }

  const isYesterday =
    now.getDate() - 1 === date.getDate() && sameMonth && sameYear;
  if (isYesterday) {
    return translate('YESTERDAY');
  }

  const isInSameWeek =
    getWeekNumberOfYearFromDate(now) === getWeekNumberOfYearFromDate(date) &&
    sameYear;
  if (isInSameWeek) {
    return date.toLocaleString(locale, {
      ...dayFormat,
    });
  }

  if (sameYear) {
    return date
      .toLocaleString(locale, {
        ...dateFormat,
      })
      .replace(/,/g, '');
  }

  return getLocaleDateFromISOString(dateTimeString, locale);
}

/**
 * Formats DateTimes to following Gless dateTimes (based on de locale):
 * - Tomorrow 14:30
 * - Today 14:30
 * - Yesterday 14:30
 * - Monday, Tuesday, whatever 14:30 (if it is in the same week)
 * - 18.01 14:30 (Year ommitted if it's the same as the current one)
 * - 20.12.2019 14:30 (if nothing of the above is applicable)
 * @param {String} dateTimeString ISO string
 * @param {String} locale Override, defaults to the current locale
 */
export function formatDateTime(
  dateTimeString?: string,
  locale = currentLocale
) {
  if (!dateTimeString || dateTimeString === '') return '';

  // Special handling for date (prepend 'TODAY' if today)
  const dateString = isToday(dateTimeString)
    ? translate('TODAY')
    : formatDate(dateTimeString, locale);

  // Add time to dateString
  return `${dateString} ${getLocaleTimeFromISOString(dateTimeString, locale)}`;
}

/**
 * Formats a DateTimeDuration from a start and end date (an appointment)
 *
 * Start and end date is the same day:
 * - Tomorrow 14:30 - 16:00
 * - Today 14:30 - 16:00
 * - Yesterday 14:30 - 16:00
 * - Monday, Tuesday, whatever 14:30 - 16:00 (if it is in the same week)
 * - 18.01 14:30 - 16:00 (Year ommitted if it's the same as the current one)
 * - 20.12.2019 14:30 - 16:00 (if nothing of the above is applicable)
 *
 * Start and end date is *NOT* the same day:
 * - Today 14:30 - Tomorrow 16:00
 * - Yesterday 14:30 - Today 16:00
 * - Monday 14:30 - Tuesday 16:00 (if it is in the same week)
 * - 18.01 14:30 - 20.01. 16:00 (Year ommitted if it's the same as the current one)
 * - 20.12.2019 14:30 - 31.12.2019 16:00 (if nothing of the above is applicable)
 * @param {String} startDateTime     ISO string
 * @param {String} endDateTime       ISO string
 * @param {String} locale            Overridable, defaults to the current locale
 */
export function formatDateTimeDuration(
  startDateTime: string,
  endDateTime: string,
  locale = currentLocale
) {
  // From
  const fromDate = formatDateTime(startDateTime, locale);

  // Same day?
  const toDate = areTheSameDay(startDateTime, endDateTime)
    ? getLocaleTimeFromISOString(endDateTime, locale) // Just the time, we already got the date
    : formatDateTime(endDateTime, locale); // Format me a new date
  return `${fromDate} - ${toDate}`;
}

/**
 * Same as formatDateTimeDuration, but only with dates. Used for allDay appointments
 *
 * @param {String} startDateTime     ISO string
 * @param {String} endDateTime       ISO string
 * @param {String} locale            Overridable, defaults to the current locale
 */
export function formatDateDuration(
  startDateTime: string,
  endDateTime: string,
  locale = currentLocale
) {
  const allDayLabel = `(${translate('ALL_DAY')})`;

  const fromDate = isToday(startDateTime)
    ? translate('TODAY')
    : formatDate(startDateTime, locale);
  const toDate = formatDate(endDateTime, locale);

  // Same day?
  if (areTheSameDay(startDateTime, endDateTime)) {
    return `${fromDate} ${allDayLabel}`;
  }

  // A slightly longer appointment :)
  return `${fromDate} - ${toDate} ${allDayLabel}`;
}
