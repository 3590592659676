import { KeyValuePairOfStringAndString } from '../../../logic/api/gen';

export type SourceFieldValue = KeyValuePairOfStringAndString;

export type AdditionalData = AdditionalDataForm[];

export interface AdditionalDataForm {
  key: string;
  title: string;
  fields: AdditionalDataEntry[];
}

export enum AdditionalDataType {
  Time,
  Date,
  Html,
  Boolean,
  Text,
  MultilineText,
  Number,
  Color,
  Radio,
  Select,
  Source,
  Unknown,
}

interface AdditionalDataBase {
  formName: string;
  key: string;
  caption: string;
  value: string | boolean | number | SourceFieldValue | null;
  description?: string;
  editable: boolean;
  required: boolean;
  type: AdditionalDataType;
}

interface AdditionalDataBool extends AdditionalDataBase {
  type: AdditionalDataType.Boolean;
  value: boolean | null;
}

interface AdditionalDataString extends AdditionalDataBase {
  type:
    | AdditionalDataType.Text
    | AdditionalDataType.MultilineText
    | AdditionalDataType.Date
    | AdditionalDataType.Time
    | AdditionalDataType.Color
    | AdditionalDataType.Html
    | AdditionalDataType.Unknown;
  value: string | null;
}

interface AdditionalDataSelect extends AdditionalDataBase {
  type: AdditionalDataType.Radio | AdditionalDataType.Select;
  value: number | null;
  options: { value: number; label: string }[];
}

interface AdditionalDataNumber extends AdditionalDataBase {
  type: AdditionalDataType.Number;
  value: number | null;
}

export interface AdditionalDataSource extends AdditionalDataBase {
  type: AdditionalDataType.Source;
  value: SourceFieldValue | null;
}

export type AdditionalDataEntry =
  | AdditionalDataString
  | AdditionalDataBool
  | AdditionalDataNumber
  | AdditionalDataSelect
  | AdditionalDataSource;
