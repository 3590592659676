import {
  GetAktResponseAppointmentAllOfMembersReservations,
  RecurrenceInfo,
} from '@consolidate/shared/data-access-legacy-api';
import { ActivityBase, ActivityTechnology } from './Activity';

export enum AppointmentConfirmationStatus {
  NoConfirmationNeeded = 0,
  Outstanding = 1,
  Confirmed = 2,
  Declined = 3,
  Tentative = 4,
}

export interface Attendee {
  userName?: string;
  cid?: number;
  anr?: string;
  name: string;
  email?: string;
  confirmationStatus: AppointmentConfirmationStatus;
  confirmationDate?: string;
  exclusionDates?: string;
}

export enum AttendeeType {
  Internal,
  External,
}

export interface ActivityAppointment extends ActivityBase {
  specializedType: ActivityTechnology.Appointment;
  startDateTimeUtc: string;
  endDateTimeUtc: string;
  alarmTime: number;

  isAllDay: boolean;
  shouldSendReply: boolean;
  organizer: string;
  attendeesInternal: Attendee[];
  attendeesExternal: Attendee[];
  reservations: GetAktResponseAppointmentAllOfMembersReservations[];
  recurrence?: RecurrenceInfo;
  onlineMeeting?: OnlineMeeting | null;
}

export interface OnlineMeeting {
  joinLink: string;
  provider: number;
}
