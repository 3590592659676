import Client from '../../api/Client';
import ConsolidateBaseCommand from '../baseCommands/ConsolidateBaseCommand';

interface Payload {
  actId: number;
  projectId: number;
}

class SetActivityProjectCommand extends ConsolidateBaseCommand<Payload> {
  constructor(payload: Payload) {
    super('SetActivityProjectCommand', payload);
  }

  async execute() {
    try {
      const { actId, projectId } = this.payload;

      const dto = {
        aktid: actId,
        pid: projectId,
      };
      const data = await new Client().api.setAktProject(dto);
      const success = data?._return === 'TRUE';
      return this.result(success);
    } catch (error) {
      if (this.isRetryable(error)) {
        return this.save();
      }
      return this.notOk();
    }
  }
}

export default SetActivityProjectCommand;
