import {
  AddActivityRequest,
  AddActivityResponse,
  GetAktMemberDefault,
} from '@consolidate/shared/data-access-legacy-api';
import { NotificationService } from '@consolidate/shared/ui-components';
import { translate } from '@consolidate/shared/util-translations';
import { RRule } from 'rrule';
import {
  ActivityFactory,
  ActivityPriority,
  ActivityTechnology,
  ActivityTodoType,
  ActivityType,
  AdditionalDataEntry,
  Attendee,
  Confidentiality,
  ContactOrAddress,
  User,
} from '../../../domain';
import ActivityEvents from '../../../domain/application/ActivityEvents';
import ActivityService from '../../../domain/application/ActivityService';
import { isAktMemberDefault } from '../../../utils/checkType';
import { getConsiDateTimeFromUTCString } from '../../../utils/date';
import { mapFromRRule } from '../../../utils/recurrence/recurrence';
import { getConsiCSV } from '../../../utils/string';
import Client from '../../api/Client';
import ConsolidateBaseCommand from '../baseCommands/ConsolidateBaseCommand';
import EditAdditionalDataCommand from '../custom/EditAdditionalDataCommand';

export interface AddActivityCommandPayload {
  // Required
  technology: ActivityTechnology;
  actType: ActivityType;
  subject: string;
  responsible: string;

  // Common options
  transferComment?: string;
  internalCCs?: string[]; // ['xx', 'db']
  internalCCsComment?: string;
  dueDateTimeUtc?: string; // ISO String
  body?: string; // 'comment'
  previousActivityId?: number;
  previousActivity?: number;
  objects?: number[];

  // General options
  attachments?: { name: string; base64: string; size?: number }[];
  project?: { id: number; name: string };
  priority?: ActivityPriority; // 'Normal'
  todoType?: ActivityTodoType; // 'Zu erledigen'
  confidentiality?: Confidentiality;

  clients?: (GetAktMemberDefault | ContactOrAddress)[];

  // Appointment
  appointmentExternalParticipants?: (
    | GetAktMemberDefault
    | Attendee
    | ContactOrAddress
  )[]; // ['1234', 'ADR123', > cid ],
  appointmentInternalParticipants?: (Attendee | User)[]; // ['xx', 'db', > userName ],
  appointmentStartDateTimeUtc?: string;
  appointmentEndDateTimeUtc?: string;
  appointmentIsAllDay?: boolean;
  appointmentIsBooked?: boolean;

  // Document
  documentContacts?: (GetAktMemberDefault | Attendee | ContactOrAddress)[]; // ['ADR123', 12345]

  // Email
  emailReceivers?: (
    | GetAktMemberDefault
    | Attendee
    | ContactOrAddress
    | string
  )[];
  emailExternalCCs?: (ContactOrAddress | string)[];
  mailFrom: string | null;

  // Forward / Reply / ReplyAll stuff
  sendHistory?: boolean;
  selectedAttachments?: number[]; // [12, 644]

  // recurrence
  recurrence?: RRule;

  // additional data
  additionalData: Record<string, AdditionalDataEntry[]>;
}

export default class AddActivityCommand extends ConsolidateBaseCommand<
  AddActivityCommandPayload,
  AddActivityResponse
> {
  constructor(payload: AddActivityCommandPayload) {
    super('AddActivityCommand', payload);
  }

  public async execute() {
    try {
      const dto = this.mapDto();

      const data = await new Client().api.addActivity(dto);

      const id = +data?._return; // Returns 0 on failure, otherwise the actId

      if (id) {
        const additionalDataCommands = Object.entries(
          this.payload.additionalData
        ).map(([form, fields]) =>
          new EditAdditionalDataCommand({
            id,
            form,
            fields,
          }).execute()
        );

        await Promise.all(additionalDataCommands);
      }

      return this.result(id > 0, data);
    } catch (error) {
      if (this.isRetryable(error)) {
        return this.save();
      }
      return this.notOk();
    }
  }

  protected onError() {
    NotificationService.showNotification({
      message: translate('ACT_CREATE_ERROR', {
        subject: this.payload.subject,
      }),
      type: 'error',
    });
  }

  protected async onSuccess(response: AddActivityResponse) {
    const id = +response._return;
    if (!id) return;

    if (response.substitute) {
      NotificationService.showNotification({
        message: translate('ACT_CREATE_SUCCESS_SUBSTITUTE', {
          subject: this.payload.subject,
          substitute: response.substitute.user,
        }),
        type: 'success',
        timeout: 5000,
        message2: response.substitute.message
          ? translate('INFO') + ': ' + response.substitute.message
          : '',
      });
    } else {
      NotificationService.showNotification({
        message: translate('ACT_CREATE_SUCCESS', {
          subject: this.payload.subject,
        }),
        type: 'success',
      });
    }

    try {
      const newActivity = await ActivityService.loadActivity(id);

      ActivityEvents.emit('activityCreated', newActivity);
    } catch (error) {
      // ignore
    }

    ActivityService.activityTypeChanged(id, this.payload.actType);
  }

  private mapDto(): AddActivityRequest {
    if (
      !this.payload.technology ||
      !this.payload.actType?.id ||
      !this.payload.subject ||
      !this.payload.responsible
    ) {
      throw new Error('ACT_CREATE_MISSING_PARAMETERS');
    }

    // Timezones? UTC? Consi doesn't wanna know - local time it is, bby
    const now = new Date().toISOString();
    const startDateTime = getConsiDateTimeFromUTCString(
      this.payload.appointmentStartDateTimeUtc || now
    );

    let endDateTime: string | undefined;
    if (this.payload.recurrence) {
      const recurrenceEndDate = this.payload.recurrence?.all()?.at(-1);
      if (recurrenceEndDate) {
        const tempEndDate = new Date(
          this.payload.appointmentEndDateTimeUtc as string
        );
        tempEndDate.setFullYear(
          recurrenceEndDate.getFullYear(),
          recurrenceEndDate.getMonth(),
          recurrenceEndDate.getDate()
        );
        endDateTime = getConsiDateTimeFromUTCString(
          tempEndDate.toISOString() || now
        );
      }
    } else {
      endDateTime = getConsiDateTimeFromUTCString(
        this.payload.appointmentEndDateTimeUtc || now
      );
    }

    const dueDateTime = this.payload.dueDateTimeUtc
      ? getConsiDateTimeFromUTCString(this.payload.dueDateTimeUtc)
      : null;

    return {
      // Required
      akttype: this.payload.actType?.id,
      aktsakt: ActivityFactory.getAktSAktFromTechnology(
        this.payload.technology
      ),
      aktresp: this.payload.responsible,
      subject: this.payload.subject,

      // Common options
      transferComment: this.payload.transferComment,
      internalCCs: getConsiCSV(this.payload.internalCCs),
      cCcomment: this.payload.internalCCsComment,
      comment: this.payload.body,
      externalCCs: getConsiCSV([
        ...(this.payload.documentContacts?.map((x) => {
          if (typeof x === 'string') {
            return x;
          }

          if ('id' in x) {
            return x.id.toString();
          }

          return x.cid?.toString() ?? '';
        }) ?? []),
        ...(this.payload.emailExternalCCs?.map(this.mapEmail) ?? []),
      ]), // Document and mail
      prevakt: this.payload.previousActivityId,
      objects: this.payload.objects,

      // Sorry, but it has to be that way :/ (Current app does it the same way..)
      fromDate: dueDateTime || startDateTime,
      toDate: dueDateTime || endDateTime,
      fromTime: dueDateTime || startDateTime,
      toTime: dueDateTime || endDateTime,

      // General options
      attachments: this.payload.attachments,
      attachmentsCount: this.payload.attachments?.length,
      project: this.payload.project?.id,
      priority: this.payload.priority?.id,
      type: this.payload.todoType?.id,
      secretGroup: this.payload.confidentiality?.id,
      clients: getConsiCSV(
        this.payload.clients?.map((x) => {
          if (isAktMemberDefault(x)) {
            return x.cid ? x.cid.toString() : x.anr?.toString() ?? '';
          }

          return x.id?.toString() ?? '';
        })
      ),

      // Appointment
      externalParticipants: getConsiCSV(
        this.payload.appointmentExternalParticipants?.map((x) => {
          if (typeof x === 'string') {
            return x;
          }

          if ('id' in x) {
            return x.id.toString();
          }

          return x.cid?.toString() ?? '';
        })
      ),
      internalParticipants: getConsiCSV(
        this.payload.appointmentInternalParticipants?.map((x) =>
          'userName' in x ? x.userName ?? '' : ''
        )
      ),
      isWholeDay: this.payload.appointmentIsAllDay,
      blockType: this.payload.appointmentIsBooked ? 0 : 1,

      // Email
      receivers: getConsiCSV(this.payload.emailReceivers?.map(this.mapEmail)),
      mailfrom: this.payload.mailFrom ?? undefined,

      // Forward / Reply / ReplyAll stuff
      commentHistory: this.payload.sendHistory,
      selectedAttachments: getConsiCSV(this.payload.selectedAttachments),

      recurrence: this.payload.recurrence
        ? mapFromRRule(this.payload.recurrence)
        : undefined,
    };
  }

  private mapEmail(
    item: GetAktMemberDefault | ContactOrAddress | Attendee | string
  ) {
    if (typeof item === 'string') {
      return item;
    }
    if ('id' in item) {
      return item.id.toString();
    }

    return item.cid?.toString() ?? '';
  }
}
