/* tslint:disable */
/* eslint-disable */
/**
 * Consolidate App API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AppRecurrencePatternDto,
    AppRecurrencePatternDtoFromJSON,
    AppRecurrencePatternDtoFromJSONTyped,
    AppRecurrencePatternDtoToJSON,
} from './';

/**
 * 
 * @export
 * @interface EditRecurrenceDto
 */
export interface EditRecurrenceDto {
    /**
     * 
     * @type {Date}
     * @memberof EditRecurrenceDto
     */
    splitDate: Date;
    /**
     * 
     * @type {AppRecurrencePatternDto}
     * @memberof EditRecurrenceDto
     */
    recurrence: AppRecurrencePatternDto;
}

export function EditRecurrenceDtoFromJSON(json: any): EditRecurrenceDto {
    return EditRecurrenceDtoFromJSONTyped(json, false);
}

export function EditRecurrenceDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): EditRecurrenceDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'splitDate': (new Date(json['splitDate'])),
        'recurrence': AppRecurrencePatternDtoFromJSON(json['recurrence']),
    };
}

export function EditRecurrenceDtoToJSON(value?: EditRecurrenceDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'splitDate': (value.splitDate.toISOString().substr(0,10)),
        'recurrence': AppRecurrencePatternDtoToJSON(value.recurrence),
    };
}


