import {
  ApiResponse,
  GetUserTeamsResponse,
} from '@consolidate/shared/data-access-legacy-api';
import Client from '../../logic/api/Client';
import { TeamItem } from '../entitites';
import { TeamModel } from '../infrastructure/+state/models';
import { ListFacadeWithCaching } from './ListFacade';

class TeamsFacade extends ListFacadeWithCaching<
  TeamModel,
  TeamItem,
  GetUserTeamsResponse
> {
  protected entity = TeamModel.entity;

  protected filterProperties: (keyof TeamItem)[] = ['name'];

  protected map(item: TeamModel): TeamItem {
    return {
      id: item.id,
      name: item.name,
    };
  }

  protected makeApiRequest(
    hash: number
  ): Promise<ApiResponse<GetUserTeamsResponse>> {
    return new Client().api.getUserTeamsRaw({ showFuture: true, hash });
  }

  protected mapResponse(
    response: GetUserTeamsResponse
  ): Record<string, unknown>[] {
    return response.items.map((x) => ({
      id: x.id,
      name: x.teamname,
    }));
  }
}

export default new TeamsFacade();
