










































































































import {
  getRRuleText,
  mapToRRule,
} from '../../../../utils/recurrence/recurrence';
import Vue, { PropType } from 'vue';
import {
  Activity,
  ActivityTechnology,
  ACTIVITY_PRIORITY_LIST,
} from '../../../../domain';
import ActivityDetailFacade from '../../../../domain/application/ActivityDetailFacade';
import {
  formatDate,
  formatDateDuration,
  formatDateTimeDuration,
} from '../../../../utils/date';
import ModalService from '../../../../logic/services/ModalService';

export default Vue.extend({
  props: {
    data: { type: Object as PropType<Activity>, required: true },
  },
  computed: {
    firstAppointmentDateFormatted(): string {
      if (this.data.specializedType === 'APPOINTMENT') {
        const tempStartDate = new Date(this.data.startDateTimeUtc);
        const tempEndDate = new Date(this.data.endDateTimeUtc);
        let startDateTime = tempStartDate.toLocaleDateTimeStringLS();
        let startDate = tempStartDate.toLocaleDateStringLS();
        let startTime = tempStartDate.toShortTimeString();
        let endTime = tempEndDate.toShortTimeString();

        if (startTime === endTime) {
          return `${this.$t('APPOINTMENT_FROM')} ${startDate} - ${this.$t(
            'ALL_DAY'
          )}`;
        }

        return `${this.$t('APPOINTMENT_FROM')} ${startDateTime} - ${endTime}`;
      }
      return '';
    },
    repetitionFormatted(): string {
      if (this.data.specializedType === 'APPOINTMENT' && this.data.recurrence) {
        const rule = mapToRRule(
          this.data.startDateTimeUtc,
          this.data.recurrence
        );

        return getRRuleText(rule);
      } else {
        return this.$t('UNKNOWN_RECURRENCE');
      }
    },
    countFormatted(): string {
      if (this.data.specializedType === 'APPOINTMENT' && this.data.recurrence) {
        let text = 'APPOINTMENT';

        const rule = mapToRRule(
          this.data.startDateTimeUtc,
          this.data.recurrence
        );

        const count = rule.count();
        if ((count as number) > 1) {
          text = 'APPOINTMENT(S)';
        }
        return `${count} ${this.$t(text)}`;
      } else {
        return '';
      }
    },
    dueDateTimeFormatted(): string {
      if (this.data.specializedType === ActivityTechnology.Appointment) {
        return '';
      }
      return formatDate(this.data.dueDateTimeUtc);
    },
    durationFormatted(): string {
      if (this.data.specializedType === ActivityTechnology.Appointment) {
        return this.data.isAllDay
          ? formatDateDuration(
              this.data.startDateTimeUtc,
              this.data.endDateTimeUtc
            )
          : formatDateTimeDuration(
              this.data.startDateTimeUtc,
              this.data.endDateTimeUtc
            );
      }
      return '';
    },
    priority(): string | undefined {
      return ACTIVITY_PRIORITY_LIST.find((x) => x.id === this.data?.priorityId)
        ?.name;
    },
  },
  methods: {
    editChainOrShowAcceptionsCancellations(): void {
      ModalService.editChainOrShowAcceptionsCanncellations();
    },
    changeActType() {
      ActivityDetailFacade.changeActType();
    },
    changeActProject() {
      ActivityDetailFacade.changeActProject();
    },
    changeActPriority() {
      ActivityDetailFacade.changeActPriority();
    },
    changeActConfidentiality() {
      ActivityDetailFacade.changeActConfidentiality();
    },
    changeActTodoType() {
      ActivityDetailFacade.changeActTodoType();
    },
    changeActStartEndTime() {
      ActivityDetailFacade.changeActStartEndTime();
    },
    postponeAct() {
      ActivityDetailFacade.postponeAct();
    },
    changeRepetition() {
      ActivityDetailFacade.changeRecurrence();
    },
  },
});
