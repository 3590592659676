



































































































































import { getContrastTextColor } from '@consolidate/shared/ui-components';
import { getLang } from '@consolidate/shared/util-translations';
import moment from 'moment';
import { monthsShort } from 'moment-timezone';
import Vue, { PropType } from 'vue';
import {
  ActivityTechnology,
  CalendarItem,
  CalendarItemType,
} from '../../domain';
import ModalService from '../../logic/services/ModalService';

enum CalendarDisplayType {
  Blocked = 0,
  Dashed = 1,
  AfterHours = 2,
}

const days = ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT'];

interface Event extends Partial<CalendarItem> {
  name: string;
  color: string | null;
  start: Date;
  end: Date;
  timed: boolean;
}

export default Vue.extend({
  name: 'CalendarView',
  props: {
    type: {
      type: String,
      required: true,
    },
    events: {
      type: Array as PropType<Event[]>,
      required: true,
    },
    selectedDate: {
      type: Date as PropType<Date>,
      required: true,
    },
  },
  mounted() {
    this.ready = true;
    this.cal.checkChange();
    this.updateTime();
  },
  destroyed() {
    if (this.interval) {
      clearInterval(this.interval);
    }
  },
  data: () => ({
    ready: false,
    createEvent: null as Event | null,
    createStart: null as number | null,
    preventCreation: false,
    interval: null as NodeJS.Timer | null,
  }),
  watch: {
    type: {
      handler() {
        this.ready = false;
        this.$nextTick(() => {
          this.ready = true;

          // show current time in day view
          const scrollHour =
            this.type === 'day' ? new Date().getHours() - 2 : 6;

          this.cal.scrollToTime({ hour: scrollHour, minute: 0 });
        });
      },
      immediate: true,
    },
  },
  computed: {
    displayedEvents() {
      if (this.createEvent) {
        return [...this.events, this.createEvent];
      }

      return this.events.reduce((events, event) => {
        const existingEvent = events.find(
          (x) =>
            x.isAppointment &&
            !x.isRecurring &&
            x.activityId === event.activityId
        );

        // add multi day events once and display as allday when longer than 24 hours (that how desktop does it)
        if (existingEvent) {
          existingEvent.timed =
            existingEvent.timed &&
            existingEvent.end.getTime() - existingEvent.start.getTime() <
              24 * 3600 * 1000;
          return events;
        }

        // use actual event start and end time
        const ev = {
          ...event,
          start: event.eventStart ?? event.start,
          end: event.eventEnd ?? event.end,
        };
        return [...events, ev];
      }, [] as Event[]);
    },
    cal(): any {
      return this.ready ? this.$refs.calendar : null;
    },
    nowY(): string {
      return this.cal ? this.cal.timeToY(this.cal.times.now) + 'px' : '-10px';
    },
    internalSelectedDate: {
      get() {
        return this.selectedDate.toISODateString();
      },
      set(val: string) {
        this.$emit('dateSelected', moment(val).toDate());
      },
    },
    locale(): string {
      return getLang();
    },
    blockTypesForDays(): { [key: string]: CalendarDisplayType } {
      return this.events.reduce((map, current) => {
        if (!current.allDay) return map;
        if (
          current.type === CalendarItemType.BIRTHDAY ||
          current.type === CalendarItemType.JUBILEUM
        )
          return map;

        const allDatesOfThisEvent = [];
        const currentDate = new Date(current.start);
        const end = new Date(current.end);

        while (currentDate <= end) {
          allDatesOfThisEvent.push(currentDate.toISODateString());
          currentDate.setDate(currentDate.getDate() + 1);
        }

        for (const date of allDatesOfThisEvent) {
          if (current.type === CalendarItemType.HOLIDAY) {
            map[date] = CalendarDisplayType.AfterHours;
          }
          if (
            map[date] !== CalendarDisplayType.Blocked &&
            map[date] !== CalendarDisplayType.AfterHours
          ) {
            map[date] = current.blocktype as unknown as CalendarDisplayType;
          }
        }

        return map;
      }, {} as { [key: string]: CalendarDisplayType });
    },
    scaleIntervals(): boolean {
      return this.$vuetify.breakpoint.mobile && this.type === 'day';
    },
  },
  methods: {
    updateTime() {
      this.interval = setInterval(() => {
        this.cal.updateTimes();
      }, 60 * 1000);
    },
    getContrastColor(bgColor: string) {
      if (bgColor === 'transparent') {
        return this.$vuetify.theme.dark ? '#ffffff' : '#000000';
      }

      return getContrastTextColor(
        bgColor ?? this.$vuetify.theme.currentTheme.primary
      );
    },
    prev(): void {
      (this.$refs.calendar as any).prev();
    },
    next(): void {
      (this.$refs.calendar as any).next();
    },
    async dateChanged(newDate: any): Promise<void> {
      const from = new Date(
        newDate.start.year,
        newDate.start.month - 1,
        newDate.start.day,
        0,
        0,
        0
      );

      const to = new Date(
        newDate.end.year,
        newDate.end.month - 1,
        newDate.end.day,
        23,
        59,
        59
      );

      if (this.type === 'month') {
        from.addDays(-7);
        to.addDays(7);
      }

      this.$emit('reload', from, to);
    },
    eventClicked(event: { event: CalendarItem; day: { date: string } }): void {
      if (this.type === 'month') {
        this.dayClicked(event.day);
        return;
      }

      this.$emit('navigate', { item: event.event });
    },
    dayClicked(event: { date: string }) {
      this.$emit('navigate', { date: moment(event.date).toDate() });
    },
    translateDayOfWeek(dayOfWeek: number) {
      return this.$i18n.t(days[dayOfWeek]);
    },
    getClassForInterval(interval: { date: string; past: boolean }) {
      const blockType = this.blockTypesForDays[interval.date];

      return {
        blocked: blockType === CalendarDisplayType.Blocked,
        'after-hours': blockType === CalendarDisplayType.AfterHours,
        dashed: blockType === CalendarDisplayType.Dashed,
      };
    },
    getMonth(month: number): string {
      return monthsShort(month - 1);
    },
    roundTime(time: number, down = true) {
      const roundTo = 15; // minutes
      const roundDownTime = roundTo * 60 * 1000;

      return down
        ? time - (time % roundDownTime)
        : time + (roundDownTime - (time % roundDownTime));
    },
    toTime(tms: any) {
      return new Date(
        tms.year,
        tms.month - 1,
        tms.day,
        tms.hour,
        tms.minute
      ).getTime();
    },
    touchStart() {
      this.preventCreation = true;
    },
    startEvent() {
      this.preventCreation = true;
    },
    dragStart(tms: any) {
      if (this.preventCreation) return;
      const mouse = this.toTime(tms);

      this.createStart = this.roundTime(mouse);

      this.createEvent = {
        name: '',
        color: this.$vuetify.theme.dark ? '#ffffff66' : '#00000066',
        start: new Date(this.createStart),
        end: new Date(this.createStart),
        timed: true,
      };
    },
    dragMove(tms: any) {
      const mouse = this.toTime(tms);

      if (this.createEvent && this.createStart !== null) {
        const mouseRounded = this.roundTime(mouse, false);
        const min = Math.min(mouseRounded, this.createStart);
        const max = Math.max(mouseRounded, this.createStart);

        this.createEvent.start = new Date(min);
        this.createEvent.end = new Date(max);
      }
    },
    dragEnd() {
      if (this.createEvent) {
        ModalService.createActivity({
          fixedTechnology: ActivityTechnology.Appointment,
          creationContext: {
            startDate: this.createEvent?.start,
            endDate: this.createEvent?.end,
          },
        });
      }

      this.dragCancel();
    },
    dragCancel() {
      this.createEvent = null;
      this.createStart = null;
      this.preventCreation = false;
    },
  },
});
