/* tslint:disable */
/* eslint-disable */
/**
 * Consolidate App API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Provider,
    ProviderFromJSON,
    ProviderFromJSONTyped,
    ProviderToJSON,
} from './';

/**
 * 
 * @export
 * @interface ScheduleOnlineMeetingRequest
 */
export interface ScheduleOnlineMeetingRequest {
    /**
     * 
     * @type {Provider}
     * @memberof ScheduleOnlineMeetingRequest
     */
    provider: Provider;
}

export function ScheduleOnlineMeetingRequestFromJSON(json: any): ScheduleOnlineMeetingRequest {
    return ScheduleOnlineMeetingRequestFromJSONTyped(json, false);
}

export function ScheduleOnlineMeetingRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): ScheduleOnlineMeetingRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'provider': ProviderFromJSON(json['provider']),
    };
}

export function ScheduleOnlineMeetingRequestToJSON(value?: ScheduleOnlineMeetingRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'provider': ProviderToJSON(value.provider),
    };
}


