

















import { GetAktMemberDefault } from '@consolidate/shared/data-access-legacy-api';

import Vue from 'vue';
import { Attendee, ContactOrAddress } from '../../domain';
import ContactsFacade from '../../domain/application/ContactsFacade';
import {
  isAttendee,
  isContact,
  isAddress,
  isAktMemberDefault,
} from '../../utils/checkType';
import { getContactSubtitle } from '../../utils/contact';

export default Vue.extend({
  props: {
    label: { type: String },
    value: { type: [Object, Array] },
    multiple: { type: Boolean },
    allowCustomEmail: { type: Boolean },
    allowWithoutEmail: { type: Boolean, default: true },
    rules: { type: Array, default: () => [] },
    itemSubtitle: { type: [String, Function], required: false },
  },
  data: () => ({
    initialOptions: [] as ContactOrAddress[],
  }),
  async created() {
    this.initialOptions = ContactsFacade.default.withoutDividers;
    if (!this.allowWithoutEmail)
      this.initialOptions = this.initialOptions.filter((i) => !!i.email);
  },
  methods: {
    async findItems(val: string) {
      let items = await ContactsFacade.findItems(val);
      if (!this.allowWithoutEmail) items = items.filter((i) => !!i.email);
      return items;
    },
    getDisplayText(
      item: ContactOrAddress | Attendee | GetAktMemberDefault
    ): string {
      if (isAttendee(item)) {
        return item.name;
      }

      if (isContact(item)) {
        return `${item.firstname} ${item.lastname}`;
      }

      if (isAddress(item)) {
        return item.companyname;
      }

      if (isAktMemberDefault(item)) {
        return item.description ?? '';
      }

      return '';
    },
    getItemValue(
      item: ContactOrAddress | Attendee | GetAktMemberDefault
    ): string {
      if (isAttendee(item)) {
        return item.cid?.toString() ?? item.anr ?? '';
      }

      if (isContact(item)) {
        return item.id.toString();
      }

      if (isAddress(item)) {
        return item.id;
      }

      if (isAktMemberDefault(item)) {
        return item.cid?.toString() ?? item.anr ?? item.uid ?? '';
      }

      return '';
    },
    getItemSubtitle(item: ContactOrAddress) {
      if (this.itemSubtitle) {
        if (typeof this.itemSubtitle === 'string')
          return (item as any)[this.itemSubtitle];
        else return this.itemSubtitle(item);
      }

      return getContactSubtitle(item);
    },
    updateValue(val: unknown) {
      this.$emit('input', val);
    },
    focus() {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (this.$refs.input as any).focus();
    },
  },
});
