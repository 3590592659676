import { WorkflowDoNextStepRequest } from '@consolidate/shared/data-access-legacy-api';
import Client from '../../api/Client';
import ConsolidateBaseCommand from '../baseCommands/ConsolidateBaseCommand';

class WorkflowContinueCommand extends ConsolidateBaseCommand<WorkflowDoNextStepRequest> {
  constructor(payload: WorkflowDoNextStepRequest) {
    super('WorkflowContinueCommand', payload);
  }

  async execute() {
    try {
      const value = await new Client().api.workflowDoNextStep(this.payload);

      return this.result(value._return === 1);
    } catch (error) {
      if (this.isRetryable(error)) {
        return this.save();
      }
      return this.notOk();
    }
  }
}

export default WorkflowContinueCommand;
