import { Capacitor } from '@capacitor/core';
import { Device } from '@capacitor/device';
import {
  ActionPerformed,
  PushNotifications,
  PushNotificationSchema,
  Token,
} from '@capacitor/push-notifications';
import { RegisterPushRequestDevicetypeEnum } from '@consolidate/shared/data-access-legacy-api';
import * as Sentry from '@sentry/capacitor';
import ActivityEvents from '../../domain/application/ActivityEvents';
import OverviewFacade from '../../domain/application/OverviewFacade';
import router from '../../router';
import Client from '../api/Client';
import LogoutService from './LogoutService';

class PushService {
  constructor(
    private knownNotifications: {
      [key: number]: PushNotificationSchema[];
    } = {}
  ) {
    LogoutService.registerPreLogoutTask(() => this.deRegister());
    ActivityEvents.on('activityRead', this.removeNotifications.bind(this));
  }

  public async init(): Promise<void> {
    if (!this.isAvailable()) return;

    PushNotifications.addListener(
      'pushNotificationReceived',
      this.pushReceived.bind(this)
    );

    PushNotifications.addListener(
      'pushNotificationActionPerformed',
      this.actionPerformed.bind(this)
    );

    try {
      const token = await this.getPushToken();
      if (!token) return;

      const info = await Device.getInfo();
      const id = await Device.getId();

      await new Client().api.registerPush({
        devicetype: this.getDeviceType(),
        deviceid: id.identifier,
        devicetoken: token,
        devicename: info.name,
      });
    } catch (error) {
      console.error(error);
      Sentry.captureException(error);
    }
  }

  public async deRegister(): Promise<void> {
    if (!this.isAvailable()) return;

    const id = await Device.getId();

    await new Client().api.deRegisterPush({
      deviceid: id.identifier,
    });
  }

  public async removeNotifications(aktId: number): Promise<void> {
    if (!this.isAvailable()) return;

    const notifications = this.knownNotifications[aktId];

    if (notifications) {
      PushNotifications.removeDeliveredNotifications({ notifications });

      delete this.knownNotifications[aktId];
    }
  }

  private isAvailable() {
    return (
      Capacitor.isNativePlatform() &&
      Capacitor.isPluginAvailable('PushNotifications')
    );
  }

  private pushReceived(notification: PushNotificationSchema) {
    // when push is recieved and the app is in the foreground
    OverviewFacade.refreshCounts();

    const aktId = notification.data.id;

    if (aktId) {
      const notifications = this.knownNotifications[aktId] ?? [];
      this.knownNotifications[aktId] = [
        ...notifications,
        { id: notification.data.notId, data: notification.data },
      ];
    }
  }

  private actionPerformed(action: ActionPerformed) {
    const aktId = action.notification.data.id;

    if (aktId) {
      router.push({
        name: 'inbox-detail',
        params: { id: aktId },
      });
    }
  }

  private getDeviceType(): RegisterPushRequestDevicetypeEnum {
    const platform = Capacitor.getPlatform();
    switch (platform) {
      case 'ios':
        return RegisterPushRequestDevicetypeEnum.Ios;
      case 'android':
        return RegisterPushRequestDevicetypeEnum.Android;
      default:
        return RegisterPushRequestDevicetypeEnum.Browser;
    }
  }

  private async getPushToken(): Promise<string | undefined> {
    if (!this.isAvailable()) return;

    const result = await PushNotifications.requestPermissions();

    if (!result) return;

    return await new Promise((resolve, reject) => {
      PushNotifications.addListener('registration', (token: Token) => {
        resolve(token.value);
      });

      PushNotifications.addListener('registrationError', (error: any) => {
        reject(error);
      });

      PushNotifications.register();
    });
  }
}

export default new PushService();
