












import Vue from 'vue';
import { AddressListItem } from '../../domain';
import ContactsFacade from '../../domain/application/ContactsFacade';

export default Vue.extend({
  name: 'AddressAutoComplete',
  props: {
    label: { type: String },
    value: { type: Object },
  },
  data: () => ({
    initialOptions: [] as AddressListItem[],
  }),
  async created() {
    this.initialOptions = ContactsFacade.default.withoutDividers.filter(
      (coa) => coa.type === 'ADDRESS'
    ) as AddressListItem[];
  },
  methods: {
    findItems(val: string) {
      return ContactsFacade.findAddress(val);
    },
    updateValue(val: unknown) {
      this.$emit('input', val);
    },
  },
});
