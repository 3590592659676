/* tslint:disable */
/* eslint-disable */
/**
 * Consolidate App API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    MeetingLinkProvider,
    MeetingLinkProviderFromJSON,
    MeetingLinkProviderFromJSONTyped,
    MeetingLinkProviderToJSON,
} from './';

/**
 * 
 * @export
 * @interface Result4
 */
export interface Result4 {
    /**
     * 
     * @type {Array<MeetingLinkProvider>}
     * @memberof Result4
     */
    providers: Array<MeetingLinkProvider>;
}

export function Result4FromJSON(json: any): Result4 {
    return Result4FromJSONTyped(json, false);
}

export function Result4FromJSONTyped(json: any, ignoreDiscriminator: boolean): Result4 {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'providers': ((json['providers'] as Array<any>).map(MeetingLinkProviderFromJSON)),
    };
}

export function Result4ToJSON(value?: Result4 | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'providers': ((value.providers as Array<any>).map(MeetingLinkProviderToJSON)),
    };
}


