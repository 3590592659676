import { ActivityListItem, ActivityListItemFactory } from '../../../domain';
import { SearchType } from '../../../domain/application/ActivitySearchFacade';
import Client from '../../api/Client';
import ConsolidateBaseQuery, { Paged } from '../ConsolidateBaseQuery';

interface Payload {
  page: number;
  type: SearchType;
  id: string | number;
}

class GetActivitiesFromQuery extends ConsolidateBaseQuery<
  Paged<ActivityListItem>,
  Payload
> {
  constructor(payload: Payload) {
    super(payload);
  }

  async execute() {
    const { page, type, id } = this._payload;

    const response = await new Client().api.getAktsFromId({
      type: this.getTypeId(type),
      id: id.toString(),
      showFuture: true,
      ...this._addPaging(page),
    });

    const result: ActivityListItem[] = response.items.map((x) =>
      ActivityListItemFactory.getFromIdSearch(x)
    );
    const paged = this._pagedObject(response.found, page, result);
    return paged;
  }

  private getTypeId(type: SearchType) {
    switch (type) {
      case SearchType.Contact:
        return 1;
      case SearchType.Address:
        return 2;
      case SearchType.Project:
        return 3;
      case SearchType.Folder:
        return 4;
      case SearchType.Team:
        return 5;
      case SearchType.Object:
        return 6;
      default:
        return 0;
    }
  }
}

export default GetActivitiesFromQuery;
