import { Browser } from '@capacitor/browser';
import { Capacitor } from '@capacitor/core';
import Client from '../../logic/api/Client';
import { MeetingLinkProvider, Provider } from '../../logic/api/gen';
import ModalService from '../../logic/services/ModalService';
import { ensureMinApiVersion, minApiVersion } from '../../utils/versioning';
import { MeetingProviderModel } from '../infrastructure/+state/models';
import { ListFacade } from './ListFacade';

class MeetingProviderFacade extends ListFacade<
  MeetingProviderModel,
  MeetingLinkProvider
> {
  protected entity: string = MeetingProviderModel.entity;
  protected filterProperties: (keyof MeetingLinkProvider)[] = ['name'];

  protected map(item: MeetingProviderModel): MeetingLinkProvider {
    return item;
  }

  protected async getItems(): Promise<Record<string, unknown>[] | undefined> {
    if (ensureMinApiVersion('5.2100', true)) {
      const response =
        await new Client().meetings.meetingsGetOnlineMeetingProviders();

      return response.providers.map((x) => ({ ...x }));
    }

    return [];
  }

  @minApiVersion('5.2100')
  public async loginOAuth(provider: Provider, onSuccess: () => void) {
    const urls = await new Client().meetings.meetingsGetOAuthUrls(
      provider,
      window.location.origin + '/meeting-provider-callback',
      provider.toString()
    );
    const channel = new BroadcastChannel('login');
    channel.onmessage = (ev) => {
      if (ev.data === 'loginComplete') {
        onSuccess();
        channel.close();
      }
    };

    if (Capacitor.isNativePlatform()) {
      await Browser.open({
        presentationStyle: 'popover',
        url: urls.authorizationUrl,
        windowName: 'Meeting Provider Login',
      });
    } else {
      window.open(
        urls.authorizationUrl,
        'Meeting Provider Login',
        'status=no,location=no,toolbar=no,menubar=no,width=500,height=500'
      );
    }
  }

  @minApiVersion('5.2100')
  public async loginCredentials(provider: Provider, onSuccess: () => void) {
    ModalService.loginCredentials(async (username, password) => {
      try {
        await new Client().meetings.meetingsLoginCredentials(provider, {
          username,
          password,
        });

        onSuccess();

        return true;
      } catch {
        return false;
      }
    });
  }
}

export default new MeetingProviderFacade();
