import { AdditionalData } from '../..';
import { ActivityTechnology } from './Activity';

export enum ActivityTypeDocsAllowed {
  None = 0,
  Single = 1,
  Multiple = 2,
}

export interface ActivityType {
  id: number;
  name: string;
  technology: ActivityTechnology;
  icon: string;
  color: string | null;
  allowdocs: ActivityTypeDocsAllowed;
  allowobjects: boolean;
  defaultanswer: boolean;
  defaultanswerall: boolean;
  defaultforward: boolean;
  active: boolean;
  hideOnMobile: boolean;
  workflows?: ActivityTypeWorkflow[];
  secretgroup: number;
  additionalData?: AdditionalData;
}

export interface ActivityTypeWorkflow {
  id: number;
  name: string;
}
