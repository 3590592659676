import RemoveFavoritesBaseCommand from '../baseCommands/RemoveFavoriteBaseCommand';

class RemoveContactFromFavoritesCommand extends RemoveFavoritesBaseCommand {
  constructor({ id }: { id: number }) {
    super('RemoveContactFromFavoritesCommand', { type: 1, id });
  }

  async execute() {
    return super.executeInternal();
  }
}
export default RemoveContactFromFavoritesCommand;
