import {
  AdditionalCommunication,
  GetContactResponseCompanies,
  GetContactResponseLocations,
} from '@consolidate/shared/data-access-legacy-api';
import { AdditionalData, Contact } from '../../../entitites';
import { ContactModel } from './contactHierarchy';

export class ContactDetailModel extends ContactModel implements Contact {
  static entity = 'contactDetails';
  static baseEntity = ContactModel.entity;

  id!: number;
  CID!: number;
  additionalData?: AdditionalData;
  function?: string;
  title?: string;
  attention?: string;
  gender?: number;
  salutationpriv?: string;
  suffix?: string;
  ANR?: string;
  department?: string;
  email?: string;
  mobile?: string;
  info?: string;
  infosecret?: string;
  skypename?: string;
  telephone?: string;
  dateofbirth?: string;
  telsecret?: string;
  comment?: string;
  communications?: AdditionalCommunication[];
  companies?: GetContactResponseCompanies[];
  locations?: GetContactResponseLocations[];
  picture?: string;

  type!: 'CONTACT';
  isDivider!: false;

  static fields() {
    return {
      ...super.fields(),

      CID: this.number(0),
      additionalData: this.attr(null).nullable(),
      function: this.string(null).nullable(),
      title: this.string(null).nullable(),
      attention: this.string(null).nullable(),
      gender: this.number(null).nullable(),
      salutationpriv: this.string(null).nullable(),
      suffix: this.string(null).nullable(),
      ANR: this.string(null).nullable(),
      department: this.string(null).nullable(),
      email: this.string(null).nullable(),
      mobile: this.string(null).nullable(),
      info: this.string(null).nullable(),
      infosecret: this.string(null).nullable(),
      skypename: this.string(null).nullable(),
      telephone: this.string(null).nullable(),
      dateofbirth: this.attr(null).nullable(),
      telsecret: this.string(null).nullable(),
      comment: this.string(null).nullable(),
      communications: this.attr(null).nullable(),
      companies: this.attr(null).nullable(),
      locations: this.attr(null).nullable(),
      picture: this.string(null).nullable(),
    };
  }

  static state() {
    return {
      ...super.state(),
      loadingDetail: false,
      detailId: null as number | null,
      canEditContact: false,
    };
  }
}
