<template>
  <form class="modal-activity-priority" @submit.prevent="handleYesCallback">
    <p v-show="error" class="error--text" v-text="error" />

    <cs-select
      v-model="priority"
      :items="priorityList"
      :label="$t('PRIORITY')"
      item-value="id"
      :allow-empty="false"
      item-text="name"
      @input="resetError"
      return-object
    />

    <BaseModalControls
      class="margin-top"
      :blur="blurButtons"
      :loading="isSubmitting"
      @no="$emit('close')"
    >
      <template #yes>
        <span v-text="$t('SAVE')" />
      </template>
    </BaseModalControls>
  </form>
</template>

<script>
import { ACTIVITY_PRIORITY_LIST } from '../../../domain';
import BaseModalControls from '../partials/BaseModalControls.vue';

export default {
  name: 'ModalActivityPriority',
  components: {
    BaseModalControls,
  },
  props: {
    priorityId: { type: Number, default: 1 }, // Normal
    callback: {
      type: Object,
      default: () => ({
        onYes: { type: Function, default: null },
      }),
    },
  },
  data: () => ({
    error: '',
    priority: null,
    priorityList: ACTIVITY_PRIORITY_LIST,
    isSubmitting: false,
    blurButtons: false,
  }),
  created() {
    // Set initial priority
    if (this.priorityId > -1) {
      this.priority = this.priorityList.find((p) => p.id === this.priorityId);
    }
  },
  methods: {
    async handleYesCallback() {
      // Check if we have a different priority id
      if (this.priority.id === this.priorityId) {
        this.error = `${this.$t('NO_CHANGES_WERE_MADE')}.`;
        return;
      }

      const { onYes } = this.callback;
      if (onYes) {
        this.isSubmitting = true;
        await onYes({ priority: this.priority.id });
        this.isSubmitting = false;
      }

      this.$emit('close');
    },
    resetError() {
      this.error = '';
    },
  },
};
</script>
