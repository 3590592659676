import fetchProgress from 'fetch-progress';
import Client from '../../api/Client';
import ConsolidateBaseQuery from '../ConsolidateBaseQuery';

interface Payload {
  id: number;
  aktid: number;
  versionId?: number;
}

class GetAttachmentQuery extends ConsolidateBaseQuery<Blob, Payload> {
  constructor(payload: Payload) {
    super(payload);
  }

  async execute(progressCallback?: (progress: number) => void) {
    const { id, aktid, versionId } = this._payload;

    return await new Client().api
      .getAttachmentRaw({
        id,
        getAttachmentRequest: { aktid, versionId },
      })
      .then((x) =>
        fetchProgress({
          onProgress(progress) {
            if (progressCallback) {
              progressCallback((progress.transferred / progress.total) * 100);
            }
          },
        })(x.raw)
      )
      .then((x) => x.blob());
  }
}
export default GetAttachmentQuery;
