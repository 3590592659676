import Client from '../../api/Client';
import ConsolidateBaseCommand from '../baseCommands/ConsolidateBaseCommand';

interface Payload {
  actId: number;
  attId: number;
}

class RemoveAttachmentFromActivityCommand extends ConsolidateBaseCommand<Payload> {
  constructor(payload: Payload) {
    super('RemoveAttachmentFromActivityCommand', payload);
  }

  async execute() {
    try {
      const { actId, attId } = this.payload;

      const dto = {
        aktid: actId,
        attid: attId,
      };

      const data = await new Client().api.removeAttachment(dto);
      const success = data?._return === 'TRUE';
      return this.result(success);
    } catch (error) {
      if (this.isRetryable(error)) {
        return this.save();
      }
      return this.notOk();
    }
  }
}

export default RemoveAttachmentFromActivityCommand;
