






























import Vue from 'vue';

export default Vue.extend({
  name: 'ModalCredentialsLogin',
  props: {
    callback: { type: Function },
  },
  data: () => ({
    username: '',
    password: '',
    isError: false,
    isLoading: false,
  }),
  methods: {
    async login() {
      this.isLoading = true;
      if (await this.callback(this.username, this.password)) {
        this.$emit('close');
      } else {
        this.isError = true;
      }

      this.isLoading = false;
    },
  },
});
