




























































import { translate } from '@consolidate/shared/util-translations';
import Vue from 'vue';
import ObjectsFacade from '../../domain/application/ObjectsFacade';
import { filterMixin } from '../../mixins/filterMixin';

export default Vue.extend({
  metaInfo: {
    titleTemplate: `${translate('OBJECTS')}  %s`,
  },
  mixins: [filterMixin(ObjectsFacade)],
  computed: {
    items() {
      return ObjectsFacade.filteredItems;
    },
    isLoading() {
      return ObjectsFacade.loading;
    },
    loadError(): boolean {
      return ObjectsFacade.loadError;
    },
  },
  mounted() {
    this.reload();
  },
  methods: {
    reload() {
      ObjectsFacade.load();
    },
    loadMore() {
      ObjectsFacade.loadMore();
    },
  },
});
