import semver from 'semver';
import { EmployeeModel } from '../../domain/infrastructure/+state/models';
import store from '../../domain/infrastructure/+state/store';
import { getAppVersion } from '../../utils/versioning';

class MigrationService {
  private migrations: Record<string, () => void> = {
    '1.1.004': () => {
      EmployeeModel.commit((state) => {
        state.hash = undefined;
      });
    },
  };

  async migrate() {
    // wait until vuex is initialized
    await store.restored;

    const previousVersion =
      semver.valid(localStorage.getItem('previousVersion'), true) ?? '0.0.0';

    Object.keys(this.migrations)
      .filter((version) => {
        const migrationVersion = semver.valid(version, true);
        return migrationVersion && semver.lt(previousVersion, migrationVersion);
      })
      .forEach((version) => this.migrations[version]());

    localStorage.setItem('previousVersion', getAppVersion().version);
  }
}

export default new MigrationService();
