









































import { GetAktResponseBaseObjects } from '@consolidate/shared/data-access-legacy-api';
import { Activity } from '../../../../domain';
import ActivityDetailFacade from '../../../../domain/application/ActivityDetailFacade';

import Vue, { PropType } from 'vue';

export default Vue.extend({
  name: 'ActivityDetailObjects',
  props: {
    data: {
      type: Object as PropType<Activity>,
      required: true,
    },
  },
  methods: {
    add() {
      ActivityDetailFacade.addObject();
    },
    remove(object: GetAktResponseBaseObjects) {
      ActivityDetailFacade.removeObject(object);
    },
    link(item: GetAktResponseBaseObjects) {
      return {
        name: 'object-detail',
        params: { typeId: item.id },
      };
    },
  },
});
