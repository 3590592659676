




import { Browser } from '@capacitor/browser';
import { Capacitor } from '@capacitor/core';
import Vue from 'vue';
import Client from '../../logic/api/Client';

export default Vue.extend({
  name: 'MeetingLoginCallback',
  data: () => ({
    error: false,
  }),
  async created() {
    const params = new URLSearchParams(window.location.search);
    const code = params.get('code');
    const state = params.get('state');
    if (!code || !state) {
      this.error = true;
      return;
    }
    const provider = +state;

    await new Client().meetings.meetingsLoginOAuth(provider, {
      authorizationCode: code,
      redirectUrl: window.location.origin + '/meeting-provider-callback',
    });

    const channel = new BroadcastChannel('login');
    channel.postMessage('loginComplete');

    if (Capacitor.isNativePlatform()) {
      Browser.close();
    } else {
      window.close();
    }
  },
});
