import { NotificationService } from '@consolidate/shared/ui-components';
import { translate } from '@consolidate/shared/util-translations';
import QueueService from '../../../domain/application/QueueService';
import { isConnectionError } from '../../../utils/network';

interface CommandResult<T> {
  ok: boolean | 'queued';
  data?: T;
}
export default abstract class ConsolidateBaseCommand<T, TData = any> {
  public persist = true;

  constructor(public _type: string, public payload: T) {}

  public abstract execute(): Promise<CommandResult<TData>>;

  /**
   * Will save the whole command including payload to the Queue to execute later.
   * The command will not be saved if the persist flag is set to false.
   */
  public save() {
    if (!this.persist) return this.result('queued');

    NotificationService.showWarning({
      message: translate('OFFLINE_ACTION_QUEUED'),
      message2: translate('OFFLINE_ACTION_QUEUED_DETAIL'),
    });
    QueueService.add(this._type, this.payload);

    return this.result('queued');
  }

  protected result(
    success: boolean | 'queued',
    data?: TData
  ): CommandResult<TData> {
    if (success === true) {
      this.onSuccess && this.onSuccess(data);
    } else if (success === false) {
      this.onError && this.onError();
    }
    return { ok: success, data };
  }

  protected ok(data?: TData): CommandResult<TData> {
    return this.result(true, data);
  }

  protected notOk(): CommandResult<TData> {
    return this.result(false);
  }

  protected isRetryable(error: unknown) {
    // do not retry client errors
    return isConnectionError(error);
  }

  protected onSuccess?(result?: TData): void;
  protected onError?(): void;

  /**
   * Little helper function to achive a Consi interpretation of a CSV string ^.^
   * @param {Array} array  Values to concatenate
   * @returns {String} '' for an empty array or a string in following format: ';val1;val2;...'
   */
  protected toConsolidateCSV(array: unknown[] = [], separator = ';') {
    const values = array.join(separator);
    return values ? `${separator}${values}` : ''; // Prepend separator if we got sth
  }
}
