











































































































































import { AuthService } from '@consolidate/shared/util-auth';
import moment from 'moment';
import Vue from 'vue';
import AgendaView from '../../components/calendar/AgendaView.vue';
import CalendarView from '../../components/calendar/CalendarView.vue';
import { CalendarItem, CalendarItemType } from '../../domain';
import ActivityEvents from '../../domain/application/ActivityEvents';
import ActivityService from '../../domain/application/ActivityService';
import CalendarFacade from '../../domain/application/CalendarFacade';
import EmployeeService from '../../domain/application/EmployeeService';
import ModalService from '../../logic/services/ModalService';
import { getCalendarItemTarget } from '../../utils/calendar';

type CalendarViewMode = {
  key: 'agenda' | 'day' | '4day' | 'week' | 'month';
  displayName: string;
  icon: string;
};

const fallbackViewIndex = 3; // month

export default Vue.extend({
  name: 'CalendarPage',
  metaInfo() {
    const title: string = this.title;
    return {
      titleTemplate: `${title}  %s`,
    };
  },
  components: {
    AgendaView,
    CalendarView,
  },
  data: () => ({
    selectedDate: new Date(),
    selectedUsername: AuthService.getUser()?.uid ?? '',

    from: new Date(),
    to: new Date(),
    showAnniversaries: false,
    showBirthdays: false,

    previousViewIndex: null as number | null,
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion -- set on created, has to be null so previousViewIndex doesnt get set initially
    viewIndex: null! as number,
  }),
  created() {
    const index = localStorage.getItem('calendarView');
    this.viewIndex = +(index ?? fallbackViewIndex);

    ActivityService.creationContext.selectedDate =
      this.selectedDate.toISODateString();
    ActivityEvents.on('appointmentDateChanged', this.softReload);
  },
  destroyed() {
    ActivityEvents.off('appointmentDateChanged', this.softReload);
  },
  watch: {
    selectedDate() {
      ActivityService.creationContext.selectedDate =
        this.selectedDate.toISODateString();
    },
    viewIndex(current: number, previous: number) {
      localStorage.setItem('calendarView', `${current ?? fallbackViewIndex}`);

      this.previousViewIndex = previous;
    },
  },
  computed: {
    isLoading() {
      return CalendarFacade.loading;
    },
    loadError() {
      return CalendarFacade.loadError;
    },
    view(): CalendarViewMode {
      return this.views[this.viewIndex] ?? this.views[fallbackViewIndex];
    },
    views(): CalendarViewMode[] {
      return [
        { key: 'agenda', displayName: 'AGENDA', icon: 'AgendaView' },
        { key: 'day', displayName: 'DAY', icon: 'DayView' },
        this.$vuetify.breakpoint.mobile
          ? { key: '4day', displayName: '4_DAY', icon: 'SeveralDaysView' }
          : { key: 'week', displayName: 'WEEK', icon: 'SeveralDaysView' },
        { key: 'month', displayName: 'MONTH', icon: 'MonthView' },
      ];
    },
    visibleCalendarItems(): CalendarItem[] {
      return CalendarFacade.getItemsByUser(this.selectedUsername).filter(
        (x) =>
          (x.allDay ? x.end >= this.from : x.end > this.from) &&
          x.start <= this.to
      );
    },
    events(): any[] {
      let items = this.visibleCalendarItems;

      if (!this.showBirthdays)
        items = items.filter((item) => item.type !== CalendarItemType.BIRTHDAY);

      if (!this.showAnniversaries)
        items = items.filter((item) => item.type !== CalendarItemType.JUBILEUM);

      return items.map((x) => ({
        ...x,
        // fix value from webservice: allDay appointments end at 00:00 the next day, should end at 23:59 the same day.
        end:
          x.isAppointment && x.allDay
            ? new Date(
                x.end.getFullYear(),
                x.end.getMonth(),
                x.end.getDate() - 1,
                23,
                59
              )
            : x.end,
        name: x.title,
        color: x.isAppointment ? x.iconColor : 'transparent',
        timed: !x.allDay,
      }));
    },
    displayDate(): string {
      switch (this.view.key) {
        case 'month':
        case 'agenda':
          return this.selectedDate.toLocaleDateStringLS({
            month: 'short',
            year: 'numeric',
          });
        case 'day':
          return this.selectedDate.toLocaleDateStringLS({
            day: 'numeric',
            month: 'numeric',
            year: '2-digit',
          });

        default:
          return `${this.from.toLocaleDateStringLS({
            day: 'numeric',
            month: 'numeric',
          })} - ${this.to.toLocaleDateStringLS({
            day: 'numeric',
            month: 'numeric',
            year: '2-digit',
          })}`;
      }
    },
    birthdayCount(): number {
      return this.visibleCalendarItems.filter(
        (x) => x.type === CalendarItemType.BIRTHDAY
      ).length;
    },
    anniversaryCount(): number {
      return this.visibleCalendarItems.filter(
        (x) => x.type === CalendarItemType.JUBILEUM
      ).length;
    },
    actions(): { name: string; handler: () => void }[] {
      return [
        {
          name: this.$t('CALENDAR_JUMP_TO_DATE'),
          handler: this.jumpToDateClick,
        },
        {
          name: this.$t('CALENDAR_FROM'),
          handler: this.displayCalendarFromModal,
        },
      ];
    },
    title(): string {
      if (this.selectedUsername === AuthService.getUser()?.uid) {
        return this.$t('MY_CALENDAR');
      } else {
        const name = EmployeeService.find(this.selectedUsername)?.fullName;
        return this.$t('CALENDAR_OF', {
          user: name,
          context: ['s', 'ß', 'x', 'z'].some((x) =>
            name?.toLowerCase()?.endsWith(x)
          )
            ? 'ENDS_WITH_S_SOUND'
            : '',
        });
      }
    },
  },
  methods: {
    next(): void {
      (this.$refs.activeView as any).next();
    },
    prev(): void {
      (this.$refs.activeView as any).prev();
    },
    handleReload(from: Date, to: Date): void {
      this.from = from;
      this.to = to;

      this.softReload();
    },
    displayCalendarFromModal(): void {
      ModalService.calendarFrom({
        callback: {
          onYes: async (data) => {
            // const { selectedMySelf, fullName, userName } = data;
            const { userName } = data;
            this.selectedUsername = userName;

            this.softReload();
          },
        },
      });
    },
    handleNavigate({ item, date }: { item?: CalendarItem; date?: Date }) {
      if (date) {
        this.selectedDate = date;
        this.viewIndex =
          this.views.findIndex((x) => x.key == 'day') ?? fallbackViewIndex;
      }
      if (item) {
        const target = getCalendarItemTarget(item);

        if (target) this.$router.push(target);
      }
    },
    showPreviousView(): void {
      if (this.previousViewIndex !== null) {
        this.viewIndex = this.previousViewIndex;
      }
    },
    jumpToDate(date: Date): void {
      this.selectedDate = date;
    },
    jumpToDateClick(): void {
      ModalService.jumpToDate({
        callback: {
          onYes: async (data) => {
            if (data?.date) {
              this.jumpToDate(moment(data.date).toDate());
            }
          },
        },
      });
    },
    async softReload(): Promise<void> {
      await this.reload(false);
    },
    async reload(force = true) {
      await CalendarFacade.loadItems(
        this.selectedUsername,
        this.from.toISODateString(),
        this.to.toISODateString(),
        force ?? true
      );
    },
  },
});
