import { NotificationService } from '@consolidate/shared/ui-components';
import { translate } from '@consolidate/shared/util-translations';
import Client from '../../api/Client';
import ConsolidateBaseCommand from '../baseCommands/ConsolidateBaseCommand';

interface Payload {
  aktid: number;
  method?: string;
  mailfrom?: string;
  withcomment?: boolean;
  activitydeleted?: boolean;
  email?: string;
  cID?: string;
  aNR?: string;
}

export default class SendAppointmentInvitationCommand extends ConsolidateBaseCommand<Payload> {
  constructor(payload: Payload) {
    super('SendAppointmentInvitationCommand', payload);
  }

  async execute() {
    try {
      await new Client().api.createAppointmentMail(this.payload);

      return this.result(true);
    } catch (error) {
      if (this.isRetryable(error)) {
        return this.save();
      }
      return this.notOk();
    }
  }

  protected onSuccess() {
    NotificationService.showSuccess({
      message: translate('SEND_APPOINTMENT_INVITATION_SUCCESS'),
    });
  }

  protected onError() {
    NotificationService.showError({
      message: translate('ERROR_OCCURRED'),
    });
  }
}
