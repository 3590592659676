<template>
  <div class="modal-activity-history">
    <cs-html
      style="overflow: auto; width: 100%; height: 100%"
      :html="actMailText"
    />
  </div>
</template>

<script>
export default {
  name: 'ModalActivityHistory',
  props: {
    actMailText: { type: String },
  },
};
</script>

<style scoped>
.modal-activity-history {
  height: 100%;
  overflow: auto;
}
</style>
