import Client from '../../logic/api/Client';
import { Confidentiality } from '../entitites';
import { ConfidentialityModel } from '../infrastructure/+state/models';

class ConfidentialitiesService {
  public get items(): Confidentiality[] {
    return ConfidentialityModel.all();
  }

  public async load() {
    try {
      const response = await new Client().api.getSecretGroups();

      await ConfidentialityModel.deleteAll();
      if (response.items) {
        ConfidentialityModel.insert({
          data: response.items.map(
            (i): Confidentiality => ({
              id: i.id,
              name: i.secName,
            })
          ),
        });
      }
    } catch (error) {
      // if we have previously loaded data ignore the error and work with offline data
      // otherwise throw an error
      if (!ConfidentialityModel.all().length) {
        throw error;
      }
    }
  }

  public find(id: number | undefined): Confidentiality | null {
    if (id === undefined) return null;

    return ConfidentialityModel.find(id);
  }
}

export default new ConfidentialitiesService();
