




































import { translate } from '@consolidate/shared/util-translations';
import Vue from 'vue';
import LoadingIndicator from '../../components/loading/LoadingIndicator.vue';
import TeamsFacade from '../../domain/application/TeamsFacade';

export default Vue.extend({
  name: 'TeamsPage',
  metaInfo: {
    titleTemplate: `${translate('TEAM_TASKS')}  %s`,
  },
  components: {
    LoadingIndicator,
  },
  computed: {
    data() {
      return TeamsFacade.filteredItems;
    },
    isLoading() {
      return TeamsFacade.loading;
    },
    loadError() {
      return TeamsFacade.loadError;
    },
  },
  methods: {
    reload() {
      TeamsFacade.load();
    },
  },
});
