import { Capacitor } from '@capacitor/core';
import { Directory } from '@capacitor/filesystem';
import { NotificationService } from '@consolidate/shared/ui-components';
import { translate } from '@consolidate/shared/util-translations';
import { FileOpener } from '@ionic-native/file-opener';
import writeFile from 'capacitor-blob-writer';

class FileService {
  private directoryName = 'Consolidate';

  public async saveFile(data: Blob, name: string, mode: 'download' | 'open') {
    if (!Capacitor.isNativePlatform()) {
      this.saveFileWeb(data, name, mode);
    } else {
      await this.saveFileNative(data, name, mode);
    }
  }

  private saveFileWeb(data: Blob, name: string, mode: 'download' | 'open') {
    const isSafari = !!navigator.userAgent.match(/Version\/[\d.]+.*Safari/);
    const iOS = /iPad|iPhone|iPod/.test(navigator.userAgent);

    const url = window.URL.createObjectURL(data);
    const a = document.createElement('a');
    a.href = url;
    a.rel = 'noopener noreferrer';
    if (mode === 'download') a.download = name;
    if (mode === 'open' && !(isSafari || iOS)) a.target = '_blank'; // Not allowed on Apple devices
    a.click();

    setTimeout(() => window.URL.revokeObjectURL(url), 500);
  }

  private async saveFileNative(
    data: Blob,
    name: string,
    mode: 'download' | 'open'
  ) {
    const uri = await writeFile({
      path: `${this.directoryName}/${name}`,
      blob: data,
      directory: Directory.Documents,
      recursive: true,
    });

    if (!uri) {
      NotificationService.showError({
        message: translate('FILE_COULD_NOT_BE_SAVED'),
      });

      return;
    }

    if (mode === 'download') {
      const { buttonClicked } = await NotificationService.showInfo({
        message: translate('FILE_SAVED'),
        buttonText: translate('FILE_OPEN'),
      }).getResult();

      if (!buttonClicked) return;
    }

    // remove other information in the mime type like charset
    const contentType = data.type.split(';')[0];

    try {
      await FileOpener.open(uri, contentType);
    } catch {
      await FileOpener.showOpenWithDialog(uri, contentType);
    }
  }
}

export default new FileService();
