



































import ButtonBar from '../../button/ButtonBar.vue';
import Vue, { PropType } from 'vue';
import { WorkflowStep } from '../../../domain';

export default Vue.extend({
  components: {
    ButtonBar,
  },
  props: {
    workflow: { type: Object as PropType<WorkflowStep>, default: null },
    callback: {
      type: Function,
    },
  },
  data: () => ({
    comment: '',
    isLoading: false,
  }),
  methods: {
    cancelWorkflow() {
      this.$emit('cancelWorkflow', { comment: this.comment });
      this.close();
    },
    async decisionMade(decisionYesOrNo: boolean) {
      const decision = {
        decision: decisionYesOrNo,
        comment: this.comment,
      };

      if (this.callback) {
        this.isLoading = true;
        await this.callback(decision);
        this.isLoading = false;
      }

      this.close();
    },
    close() {
      this.$emit('close');
    },
  },
});
