export type Nested<T> = T & {
  nested?: boolean;
  skipCount?: boolean;
};

export function nestItems<
  T extends {
    anr?: string;
    cid?: number;
  }
>(value: T[] | null | undefined, addressList?: T[]): Nested<T>[] {
  if (!value) return [];

  const data: Nested<T>[] = [];
  const grouped = value?.groupBy((x) => x.anr ?? 0);

  for (const [anr, items] of Object.entries(grouped)) {
    let company: T | undefined = undefined;

    if (anr) {
      company = items.find((x) => !x.cid);
      let skipCount = items.length > 1;

      if (!company) {
        company = addressList?.find((x) => x.anr === anr);
        skipCount = true;
      }

      if (company) {
        data.push({
          ...company,
          // don't count the company if we have people of the company
          skipCount: skipCount,
          nested: false,
        });
      }
    }

    data.push(
      ...items
        .filter((x) => x !== company)
        .map((x) => ({
          ...x,
          nested: !!company,
        }))
    );
  }

  return data;
}
