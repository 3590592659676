import { ApiResponse } from '@consolidate/shared/data-access-legacy-api';
import { Model, Query } from '@vuex-orm/core';
import { isConnectionError } from '../../utils/network';
import { ListModel } from '../infrastructure/+state/models';
import store from '../infrastructure/+state/store';

export abstract class ListFacade<
  T extends ListModel,
  TListItem extends Record<string, any>
> {
  protected baseFilter(query: Query<Model>): Query<Model> {
    return query;
  }

  protected abstract entity: string;

  protected abstract map(item: T): TListItem;

  protected abstract getItems(): Promise<Record<string, unknown>[] | undefined>;

  protected abstract filterProperties: (keyof TListItem)[];

  protected filter(items: TListItem[]): TListItem[] {
    if (!this.filterProperties || !this.filterString) return items;

    const tokens = this.filterString.split(' ');

    return items.filter((i) =>
      tokens.every((t) =>
        this.filterProperties.some((f) =>
          i[f]?.toString().toLowerCase().includes(t.toLowerCase())
        )
      )
    );
  }

  protected get model(): typeof Model {
    return store.$db().model(this.entity);
  }

  public get items(): TListItem[] {
    return this.baseFilter(this.model.query().withAll())
      .all()
      .map((x) => this.map(<T>x));
  }

  public get filteredItems(): TListItem[] {
    return this.filter(this.items);
  }

  public get loading(): boolean {
    return store.state.entities[this.model.entity].loading;
  }

  public get loadError(): boolean {
    return store.state.entities[this.model.entity].loadError;
  }

  public get filterString(): string {
    return store.state.entities[this.model.entity].filterString;
  }

  public get filterActive(): boolean {
    return !!store.state.entities[this.model.entity].filterString;
  }

  public clear() {
    this.model.deleteAll();
  }

  protected async deleteAfterReload(items: Record<string, unknown>[]) {
    const currentIds = this.baseFilter(this.model.query())
      .all()
      .map((x) => this.model.getIdFromRecord(x));

    const newIds = items.map((x) => this.model.getIdFromRecord(x));

    const idsToDelete = currentIds.filter((x) => !newIds.includes(x));

    await this.model.delete((x) =>
      idsToDelete.includes(this.model.getIdFromRecord(x))
    );
  }

  public async load() {
    this.model.commit((state) => {
      state.loading = true;
    });

    let loadError = false;
    try {
      const items = await this.getItems();

      if (items !== undefined) {
        await this.deleteAfterReload(items);

        if (items.length > 0) {
          this.model.insert({
            data: items,
          });
        }
      }
    } catch (error) {
      loadError = isConnectionError(error);
    }

    this.model.commit((state) => {
      state.loading = false;
      state.loadError = loadError;
    });

    return !loadError;
  }

  public setFilter(filter = '') {
    this.model.commit((state) => {
      state.filterString = filter;
    });
  }

  public clearFilter() {
    this.setFilter('');
  }
}

export abstract class ListFacadeWithCaching<
  T extends ListModel,
  TListItem extends Record<string, any>,
  TApiResponse
> extends ListFacade<T, TListItem> {
  protected get hash(): number {
    return store.state.entities[this.model.entity].hash;
  }

  protected set hash(val: number) {
    this.model.commit((state) => {
      state.hash = val;
    });
  }

  public load(force?: boolean) {
    if (force) {
      this.model.commit((state) => {
        state.hash = undefined;
      });
    }

    return super.load();
  }

  protected abstract makeApiRequest(
    hash: number
  ): Promise<ApiResponse<TApiResponse>>;

  protected abstract mapResponse(
    response: TApiResponse
  ): Record<string, unknown>[];

  protected async getItems(): Promise<Record<string, unknown>[] | undefined> {
    const resp = await this.makeApiRequest(this.hash);

    const hash = +(resp.raw.headers.get('hash') ?? '0');
    if (hash === this.hash) {
      return undefined;
    }
    this.hash = hash;

    return this.mapResponse(await resp.value());
  }
}
