/* tslint:disable */
/* eslint-disable */
/**
 * Consolidate App API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AddressDto,
    AddressDtoFromJSON,
    AddressDtoFromJSONTyped,
    AddressDtoToJSON,
    AppAdditionalDataForm,
    AppAdditionalDataFormFromJSON,
    AppAdditionalDataFormFromJSONTyped,
    AppAdditionalDataFormToJSON,
    ContactDto,
    ContactDtoFromJSON,
    ContactDtoFromJSONTyped,
    ContactDtoToJSON,
    ObjectTypeDto,
    ObjectTypeDtoFromJSON,
    ObjectTypeDtoFromJSONTyped,
    ObjectTypeDtoToJSON,
    WorkflowDto,
    WorkflowDtoFromJSON,
    WorkflowDtoFromJSONTyped,
    WorkflowDtoToJSON,
} from './';

/**
 * 
 * @export
 * @interface ObjectDto
 */
export interface ObjectDto {
    /**
     * 
     * @type {number}
     * @memberof ObjectDto
     */
    id: number;
    /**
     * 
     * @type {ObjectTypeDto}
     * @memberof ObjectDto
     */
    type: ObjectTypeDto;
    /**
     * 
     * @type {boolean}
     * @memberof ObjectDto
     */
    active?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof ObjectDto
     */
    description?: string | null;
    /**
     * 
     * @type {ContactDto}
     * @memberof ObjectDto
     */
    manager?: ContactDto | null;
    /**
     * 
     * @type {AddressDto}
     * @memberof ObjectDto
     */
    location?: AddressDto | null;
    /**
     * 
     * @type {string}
     * @memberof ObjectDto
     */
    responsible?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ObjectDto
     */
    comment?: string | null;
    /**
     * 
     * @type {WorkflowDto}
     * @memberof ObjectDto
     */
    workflow?: WorkflowDto | null;
    /**
     * 
     * @type {string}
     * @memberof ObjectDto
     */
    base64Image?: string | null;
    /**
     * 
     * @type {Array<AppAdditionalDataForm>}
     * @memberof ObjectDto
     */
    additionalData: Array<AppAdditionalDataForm>;
}

export function ObjectDtoFromJSON(json: any): ObjectDto {
    return ObjectDtoFromJSONTyped(json, false);
}

export function ObjectDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ObjectDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'type': ObjectTypeDtoFromJSON(json['type']),
        'active': !exists(json, 'active') ? undefined : json['active'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'manager': !exists(json, 'manager') ? undefined : ContactDtoFromJSON(json['manager']),
        'location': !exists(json, 'location') ? undefined : AddressDtoFromJSON(json['location']),
        'responsible': !exists(json, 'responsible') ? undefined : json['responsible'],
        'comment': !exists(json, 'comment') ? undefined : json['comment'],
        'workflow': !exists(json, 'workflow') ? undefined : WorkflowDtoFromJSON(json['workflow']),
        'base64Image': !exists(json, 'base64Image') ? undefined : json['base64Image'],
        'additionalData': ((json['additionalData'] as Array<any>).map(AppAdditionalDataFormFromJSON)),
    };
}

export function ObjectDtoToJSON(value?: ObjectDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'type': ObjectTypeDtoToJSON(value.type),
        'active': value.active,
        'description': value.description,
        'manager': ContactDtoToJSON(value.manager),
        'location': AddressDtoToJSON(value.location),
        'responsible': value.responsible,
        'comment': value.comment,
        'workflow': WorkflowDtoToJSON(value.workflow),
        'base64Image': value.base64Image,
        'additionalData': ((value.additionalData as Array<any>).map(AppAdditionalDataFormToJSON)),
    };
}


