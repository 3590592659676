import {
  ApiResponse,
  GetContactItem,
  GetContactsResponse,
} from '@consolidate/shared/data-access-legacy-api';
import { Model, Query } from '@vuex-orm/core';
import Client from '../../logic/api/Client';
import { capitalize } from '../../utils/string';
import {
  AddressListItem,
  ContactOrAddress,
  ContactOrAddressOrDivider,
} from '../entitites';
import {
  ContactModel,
  ContactSearchResultModel,
} from '../infrastructure/+state/models';
import store from '../infrastructure/+state/store';
import { ListFacade, ListFacadeWithCaching } from './ListFacade';

function mapItem(item: GetContactItem): ContactOrAddressOrDivider {
  if (item.type === 'D') {
    return {
      isDivider: true,
      id: item.id,
      label: item.label,
    };
  } else if (item.type === 'K') {
    return {
      isDivider: false,
      id: item.cid,
      firstname: item.firstname,
      lastname: item.lastname,
      email: item.email,
      companyname: item.companyname,
      companyCity: item.city,
      icon: 'Gender' + capitalize(item.icon),
      isfavorite: item.isfavorite,
      type: 'CONTACT',
    };
  } else {
    return {
      isDivider: false,
      id: item.anr,
      companyname: item.companyname,
      companyCity: item.city,
      email: item.email,
      icon: `Contact${item.icon ?? 1}`,
      isfavorite: item.isfavorite,
      type: 'ADDRESS',
    };
  }
}

class Default extends ListFacadeWithCaching<
  ContactModel,
  ContactOrAddressOrDivider,
  GetContactsResponse
> {
  protected entity: string = ContactModel.entity;

  protected baseFilter(query: Query<Model>): Query<Model> {
    return query
      .where('isListedInDefault', true)
      .orderBy('orderKey', 'asc')
      .orderBy('isDivider', 'desc');
  }

  protected map(item: ContactModel): ContactOrAddressOrDivider {
    return {
      ...item,
    } as ContactOrAddressOrDivider;
  }

  protected makeApiRequest(
    hash: number
  ): Promise<ApiResponse<GetContactsResponse>> {
    return new Client().api.getContactsRaw({
      hash,
    });
  }

  protected mapResponse(
    response: GetContactsResponse
  ): Record<string, unknown>[] {
    const { items, cancreateaddress, cancreatecontact } = response;

    ContactModel.commit((state) => {
      state.canAddContact = cancreatecontact;
      state.canAddAddress = cancreateaddress;
    });

    if (!items) {
      return [];
    }

    // get all unique dividers
    const dividers = [
      ...new Map(
        items
          .filter((x) => x.type === 'D')
          .map((item) => [
            (item as { type: 'D' } & GetContactItem)['label'],
            item,
          ])
      ).values(),
    ];

    const results = [...items.filter((x) => x.type !== 'D'), ...dividers];

    return results.map((x) => {
      const c = mapItem(x);
      return {
        ...ContactModel.find(c.id),
        ...c,
        isListedInDefault: true,
      };
    });
  }

  protected filterProperties: any = ['firstname', 'lastname', 'companyname'];

  public get withoutDividers(): ContactOrAddress[] {
    return this.items.filter((x) => !x.isDivider) as ContactOrAddress[];
  }
}

class SearchResults extends ListFacade<
  ContactSearchResultModel,
  ContactOrAddressOrDivider
> {
  protected entity: string = ContactSearchResultModel.entity;
  protected filterProperties = [];

  public get searchTerm(): string | undefined {
    return store.state.entities[ContactSearchResultModel.entity].searchTerm;
  }

  protected baseFilter(query: Query<Model>): Query<Model> {
    return query.orderBy('sortOrder', 'asc').whereHas('contact', (query) => {
      query.where('isListedInDefault', false);
    });
  }

  public map(item: ContactSearchResultModel) {
    return {
      ...item.contact,
    } as ContactOrAddressOrDivider;
  }

  public async getItems(
    searchTerm?: string
  ): Promise<Record<string, unknown>[] | undefined> {
    const { items } = await new Client().api.getContacts(undefined, {
      searchstring: searchTerm ?? this.searchTerm,
      limit: 200,
    });

    if (!items) {
      return [];
    }

    let sortOrder = ContactSearchResultModel.all().length;
    // no dividers for search results
    return items
      .filter((x) => x.type !== 'D')
      .map((x) => {
        const c = mapItem(x);
        return {
          id: c.id,
          sortOrder: ++sortOrder,
          contact: {
            ...ContactModel.find(c.id),
            ...c,
          },
        };
      });
  }

  public load(searchTerm?: string) {
    ContactSearchResultModel.commit((state) => {
      state.searchTerm = searchTerm;
    });

    ContactSearchResultModel.deleteAll();

    return super.load();
  }

  public async loadMore() {
    // currently the webservice does not support paging
  }
}

class ContactsFacade {
  public default = new Default();
  public searchResults = new SearchResults();

  /** Returns the items without persisting them in the store */
  public async findItems(searchTerm: string): Promise<ContactOrAddress[]> {
    const items = await this.searchResults.getItems(searchTerm);
    if (items) {
      return items
        .map((x) => new ContactSearchResultModel(x))
        .map(this.searchResults.map)
        .filter((x) => !x.isDivider) as ContactOrAddress[];
    }
    return [];
  }

  /** Returns the items without persisting them in the store */
  public async findAddress(searchTerm: string): Promise<AddressListItem[]> {
    const { items } = await new Client().api.findAddresses({
      searchstring: searchTerm,
    });

    if (items) {
      return items.map(
        (adr): AddressListItem => ({
          isDivider: false,
          id: adr.anr,
          companyname: adr.name,
          companyCity: adr.city,
          icon: (adr.icon ?? 1).toString(),
          isfavorite: false,
          type: 'ADDRESS',
        })
      );
    }
    return [];
  }

  public load() {
    this.default.load();
  }

  public find(id: number | string): ContactOrAddressOrDivider | null {
    return ContactModel.find(id) as ContactOrAddressOrDivider;
  }

  public get canAddContact(): boolean {
    return store.state.entities[ContactModel.entity].canAddContact;
  }

  public get canAddAddress(): boolean {
    return store.state.entities[ContactModel.entity].canAddAddress;
  }
}

export default new ContactsFacade();
