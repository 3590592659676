export enum CalendarItemType {
  BIRTHDAY = 'B',
  APPOINTMENT = 'A',
  JUBILEUM = 'J',
  HOLIDAY = 'H',
}

export enum BlockType {
  Blocked = 0,
  Available = 1,
}

export enum AppointmentStatus {
  Open = 0,
  Done = 1,
}

export interface CalendarItem {
  uid: string;
  start: Date;
  end: Date;
  eventStart: Date;
  eventEnd: Date;
  type: CalendarItemType;
  title: string;
  content?: string;
  activityId?: number;
  allDay: boolean;
  icon: string;
  iconColor: string | null;
  isAppointment: boolean;
  user: string;
  isRecurring?: boolean;
  status: AppointmentStatus;
  blocktype: BlockType;
}
