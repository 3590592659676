<template>
  <v-sheet
    class="profile-picture"
    color="surface"
    :class="this.$vuetify.theme.dark"
    :src="picture"
    :style="{ backgroundImage: 'url(' + picture + ')' }"
  >
    <cs-icon color="black" v-if="!picture">ImageUpload </cs-icon>
  </v-sheet>
</template>

<script>
//

export default {
  components: {},
  props: {
    picture: { type: String, default: undefined },
  },
};
</script>

<style lang="scss" scoped>
.profile-picture {
  cursor: pointer;
  display: flex;
  position: relative;
  width: 100%;
  height: 90%;
  max-width: 144px;

  justify-content: center;
  align-items: center;
  border-radius: 15px;
  background-size: cover;
}

.profile-picture:hover > .overlay {
  opacity: 1;
}
</style>
