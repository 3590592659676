import {
  ApiResponse,
  GetInboxResponse,
} from '@consolidate/shared/data-access-legacy-api';
import { Model, Query } from '@vuex-orm/core';
import { ActivityListItem } from '../../domain';
import Client from '../../logic/api/Client';
import {
  MarkActivityAsReadCommand,
  MarkActivityAsSeenCommand,
} from '../../logic/commands';
import { ActivityFactory, ActivityListItemFactory } from '../factories';
import { ActivityModel, InboxItemModel } from '../infrastructure/+state/models';
import { ListFacadeWithCaching } from './ListFacade';
import OverviewFacade from './OverviewFacade';

class InboxFacade extends ListFacadeWithCaching<
  InboxItemModel,
  ActivityListItem,
  GetInboxResponse
> {
  protected entity = InboxItemModel.entity;

  protected filterProperties: (keyof ActivityListItem)[] = [
    'subject',
    'contact',
    'summary',
  ];

  protected baseFilter(query: Query<Model>): Query<Model> {
    return query.orderBy('received', 'desc');
  }

  protected map(item: InboxItemModel): ActivityListItem {
    return {
      ...item.activity,
      uid: item.id,
      summary: item.summary,
      isNew: item.isNew,
      received: item.received,
      contact: item.sender,
      isSpam: item.isSpam,
    };
  }

  protected makeApiRequest(
    hash: number
  ): Promise<ApiResponse<GetInboxResponse>> {
    return new Client().api.getInboxRaw({
      getInboxRequest: {
        limit: Number.MAX_SAFE_INTEGER,
        divider: false,
      },
      hash,
    });
  }
  protected mapResponse(response: GetInboxResponse): Record<string, unknown>[] {
    if (response.items) {
      return response.items.map((x) => ({
        id: x.id,
        summary: x.summary,
        isSpam: x.isspam,
        isNew: x.newinfo,
        received: `${x.received}Z`,
        sender: x.sender,
        activity: {
          ...ActivityModel.find(x.aktid),
          ...ActivityListItemFactory.create({
            id: x.aktid,
            subject: x.subject,
            hasAttachments: x.hasattachment,
            priorityId: x.priority,
            icon: x.icon,
            iconColor: x.color,
            responsible: x.resp,
            todoType: ActivityFactory.getTodoTypeFromConsolidateId(x.todotype),
            actType: {
              ...ActivityModel.find(x.aktid)?.actType,
              id: x.akttype,
              name: x.aktname,
            },
            isRecurring: x.isrecurring,
          }),
        },
      }));
    }

    return [];
  }

  public get items(): ActivityListItem[] {
    return super.items.filter((x) => !x.isSpam);
  }

  public get spamItems(): ActivityListItem[] {
    return super.items.filter((x) => x.isSpam);
  }

  public get filteredSpamItems(): ActivityListItem[] {
    return this.filter(this.spamItems);
  }

  public async load(force = false) {
    const result = await super.load(force);
    OverviewFacade.refreshCounts();
    return result;
  }

  public async setActAsRead(aktId: number) {
    const { ok } = await new MarkActivityAsReadCommand({
      actId: aktId,
    }).execute();
    if (ok) {
      this.deleteForActivity(aktId);
      return true;
    } else {
      return false;
    }
  }

  public async setActAsSeen(aktId: number) {
    const { ok } = await new MarkActivityAsSeenCommand({
      actId: aktId,
    }).execute();
    if (ok) {
      InboxItemModel.update({
        where: (x) => x.aktid === aktId,
        data: {
          isNew: false,
        },
      });
      return true;
    } else {
      return false;
    }
  }

  public deleteForActivities(aktIds: number[]) {
    InboxItemModel.delete((x) => aktIds.includes(x.aktid ?? 0));
  }

  public deleteForActivity(aktId: number) {
    InboxItemModel.delete((x) => x.aktid === aktId);
  }
}

export default new InboxFacade();
