









import Vue from 'vue';
import animationData from '../../../assets/02-success.json';

export default Vue.extend({
  data: () => ({
    animationData: animationData,
  }),
});
