import { ContactModel } from './contactModel';
import { ListModel } from './listModel';

export class ContactSearchResultModel extends ListModel {
  static entity = 'contactSearchResults';

  id!: number | string;
  sortOrder!: number;
  contact!: ContactModel;

  static fields() {
    return {
      id: this.attr(''),
      sortOrder: this.number(0),
      contact: this.belongsTo(ContactModel, 'id'),
    };
  }

  static state() {
    return {
      ...super.state(),
      searchTerm: '',
    };
  }
}
