import { ContactListItemBase } from '../../..';
import { AddressDetailModel, ContactDetailModel } from './contactHierarchy';
import { ListModel } from './listModel';

export class ContactModel extends ListModel implements ContactListItemBase {
  static entity = 'contacts';

  item_type!: 'LIST' | 'CONTACT_DETAIL' | 'ADDRESS_DETAIL';
  id!: string | number;
  isDivider!: boolean;
  label!: string;
  firstname!: string;
  lastname!: string;
  email?: string;
  companyname?: string;
  companyCity?: string;
  icon!: string;
  isfavorite!: boolean;
  type!: 'ADDRESS' | 'CONTACT';

  public get displayName() {
    if (this.isDivider) return this.label;
    if (this.type === 'CONTACT')
      return `${this.lastname} ${this.firstname}`.trim();

    return this.companyname;
  }

  public get orderKey() {
    return this.displayName?.toLowerCase();
  }

  static fields() {
    return {
      item_type: this.string('LIST'),
      id: this.attr(0),
      isDivider: this.boolean(false),
      label: this.string(null).nullable(),
      firstname: this.string(''),
      lastname: this.string(''),
      email: this.string(null).nullable(),
      companyname: this.string(null).nullable(),
      companyCity: this.string(null).nullable(),
      icon: this.string(''),
      type: this.string(null).nullable(),
      isfavorite: this.boolean(false),
      isListedInDefault: this.boolean(false),
    };
  }

  static state() {
    return {
      ...super.state(),
      canAddContact: false,
      canAddAddress: false,
    };
  }

  static typeKey = 'item_type';

  static types() {
    return {
      LIST: ContactModel,
      CONTACT_DETAIL: ContactDetailModel,
      ADDRESS_DETAIL: AddressDetailModel,
    };
  }
}
