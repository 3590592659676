import Client from '../../logic/api/Client';
import { Country } from '../entitites';
import { CountryModel } from '../infrastructure/+state/models';

class CountriesService {
  public get countries(): Country[] {
    return CountryModel.all();
  }

  public async load() {
    try {
      const response = await new Client().api.getCountryList();

      await CountryModel.deleteAll();
      if (response.items) {
        CountryModel.insert({
          data: response.items.map(
            (i): Country => ({
              id: i.countryid,
              name: i.countryname,
            })
          ),
        });
      }
    } catch (error) {
      // if we have previously loaded data ignore the error and work with offline data
      // otherwise throw an error
      if (!CountryModel.all().length) {
        throw error;
      }
    }
  }

  public find(id: string): Country | null {
    return CountryModel.find(id);
  }
}

export default new CountriesService();
