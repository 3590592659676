import { AuthService } from '@consolidate/shared/util-auth';
import { translate } from '@consolidate/shared/util-translations';
import ModalService from './ModalService';

class LicensingService {
  private modalShown = false;

  public checkLicense() {
    if (this.hasLicense()) {
      return true;
    }

    if (!this.modalShown) {
      this.modalShown = true;
      ModalService.error(
        translate('MISSING_LICENSE_TEXT'),
        () => {
          AuthService.logout();
        },
        translate('MISSING_LICENSE')
      );
    }

    return false;
  }

  private hasLicense() {
    const user = AuthService.getUser();
    if (user) {
      const appAccess = Boolean(user['app_access']);

      return appAccess;
    }

    return false;
  }
}

export default new LicensingService();
