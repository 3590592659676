

















































































































import Vue from 'vue';
import LoadingIndicator from '../../components/loading/LoadingIndicator.vue';
import DetailChip from '../../components/shared/DetailChip.vue';
import DetailPage from '../../components/shared/DetailPage.vue';
import { ProjectDetail } from '../../domain';
import AdditionalDataFacade from '../../domain/application/AdditionalDataFacade';
import ProjectsFacade from '../../domain/application/ProjectsFacade';
import { ProjectModel } from '../../domain/infrastructure/+state/models';

export default Vue.extend({
  name: 'ProjectDetail',
  metaInfo() {
    const name: string = this.project?.name ?? '';
    const path = this.$route.path;

    if (path.includes('activities')) {
      return {
        titleTemplate: `${name} | %s`,
      };
    } else {
      return {
        title: `| ${name}`,
      };
    }
  },
  components: {
    DetailPage,
    DetailChip,
    LoadingIndicator,
  },
  computed: {
    isLoading() {
      return ProjectsFacade.loading;
    },
    loadError() {
      return ProjectsFacade.loadError;
    },
    dates(): string {
      if (!this.project) return '';

      const start = this.project.start ? new Date(this.project.start) : null;
      const end = this.project.end ? new Date(this.project.end) : null;
      let startDate = null;
      let endDate = null;
      if (start) startDate = start.toLocaleDateStringLS();
      if (end && !end.toISOString().includes('1899')) {
        endDate = end?.toLocaleDateStringLS();
      }

      return [startDate, endDate].filter(Boolean).join(' - ');
    },
    startAndOreEndDate(): string {
      if (!this.project) return '';

      const start = this.project.start ? new Date(this.project.start) : null;
      const end = this.project.end ? new Date(this.project.end) : null;

      if (start && end && !end.toISOString().includes('1899')) {
        return 'START_END_DATE';
      } else if (start) {
        return 'START_DATE';
      } else if (end && !end.toISOString().includes('1899')) {
        return 'END_DATE';
      }
      return '';
    },
    project(): ProjectDetail | null {
      return ProjectsFacade.getProject(this.id);
    },
    id(): number {
      return +this.$route.params.typeId;
    },
  },
  watch: {
    id: {
      immediate: true,
      async handler() {
        if (this.id) {
          await ProjectsFacade.loadAdditionalData(this.id);
        }
      },
    },
  },
  methods: {
    editAdditionalData(key: string) {
      if (this.project?.additionalData) {
        AdditionalDataFacade.editAdditionalData(
          this.project.id,
          key,
          ProjectModel
        );
      }
    },
    goToProjectActivities() {
      this.$router.push({ name: 'projects-detail-activities' });
    },
    async goToProjectDetail(id: number) {
      this.$router.push({
        name: 'projects-detail',
        params: { typeId: id.toString() },
      });
    },
    async toggleFavorite() {
      if (!this.project) return;

      await ProjectsFacade.setFavorite(
        this.project.id,
        !this.project.isFavorite
      );
    },
  },
});
