import { EventEmitter } from 'events';
import { Activity } from '../entitites';

interface ActivityEvents {
  appointmentDateChanged: (id: number) => void;
  activityCreated: (activity: Activity) => void;
  activityChanged: (id: number) => void;
  activityRead: (id: number) => void;
  activityDeleted: (id: number) => void;
}

declare interface ActivityEventEmitter {
  on<U extends keyof ActivityEvents>(
    event: U,
    listener: ActivityEvents[U]
  ): this;

  emit<U extends keyof ActivityEvents>(
    event: U,
    ...args: Parameters<ActivityEvents[U]>
  ): boolean;
}

class ActivityEventEmitter extends EventEmitter {}

export default new ActivityEventEmitter();
