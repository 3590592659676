















import Vue from 'vue';
import animationData from './01-problem.json';

export default Vue.extend({
  data: () => ({
    animationData,
  }),
});
