import { RemoveParticipantRequest } from '@consolidate/shared/data-access-legacy-api';
import { NotificationService } from '@consolidate/shared/ui-components';
import { translate } from '@consolidate/shared/util-translations';
import ActivityEventEmitter from '../../../domain/application/ActivityEvents';
import Client from '../../api/Client';
import ConsolidateBaseCommand from '../baseCommands/ConsolidateBaseCommand';

export default class RemoveActivityParticipantCommand extends ConsolidateBaseCommand<RemoveParticipantRequest> {
  constructor(payload: RemoveParticipantRequest) {
    super('RemoveActivityParticipantCommand', payload);
  }

  async execute() {
    try {
      await new Client().api.removeParticipant(this.payload);

      return this.ok();
    } catch (error) {
      if (this.isRetryable(error)) {
        return this.save();
      }
      return this.notOk();
    }
  }

  protected onSuccess() {
    ActivityEventEmitter.emit('activityChanged', this.payload.aktid);
  }

  protected onError() {
    NotificationService.showError({
      message: translate('COULDNT_CHANGE_ACTIVITY'),
    });
  }
}
