










































































































































































































import Vue, { PropType } from 'vue';
import { AddContactCommand, EditContactCommand } from '../../logic/commands';

import { AddOrEditContactRequest } from '@consolidate/shared/data-access-legacy-api';
import AddressAutoComplete from '../../components/input/AddressAutoComplete.vue';
import SingleFileInput from '../../components/input/SingleFileInput.vue';
import ProfilePicture from '../../components/profilepicture/ProfilePicture.vue';
import { Contact } from '../../domain';
import CountriesService from '../../domain/application/CountriesService';
import { isEmail } from '../../utils/validate';

export default Vue.extend({
  name: 'ContactFormPage',
  components: {
    ProfilePicture,
    SingleFileInput,
    AddressAutoComplete,
  },
  props: {
    prefilledValues: { type: Object as PropType<Contact>, default: null },
    headerKey: { type: String },
  },
  data: () => ({
    isFormValid: false,
    selectedCompany: null as { id: string; companyName: string } | null,
    picture: '' as string | undefined,
    submitting: false,
    edit: false,
    contact: {} as AddOrEditContactRequest,
  }),
  computed: {
    countries() {
      return CountriesService.countries;
    },
    genderOptions(): { key: string; value: number }[] {
      return [
        { key: this.$t('FEMALE'), value: 0 },
        { key: this.$t('MALE'), value: 1 },
        { key: this.$t('DIVERS'), value: 2 },
        { key: this.$t('NOT_STATED'), value: 3 },
      ];
    },
  },
  async created() {
    if (this.prefilledValues) {
      this.edit = true;
      this.contact = {
        ...this.prefilledValues,
        infotext: this.prefilledValues.info,
        sex: this.prefilledValues.gender,
        dob: this.prefilledValues.dateofbirth,
        salutationpriv: this.prefilledValues.salutationpriv,
      };
    }

    this.picture = this.prefilledValues.picture;

    if (this.prefilledValues.locations?.length) {
      const location = this.prefilledValues.locations[0];

      this.contact.locationID = location.locationID;
      this.contact.privstreet = location.street;
      this.contact.privcountrycode = location.countryid;
      this.contact.privzip = location.zip;
      this.contact.privcity = location.city;
    }

    if (this.prefilledValues.ANR && this.prefilledValues.companyname) {
      this.selectedCompany = {
        id: this.prefilledValues.ANR,
        companyName: this.prefilledValues.companyname,
      };
    }
  },
  methods: {
    validateEmail(v: string) {
      if (!v) return true;
      if (!isEmail(v)) return this.$t('EMAIL_NOT_VALID', { email: v });
      return true;
    },
    validateInfoText(v: string) {
      return v.length <= 80 || this.$t('INFOTEXT_NOT_VALID');
    },
    validate() {
      (this.$refs.submitFormRef as any).validate();
    },
    showFileInput() {
      (this.$refs.singleFileInput as any).click();
    },
    setPicture(event: { base64: string }) {
      this.picture = event.base64;
    },
    closeModal() {
      this.$emit('close', false);
    },
    async submitForm() {
      try {
        this.validate();
        if (!this.isFormValid) return;

        this.submitting = true;

        this.contact.jpg = this.picture;

        // Allows null values; send with null means reset the company association
        this.contact.anr = this.selectedCompany?.id;

        if (this.edit) {
          new EditContactCommand({
            contact: this.contact,
          }).execute();
        } else {
          const { ok, data } = await new AddContactCommand({
            contact: this.contact,
          }).execute();
          if (ok && !!data) {
            this.$router.replace({
              name: 'contact-detail',
              params: { typeId: data.toString() },
            });
          }
        }
      } catch (error) {
        // TODO: ? NOTY?
      } finally {
        this.submitting = false;
      }
      this.closeModal();
    },
  },
});
