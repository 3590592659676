




































import Vue from 'vue';

export default Vue.extend({
  name: 'BaseModal',
  components: {},
  props: {
    open: { type: Boolean, default: false },
    title: { type: String, default: '' },
    subtitle: { type: String, default: '' },
    fullscreen: [Boolean, String],
    width: { type: String, default: '450' },
    height: { type: String, default: 'auto' },
    headerKey: { type: String },
    preventClose: { type: Boolean },
  },
  computed: {
    isFullscreen(): boolean {
      if (this.fullscreen === 'mobile') {
        return (this.$vuetify as any).breakpoint.mobile;
      }

      return !!this.fullscreen;
    },
  },
  methods: {
    closeModal() {
      if (!this.preventClose) this.$emit('close');
    },
  },
});
