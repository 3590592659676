






































import { AuthService } from '@consolidate/shared/util-auth';
import { ensureMinApiVersion } from 'apps/web/src/utils/versioning';
import Vue, { PropType } from 'vue';
import { User } from '../../../domain';
import EmployeeService from '../../../domain/application/EmployeeService';
import ModalService from '../../../logic/services/ModalService';

export default Vue.extend({
  name: 'ModalActivityDelegate',
  props: {
    user: { type: String },
    callback: {
      type: Object,
      default() {
        return {
          onYes: null,
        };
      },
    },
    context: { type: String as PropType<'create' | 'edit'> },
  },
  data: () => ({
    error: '',
    blurButtons: false,
    selectedUser: {} as User,
    comment: '',
    isLoading: false,
  }),
  computed: {
    myUserName() {
      return AuthService.getUser()?.uid;
    },
    allUsers(): User[] {
      return EmployeeService.items;
    },
    allowedUsers(): User[] {
      return this.allUsers.filter((x) => x.allowDelegation);
    },
    delegationCommentDisabled(): boolean {
      return this.context === 'create' && !ensureMinApiVersion('5.2039', true);
    },
  },
  created() {
    const index = this.allUsers.findIndex(
      (u) => u.userName === this.user ?? this.myUserName
    );
    this.selectedUser = this.allUsers[index];
  },
  methods: {
    async handleYesCallback() {
      // Check if user is different
      if (this.selectedUser.userName === this.user) {
        this.error = this.$t('ACT_DELEGATE_TO_SAME_USER_ERROR');
        this.blurButtons = true;
      } else {
        if (
          this.selectedUser.substituteStart &&
          this.selectedUser.substituteEnd
        ) {
          const start = new Date(this.selectedUser.substituteStart);
          const end = new Date(this.selectedUser.substituteEnd);
          const now = new Date();

          if (start <= now && end >= end) {
            ModalService.confirmSubstitute({
              user: this.selectedUser,
              callback: {
                onYes: ({ userName }) => {
                  this.confirm(userName);
                },
              },
            });
            return;
          }
        }
        this.confirm(this.selectedUser.userName);
      }
    },
    async confirm(userName: string) {
      const { onYes } = this.callback;

      if (onYes) {
        this.isLoading = true;
        await onYes({
          userName: userName,
          comment: this.comment,
        });
        this.isLoading = false;
      }

      this.$emit('close');
    },
  },
});
