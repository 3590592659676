import OverviewFacade from '../../../domain/application/OverviewFacade';
import { getConsiDateTimeFromUTCString } from '../../../utils/date';
import Client from '../../api/Client';
import ConsolidateBaseCommand from '../baseCommands/ConsolidateBaseCommand';

interface Payload {
  id: number;
  dateTimeUtc: string;
}

class SetActivityDueDateCommand extends ConsolidateBaseCommand<Payload> {
  constructor(payload: Payload) {
    super('SetActivityDueDateCommand', payload);
  }

  async execute() {
    try {
      const { id, dateTimeUtc } = this.payload;

      const dto = {
        aktid: id,
        date: getConsiDateTimeFromUTCString(dateTimeUtc),
      };

      const data = await new Client().api.setDate(dto);
      const success = data?._return === 'TRUE';

      return this.result(success);
    } catch (error) {
      if (this.isRetryable(error)) {
        return this.save();
      }
      return this.notOk();
    }
  }

  protected onSuccess() {
    OverviewFacade.refreshCounts();
  }
}

export default SetActivityDueDateCommand;
