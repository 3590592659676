













import { GetAktResponseBaseObjects } from '@consolidate/shared/data-access-legacy-api';
import Vue, { PropType } from 'vue';
import ObjectsFacade from '../../domain/application/ObjectsFacade';

export default Vue.extend({
  name: 'ObjectAutoComplete',
  props: {
    label: { type: String },
    multiple: { type: Boolean, default: true },
    value: { type: [] as PropType<GetAktResponseBaseObjects> },
  },
  computed: {
    initialOptions() {
      return ObjectsFacade.items;
    },
  },
  methods: {
    async findItems(val: string) {
      const result = await ObjectsFacade.findItems(val);
      return result.items;
    },
    updateValue(val: GetAktResponseBaseObjects[] | null) {
      this.$emit('input', val);
    },
  },
});
