import { Model } from '@vuex-orm/core';

export abstract class ListModel extends Model {
  static state() {
    return {
      loading: false,
      filterString: '',
      hash: 0,
      loadError: false,
    };
  }
}
