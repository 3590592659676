import { NotificationService } from '@consolidate/shared/ui-components';
import { translate } from '@consolidate/shared/util-translations';
import Client from '../../api/Client';
import ConsolidateBaseCommand from '../baseCommands/ConsolidateBaseCommand';

interface Payload {
  activityIDs: number[];
  subject: string;
  folderId: number;
}

class AddActivityToFolderCommand extends ConsolidateBaseCommand<
  Payload,
  boolean
> {
  constructor(payload: Payload) {
    super('AddActivityToFolderCommand', payload);
  }

  async execute() {
    const { activityIDs, folderId } = this.payload;
    try {
      const dto = {
        aktids: this.toConsolidateCSV(activityIDs, ','),
        folderid: folderId,
      };

      const response = await new Client().api.addAktsToFolder(dto);
      const statusCode = +response._return;

      // 0 - success, 4 - already in folder
      if (statusCode === 0 || statusCode === 4) {
        return this.ok(statusCode === 4);
      } else {
        return this.notOk();
      }
    } catch (error) {
      if (this.isRetryable(error)) {
        return this.save();
      }
      return this.notOk();
    }
  }

  protected onError() {
    NotificationService.showError({
      message: translate('ACT_ADD_TO_FOLDER_ERROR'),
    });
  }

  protected onSuccess(alreadyInFolder: boolean) {
    const { subject } = this.payload;

    if (!alreadyInFolder) {
      NotificationService.showSuccess({
        message: translate('ADDED_ACT_TO_FOLDER_TEXT', {
          act: subject,
        }),
      });
    } else {
      NotificationService.showInfo({
        message: translate('ACT_ALREADY_IN_FOLDER_TEXT', {
          act: subject,
        }),
      });
    }
  }
}

export default AddActivityToFolderCommand;
