



































import ButtonBar from '../../button/ButtonBar.vue';
import Vue, { PropType } from 'vue';
import EmployeeService from '../../../domain/application/EmployeeService';
import { User, WorkflowStep } from '../../../domain';

export default Vue.extend({
  components: {
    ButtonBar,
  },
  props: {
    workflow: { type: Object as PropType<WorkflowStep>, default: null },
    allowMultiple: { type: Boolean },
    callback: {
      type: Function,
    },
  },
  data() {
    return {
      selectedUsers: [],
      isLoading: false,
    };
  },
  computed: {
    users(): User[] {
      return EmployeeService.items;
    },
  },
  methods: {
    async continueWorkflow() {
      if (!this.selectedUsers.length) return;

      const workflowData = {
        username: this.selectedUsers.join(','),
      };

      if (this.callback) {
        this.isLoading = true;
        await this.callback(workflowData);
        this.isLoading = false;
      }

      this.close();
    },
    close() {
      this.$emit('close');
    },
  },
});
