import {
  AdditionalCommunication,
  GetAddressResponseContacts,
  GetAddressResponseObjects,
} from '@consolidate/shared/data-access-legacy-api';
import { Address } from '../../..';
import { AdditionalData } from '../../../entitites';
import { ContactModel } from './contactHierarchy';

export class AddressDetailModel extends ContactModel implements Address {
  static entity = 'addressDetails';
  static baseEntity = ContactModel.entity;

  id!: string;
  active!: boolean;
  ANR!: string;
  name!: string;
  isfavorite!: boolean;
  name2?: string;
  name3?: string;
  street?: string;
  city?: string;
  zip?: string;
  state?: string;
  country?: string;
  countryid?: string;
  pobox?: string;
  poboxzip?: string;
  tel?: string;
  telprev?: string;
  telcomplete?: string;
  fax?: string;
  lastChange?: string;
  comment?: string;
  communications?: AdditionalCommunication[];
  contacts?: GetAddressResponseContacts[];
  additionalData?: AdditionalData;
  objects?: GetAddressResponseObjects[];

  static fields() {
    return {
      ...super.fields(),
      item_type: this.string('ADDRESS_DETAIL'),

      active: this.boolean(false),
      ANR: this.string(''),
      name: this.string(''),
      isfavorite: this.boolean(false),

      name2: this.string(null).nullable(),
      name3: this.string(null).nullable(),
      street: this.string(null).nullable(),
      city: this.string(null).nullable(),
      zip: this.string(null).nullable(),
      state: this.string(null).nullable(),
      country: this.string(null).nullable(),
      countryid: this.string(null).nullable(),
      pobox: this.string(null).nullable(),
      poboxzip: this.string(null).nullable(),
      tel: this.string(null).nullable(),
      telprev: this.string(null).nullable(),
      telcomplete: this.string(null).nullable(),
      fax: this.string(null).nullable(),
      lastChange: this.string(null).nullable(),
      comment: this.string(null).nullable(),
      communications: this.attr(null).nullable(),
      contacts: this.attr(null).nullable(),
      additionalData: this.attr(null).nullable(),
      objects: this.attr(null).nullable(),
    };
  }

  static state() {
    return {
      ...super.state(),
      loadingDetail: false,
      detailId: null as number | null,
      canEditAddress: false,
      loadError: false,
    };
  }
}
