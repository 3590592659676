import {
  AppointmentStatus,
  BlockType,
  CalendarItemType,
} from '../../../entitites';
import { ActivityDetailModel } from './activityDetailModel';
import { ListModel } from './listModel';

export class CalendarItemModel extends ListModel {
  static entity = 'calendarItems';

  id!: string;
  type!: CalendarItemType;
  start!: string;
  end!: string;
  eventStart!: string;
  eventEnd!: string;
  allDay!: boolean;
  backupIcon!: string;
  subject!: string;
  backupIconColor!: string;
  user!: string;
  aktid?: number;
  isRecurring?: boolean;
  status!: AppointmentStatus;
  blocktype!: BlockType;

  get icon() {
    switch (this.type) {
      case CalendarItemType.BIRTHDAY:
        return 'Birthday';
      case CalendarItemType.JUBILEUM:
        return 'Anniversary';
      case CalendarItemType.HOLIDAY:
        return 'Holiday';
      default:
        return this.activity?.icon ?? this.backupIcon;
    }
  }

  get iconColor() {
    return this.activity?.iconColor ?? this.backupIconColor;
  }

  activity?: ActivityDetailModel;

  static fields() {
    return {
      id: this.string(0),
      type: this.string(''),
      start: this.string(''),
      end: this.string(''),
      eventStart: this.string(''),
      eventEnd: this.string(''),
      allDay: this.boolean(false),
      subject: this.string(''),
      backupIcon: this.string(''),
      backupIconColor: this.string(null).nullable(),
      user: this.string(''),
      isRecurring: this.boolean(false).nullable(),
      status: this.number(0),
      blocktype: this.number(0),

      aktid: this.number(null).nullable(),
      activity: this.belongsTo(ActivityDetailModel, 'aktid'),
    };
  }
}
