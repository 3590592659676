
































import AttachmentListRow from './AttachmentListRow.vue';

import Vue, { PropType } from 'vue';
import { ActivityTypeDocsAllowed } from '../../domain';
import ActivityDetailFacade from '../../domain/application/ActivityDetailFacade';
import {
  FileWithBlob,
  ensureFileSize,
  fileToBase64,
} from '../../utils/attachment';
import FileInput from '../input/FileInput.vue';

export default Vue.extend({
  components: {
    AttachmentListRow,
    FileInput,
  },
  props: {
    canEdit: { type: Boolean, default: false },
    canAdd: { type: Number as PropType<ActivityTypeDocsAllowed> },
  },
  computed: {
    items() {
      return ActivityDetailFacade.Activity?.attachments;
    },
  },
  data() {
    return {
      uploadFiles: [] as FileWithBlob[],
      isUploading: false,
    };
  },
  methods: {
    async uploadFile() {
      while (this.uploadFiles.length > 0) {
        const file = this.uploadFiles[0];

        if (!ensureFileSize([file])) {
          this.uploadFiles.pop();
          continue;
        }

        file.uploading = true;

        await ActivityDetailFacade.addAttachment({
          name: file.name,
          size: file.size,
          mimeType: file.type,
          base64Data: await fileToBase64(file.blob),
        });

        this.uploadFiles.splice(0, 1);
      }
    },
    async downloadAll() {
      if (!this.items) return;

      for (let index = 0; index < this.items.length; index += 1) {
        const element = this.$refs[`item${index}`] as any;

        // Since the webservice can't handle parallel requests
        // We need to wait for them.
        // eslint-disable-next-line no-await-in-loop
        await element[0].download();
      }
    },
  },
});
