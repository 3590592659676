import { User } from '../../../entitites';
import { ListModel } from './listModel';

export class EmployeeModel extends ListModel implements User {
  static entity = 'employees';
  static primaryKey = 'userName';

  cid!: number;
  userName!: string;
  firstName!: string;
  lastName!: string;
  phoneNumber!: string;
  mobilePhoneNumber!: string;
  isTeam!: boolean;
  fullName!: string;
  allowDelegation!: boolean;
  substituteUser?: string;
  substituteStart?: string;
  substituteEnd?: string;
  substituteInfo?: string;

  static fields() {
    return {
      cid: this.number(0),
      userName: this.string(''),
      firstName: this.string(''),
      lastName: this.string(''),
      phoneNumber: this.string(''),
      mobilePhoneNumber: this.string(''),
      isTeam: this.boolean(false),
      fullName: this.string(''),
      allowDelegation: this.boolean(false),
      substituteUser: this.string(null).nullable(),
      substituteStart: this.string(null).nullable(),
      substituteEnd: this.string(null).nullable(),
      substituteInfo: this.string(null).nullable(),
    };
  }
}
