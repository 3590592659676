var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"cs-page"},[_c('cs-toolbar',[_c('v-toolbar-title',[_vm._v(_vm._s(_vm.title))]),_c('v-spacer'),_c('v-btn',{attrs:{"icon":""},on:{"click":_vm.reload}},[_c('cs-icon',{attrs:{"icon":"Refresh","animate":_vm.isLoading,"active":_vm.isLoading}})],1),(_vm.previousViewIndex !== null)?_c('v-btn',{attrs:{"icon":""},on:{"click":_vm.showPreviousView}},[_c('cs-icon',{attrs:{"icon":"SwitchView"}})],1):_vm._e(),_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('cs-icon',{attrs:{"icon":_vm.view.icon}})],1)]}}])},[_c('v-list',_vm._l((_vm.views),function(v,i){return _c('v-list-item',{key:i,attrs:{"link":""},on:{"click":function($event){_vm.viewIndex = i}}},[_c('v-list-item-icon',[_c('cs-icon',{attrs:{"icon":v.icon}})],1),_c('v-list-item-title',{domProps:{"textContent":_vm._s(_vm.$t(v.displayName))}})],1)}),1)],1),_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('cs-icon',[_vm._v("ExtendedMenu")])],1)]}}])},[_c('v-list',_vm._l((_vm.actions),function(action,i){return _c('v-list-item',{key:i,attrs:{"link":""},on:{"click":action.handler}},[_c('v-list-item-title',[_vm._v(_vm._s(action.name))])],1)}),1)],1)],1),_c('div',{staticClass:"cs-content"},[(_vm.loadError)?_c('offline-indicator'):_c('div',[_c('v-container',{staticClass:"d-flex flex-wrap pb-0",attrs:{"fluid":""}},[_c('span',{staticClass:"d-flex",staticStyle:{"width":"165px"}},[_c('cs-button',{staticStyle:{"z-index":"1","margin-right":"-2px"},attrs:{"icon":""},on:{"click":_vm.prev}},[_c('cs-icon',{attrs:{"icon":"Left"}})],1),_c('cs-button',{staticStyle:{"margin":"0 -10px","padding":"0 8px","flex":"1"},attrs:{"text":""},domProps:{"textContent":_vm._s(_vm.displayDate)},on:{"click":_vm.jumpToDateClick}}),_c('cs-button',{attrs:{"icon":""},on:{"click":_vm.next}},[_c('cs-icon',{attrs:{"icon":"Right"}})],1)],1),_c('v-spacer'),_c('span',{staticClass:"mr-2"},[_c('cs-toggle-button',{staticClass:"mr-2",attrs:{"icon":""},model:{value:(_vm.showAnniversaries),callback:function ($$v) {_vm.showAnniversaries=$$v},expression:"showAnniversaries"}},[_c('v-badge',{attrs:{"content":_vm.anniversaryCount.toString(),"value":_vm.anniversaryCount,"overlap":"","color":"accent"}},[_c('cs-icon',{attrs:{"icon":"Anniversary"}})],1)],1),_c('cs-toggle-button',{attrs:{"icon":""},model:{value:(_vm.showBirthdays),callback:function ($$v) {_vm.showBirthdays=$$v},expression:"showBirthdays"}},[_c('v-badge',{attrs:{"content":_vm.birthdayCount.toString(),"value":_vm.birthdayCount,"overlap":"","color":"accent"}},[_c('cs-icon',{attrs:{"icon":"Birthday"}})],1)],1)],1),_c('cs-button',{attrs:{"outlined":""},domProps:{"textContent":_vm._s(_vm.$t('TODAY'))},on:{"click":function($event){_vm.jumpToDate(new Date())}}})],1),(_vm.view.key === 'agenda')?_c('agenda-view',{directives:[{name:"touch",rawName:"v-touch",value:({
          left: _vm.next,
          right: _vm.prev,
        }),expression:"{\n          left: next,\n          right: prev,\n        }"}],ref:"activeView",attrs:{"selectedDate":_vm.selectedDate,"events":_vm.events},on:{"reload":_vm.handleReload,"navigate":_vm.handleNavigate,"dateSelected":_vm.jumpToDate}}):_c('calendar-view',{directives:[{name:"touch",rawName:"v-touch",value:({
          left: _vm.next,
          right: _vm.prev,
        }),expression:"{\n          left: next,\n          right: prev,\n        }"}],ref:"activeView",staticStyle:{"height":"100%"},attrs:{"selectedDate":_vm.selectedDate,"events":_vm.events,"type":_vm.view.key},on:{"reload":_vm.handleReload,"navigate":_vm.handleNavigate,"dateSelected":_vm.jumpToDate}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }