import { NotificationService } from '@consolidate/shared/ui-components';
import { translate } from '@consolidate/shared/util-translations';
import Client from '../../api/Client';
import ConsolidateBaseCommand from '../baseCommands/ConsolidateBaseCommand';

interface Payload {
  id: number;
  alarmTime: string | number;
}

class SetActivityAlarmCommand extends ConsolidateBaseCommand<Payload> {
  constructor(payload: Payload) {
    super('SetActivityAlarmCommand', payload);
  }

  async execute() {
    try {
      const { id, alarmTime } = this.payload;

      const data = await new Client().api.setAlarmTime({
        aktid: id,
        alarmtime: alarmTime,
      });

      const success = data?._return === 'TRUE';

      return this.result(success);
    } catch (error) {
      if (this.isRetryable(error)) {
        return this.save();
      }
      return this.notOk();
    }
  }

  protected onError() {
    NotificationService.showError({
      message: translate('ADDING_REMINDER_TO_ACTIVITY_FAILED'),
    });
  }
}

export default SetActivityAlarmCommand;
