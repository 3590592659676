





















































































import Vue, { PropType } from 'vue';
import { Activity, AdditionalDataForm } from '../../../domain';
import ActivityDetailHeader from './partials/ActivityDetailHeader.vue';
import AttachmentsList from '../../list/AttachmentsList.vue';
import ActivityDetailInfos from './partials/ActivityDetailInfos.vue';
import ActivityDetailContacts from './partials/ActivityDetailContacts.vue';
import ActivityDetailHistory from './partials/ActivityDetailHistory.vue';
import ActivityService from '../../../domain/application/ActivityService';
import ActivityDetailObjects from './partials/ActivityDetailObjects.vue';
import ModalService from 'apps/web/src/logic/services/ModalService';
import ActivityDetailFacade from 'apps/web/src/domain/application/ActivityDetailFacade';

export default Vue.extend({
  name: 'ActivityDetailTemplate',
  components: {
    ActivityDetailHeader,
    AttachmentsList,
    ActivityDetailInfos,
    ActivityDetailHistory,
    ActivityDetailContacts,
    ActivityDetailObjects,
  },
  props: {
    data: { type: Object as PropType<Activity> },
  },
  data: () => ({
    selectedTab: 0,
    body: null as string | null,
    currentId: 0,
  }),
  watch: {
    data: {
      async handler(data: Activity) {
        if (!data) return;

        if (data.id !== this.currentId) {
          this.selectedTab = 0;
        }
        this.currentId = data.id;

        this.body = null;
        this.body = await ActivityService.getActivityComment(data.id);
      },
      immediate: true,
    },
  },
  methods: {
    canEdit(form: AdditionalDataForm) {
      return this.data.rights.edit && form.fields.some((x) => x.editable);
    },
    edit(formKey: string) {
      const form = this.data.additionalData?.find((x) => x.key == formKey);
      if (form) {
        ModalService.editAdditionalData({
          fields: form.fields,
          callback: (fields) =>
            ActivityDetailFacade.editAdditionalData(form.key, fields),
        });
      }
    },
  },
});
