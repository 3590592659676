/* tslint:disable */
/* eslint-disable */
/**
 * Consolidate App API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 0 = NoRepeat
 * 1 = Daily
 * 4 = MonthlyByPos
 * 6 = YearlyByPos
 * 10 = MonthlyByPosAndClass
 * 11 = YearlyByPosAndClass
 * 12 = WeeklyDays
 * @export
 * @enum {string}
 */
export enum ActivityRecurrenceType {
    NUMBER_0 = 0,
    NUMBER_1 = 1,
    NUMBER_4 = 4,
    NUMBER_6 = 6,
    NUMBER_10 = 10,
    NUMBER_11 = 11,
    NUMBER_12 = 12
}

export function ActivityRecurrenceTypeFromJSON(json: any): ActivityRecurrenceType {
    return ActivityRecurrenceTypeFromJSONTyped(json, false);
}

export function ActivityRecurrenceTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): ActivityRecurrenceType {
    return json as ActivityRecurrenceType;
}

export function ActivityRecurrenceTypeToJSON(value?: ActivityRecurrenceType | null): any {
    return value as any;
}

