
































import Vue, { PropType } from 'vue';
import { AdditionalDataEntry, AdditionalDataType } from '../../../domain';
import AdditionalDataEdit from '../../shared/AdditionalData/AdditionalDataEdit.vue';

export default Vue.extend({
  components: { AdditionalDataEdit },
  props: {
    fields: { type: Array as PropType<AdditionalDataEntry[]>, required: true },
    callback: {
      type: Function as PropType<
        (fields: AdditionalDataEntry[]) => Promise<void>
      >,
      required: true,
    },
  },
  data() {
    return {
      editableFields: [
        ...this.fields
          .filter((x) => x.editable && x.type !== AdditionalDataType.Html)
          .map((x) => ({ ...x })),
      ],
      isValid: false,
      isLoading: false,
    };
  },
  computed: {
    isDirty(): boolean {
      return (
        JSON.stringify(
          this.fields.filter(
            (x) => x.editable && x.type !== AdditionalDataType.Html
          )
        ) !== JSON.stringify(this.editableFields)
      );
    },
  },
  methods: {
    validate() {
      (this.$refs.formRef as any).validate();
    },
    async save() {
      this.validate();
      if (!this.isValid) return;

      // set description for source and select values
      this.editableFields = this.editableFields.map((x) => {
        switch (x.type) {
          case AdditionalDataType.Source:
            return { ...x, description: x.value?.value };
          case AdditionalDataType.Radio:
          case AdditionalDataType.Select:
            return {
              ...x,
              description: x.options.find((o) => o.value === x.value)?.label,
            };
          default:
            return x;
        }
      });

      this.isLoading = true;
      await this.callback(this.editableFields);
      this.isLoading = false;

      this.$emit('close');
    },
  },
});
