/* tslint:disable */
/* eslint-disable */
/**
 * Consolidate App API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    LoginCredentialsRequest,
    LoginCredentialsRequestFromJSON,
    LoginCredentialsRequestToJSON,
    LoginOAuthRequest,
    LoginOAuthRequestFromJSON,
    LoginOAuthRequestToJSON,
    Provider,
    ProviderFromJSON,
    ProviderToJSON,
    Result4,
    Result4FromJSON,
    Result4ToJSON,
    Result5,
    Result5FromJSON,
    Result5ToJSON,
} from '../models';

export interface MeetingsGetOAuthUrlsRequest {
    provider: Provider;
    redirectUrl?: string | null;
    state?: string | null;
}

export interface MeetingsLoginCredentialsRequest {
    provider: Provider;
    loginCredentialsRequest: LoginCredentialsRequest;
}

export interface MeetingsLoginOAuthRequest {
    provider: Provider;
    loginOAuthRequest: LoginOAuthRequest;
}

/**
 * 
 */
export class MeetingsApi extends runtime.BaseAPI {

    /**
     */
    async meetingsGetOAuthUrlsRaw(requestParameters: MeetingsGetOAuthUrlsRequest): Promise<runtime.ApiResponse<Result5>> {
        if (requestParameters.provider === null || requestParameters.provider === undefined) {
            throw new runtime.RequiredError('provider','Required parameter requestParameters.provider was null or undefined when calling meetingsGetOAuthUrls.');
        }

        const queryParameters: any = {};

        if (requestParameters.redirectUrl !== undefined) {
            queryParameters['redirectUrl'] = requestParameters.redirectUrl;
        }

        if (requestParameters.state !== undefined) {
            queryParameters['state'] = requestParameters.state;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("oauth2", []);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/app/v1/Meetings/providers/{provider}/oauthUrls`.replace(`{${"provider"}}`, encodeURIComponent(String(requestParameters.provider))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => Result5FromJSON(jsonValue));
    }

    /**
     */
    async meetingsGetOAuthUrls(provider: Provider, redirectUrl?: string | null, state?: string | null): Promise<Result5> {
        const response = await this.meetingsGetOAuthUrlsRaw({ provider: provider, redirectUrl: redirectUrl, state: state });
        return await response.value();
    }

    /**
     */
    async meetingsGetOnlineMeetingProvidersRaw(): Promise<runtime.ApiResponse<Result4>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("oauth2", []);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/app/v1/Meetings/providers`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => Result4FromJSON(jsonValue));
    }

    /**
     */
    async meetingsGetOnlineMeetingProviders(): Promise<Result4> {
        const response = await this.meetingsGetOnlineMeetingProvidersRaw();
        return await response.value();
    }

    /**
     */
    async meetingsLoginCredentialsRaw(requestParameters: MeetingsLoginCredentialsRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.provider === null || requestParameters.provider === undefined) {
            throw new runtime.RequiredError('provider','Required parameter requestParameters.provider was null or undefined when calling meetingsLoginCredentials.');
        }

        if (requestParameters.loginCredentialsRequest === null || requestParameters.loginCredentialsRequest === undefined) {
            throw new runtime.RequiredError('loginCredentialsRequest','Required parameter requestParameters.loginCredentialsRequest was null or undefined when calling meetingsLoginCredentials.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("oauth2", []);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/app/v1/Meetings/providers/{provider}/loginCredentials`.replace(`{${"provider"}}`, encodeURIComponent(String(requestParameters.provider))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: LoginCredentialsRequestToJSON(requestParameters.loginCredentialsRequest),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async meetingsLoginCredentials(provider: Provider, loginCredentialsRequest: LoginCredentialsRequest): Promise<void> {
        await this.meetingsLoginCredentialsRaw({ provider: provider, loginCredentialsRequest: loginCredentialsRequest });
    }

    /**
     */
    async meetingsLoginOAuthRaw(requestParameters: MeetingsLoginOAuthRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.provider === null || requestParameters.provider === undefined) {
            throw new runtime.RequiredError('provider','Required parameter requestParameters.provider was null or undefined when calling meetingsLoginOAuth.');
        }

        if (requestParameters.loginOAuthRequest === null || requestParameters.loginOAuthRequest === undefined) {
            throw new runtime.RequiredError('loginOAuthRequest','Required parameter requestParameters.loginOAuthRequest was null or undefined when calling meetingsLoginOAuth.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("oauth2", []);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/app/v1/Meetings/providers/{provider}/loginOAuth`.replace(`{${"provider"}}`, encodeURIComponent(String(requestParameters.provider))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: LoginOAuthRequestToJSON(requestParameters.loginOAuthRequest),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async meetingsLoginOAuth(provider: Provider, loginOAuthRequest: LoginOAuthRequest): Promise<void> {
        await this.meetingsLoginOAuthRaw({ provider: provider, loginOAuthRequest: loginOAuthRequest });
    }

}
