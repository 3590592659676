































import Vue, { PropType } from 'vue';
import { ProjectItem } from '../../domain';

export default Vue.extend({
  props: {
    items: { type: Array as PropType<ProjectItem[]> },
    loading: { type: Boolean, default: false },
  },
  methods: {
    getDates(item: ProjectItem) {
      let start = item.start ? new Date(+item.start) : null;
      let end = item.end ? new Date(+item.end) : null;
      let startDate = null;
      let endDate = null;

      if (start) startDate = start.toLocaleDateStringLS();
      if (end && start && !end.toISOString().includes('1899')) {
        endDate = end.toLocaleDateStringLS();
      }
      return [startDate, endDate].filter(Boolean).join(' - ');
    },
  },
});
