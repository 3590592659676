import { CustomFormField } from '@consolidate/shared/data-access-legacy-api';
import { AppAdditionalDataForm } from '../../logic/api/gen';
import {
  AdditionalData,
  AdditionalDataEntry,
  AdditionalDataForm,
  AdditionalDataType,
} from '../entitites';

type CustomForm = {
  title?: string;
  [key: string]: CustomFormField | string | undefined;
};

/**
 * This class will parse the dynamic additional data (Zusatzdaten) from consolidates webservice.
 * Within consolidate an Activity type can have up to 3 forms. A global one that
 * applies to all activities, then a form 1 and form 2 that can be different per
 * activity type. Please note that an activity can have a form 2 but not a global
 * or a form 1 attached!
 * There are a couple of types that can be used within Formular-designer. And they need to be
 * turned into its representing type.
 * An additional data field will only be sent back, when the user is allowed to see that field, AND
 * when the Form in Formular-designer has been changed to render the fields on Mobile. To accomplish
 * this, the user responsible for the forms must add a *position* and *key*. To each field that he
 * wants to be visible on consolidate-web. The position is used to determine the order in which it
 * will show up in the list. so position 0 will show first followed by position 1 and so on.
 *
 * Projects, contacts and addresses can also contain additional data.
 */
export class AdditionalDataFactory {
  public parse(daten?: Record<string, CustomForm>): AdditionalData {
    if (!daten) return [];

    const keys = Object.keys(daten);
    return keys.map((key) => this.parseForm(daten[key], key));
  }

  public parseNew(forms?: AppAdditionalDataForm[]): AdditionalData {
    if (!forms) return [];

    return forms.map((form) => {
      const customForm: CustomForm = {
        title: form.title,
        ...form.fields,
      };

      return this.parseForm(customForm, form.name);
    });
  }

  /**
   * Parses the fields within one form
   * @param {Array} daten The parsed fields of the form
   */
  public parseForm(daten: CustomForm, formName: string): AdditionalDataForm {
    const form: AdditionalDataForm = {
      key: formName,
      title: formName,
      fields: [],
    };
    const keys = Object.keys(daten);
    const positionSortedKeys = keys.sort((a: any, b: any) => a.pos - b.pos);
    positionSortedKeys.forEach((fieldName) => {
      if (fieldName === 'title') {
        form.title = daten[fieldName] ?? '';
      } else {
        const data = daten[fieldName];

        if (typeof data === 'object')
          form.fields.push(this.parseField(formName, fieldName, data));
      }
    });

    return form;
  }

  /**
   * Parses a single field from the form. Checks the type and will transform the
   * data in its needed form
   * @param {String} fieldName The key of the key value pair
   * @param {Object} fieldData One field-data from the conslidate api that needs to be parsed
   */
  private parseField(
    formName: string,
    fieldName: string,
    fieldData: CustomFormField
  ): AdditionalDataEntry {
    const fieldType = fieldName.substring(0, 3);
    const base = {
      formName: formName,
      key: fieldName,
      caption: fieldData.caption,
      description: fieldData.description,
      editable: fieldData.editable ?? false,
      required: fieldData.required ?? false,
    };
    switch (fieldType) {
      case 'tim':
        return {
          ...base,
          type: AdditionalDataType.Time,
          value: this.timeField(fieldData),
        };
      case 'dat':
        return {
          ...base,
          type: AdditionalDataType.Date,
          value: this.dateField(fieldData),
        };
      case 'rtf':
        return {
          ...base,
          type: AdditionalDataType.Html,
          value: this.htmlField(fieldData),
        };
      case 'chk':
        return {
          ...base,
          type: AdditionalDataType.Boolean,
          value: this.booleanField(fieldData),
        };
      case 'clr':
        return {
          ...base,
          type: AdditionalDataType.Color,
          value: this.colorField(fieldData),
        };
      case 'txt':
        return {
          ...base,
          type: AdditionalDataType.Text,
          value: this.textField(fieldData),
        };
      case 'txm':
        return {
          ...base,
          type: AdditionalDataType.MultilineText,
          value: this.textField(fieldData),
        };
      case 'num':
        return {
          ...base,
          type: AdditionalDataType.Number,
          value: this.numberField(fieldData),
        };
      case 'opt':
        return {
          ...base,
          type: AdditionalDataType.Radio,
          value: this.numberField(fieldData),
          options: this.options(fieldData),
        };
      case 'lst':
      case 'cmb':
        return {
          ...base,
          type: AdditionalDataType.Select,
          value: this.numberField(fieldData),
          options: this.options(fieldData),
        };
      case 'src':
        return {
          ...base,
          type: AdditionalDataType.Source,
          value: this.textField(fieldData)
            ? {
                key: this.textField(fieldData) ?? '',
                value: fieldData.description ?? '',
              }
            : null,
        };
      default:
        return {
          ...base,
          type: AdditionalDataType.Unknown,
          value: this.textField(fieldData),
        };
    }
  }

  /**
   * Will parse consolidate time to a Date object.
   * In consolidate time is stored as 1200 for 12:00
   * or 900 for 09:00 or 0900 for 09:00.
   * @param {Object} fieldData The field data from Consolidates webservice
   * @returns {Date|null} Date object with the date set to default and the time set
   * to the parsed time. null if no value is found
   */
  private timeField(fieldData: CustomFormField) {
    const { value } = fieldData;
    if (!value || value === '0' || typeof value !== 'string') return null;
    const parsedTime =
      value.length === 3
        ? `0${value.substring(0, 1)}:${value.substring(1)}`
        : `${value.substring(0, 2)}:${value.substring(2)}`;
    return parsedTime;
  }

  private dateField(fieldData: CustomFormField): string | null {
    if (!fieldData.value || typeof fieldData.value !== 'string') return null;
    return new Date(fieldData.value.substring(0, 10)).toISODateString();
  }

  /**
   * Will set the value as is.
   * In Consolidate HTML is called RTF but it will contain HTML data
   * @param {Object} fieldData The fiel data from Consolidated webservice
   */
  private htmlField(fieldData: CustomFormField): string | null {
    if (typeof fieldData.value !== 'string') return null;
    return fieldData.value ?? null;
  }

  private booleanField(fieldData: CustomFormField): boolean | null {
    return fieldData.value ? Boolean(+fieldData.value) : null;
  }

  private textField(fieldData: CustomFormField): string | null {
    if (typeof fieldData.value !== 'string') return null;
    return fieldData.value;
  }

  private numberField(fieldData: CustomFormField): number | null {
    if (!fieldData.value) return null;

    return Number(fieldData.value);
  }

  private colorField(fieldData: CustomFormField) {
    if (!fieldData.value) return null;

    const Hex = (val: number) =>
      val.toString(16).padStart(2, '0').toUpperCase();

    const value = +fieldData.value;
    const red = Hex(value & 0xff);
    const green = Hex((value & 0xff00) / 0x100);
    const blue = Hex((value & 0xff0000) / 0x10000);

    return `#${red}${green}${blue}`;
  }

  private options(fieldData: any): { value: number; label: string }[] {
    return fieldData.options?.map((val: string, index: number) => ({
      value: index,
      label: val,
    }));
  }
}
