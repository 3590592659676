import { ActivityModel } from './activityModel';
import { ListModel } from './listModel';

export class TeamTaskModel extends ListModel {
  static entity = 'teamTasks';

  id!: number;
  activity!: ActivityModel;

  static fields() {
    return {
      id: this.number(0),
      activity: this.belongsTo(ActivityModel, 'id'),
    };
  }

  static state() {
    return {
      ...super.state(),
      showFuture: false,
      team: undefined,
    };
  }
}
