import { translate } from '@consolidate/shared/util-translations';

export interface ActivityPriority {
  id: 0 | 1 | 2;
  name: string;
}

export const ACTIVITY_PRIORITY_LIST: ActivityPriority[] = [
  { id: 2, name: translate('HIGH') },
  { id: 1, name: translate('NORMAL') },
  { id: 0, name: translate('LOW') },
];
