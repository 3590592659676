import { ContactOrAddress } from '../domain';
import { isAddress, isContact } from './checkType';

export const getContactSubtitle = (item: ContactOrAddress) => {
  if (isContact(item) && item.companyname) {
    return item.companyname + ', ' + item.companyCity;
  }
  if (isAddress(item)) {
    return item.companyCity;
  }
};
