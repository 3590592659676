import Vue from 'vue';
import { ListFacade } from '../domain/application/ListFacade';
import { ListModel } from '../domain/infrastructure/+state/models';
import { filterMixin } from './filterMixin';

export function searchMixin<
  T extends ListModel,
  TSearchResults extends ListModel,
  TListItem extends Record<string, any>
>(
  defaultItems: ListFacade<T, TListItem>,
  searchResults: ListFacade<TSearchResults, TListItem> & {
    searchTerm: string | undefined;
    load(searchTerm?: string): Promise<boolean>;
  }
) {
  return Vue.extend({
    mixins: [filterMixin(defaultItems)],
    data: () => ({
      searched: false,
    }),
    computed: {
      searchResults(): TListItem[] {
        return searchResults.items;
      },
      searchResultsLoadError(): boolean {
        return searchResults.loadError;
      },
      isLoading() {
        return searchResults.loading;
      },
    },
    watch: {
      filterString() {
        this.searched = false;
      },
    },
    created() {
      this.searched =
        defaultItems.filterActive &&
        defaultItems.filterString === searchResults.searchTerm;
    },
    methods: {
      async search() {
        await searchResults.load(defaultItems.filterString);
        this.searched = true;
      },
    },
  });
}
