







































import { translate } from '@consolidate/shared/util-translations';
import Vue from 'vue';
import FoldersFacade from '../../domain/application/FoldersFacade';

export default Vue.extend({
  name: 'FolderPage',
  metaInfo: {
    titleTemplate: `${translate('FOLDERS')}  %s`,
  },
  computed: {
    items() {
      return FoldersFacade.items;
    },
    isLoading() {
      return FoldersFacade.loading;
    },
    loadError(): boolean {
      return FoldersFacade.loadError;
    },
  },
  methods: {
    reload() {
      FoldersFacade.load(true);
    },
  },
});
