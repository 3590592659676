
























































import Vue from 'vue';
import { ContactOrAddress } from '../../domain';
import { capitalize } from '../../utils/string';

export default Vue.extend({
  name: 'ContactAndAddressList',
  components: {},
  props: {
    data: { type: Array, default: () => [] },
    loading: { type: Boolean, default: false },
  },
  methods: {
    capitalize(val: string) {
      return capitalize(val);
    },
    to(item: ContactOrAddress) {
      return {
        name: item.type === 'ADDRESS' ? 'address-detail' : 'contact-detail',
        params: { typeId: item.id },
      };
    },
  },
});
