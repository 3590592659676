/* tslint:disable */
/* eslint-disable */
/**
 * Consolidate App API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface KeyValuePairOfStringAndString
 */
export interface KeyValuePairOfStringAndString {
    /**
     * 
     * @type {string}
     * @memberof KeyValuePairOfStringAndString
     */
    key: string;
    /**
     * 
     * @type {string}
     * @memberof KeyValuePairOfStringAndString
     */
    value: string;
}

export function KeyValuePairOfStringAndStringFromJSON(json: any): KeyValuePairOfStringAndString {
    return KeyValuePairOfStringAndStringFromJSONTyped(json, false);
}

export function KeyValuePairOfStringAndStringFromJSONTyped(json: any, ignoreDiscriminator: boolean): KeyValuePairOfStringAndString {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'key': json['key'],
        'value': json['value'],
    };
}

export function KeyValuePairOfStringAndStringToJSON(value?: KeyValuePairOfStringAndString | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'key': value.key,
        'value': value.value,
    };
}


