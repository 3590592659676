import {
  NotificationHandle,
  NotificationService,
} from '@consolidate/shared/ui-components';
import { translate } from '@consolidate/shared/util-translations';
import * as Sentry from '@sentry/capacitor';
import Vue from 'vue';
import ActivityTypesService from '../../domain/application/ActivityTypesService';
import ConfidentialitiesService from '../../domain/application/ConfidentialitiesService';
import ContactsFacade from '../../domain/application/ContactsFacade';
import CountriesService from '../../domain/application/CountriesService';
import EmployeeService from '../../domain/application/EmployeeService';
import FoldersFacade from '../../domain/application/FoldersFacade';
import JunkmailsFacade from '../../domain/application/JunkmailsFacade';
import MeetingProvidersFacade from '../../domain/application/MeetingProvidersFacade';
import OverviewFacade from '../../domain/application/OverviewFacade';
import ProjectsFacade from '../../domain/application/ProjectsFacade';
import SettingsFacade from '../../domain/application/SettingsFacade';
import TeamsFacade from '../../domain/application/TeamsFacade';
import store from '../../domain/infrastructure/+state/store';
import ConnectivityService from './ConnectivityService';
import ModalService from './ModalService';
import PushService from './PushService';

interface InitializationServiceState {
  isLoading: boolean;
}

class InitializationService {
  public state: InitializationServiceState;

  constructor() {
    this.state = Vue.observable({
      isLoading: false,
      isReloading: false,
    });
  }

  public async loadData() {
    this.state.isLoading = true;
    let notificationShown: Date | null = null;
    let notification: NotificationHandle | null = null;

    const onlineStateChanged = (online: boolean) => {
      if (!online) notification?.hide();
    };

    ConnectivityService.on('onlineStateChanged', onlineStateChanged);

    const timeout = setTimeout(() => {
      if (ConnectivityService.online) {
        notification = NotificationService.showInfo({
          message: translate('ONE_MOMENT_PLEASE'),
          message2: translate('WAITING_FOR_DATA'),
          timeout: -1,
          loading: true,
        });

        notificationShown = new Date();
      }

      // if the data loads within 1 second, we don't want to show a notification
    }, 1000);

    try {
      await store.restored;

      // Settings service needs to be initialized first
      await SettingsFacade.load();
      await PushService.init();
      await OverviewFacade.load();

      this.state.isLoading = false;

      if (timeout) {
        clearTimeout(timeout);
      }
      if (notificationShown && notification) {
        // show the notification for 1 second minimum, else it just flashes and the user can not read it
        const elapsed =
          new Date().getTime() - (notificationShown as Date).getTime();
        setTimeout(() => notification?.hide(), 1000 - elapsed);
      }

      await this.loadBaseData();
    } catch (error) {
      console.error('error during initial load', error);
      Sentry.captureException(error);
      ModalService.error(translate('INITIAL_LOAD_ERROR'), () => {
        window.location.reload();
      });
    }

    ConnectivityService.off('onlineStateChanged', onlineStateChanged);
  }

  private async loadBaseData() {
    await Promise.all([
      ActivityTypesService.load(),
      ProjectsFacade.load(),
      TeamsFacade.load(),
      FoldersFacade.load(),
      JunkmailsFacade.load(),
      EmployeeService.load(),
      ContactsFacade.load(),
      CountriesService.load(),
      ConfidentialitiesService.load(),
      MeetingProvidersFacade.load(),
    ]);
  }
}

export default new InitializationService();
