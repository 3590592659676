


























import Vue from 'vue';
import ActivityFormBase from './partials/ActivityFormBase.vue';
import ContactAutoComplete from '../../input/ContactAutoComplete.vue';
import ContactGroup from '../shared/ContactGroup.vue';
import { ContactOrAddress } from '../../../domain';
import { getContactSubtitle } from '../../../utils/contact';

export default Vue.extend({
  name: 'ActivityFormDocument',
  components: {
    ActivityFormBase,
    ContactAutoComplete,
    ContactGroup,
  },
  methods: {
    getSubtitle(item: ContactOrAddress) {
      return getContactSubtitle(item);
    },
    async focusContacts() {
      (this.$refs.base as any).expand();
      await this.$nextTick();
      (this.$refs.contacts as any).focus();
    },
    getMemberIcon(item: ContactOrAddress | string) {
      if (typeof item === 'string') return 'UnknownEmailAddress';
      if (item.type === 'CONTACT') return 'GenderNeutral';
      if (item.type === 'ADDRESS') return 'Address';
    },
  },
});
