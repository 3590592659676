import { normalizeColor } from '@consolidate/shared/ui-components';
import {
  ActivityFactory,
  ActivityType,
  ActivityTypeDocsAllowed,
  AdditionalDataFactory,
} from '..';
import Client from '../../logic/api/Client';
import { resolveIconId } from '../../utils/activity/icon';
import { ActivityTypeModel } from '../infrastructure/+state/models';
import store from '../infrastructure/+state/store';

class ActivityTypesService {
  public get types(): ActivityType[] {
    return ActivityTypeModel.all().filter((x) => x.active && !x.hideOnMobile);
  }

  public get allTypes(): ActivityType[] {
    return ActivityTypeModel.all();
  }

  public get latestFive(): number[] {
    return store.state.entities[ActivityTypeModel.entity].latestFive;
  }

  public activityCreated(actType: ActivityType) {
    ActivityTypeModel.commit((state) => {
      if (!state.latestFive.includes(actType.id)) {
        state.latestFive.unshift(actType.id);

        if (state.latestFive.length > 5) {
          state.latestFive.pop();
        }
      }
    });
  }

  public async load() {
    try {
      const response = await new Client().api.getAktTypes(undefined, true);

      await ActivityTypeModel.deleteAll();
      if (response.items) {
        ActivityTypeModel.insert({
          data: response.items.map(
            (i): ActivityType => ({
              id: i.id,
              name: i.name,
              technology: ActivityFactory.getTechnologyFromAktSAkt(i.artsakt),
              icon: resolveIconId(i.icon - 100 + 1),
              color: normalizeColor(i.color),
              allowdocs: i.allowmultipledocs
                ? ActivityTypeDocsAllowed.Multiple
                : i.allowdocs
                ? ActivityTypeDocsAllowed.Single
                : ActivityTypeDocsAllowed.None,
              allowobjects: i.allowobjects ?? false,
              defaultanswer: i.defaultanswer,
              defaultanswerall: i.defaultanswerall,
              defaultforward: i.defaultforward,
              workflows: i.workflows?.map((x) => ({
                id: x.processid,
                name: x.processname,
              })),
              secretgroup: i.secretgroup,
              additionalData: new AdditionalDataFactory().parse(i.custom),
              active: i.active ?? true,
              hideOnMobile: i.hideonmobile ?? false,
            })
          ),
        });
      }
    } catch (error) {
      // if we have previously loaded data ignore the error and work with offline data
      // otherwise throw an error
      if (!ActivityTypeModel.all().length) {
        throw error;
      }
    }
  }
}

export default new ActivityTypesService();
