



























































































import ActivityListItemVue from './ActivityListItem.vue';
import Vue, { PropType } from 'vue';
import { ActivityListItem } from '../../../domain';
import ActivityService from '../../../domain/application/ActivityService';

export default Vue.extend({
  components: { ActivityListItem: ActivityListItemVue },
  props: {
    items: { type: Array as PropType<ActivityListItem[]>, required: true },
    isLoadingMore: { type: Boolean, default: false },
    deleteEnabled: { type: Boolean, default: true },
    delegateEnabled: { type: Boolean, default: true },
    markAsReadEnabled: { type: Boolean, default: false },
    markAsDoneEnabled: { type: Boolean, default: true },
    listActionsTarget: { type: String },
    hideResponsible: { type: Boolean, default: false },
    loadError: { type: Boolean, default: false },
    filterActive: { type: Boolean, default: false },
  },
  data: () => ({
    selectedItems: [] as number[],
    selecting: false,
  }),
  computed: {
    canMarkAllAsDone(): boolean {
      const activityItems: ActivityListItem[] = this.items.filter((x) =>
        this.selectedItems.includes(x.id)
      );
      const notAllDone = activityItems.some((x) => x.todoType?.id !== 5);

      return this.selectedItems.length > 0 && notAllDone;
    },
    canDeleteAll(): boolean {
      return this.selectedItems.length > 0;
    },
    canMarkAllAsRead(): boolean {
      return this.selectedItems.length > 0;
    },
    canDelegateAll(): boolean {
      return this.selectedItems.length > 0;
    },
    canRemoveAllFromFolder(): boolean {
      return this.selectedItems.length > 0;
    },
  },
  methods: {
    clearSelection() {
      this.selectedItems = [];
    },
    deleteAll() {
      ActivityService.deleteAll(this.selectedItems);
      this.toggleSelecting();
    },
    markAllAsRead() {
      ActivityService.markAllAsRead(this.selectedItems);
      this.toggleSelecting();
    },
    markAllAsDone() {
      ActivityService.markAllAsDone(this.selectedItems);
      this.toggleSelecting();
    },
    delegateAll() {
      ActivityService.delegateAll(this.selectedItems);
      this.toggleSelecting();
    },
    removeAllFromFolder() {
      const folderId = this.$route.params.typeId;
      ActivityService.removeAllFromFolder(this.selectedItems, +folderId);
    },
    toggleSelection(id: number) {
      const index = this.selectedItems.indexOf(id);
      if (index > -1) {
        this.selectedItems.splice(index, 1);
      } else {
        this.selectedItems.push(id);
      }

      if (this.selectedItems?.length < 1) this.toggleSelecting();
    },
    startSelection(id: number) {
      // if no actions can be performed we don't need selection
      if (!this.listActionsTarget) return;

      this.selecting = true;
      this.selectedItems = [id];
    },
    toggleSelecting() {
      this.clearSelection();
      this.selecting = !this.selecting;
    },
  },
});
