import { AuthService } from '@consolidate/shared/util-auth';
import Vue from 'vue';
import Client from '../../logic/api/Client';
import { OverviewCounts } from '../../logic/api/gen';
import { isOutdatedVersionError, minApiVersion } from '../../utils/versioning';
import { CalendarItem, CalendarItemType } from '../entitites';
import CalendarFacade from './CalendarFacade';
import InboxFacade from './InboxFacade';
import TasksFacade from './TasksFacade';

interface State {
  counts?: OverviewCounts;
  loading: boolean;
}

class OverviewFacade {
  private state: State;
  private interval?: NodeJS.Timeout;

  constructor() {
    this.state = Vue.observable({
      loading: false,
      counts: undefined,
    });

    this.setInterval();
  }

  public get loading(): boolean {
    return this.state.loading;
  }

  public get notificationsCount(): number {
    return (this.inboxCount ?? 0) + (this.spamCount ?? 0);
  }

  public get inboxCount(): number | undefined {
    return this.state.counts?.inbox;
  }

  public get junkmailCount(): number | undefined {
    return this.state.counts?.quarantined;
  }

  public get spamCount(): number | undefined {
    return this.state.counts?.spam;
  }

  public get tasksCount(): number | undefined {
    return this.state.counts?.tasks;
  }

  public get itemsOfToday(): CalendarItem[] {
    const start = new Date(new Date().setHours(0, 0, 0, 0));
    const end = new Date(new Date().setHours(23, 59, 59, 0));
    return CalendarFacade.getItemsByUser(
      AuthService.getUser()?.uid ?? ''
    ).filter(
      (x) =>
        (x.allDay && x.type === CalendarItemType.APPOINTMENT
          ? x.end > start
          : x.end >= start) && x.start <= end
    );
  }

  private setInterval() {
    this.interval = setInterval(() => {
      if (AuthService.isLoggedIn()) {
        this.refreshCountsInternal();
      }
    }, 30 * 1000);
  }

  public async load() {
    this.state.loading = true;

    await Promise.all([
      await this.refreshCountsInternal(),
      await CalendarFacade.load(),
    ]);

    this.state.loading = false;
  }

  public async refreshCounts() {
    if (this.interval) clearInterval(this.interval);

    await this.refreshCountsInternal(true);
    this.setInterval();
  }

  private async refreshCountsInternal(preventFallback = false) {
    try {
      this.state.counts = await this.loadCounts();
    } catch (error) {
      if (isOutdatedVersionError(error)) {
        if (!preventFallback) {
          await Promise.all([InboxFacade.load(), TasksFacade.load()]);
        }

        this.state.counts = {
          inbox: InboxFacade.items.length,
          spam: InboxFacade.spamItems.length,
          tasks: TasksFacade.items.length,
          quarantined: 0,
        };
      }
    }
  }

  @minApiVersion('5.2008', true)
  private async loadCounts() {
    const result = await new Client().overview.overviewGet();
    return result.counts;
  }
}

export default new OverviewFacade();
