import { NotificationService } from '@consolidate/shared/ui-components';
import { translate } from '@consolidate/shared/util-translations';
import ActivityService from '../../../domain/application/ActivityService';
import ActivityTypesService from '../../../domain/application/ActivityTypesService';
import Client from '../../api/Client';
import ConsolidateBaseCommand from '../baseCommands/ConsolidateBaseCommand';

interface Payload {
  actId: number;
  actType: number;
}

class SetActivityTypeCommand extends ConsolidateBaseCommand<Payload> {
  constructor(payload: Payload) {
    super('SetActivityTypeCommand', payload);
  }

  async execute() {
    try {
      const { actId, actType } = this.payload;

      const currentType = ActivityTypesService.types.find(
        (x) => x.id === actType
      );
      if (!currentType) {
        return this.notOk();
      }

      const dto = {
        aktid: actId,
        akttype: actType,
      };

      const data = await new Client().api.setAktType(dto);
      const success = data?._return === 'TRUE';

      return this.result(success);
    } catch (error) {
      if (this.isRetryable(error)) {
        return this.save();
      }
      return this.notOk();
    }
  }

  protected async onSuccess() {
    const currentType = ActivityTypesService.types.find(
      (x) => x.id === this.payload.actType
    );

    if (currentType) {
      ActivityService.activityTypeChanged(this.payload.actId, currentType);
    }
  }

  protected onError() {
    NotificationService.showError({
      message: translate('COULDNT_CHANGE_ACTIVITY'),
    });
  }
}

export default SetActivityTypeCommand;
