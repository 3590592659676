import { NotificationService } from '@consolidate/shared/ui-components';
import { translate } from '@consolidate/shared/util-translations';
import 'vue-upload-component';
import { formatBytes } from './file';

const FALLBACK_ICON = 'Happy';

export interface FileWithBlob extends VUFile {
  blob: string;
  uploading?: boolean;
}

export const attachmentIconResolveTable: Record<string, string> = {
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
    'Pdf', // .docx
  'application/msword': 'Pdf', // .doc
  'application/pdf': 'Pdf',
  'image/jpeg': 'Camera',
  'image/bmp': 'Camera',
  'image/png': 'Camera',
  'image/tiff': 'Camera',
  'text/plain': 'Pdf',
  'text/html': 'Pdf',
  'text/markdown': 'Pdf',
};

/**
 * Returns a Icon's identifier (Camera, Pdf) if an icon
 * is specified for the given MIME type. Otherwise returns a fallback icon.
 *
 * @param {string} mimetype The mimetype to get the icon to
 * @returns {string} The found icon identifier or fallback icon
 */
export function getIconFromMimeType(mimetype: string): string {
  return attachmentIconResolveTable[mimetype] || FALLBACK_ICON;
}

export async function fileToBase64(objectUrl: string): Promise<string> {
  const blob = await fetch(objectUrl).then((r) => r.blob());
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = (e) => reject(e);
  });
}

export function ensureFileSize(
  newFiles: FileWithBlob[],
  existingFiles?: { name: string; base64?: string; size?: number }[]
): boolean {
  const files = [...(existingFiles ?? []), ...newFiles];

  const totalSize = files.reduce((sum, file) => sum + (file.size ?? 0), 0);
  const maxSize = 30 * Math.pow(1024, 2);
  if (totalSize > maxSize) {
    NotificationService.showError({
      message: translate('ATTACHMENT_TOO_BIG', {
        size: formatBytes(maxSize),
      }),
    });
    return false;
  }
  return true;
}
