import {
  ActivityListItemFactory,
  ActivityListItemSearchResult,
} from '../../../domain';
import Client from '../../api/Client';
import ConsolidateBaseQuery, { Paged } from '../ConsolidateBaseQuery';

interface Payload {
  searchTerm: string;
  page: number;
  project?: string;
  akttype?: string;
  responsible?: string;
  anrlist?: string;
  cidlist?: string;
  createdfrom?: string;
  createdto?: string;
  status?: boolean;
}

class FindActivitiesQuery extends ConsolidateBaseQuery<
  Paged<ActivityListItemSearchResult>,
  Payload
> {
  constructor(payload: Payload) {
    super(payload);
  }

  async execute() {
    const { searchTerm, page, ...advancedOptions } = this._payload;

    const response = await new Client().api.findActivityRaw({
      findActivityRequest: {
        searchstring: searchTerm,
        ...this._addPaging(page),
        ...advancedOptions,
      },
    });

    if (response.raw.headers.get('content-length') === '0') {
      return this._pagedObject(0, page, []);
    }

    const { items, found } = await response.value();

    const result: ActivityListItemSearchResult[] =
      items.map((x: any) =>
        ActivityListItemFactory.getFromConsolidateElasticSearch(x)
      ) ?? [];

    return this._pagedObject(found, page, result);
  }
}

export default FindActivitiesQuery;
