
















































































import { NotificationService } from '@consolidate/shared/ui-components';
import Vue, { PropType } from 'vue';
import { RawLocation } from 'vue-router';
import { ACTIVITY_TODO_TYPE_DONE, ActivityListItem } from '../../../domain';
import InboxFacade from '../../../domain/application/InboxFacade';
import { formatDate, formatDateTime } from '../../../utils/date';
import { capitalize } from '../../../utils/string';

export default Vue.extend({
  name: 'ActivityListItem',
  props: {
    item: { type: Object as PropType<ActivityListItem>, required: true },
    selectable: { type: Boolean, default: false },
    selected: { type: Boolean, default: false },
    hideResponsible: { type: Boolean, default: false },
  },
  data: () => ({
    READ_STATUS_TIMEOUT: 1000, // When an activity should be marked as read [ms]
    isHold: false,
  }),
  computed: {
    formattedContact(): string {
      if (!this.item.contact) return '';
      return `${capitalize(this.$t('FROM'))}: ${this.item.contact}`;
    },
    isInbox(): boolean {
      return this.$route.name?.includes('inbox') ?? false;
    },
    dueDate(): string {
      if (this.isInbox) {
        return formatDateTime(this.item.received ?? this.item.dueDateTimeUtc);
      }

      return formatDate(this.item.dueDateTimeUtc);
    },
    isDone(): boolean {
      return this.item.todoType?.id === ACTIVITY_TODO_TYPE_DONE.id;
    },
    isDeleted(): boolean {
      return this.item.actType?.icon === 'Trash' && !this.item.responsible;
    },
    to(): RawLocation | null {
      if (this.isDeleted) {
        return null;
      }

      const name = this.$route.name?.endsWith('-detail')
        ? this.$route.name
        : this.$route.name + '-detail';

      return { name: name, params: { id: this.item.id.toString() } };
    },
  },
  methods: {
    handleClicks() {
      if (this.isHold) return;

      if (this.selectable) {
        this.$emit('toggle', this.item.id);
        return;
      }

      // check for deleted Items
      if (this.isDeleted) {
        NotificationService.showWarning({
          message: this.$t('ACTIVITY_CANT_BE_DISPLAYED'),
        });
      }

      // Check isNew state
      if (!this.item.isNew) return;

      setTimeout(async () => {
        if (this.isInbox) {
          InboxFacade.setActAsSeen(this.item.id);
        } else {
          InboxFacade.setActAsRead(this.item.id);
        }
      }, this.READ_STATUS_TIMEOUT);
    },
    handleTouchstart() {
      this.isHold = false;
    },
    handleTouchhold() {
      if (this.selectable) return;

      this.isHold = true;
      this.$emit('startSelection', this.item.id);
    },
  },
});
