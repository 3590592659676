import { NotificationService } from '@consolidate/shared/ui-components';
import { translate } from '@consolidate/shared/util-translations';
import OverviewFacade from '../../../domain/application/OverviewFacade';
import Client from '../../api/Client';
import ConsolidateBaseCommand from '../baseCommands/ConsolidateBaseCommand';

interface Payload {
  actId: number;
  userName: string;
  comment: string;
}

class DelegateActivityCommand extends ConsolidateBaseCommand<Payload> {
  constructor(payload: Payload) {
    super('DelegateActivityCommand', payload);
  }

  async execute() {
    try {
      const { actId, userName, comment } = this.payload;
      const dto = {
        aktid: actId,
        uid: userName,
        transfercomment: comment,
      };
      const data = await new Client().api.aktDelegate(dto);
      const success = data?._return === 'TRUE';

      return this.result(success);
    } catch (error) {
      if (this.isRetryable(error)) {
        return this.save();
      }
      return this.notOk();
    }
  }

  protected onSuccess() {
    OverviewFacade.refreshCounts();
  }

  protected onError() {
    NotificationService.showError({
      message: translate('ACT_DELEGATE_ERROR'),
    });
  }
}

export default DelegateActivityCommand;
