import { ActivityModel } from './activityModel';
import { ListModel } from './listModel';

export class InboxItemModel extends ListModel {
  static entity = 'inboxItems';

  id!: number;
  aktid!: number | null;
  summary!: string | null;
  activity!: ActivityModel;
  isSpam!: boolean;
  isNew!: boolean;
  received!: string;
  sender!: string;

  static fields() {
    return {
      id: this.number(0),
      summary: this.string(null).nullable(),
      isNew: this.boolean(false),
      isSpam: this.boolean(false),
      received: this.string(''),
      sender: this.string(''),
      aktid: this.number(0),
      activity: this.belongsTo(ActivityModel, 'aktid'),
    };
  }
}
