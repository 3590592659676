import {
  ConfigurationParameters,
  DefaultApi,
  Configuration as LegacyConfiguration,
} from '@consolidate/shared/data-access-legacy-api';
import { AuthService } from '@consolidate/shared/util-auth';
import ConnectivityService from '../services/ConnectivityService';
import LicensingService from '../services/LicensingService';
import LogoutService from '../services/LogoutService';
import {
  ActivitiesApi,
  CustomApi,
  MeetingsApi,
  Configuration as NewConfiguration,
  ObjectsApi,
  OverviewApi,
  QuarantinedApi,
} from './gen';

export class ApiConnectionError extends Error {
  constructor() {
    super('api connection failed');
    this.name = 'ApiConnectionError';
  }
}

class Client {
  private configuration: ConfigurationParameters;

  public api: DefaultApi;
  public overview: OverviewApi;
  public objects: ObjectsApi;
  public activities: ActivitiesApi;
  public custom: CustomApi;
  public junkmail: QuarantinedApi;
  public meetings: MeetingsApi;

  constructor() {
    const baseURL = AuthService.getWebServiceUrl();
    if (!baseURL) {
      throw new Error('Base url not set');
    }

    this.configuration = {
      basePath: baseURL,
      accessToken: () => AuthService.getAccessToken() ?? '',
      fetchApi: async (input, init) => {
        try {
          return await fetch(input, init);
        } catch (error) {
          ConnectivityService.setOnline(false);
          throw new ApiConnectionError();
        }
      },
      middleware: [
        {
          post: async (context) => {
            if (context.response.status === 401) {
              if (LicensingService.checkLicense()) {
                if (!(context.init as any).__isRetryRequest) {
                  await AuthService.refreshToken();

                  context.init.headers = {
                    ...context.init.headers,
                    Authorization: AuthService.getAccessToken() ?? '',
                  };
                  (context.init as any).__isRetryRequest = true;

                  return await context.fetch(context.url, context.init);
                } else {
                  await LogoutService.logout();
                }
              }
            }

            if (context.response.status === 502) {
              ConnectivityService.setOnline(false);
              throw new ApiConnectionError();
            } else {
              ConnectivityService.setOnline(true);
            }
          },
        },
      ],
    };

    this.api = new DefaultApi(new LegacyConfiguration(this.configuration));
    this.overview = new OverviewApi(new NewConfiguration(this.configuration));
    this.objects = new ObjectsApi(new NewConfiguration(this.configuration));
    this.activities = new ActivitiesApi(
      new NewConfiguration(this.configuration)
    );
    this.custom = new CustomApi(new NewConfiguration(this.configuration));
    this.junkmail = new QuarantinedApi(
      new NewConfiguration(this.configuration)
    );
    this.meetings = new MeetingsApi(new NewConfiguration(this.configuration));
  }
}

export default Client;
