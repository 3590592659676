import { BehaviorSubject } from 'rxjs';
import { debounceTime, filter, switchMap, tap } from 'rxjs/operators';
import { ListModel } from '../infrastructure/+state/models';
import { ListFacade } from './ListFacade';

export abstract class ServerFilteringListFacade<
  T extends ListModel,
  TListItem extends Record<string, any>
> extends ListFacade<T, TListItem> {
  private filterString$ = new BehaviorSubject<string | undefined>(undefined);

  constructor() {
    super();

    this.filterString$
      .pipe(
        // dont search when the value is null
        filter((x) => !!x),
        tap(() => {
          this.model.commit((state) => {
            state.loading = true;
          });
        }),

        // Wait 500ms before dispatching the search to not spam the server with requests
        debounceTime(500),

        switchMap(() => this.load())
      )
      .subscribe();
  }

  protected filter(items: TListItem[]): TListItem[] {
    if (this.filterString && !this.loading && !this.loadError) {
      return items;
    }

    return super.filter(items);
  }

  public setFilter(filter?: string): void {
    // filter in memory
    super.setFilter(filter);

    this.filterString$.next(filter);
    if (!filter) {
      this.load();
    }
  }
}
