














































import Vue, { PropType } from 'vue';
import { Activity } from '../../../../domain';

export default Vue.extend({
  props: {
    data: { type: Object as PropType<Activity>, required: true },
  },
  methods: {
    formatDate(date: string) {
      if (!date) return '';
      return new Date(date).toLocaleDateTimeStringLS();
    },
    goToPreviousActivity() {
      this.$router.push({
        name: 'activity-detail',
        params: { typeId: this.data.previousActivity?.toString() ?? '' },
      });
    },
    goToFollowingActivity() {
      this.$router.push({
        name: 'activity-detail',
        params: { typeId: this.data.hasFollowingActivities.toString() },
      });
    },
    goToFollowingActivities() {
      this.$router.push({
        name: 'activity-followers',
        params: { typeId: this.data.id.toString() },
      });
    },
  },
});
