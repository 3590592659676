






















import Vue from 'vue';
import { PropType } from 'vue';
import { ContactOrAddress } from '../../../domain';
import ContactAutoComplete from '../../input/ContactAutoComplete.vue';

export default Vue.extend({
  components: { ContactAutoComplete },
  props: {
    callback: {
      type: Object as PropType<{
        onYes?: (props: { contact: ContactOrAddress }) => Promise<boolean>;
      }>,
    },
    multiple: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
  },
  data() {
    return {
      isLoading: false,
      selected: undefined as ContactOrAddress | undefined,
    };
  },
  methods: {
    async handleYesCallback() {
      if (!this.selected) return;
      const { onYes } = this.callback;

      let success = false;
      if (onYes) {
        this.isLoading = true;
        success = await onYes({
          contact: this.selected,
        });
        this.isLoading = false;
      }

      if (success) {
        this.$emit('close');
      }
    },
  },
});
