/* tslint:disable */
/* eslint-disable */
/**
 * Consolidate Kanban API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Command3
 */
export interface Command3 {
    /**
     * 
     * @type {number}
     * @memberof Command3
     */
    boardId: number;
    /**
     * 
     * @type {number}
     * @memberof Command3
     */
    cardId: number;
    /**
     * 
     * @type {number}
     * @memberof Command3
     */
    destinationColumn: number;
    /**
     * 
     * @type {number}
     * @memberof Command3
     */
    afterCard?: number | null;
}

export function Command3FromJSON(json: any): Command3 {
    return Command3FromJSONTyped(json, false);
}

export function Command3FromJSONTyped(json: any, ignoreDiscriminator: boolean): Command3 {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'boardId': json['boardId'],
        'cardId': json['cardId'],
        'destinationColumn': json['destinationColumn'],
        'afterCard': !exists(json, 'afterCard') ? undefined : json['afterCard'],
    };
}

export function Command3ToJSON(value?: Command3 | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'boardId': value.boardId,
        'cardId': value.cardId,
        'destinationColumn': value.destinationColumn,
        'afterCard': value.afterCard,
    };
}


