/* tslint:disable */
/* eslint-disable */
/**
 * Consolidate App API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ScheduleOnlineMeetingResult,
    ScheduleOnlineMeetingResultFromJSON,
    ScheduleOnlineMeetingResultFromJSONTyped,
    ScheduleOnlineMeetingResultToJSON,
} from './';

/**
 * 
 * @export
 * @interface ScheduleOnlineResponse
 */
export interface ScheduleOnlineResponse {
    /**
     * 
     * @type {ScheduleOnlineMeetingResult}
     * @memberof ScheduleOnlineResponse
     */
    result: ScheduleOnlineMeetingResult;
}

export function ScheduleOnlineResponseFromJSON(json: any): ScheduleOnlineResponse {
    return ScheduleOnlineResponseFromJSONTyped(json, false);
}

export function ScheduleOnlineResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ScheduleOnlineResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'result': ScheduleOnlineMeetingResultFromJSON(json['result']),
    };
}

export function ScheduleOnlineResponseToJSON(value?: ScheduleOnlineResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'result': ScheduleOnlineMeetingResultToJSON(value.result),
    };
}


