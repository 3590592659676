
































































































































































import { AddAddressCommand, EditAddressCommand } from '../../logic/commands';
import CountriesService from '../../domain/application/CountriesService';
import Vue from 'vue';
import { AddOrEditAddressRequest } from '@consolidate/shared/data-access-legacy-api';
import { Country } from '../../domain';

export default Vue.extend({
  name: 'AddressFormPage',
  props: {
    prefilledValues: { type: Object, default: null },
    headerKey: { type: String },
  },
  data: () => ({
    isFormValid: false,
    selectedCountry: {} as Country | null,
    allowRouteLeave: false,
    submitting: false,
    edit: false,
    address: {} as AddOrEditAddressRequest,
  }),
  computed: {
    countries() {
      return CountriesService.countries;
    },
  },
  async created() {
    if (this.prefilledValues) {
      this.edit = true;
      this.address = this.prefilledValues;
    }

    if (this.address.countryid)
      this.selectedCountry = CountriesService.find(this.address.countryid);
  },
  methods: {
    closeModal() {
      this.$emit('close', false);
    },
    validate() {
      (this.$refs.submitFormRef as any).validate();
    },
    async submitForm() {
      try {
        this.validate();
        if (!this.isFormValid) return;

        this.allowRouteLeave = true;
        this.submitting = true;

        if (this.selectedCountry) {
          this.address.countryid = this.selectedCountry.id;
        }

        if (this.edit) {
          new EditAddressCommand({
            address: this.address,
          }).execute();
        } else {
          const { ok, data } = await new AddAddressCommand({
            address: this.address,
          }).execute();
          if (ok && !!data) {
            this.$router.replace({
              name: 'address-detail',
              params: { id: data },
            });
          }
        }
      } catch (error) {
        // TODO: ? NOTY?
      } finally {
        this.submitting = false;
        this.allowRouteLeave = false;
      }
      this.closeModal();
    },
  },
});
