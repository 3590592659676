import Client from '../../api/Client';
import ConsolidateBaseCommand from '../baseCommands/ConsolidateBaseCommand';

interface Payload {
  actId: number;
  subject: string;
}

class SetActivitySubjectCommand extends ConsolidateBaseCommand<Payload> {
  constructor(payload: Payload) {
    super('SetActivitySubjectCommand', payload);
  }

  async execute() {
    try {
      const { actId, subject } = this.payload;

      const dto = {
        aktid: actId,
        subject,
      };
      const data = await new Client().api.setSubject(dto);
      const success = data?._return === 'TRUE';
      return this.result(success);
    } catch (error) {
      if (this.isRetryable(error)) {
        return this.save();
      }
      return this.notOk();
    }
  }
}

export default SetActivitySubjectCommand;
