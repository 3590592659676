import { RecurrenceInfo } from '@consolidate/shared/data-access-legacy-api';
import { Frequency, RRule, RRuleSet, Weekday } from 'rrule';
import { areTheSameDay } from '../date';
import { getConsiCSV } from '../string';
import { RRuleSerializer } from './RRuleSerializer';

export function getRRuleText(rule: RRuleSet): string {
  return new RRuleSerializer(rule).serialize();
}

export function mapFromRRule(rule: RRule): RecurrenceInfo {
  ///                             Type    Day   Month  Frequency  Day_Of_Week  Week_Of_Month  Days  Duration    recumode
  ///  daily                       1                       x                        1                   x
  ///  monthly with fixed day      4        x              x                        1                   x
  ///  yearly with fixed day       6        x     x        x                        1                   x
  ///  monthly with weekday        10                      x           x            x                   x
  ///  yearly with weekday         11             x        x           x            x                   x
  ///  weekly                      12                      x                        1          x        x

  return {
    type: getRecurrenceType(rule) as number,
    frequency: rule.options.interval,

    day: rule.options.bymonthday ? rule.options.bymonthday[0] : 0,
    month: rule.options.bymonth ? rule.options.bymonth[0] : 0,

    dayOfWeek: getDayOfWeek(rule),
    weekOfMonth: getWeekOfMonth(rule),

    duration: rule.all().length,
    days: getConsiCSV(getDays(rule), false),
  };
}

function getRecurrenceType(rule: RRule): number | undefined {
  switch (rule.options.freq) {
    case Frequency.DAILY:
      return 1;
    case Frequency.WEEKLY:
      return 12;
    case Frequency.MONTHLY:
      if (rule.options.bymonthday.length === 1) return 4;
      else return 10;
    case Frequency.YEARLY:
      if (rule.options.bymonthday.length === 1) return 6;
      else return 11;
    default:
      return undefined;
  }
}

function getDayOfWeek(rule: RRule): number | undefined {
  if (rule.options.byweekday?.length === 1) {
    const rruleDay = rule.options.byweekday[0];

    // Different offset in webservice
    return (rruleDay + 2) % 7;
  }

  return 0;
}

function getWeekOfMonth(rule: RRule): number | undefined {
  if (rule.options.bysetpos?.length === 1) {
    const rruleWeekOfMonth = rule.options.bysetpos[0];

    if (rruleWeekOfMonth === -1) return 5;
    return rruleWeekOfMonth;
  }

  return 1;
}

function getDays(rule: RRule): string[] | undefined {
  if (rule.options.byweekday?.length >= 1) {
    const dayOptions = ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT'];

    const days = rule.options.byweekday
      .map((weekday) => (weekday + 1) % 7) // 0 = Sunday, 1 = Monday, ...
      .sort() // Consolidate requires the days to be sorted. Otherwise the recurrance is not processed correctly.
      .map((day) => dayOptions[day]);

    if (days?.length > 0) {
      return days;
    }
  }
  return undefined;
}

export function mapToRRule(
  startDateTime: string,
  recurrence: RecurrenceInfo
): RRuleSet {
  const ruleSet = new RRuleSet();

  const freq = getFreqFromRecurrence(recurrence);

  ruleSet.rrule(
    new RRule({
      freq: freq,
      count: recurrence.duration,
      interval: recurrence.frequency,
      dtstart: new Date(startDateTime),
      bymonth: recurrence.month === 0 ? undefined : recurrence.month,
      bymonthday: recurrence.day === 0 ? undefined : recurrence.day,
      bysetpos:
        freq <= Frequency.MONTHLY && recurrence.day === 0
          ? getWeekOfMonthFromRecurrence(recurrence)
          : undefined,
      byweekday: getWeekdayFromRecurrence(recurrence),
    })
  );

  recurrence.exclusionDates?.split(';').forEach((exclusionDate) => {
    if (exclusionDate) {
      const date = new Date(exclusionDate);
      const occurance = ruleSet.after(new Date(exclusionDate), true);
      if (occurance && areTheSameDay(date, occurance)) {
        ruleSet.exdate(occurance);
      }
    }
  });

  return ruleSet;
}

function getFreqFromRecurrence(rule: RecurrenceInfo): Frequency {
  switch (rule.type) {
    case 1:
      return Frequency.DAILY;
    case 12:
      return Frequency.WEEKLY;
    case 4:
    case 10:
      return Frequency.MONTHLY;
    case 6:
    case 11:
      return Frequency.YEARLY;
  }

  return Frequency.DAILY;
}

function getWeekdayFromRecurrence(rule: RecurrenceInfo): Weekday[] | undefined {
  const weekdayOptions: { [key: string]: Weekday } = {
    MON: RRule.MO,
    TUE: RRule.TU,
    WED: RRule.WE,
    THU: RRule.TH,
    FRI: RRule.FR,
    SAT: RRule.SA,
    SUN: RRule.SU,
  };
  const byweekday = rule.days?.split(';').map((d) => weekdayOptions[d]);
  return byweekday;
}

function getWeekOfMonthFromRecurrence(
  rule: RecurrenceInfo
): number | undefined {
  if (rule.weekOfMonth) {
    const rruleWeekOfMonth = rule.weekOfMonth;

    if (rruleWeekOfMonth === 5) return -1;
    return rruleWeekOfMonth;
  }

  return 1;
}
