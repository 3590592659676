/* tslint:disable */
/* eslint-disable */
/**
 * Consolidate App API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    EditAdditionalDataDto,
    EditAdditionalDataDtoFromJSON,
    EditAdditionalDataDtoToJSON,
    EditRecurrenceDto,
    EditRecurrenceDtoFromJSON,
    EditRecurrenceDtoToJSON,
    GetOnlineMeetingResponse,
    GetOnlineMeetingResponseFromJSON,
    GetOnlineMeetingResponseToJSON,
    ObjectIdsDto,
    ObjectIdsDtoFromJSON,
    ObjectIdsDtoToJSON,
    ProblemDetails,
    ProblemDetailsFromJSON,
    ProblemDetailsToJSON,
    RemoveRecurrencesFromChainDto,
    RemoveRecurrencesFromChainDtoFromJSON,
    RemoveRecurrencesFromChainDtoToJSON,
    Result,
    ResultFromJSON,
    ResultToJSON,
    Result2,
    Result2FromJSON,
    Result2ToJSON,
    ScheduleOnlineMeetingRequest,
    ScheduleOnlineMeetingRequestFromJSON,
    ScheduleOnlineMeetingRequestToJSON,
    ScheduleOnlineResponse,
    ScheduleOnlineResponseFromJSON,
    ScheduleOnlineResponseToJSON,
} from '../models';

export interface ActivitiesAddObjectsRequest {
    activityId: number;
    objectIdsDto: ObjectIdsDto;
}

export interface ActivitiesEditAdditionalDataRequest {
    activityId: number;
    editAdditionalDataDto: EditAdditionalDataDto;
}

export interface ActivitiesEditRecurrenceRequest {
    activityId: number;
    editRecurrenceDto: EditRecurrenceDto;
}

export interface ActivitiesGetOnlineMeetingRequest {
    activityId: number;
}

export interface ActivitiesRemoveObjectsRequest {
    activityId: number;
    objectId: number;
}

export interface ActivitiesRemoveOnlineMeetingRequest {
    activityId: number;
}

export interface ActivitiesRemoveRecurrencesFromChainRequest {
    activityId: number;
    removeRecurrencesFromChainDto: RemoveRecurrencesFromChainDto;
}

export interface ActivitiesScheduleOnlineMeetingRequest {
    activityId: number;
    scheduleOnlineMeetingRequest: ScheduleOnlineMeetingRequest;
}

/**
 * 
 */
export class ActivitiesApi extends runtime.BaseAPI {

    /**
     */
    async activitiesAddObjectsRaw(requestParameters: ActivitiesAddObjectsRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.activityId === null || requestParameters.activityId === undefined) {
            throw new runtime.RequiredError('activityId','Required parameter requestParameters.activityId was null or undefined when calling activitiesAddObjects.');
        }

        if (requestParameters.objectIdsDto === null || requestParameters.objectIdsDto === undefined) {
            throw new runtime.RequiredError('objectIdsDto','Required parameter requestParameters.objectIdsDto was null or undefined when calling activitiesAddObjects.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("oauth2", []);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/app/v1/Activities/{activityId}/objects`.replace(`{${"activityId"}}`, encodeURIComponent(String(requestParameters.activityId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ObjectIdsDtoToJSON(requestParameters.objectIdsDto),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async activitiesAddObjects(activityId: number, objectIdsDto: ObjectIdsDto): Promise<void> {
        await this.activitiesAddObjectsRaw({ activityId: activityId, objectIdsDto: objectIdsDto });
    }

    /**
     */
    async activitiesEditAdditionalDataRaw(requestParameters: ActivitiesEditAdditionalDataRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.activityId === null || requestParameters.activityId === undefined) {
            throw new runtime.RequiredError('activityId','Required parameter requestParameters.activityId was null or undefined when calling activitiesEditAdditionalData.');
        }

        if (requestParameters.editAdditionalDataDto === null || requestParameters.editAdditionalDataDto === undefined) {
            throw new runtime.RequiredError('editAdditionalDataDto','Required parameter requestParameters.editAdditionalDataDto was null or undefined when calling activitiesEditAdditionalData.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("oauth2", []);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/app/v1/Activities/{activityId}/additionalData`.replace(`{${"activityId"}}`, encodeURIComponent(String(requestParameters.activityId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: EditAdditionalDataDtoToJSON(requestParameters.editAdditionalDataDto),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async activitiesEditAdditionalData(activityId: number, editAdditionalDataDto: EditAdditionalDataDto): Promise<void> {
        await this.activitiesEditAdditionalDataRaw({ activityId: activityId, editAdditionalDataDto: editAdditionalDataDto });
    }

    /**
     */
    async activitiesEditRecurrenceRaw(requestParameters: ActivitiesEditRecurrenceRequest): Promise<runtime.ApiResponse<Result>> {
        if (requestParameters.activityId === null || requestParameters.activityId === undefined) {
            throw new runtime.RequiredError('activityId','Required parameter requestParameters.activityId was null or undefined when calling activitiesEditRecurrence.');
        }

        if (requestParameters.editRecurrenceDto === null || requestParameters.editRecurrenceDto === undefined) {
            throw new runtime.RequiredError('editRecurrenceDto','Required parameter requestParameters.editRecurrenceDto was null or undefined when calling activitiesEditRecurrence.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("oauth2", []);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/app/v1/Activities/{activityId}/editRecurrence`.replace(`{${"activityId"}}`, encodeURIComponent(String(requestParameters.activityId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: EditRecurrenceDtoToJSON(requestParameters.editRecurrenceDto),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ResultFromJSON(jsonValue));
    }

    /**
     */
    async activitiesEditRecurrence(activityId: number, editRecurrenceDto: EditRecurrenceDto): Promise<Result> {
        const response = await this.activitiesEditRecurrenceRaw({ activityId: activityId, editRecurrenceDto: editRecurrenceDto });
        return await response.value();
    }

    /**
     */
    async activitiesGetOnlineMeetingRaw(requestParameters: ActivitiesGetOnlineMeetingRequest): Promise<runtime.ApiResponse<GetOnlineMeetingResponse>> {
        if (requestParameters.activityId === null || requestParameters.activityId === undefined) {
            throw new runtime.RequiredError('activityId','Required parameter requestParameters.activityId was null or undefined when calling activitiesGetOnlineMeeting.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("oauth2", []);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/app/v1/Activities/{activityId}/onlineMeeting`.replace(`{${"activityId"}}`, encodeURIComponent(String(requestParameters.activityId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetOnlineMeetingResponseFromJSON(jsonValue));
    }

    /**
     */
    async activitiesGetOnlineMeeting(activityId: number): Promise<GetOnlineMeetingResponse> {
        const response = await this.activitiesGetOnlineMeetingRaw({ activityId: activityId });
        return await response.value();
    }

    /**
     */
    async activitiesRemoveObjectsRaw(requestParameters: ActivitiesRemoveObjectsRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.activityId === null || requestParameters.activityId === undefined) {
            throw new runtime.RequiredError('activityId','Required parameter requestParameters.activityId was null or undefined when calling activitiesRemoveObjects.');
        }

        if (requestParameters.objectId === null || requestParameters.objectId === undefined) {
            throw new runtime.RequiredError('objectId','Required parameter requestParameters.objectId was null or undefined when calling activitiesRemoveObjects.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("oauth2", []);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/app/v1/Activities/{activityId}/objects/{objectId}`.replace(`{${"activityId"}}`, encodeURIComponent(String(requestParameters.activityId))).replace(`{${"objectId"}}`, encodeURIComponent(String(requestParameters.objectId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async activitiesRemoveObjects(activityId: number, objectId: number): Promise<void> {
        await this.activitiesRemoveObjectsRaw({ activityId: activityId, objectId: objectId });
    }

    /**
     */
    async activitiesRemoveOnlineMeetingRaw(requestParameters: ActivitiesRemoveOnlineMeetingRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.activityId === null || requestParameters.activityId === undefined) {
            throw new runtime.RequiredError('activityId','Required parameter requestParameters.activityId was null or undefined when calling activitiesRemoveOnlineMeeting.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("oauth2", []);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/app/v1/Activities/{activityId}/onlineMeeting/remove`.replace(`{${"activityId"}}`, encodeURIComponent(String(requestParameters.activityId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async activitiesRemoveOnlineMeeting(activityId: number): Promise<void> {
        await this.activitiesRemoveOnlineMeetingRaw({ activityId: activityId });
    }

    /**
     */
    async activitiesRemoveRecurrencesFromChainRaw(requestParameters: ActivitiesRemoveRecurrencesFromChainRequest): Promise<runtime.ApiResponse<Result2>> {
        if (requestParameters.activityId === null || requestParameters.activityId === undefined) {
            throw new runtime.RequiredError('activityId','Required parameter requestParameters.activityId was null or undefined when calling activitiesRemoveRecurrencesFromChain.');
        }

        if (requestParameters.removeRecurrencesFromChainDto === null || requestParameters.removeRecurrencesFromChainDto === undefined) {
            throw new runtime.RequiredError('removeRecurrencesFromChainDto','Required parameter requestParameters.removeRecurrencesFromChainDto was null or undefined when calling activitiesRemoveRecurrencesFromChain.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("oauth2", []);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/app/v1/Activities/{activityId}/removeRecurrencesFromChain`.replace(`{${"activityId"}}`, encodeURIComponent(String(requestParameters.activityId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: RemoveRecurrencesFromChainDtoToJSON(requestParameters.removeRecurrencesFromChainDto),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => Result2FromJSON(jsonValue));
    }

    /**
     */
    async activitiesRemoveRecurrencesFromChain(activityId: number, removeRecurrencesFromChainDto: RemoveRecurrencesFromChainDto): Promise<Result2> {
        const response = await this.activitiesRemoveRecurrencesFromChainRaw({ activityId: activityId, removeRecurrencesFromChainDto: removeRecurrencesFromChainDto });
        return await response.value();
    }

    /**
     */
    async activitiesScheduleOnlineMeetingRaw(requestParameters: ActivitiesScheduleOnlineMeetingRequest): Promise<runtime.ApiResponse<ScheduleOnlineResponse>> {
        if (requestParameters.activityId === null || requestParameters.activityId === undefined) {
            throw new runtime.RequiredError('activityId','Required parameter requestParameters.activityId was null or undefined when calling activitiesScheduleOnlineMeeting.');
        }

        if (requestParameters.scheduleOnlineMeetingRequest === null || requestParameters.scheduleOnlineMeetingRequest === undefined) {
            throw new runtime.RequiredError('scheduleOnlineMeetingRequest','Required parameter requestParameters.scheduleOnlineMeetingRequest was null or undefined when calling activitiesScheduleOnlineMeeting.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("oauth2", []);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/app/v1/Activities/{activityId}/onlineMeeting`.replace(`{${"activityId"}}`, encodeURIComponent(String(requestParameters.activityId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ScheduleOnlineMeetingRequestToJSON(requestParameters.scheduleOnlineMeetingRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ScheduleOnlineResponseFromJSON(jsonValue));
    }

    /**
     */
    async activitiesScheduleOnlineMeeting(activityId: number, scheduleOnlineMeetingRequest: ScheduleOnlineMeetingRequest): Promise<ScheduleOnlineResponse> {
        const response = await this.activitiesScheduleOnlineMeetingRaw({ activityId: activityId, scheduleOnlineMeetingRequest: scheduleOnlineMeetingRequest });
        return await response.value();
    }

}
