import {
  ApiResponse,
  GetUsersResponse,
  GetUsersResponseItem,
  GetUsersResponseItemUser,
} from '@consolidate/shared/data-access-legacy-api';
import { User } from '../../domain';
import Client from '../../logic/api/Client';
import { EmployeeModel } from '../infrastructure/+state/models';
import { ListFacadeWithCaching } from './ListFacade';

class EmployeeService extends ListFacadeWithCaching<
  EmployeeModel,
  User,
  GetUsersResponse
> {
  protected entity = EmployeeModel.entity;

  protected makeApiRequest(
    hash: number
  ): Promise<ApiResponse<GetUsersResponse>> {
    return new Client().api.getUsersRaw({
      hash,
    });
  }

  protected mapResponse(response: GetUsersResponse): Record<string, unknown>[] {
    return response.users.filter(this.isUser).map((u) => ({
      cid: u.cid,
      userName: u.uid,
      firstName: u.vorname,
      lastName: u.nachname,
      phoneNumber: u.officephone,
      mobilePhoneNumber: u.officemobile,
      isTeam: u.isteam,
      fullName: `${u.vorname} ${u.nachname}`.trim(),
      allowDelegation: u.todelegate,
      substituteUser: u.substuid,
      substituteStart: u.subststart,
      substituteEnd: u.substend,
      substituteInfo: u.substinfo,
    }));
  }

  private isUser(
    arg: GetUsersResponseItem
  ): arg is { isdivider: false } & GetUsersResponseItemUser {
    return !arg.isdivider;
  }

  protected map(item: EmployeeModel): User {
    return item;
  }

  protected filterProperties: (keyof User)[] = [
    'firstName',
    'lastName',
    'fullName',
    'userName',
  ];

  public get users() {
    return this.items.filter((x) => !x.isTeam);
  }

  public find(userName?: string): User | null {
    if (!userName) return null;
    return EmployeeModel.find(userName);
  }
}

export default new EmployeeService();
