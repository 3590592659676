
















































































import Vue from 'vue';
import OverviewTile from '../../components/OverviewTile.vue';
import SearchBar from '../../components/SearchBar.vue';
import { activityColors } from '@consolidate/shared/ui-components';
import { AuthService } from '@consolidate/shared/util-auth';
import ActivitySearchFacade, {
  SearchType,
} from '../../domain/application/ActivitySearchFacade';
import AgendaItem from '../../components/calendar/AgendaItem.vue';
import OverviewFacade from '../../domain/application/OverviewFacade';
import InitializationService from '../../logic/services/InitializationService';
import { CalendarItem } from '../../domain';
import { getCalendarItemTarget } from '../../utils/calendar';
import { translate } from '@consolidate/shared/util-translations';

export default Vue.extend({
  metaInfo: {
    title: translate('OVERVIEW'),
  },
  components: {
    OverviewTile,
    SearchBar,
    AgendaItem,
  },
  data: () => ({
    searchTerm: '',
    activityColors: activityColors,
    today: new Date().toISODateString(),
  }),
  computed: {
    itemsOfToday(): CalendarItem[] {
      return OverviewFacade.itemsOfToday;
    },
    inboxCount(): number | undefined {
      return OverviewFacade.inboxCount;
    },
    spamCount(): number | undefined {
      return OverviewFacade.spamCount;
    },
    taskCount(): number | undefined {
      return OverviewFacade.tasksCount;
    },
    title(): string {
      return `${this.$t('HELLO')} ${AuthService.getUser()?.firstName}!`;
    },
    isReloading(): boolean {
      return OverviewFacade.loading;
    },
    isLoading(): boolean {
      return InitializationService.state.isLoading;
    },
  },
  mounted() {
    if (!this.isLoading) {
      this.reload();
    }
  },
  methods: {
    goToInbox() {
      this.$router.push({ name: 'inbox', params: { tab: 'INBOX' } });
    },
    goToSpam() {
      this.$router.push({ name: 'inbox', params: { tab: 'SPAM' } });
    },
    goToTasks() {
      this.$router.push({ name: 'tasks' });
    },
    goToCalendar() {
      this.$router.push({ name: 'calendar' });
    },
    searchClicked() {
      ActivitySearchFacade.loadFor(SearchType.Search, this.searchTerm);
      this.$router.push({
        name: 'pool',
        params: { searchString: this.searchTerm },
      });
    },
    handleNavigate({ item }: { item?: CalendarItem }) {
      if (item) {
        const target = getCalendarItemTarget(item);

        if (target) this.$router.push(target);
      }
    },
    reload() {
      OverviewFacade.load();
    },
  },
});
