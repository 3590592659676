











import Vue from 'vue';
import animationData from '../../../assets/06-multitasking.json';

export default Vue.extend({
  data: () => ({
    animationData: animationData,
  }),
});
