





























import Vue, { PropType } from 'vue';
import { Attachment } from '../../domain';
import { formatBytes } from '../../utils/file';

export default Vue.extend({
  props: {
    attachment: {
      type: Object as PropType<Attachment>,
      default: null,
    },
    canRemove: { type: Boolean, default: true },
  },
  methods: {
    formatSize(size: number) {
      return formatBytes(size);
    },
    remove() {
      this.$emit('remove', this.attachment?.id);
    },
  },
});
