/* tslint:disable */
/* eslint-disable */
/**
 * Consolidate Kanban API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Command5
 */
export interface Command5 {
    /**
     * 
     * @type {number}
     * @memberof Command5
     */
    boardId: number;
    /**
     * 
     * @type {number}
     * @memberof Command5
     */
    activityId: number;
}

export function Command5FromJSON(json: any): Command5 {
    return Command5FromJSONTyped(json, false);
}

export function Command5FromJSONTyped(json: any, ignoreDiscriminator: boolean): Command5 {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'boardId': json['boardId'],
        'activityId': json['activityId'],
    };
}

export function Command5ToJSON(value?: Command5 | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'boardId': value.boardId,
        'activityId': value.activityId,
    };
}


