





























































































































































































import Vue from 'vue';
import LoadingIndicator from '../../components/loading/LoadingIndicator.vue';
import DetailPage from '../../components/shared/DetailPage.vue';
import { Contact } from '../../domain';
import ActivityService from '../../domain/application/ActivityService';
import AdditionalDataFacade from '../../domain/application/AdditionalDataFacade';
import ContactDetailFacade from '../../domain/application/ContactDetailFacade';
import { ContactDetailModel } from '../../domain/infrastructure/+state/models';
import ModalService from '../../logic/services/ModalService';

interface KeyValuePair {
  key: string;
  value: string;
  externalLink?: string;
}

export default Vue.extend({
  name: 'ContactDetail',
  metaInfo() {
    const contact = ContactDetailFacade.contact;
    const path = this.$route.path;

    if (path.includes('/activities')) {
      return {
        titleTemplate: contact
          ? `${contact.firstname} ${contact.lastname} | %s`
          : '... | %s',
      };
    }
    return {
      title: contact ? `| ${contact.firstname} ${contact.lastname}` : '| ...',
    };
  },
  components: {
    LoadingIndicator,
    DetailPage,
  },
  data: () => ({
    tabs: [
      { label: 'PERSONAL_DATA' },
      { label: 'NOTES' },
      { label: 'ADDITIONAL_DATA' },
    ],
    selectedTab: { label: 'PERSONAL_DATA' },
  }),
  computed: {
    iconColor() {
      if (!this.contact) return;
      let color = '#AF7E9E';
      if (this.contact.icon === 'GenderWoman') color = '#E09B62';
      if (this.contact.icon === 'GenderMan') color = '#97CFD6';
      if (this.contact.icon === 'GenderOther') color = '#E8B558';
      return color;
    },
    isLoading() {
      return ContactDetailFacade.loading;
    },
    loadError() {
      return ContactDetailFacade.loadError;
    },
    contact() {
      return ContactDetailFacade.contact;
    },
    canEditContact() {
      return ContactDetailFacade.canEditContact;
    },
    contactDetails(): KeyValuePair[] {
      if (!this.contact) return [];

      const getKeyValuePairs = (obj: Contact, props: (keyof Contact)[]) => {
        const details: KeyValuePair[] = [];
        props.forEach((prop) => {
          const val = obj[prop]?.toString();
          if (val) {
            details.push({ key: prop, value: val });
          }
        });
        return details;
      };
      const details = getKeyValuePairs(this.contact, [
        'mobile',
        'email',
        'telephone',
        'skypename',
        'department',
      ]);
      if (this.contact.dateofbirth) {
        details.push({
          key: 'DATE_OF_BIRTH',
          value: this.dateOfBirthPlusAge,
        });
      }
      return details;
    },
    communications(): KeyValuePair[] {
      if (!this.contact?.communications) return [];
      return this.contact.communications.map((comm) => ({
        key: comm.conntype,
        value: comm.connvalue,
      }));
    },
    locations(): KeyValuePair[] {
      if (!this.contact?.locations) return [];

      return this.contact.locations.map((location) => {
        const address = this.getAddressString(location);

        const googleMapsLink = this.getGoogleMapsLink(address);

        return {
          key: location.locationtype,
          value: address,
          externalLink: googleMapsLink,
        };
      });
    },
    dateOfBirthPlusAge(): string {
      if (!this.contact?.dateofbirth) return '';

      const dob = new Date(this.contact.dateofbirth);
      const now = new Date();

      const difference = now.getTime() - dob.getTime();
      const diffDate = difference / 31556952000; // 1 year

      return `${dob.toLocaleDateStringLS()} (${Math.floor(diffDate)})`;
    },
    contactAddress(): string {
      if (!this.contact?.locations) return '';
      return this.getAddressString(this.contact.locations[0]);
    },
    id(): number {
      return +this.$route.params.typeId;
    },
  },
  watch: {
    id() {
      this.fetchContact();
    },
  },
  created() {
    this.fetchContact();
  },
  destroyed() {
    ActivityService.creationContext.contact = undefined;
  },
  methods: {
    editAdditionalData() {
      if (this.contact?.additionalData) {
        AdditionalDataFacade.editAdditionalData(
          this.contact.id,
          this.contact.additionalData[0].key,
          ContactDetailModel
        );
      }
    },
    editContactClick() {
      if (!this.canEditContact) return;
      ModalService.editContact(this.contact);
    },
    goToContactActivities() {
      this.$router.push({
        name: 'contact-detail-activities',
      });
    },
    navigateToCompany() {
      this.$router.push({
        name: 'address-detail',
        params: {
          typeId: this.contact?.ANR as string,
        },
      });
    },
    toggleFavorite() {
      ContactDetailFacade.toggleFavorite();
    },
    getAddressString(address: {
      street?: string;
      street2?: string;
      addressline2?: string;
      addressline3?: string;
      city?: string;
      zip?: string;
      country?: string;
    }): string {
      return [
        address.street,
        address.street2,
        address.addressline2,
        address.addressline3,
        [address.zip, address.city].join(' '),
        address.country,
      ]
        .filter(Boolean)
        .join('\n');
    },
    fetchContact() {
      if (!this.id) return;

      ContactDetailFacade.loadContact(this.id);
    },
    getGoogleMapsLink(address: string): string {
      return `https://www.google.com/maps/place/${encodeURIComponent(address)}`;
    },
    getFullName(contact: Contact): string {
      return [contact.title, contact.firstname, contact.lastname]
        .filter(Boolean)
        .join(' ');
    },
    getCompanyOrContactAddress(contact: Contact): string {
      if (contact.companyname) {
        return [contact.companyname, contact.companyCity]
          .filter(Boolean)
          .join(', ');
      }
      if (contact.locations) {
        return [
          contact.locations[0].street,
          [contact.locations[0].zip, contact.locations[0].city].join(', '),
        ]
          .filter(Boolean)
          .join('\n');
      }

      return '';
    },
  },
});
