import { GetAktResponseBaseObjects } from '@consolidate/shared/data-access-legacy-api';
import { translate } from '@consolidate/shared/util-translations';
import { RRule, RRuleSet } from 'rrule';
import ActivityFormAppointment from '../../components/activity/form/ActivityFormAppointment.vue';
import ActivityFormDocument from '../../components/activity/form/ActivityFormDocument.vue';
import ActivityFormEmail from '../../components/activity/form/ActivityFormEmail.vue';
import CreateNewModal from '../../components/modal/modals/CreateNewModal.vue';
import ModalActivityAddAttendee from '../../components/modal/modals/ModalActivityAddAttendee.vue';
import ModalActivityAddContact from '../../components/modal/modals/ModalActivityAddContact.vue';
import ModalActivityAddObject from '../../components/modal/modals/ModalActivityAddObject.vue';
import ModalActivityAddToFolder from '../../components/modal/modals/ModalActivityAddToFolder.vue';
import ModalActivityAlarm from '../../components/modal/modals/ModalActivityAlarm.vue';
import ModalActivityChooseActType from '../../components/modal/modals/ModalActivityChooseActType.vue';
import ModalActivityConfidentiality from '../../components/modal/modals/ModalActivityConfidentiality.vue';
import ModalActivityDelegate from '../../components/modal/modals/ModalActivityDelegate.vue';
import ModalActivityDetail from '../../components/modal/modals/ModalActivityDetail.vue';
import ModalActivityHistory from '../../components/modal/modals/ModalActivityHistory.vue';
import ModalActivityPostpone from '../../components/modal/modals/ModalActivityPostpone.vue';
import ModalActivityPriority from '../../components/modal/modals/ModalActivityPriority.vue';
import ModalActivityProject from '../../components/modal/modals/ModalActivityProject.vue';
import ModalActivitySendCCs from '../../components/modal/modals/ModalActivitySendCCs.vue';
import ModalActivityStartEndTime from '../../components/modal/modals/ModalActivityStartEndTime.vue';
import ModalActivityTodoType from '../../components/modal/modals/ModalActivityTodoType.vue';
import ModalAdvancedSearch from '../../components/modal/modals/ModalAdvancedSearch.vue';
import ModalAttendeeConfirmationStatus from '../../components/modal/modals/ModalAttendeeConfirmationStatus.vue';
import ModalCalendarFrom from '../../components/modal/modals/ModalCalendarFrom.vue';
import ModalCancelPrompt from '../../components/modal/modals/ModalCancelPrompt.vue';
import ModalChoice from '../../components/modal/modals/ModalChoice.vue';
import ModalChooseInstance from '../../components/modal/modals/ModalChooseInstance.vue';
import ModalChooseInstanceEditChain from '../../components/modal/modals/ModalChooseInstanceEditChain.vue';
import ModalChooseProcess from '../../components/modal/modals/ModalChooseProcess.vue';
import ModalChooseProvider from '../../components/modal/modals/ModalChooseProvider.vue';
import ModalChooseRecurringInstance from '../../components/modal/modals/ModalChooseRecurringInstance.vue';
import ModalConfirmSubstitute from '../../components/modal/modals/ModalConfirmSubstitute.vue';
import ModalCredentialsLogin from '../../components/modal/modals/ModalCredentialsLogin.vue';
import ModalEditAdditionalData from '../../components/modal/modals/ModalEditAdditionalData.vue';
import ModalEditChainOrShowAcceptionsCanncellations from '../../components/modal/modals/ModalEditChainOrShowAcceptionsCanncellations.vue';
import ModalEditSubject from '../../components/modal/modals/ModalEditSubject.vue';
import ModalError from '../../components/modal/modals/ModalError.vue';
import ModalJumpToDate from '../../components/modal/modals/ModalJumpToDate.vue';
import ModalPrompt from '../../components/modal/modals/ModalPrompt.vue';
import ModalRecurringAppointment from '../../components/modal/modals/ModalRecurringAppointment.vue';
import ModalSendAppointmentInvitation from '../../components/modal/modals/ModalSendAppointmentInvitation.vue';
import ModalWorkflowCC from '../../components/modal/modals/ModalWorkflowCC.vue';
import ModalWorkflowDecision from '../../components/modal/modals/ModalWorkflowDecision.vue';
import ModalWorkflowDelegate from '../../components/modal/modals/ModalWorkflowDelegate.vue';
import {
  ActivityTodoType,
  ActivityType,
  ActivityTypeWorkflow,
  AdditionalDataEntry,
  Attendee,
  AttendeeType,
  Confidentiality,
  ContactOrAddress,
  ProjectInfo,
  User,
  WorkflowStep,
} from '../../domain';
import ActivityService, {
  ActivityCreationContext,
} from '../../domain/application/ActivityService';
import ActivityTypesService from '../../domain/application/ActivityTypesService';
import ConfidentialitiesService from '../../domain/application/ConfidentialitiesService';
import {
  Activity,
  ActivityTechnology,
} from '../../domain/entitites/activity/Activity';
import AddressFormPage from '../../routes/contactsAndAddresses/AddressFormPage.vue';
import ContactFormPage from '../../routes/contactsAndAddresses/ContactFormPage.vue';
import { MeetingLinkProvider } from '../api/gen';
import { AddActivityCommandPayload } from '../commands/activity/AddActivityCommand';

export enum ActivityCreateMode {
  Create = 'CREATE',
  Reply = 'REPLY',
  ReplyAll = 'REPLY_ALL',
  Forward = 'FORWARD',
}

export enum PrimaryAction {
  NO = 'NO',
  YES = 'YES',
}

export interface CreationPeriod {
  text: string;
  value: Date | null;
}

export interface AdvancedSearchOptions {
  responsibles: User[];
  fromDate: CreationPeriod;
  contacts: ContactOrAddress[];
  activityType: ActivityType[];
  project: ProjectInfo[];
  showAll: boolean;
}

function getActFormForTechnology(technology: ActivityTechnology): any {
  switch (technology) {
    case ActivityTechnology.Email:
      return ActivityFormEmail;
    case ActivityTechnology.Document:
      return ActivityFormDocument;
    case ActivityTechnology.Appointment:
      return ActivityFormAppointment;
    default:
      throw new Error('Unknown technology');
  }
}

export interface ModalData {
  component: any;
  title: string;
  subtitle?: string;
  props?: any;
  fullscreen?: boolean | 'mobile';
  width?: string;
  height?: string;
  confirmClose?: boolean;
  preventClose?: boolean;
}

class ModalService {
  private modalHandler?: (data: ModalData) => void;

  public registerHandler(handler: (data: ModalData) => void) {
    this.modalHandler = handler;
  }

  private showModal(data: ModalData) {
    if (this.modalHandler) {
      this.modalHandler(data);
    }
  }

  public addAttendee(
    type: AttendeeType,
    callback: {
      onYes: (props: { attendee: User | ContactOrAddress }) => Promise<boolean>;
    }
  ) {
    this.showModal({
      component: ModalActivityAddAttendee,
      title: translate('ADD_ATTENDEES'),
      props: {
        callback,
        type,
      },
    });
  }

  public sendAppointmentInvitation(props: {
    callback: {
      onYes: (props: { withcomment: boolean }) => Promise<void>;
    };
    changed?: boolean;
    deleted?: boolean;
  }) {
    this.showModal({
      component: ModalSendAppointmentInvitation,
      title: translate('SEND_APPOINTMENT_INVITATION_HEADER'),
      props,
    });
  }

  public addContact(
    callback: {
      onYes: (props: {
        contact: ContactOrAddress | ContactOrAddress[];
      }) => Promise<boolean>;
    },
    title: string = translate('ADD_CONTACT'),
    multiple = false
  ) {
    this.showModal({
      component: ModalActivityAddContact,
      title: title,
      props: {
        callback,
        multiple,
      },
    });
  }

  public addObject(
    subtitle: string,
    callback: {
      onYes: (props: {
        objects: GetAktResponseBaseObjects[];
      }) => Promise<boolean>;
    }
  ) {
    this.showModal({
      component: ModalActivityAddObject,
      title: translate('ADD_OBJECTS'),
      subtitle: subtitle,

      props: {
        callback,
      },
    });
  }

  public addActivity(
    mode: ActivityCreateMode,
    technology: ActivityTechnology,
    actType: ActivityType,
    previousActivity?: Activity,
    prefilledValues?: Partial<AddActivityCommandPayload>
  ) {
    this.showModal({
      component: getActFormForTechnology(technology),
      title: translate('CREATE_ACTIVITY'),
      fullscreen: 'mobile',
      width: '50%',
      height: '80vh',
      confirmClose: true,
      props: {
        type: technology,
        mode: mode,
        actType,
        previousActivity,
        prefilledValues,
      },
    });
  }

  public createActivity(props: {
    fixedTechnology?: ActivityTechnology;
    creationContext?: ActivityCreationContext;
    previousActivity?: Activity;
  }) {
    this.showModal({
      component: CreateNewModal,
      title: translate('CREATE_NEW'),
      fullscreen: 'mobile',
      width: '50%',
      height: '80vh',
      props: {
        mode: ActivityCreateMode.Create,
        creationContext: ActivityService.creationContext,
        ...props,
      },
    });
  }

  public replyActivity(mode: ActivityCreateMode, previousActivity: Activity) {
    const allActTypes = ActivityTypesService.types;
    let actType;
    switch (mode) {
      case ActivityCreateMode.Reply:
        actType = allActTypes.find((x) => x.defaultanswer === true);
        break;
      case ActivityCreateMode.ReplyAll:
        actType = allActTypes.find((x) => x.defaultanswerall === true);
        break;
      case ActivityCreateMode.Forward:
        actType = allActTypes.find((x) => x.defaultforward === true);
        break;
    }
    // if there is a standard then go directly to ActivityFormEmail
    // else let the user choose an activityType himself
    if (actType) {
      this.addActivity(
        mode,
        ActivityTechnology.Email,
        actType,
        previousActivity
      );
    } else {
      this.replyActivityChoose(mode, previousActivity);
    }
  }

  public replyActivityChoose(
    mode: ActivityCreateMode,
    previousActivity: Activity
  ) {
    this.showModal({
      component: CreateNewModal,
      title: translate('CREATE_NEW'),
      fullscreen: 'mobile',
      width: '50%',
      height: '80vh',
      props: {
        fixedTechnology: ActivityTechnology.Email,
        mode,
        previousActivity,
      },
    });
  }

  public createContact() {
    this.showModal({
      component: ContactFormPage,
      title: translate('CREATE_CONTACT'),
      fullscreen: 'mobile',
      width: '50%',
      height: '80vh',
      confirmClose: true,
    });
  }

  public editContact(data: any) {
    this.showModal({
      component: ContactFormPage,
      title: translate('EDIT_CONTACT'),
      fullscreen: 'mobile',
      width: '50%',
      height: '80vh',
      confirmClose: true,
      props: {
        prefilledValues: data,
      },
    });
  }

  public addAddress() {
    this.showModal({
      component: AddressFormPage,
      title: translate('ADD_ADDRESS'),
      fullscreen: 'mobile',
      width: '50%',
      height: '80vh',
      confirmClose: true,
    });
  }

  public editAddress(data: any) {
    this.showModal({
      component: AddressFormPage,
      title: translate('EDIT_ADDRESS'),
      fullscreen: 'mobile',
      width: '50%',
      height: '80vh',
      confirmClose: true,
      props: {
        prefilledValues: data,
      },
    });
  }

  public choiceAsync(props: {
    title: string;
    subtitle?: string;
    canCancel?: boolean;
    primaryAction?: PrimaryAction;
    labels?: {
      yes: string;
      no?: string;
    };
  }): Promise<boolean> {
    return new Promise((resolve) => {
      this.choice({
        ...props,
        callback: {
          onYes: () => resolve(true),
          onNo: () => resolve(false),
        },
      });
    });
  }

  public choice({
    title,
    subtitle,
    canCancel,
    primaryAction,
    labels,
    callback,
  }: {
    title: string;
    subtitle?: string;
    canCancel?: boolean;
    primaryAction?: PrimaryAction;
    labels?: {
      yes: string;
      no?: string;
    };
    callback: {
      onYes: (props: { comment: string }) => void;
      onNo?: () => void;
    };
  }) {
    this.showModal({
      component: ModalChoice,
      title,
      subtitle,
      props: { labels, callback, canCancel, primaryAction },
      preventClose: true,
    });
  }

  public addRecurrence(
    currentStartDate: string,
    currentRecurrence: RRule | undefined,
    callback: {
      onYes: (rule?: RRule) => void;
    }
  ) {
    this.showModal({
      component: ModalRecurringAppointment,
      title: translate('APPOINTMENT_REPETITION'),
      props: {
        callback,
        currentStartDate,
        currentRecurrence,
        canEditStartDate: true,
        canRemove: true,
      },
    });
  }

  public editRecurrence(
    currentRecurrence: RRule,
    callback: {
      onYes: (rule?: RRule) => void | Promise<void>;
    }
  ) {
    this.showModal({
      component: ModalRecurringAppointment,
      title: translate('APPOINTMENT_REPETITION_EDIT'),
      props: {
        callback,
        currentRecurrence,
        canEditStartDate: false,
        canRemove: false,
      },
    });
  }

  public chooseRecurrenceInstance(
    instances: Date[],
    callback: {
      onSelected: (instance: Date) => void;
    }
  ) {
    this.showModal({
      component: ModalChooseRecurringInstance,
      title: translate('CHOOSE_RECURRING_INSTANCE'),
      subtitle: translate('CHOOSE_RECURRING_INSTANCE_SUBTITLE'),
      props: {
        callback,
        instances,
      },
    });
  }

  public editChainOrShowAcceptionsCanncellations() {
    this.showModal({
      component: ModalEditChainOrShowAcceptionsCanncellations,
      title: translate('WHAT_TO_DO'),
    });
  }

  public chooseInstanceEditChain({
    recurrence,
    callback,
  }: {
    recurrence: RRuleSet;
    callback: {
      removeFromChain: (props: {
        exceptions: Date[];
        deleteExceptions: boolean;
        wholeChain: boolean;
      }) => void;
      seperateChainFromHere: (exception: Date) => void;
      deleteChainFromHere: (exception: Date) => void;
    };
  }) {
    this.showModal({
      component: ModalChooseInstanceEditChain,
      title: translate('EDIT_CHAIN'),
      props: { recurrence, callback },
    });
  }

  public chooseInstance({
    recurrence,
    callback,
  }: {
    recurrence: RRuleSet;
    callback: { onYes: (date: Date) => void };
  }) {
    this.showModal({
      component: ModalChooseInstance,
      title: translate('SELECT_SINGLE_APPOINTMENT'),
      subtitle: translate('SELECT_SINGLE_APPOINTMENT_SUBTITLE'),
      props: { recurrence, callback },
    });
  }

  public showAttendeeConfirmationStatus({
    date,
    attendeesInternal,
    callback,
  }: {
    date: Date;
    attendeesInternal: Attendee[];
    callback: {
      onBack: () => void;
    };
  }) {
    this.showModal({
      component: ModalAttendeeConfirmationStatus,
      title: translate('ACCEPTIONS_CANCELLACTIONS'),
      subtitle: translate('ACCEPTIONS_CANCELLACTIONS_SUBTITLE', {
        date: date.toLocaleDateStringLS(),
      }),
      props: { date, attendeesInternal, callback },
    });
  }

  public prompt({
    title,
    buttonLabel,
    subtitle,
    callback,
  }: {
    title: string;
    buttonLabel: string;
    subtitle?: string;
    callback: {
      onYes: (props: { comment: string }) => void;
    };
  }) {
    this.showModal({
      component: ModalPrompt,
      title,
      subtitle,
      props: {
        buttonLabel,
        callback,
      },
    });
  }

  public ModalCancelPrompt({
    callback,
    shouldSendReply,
    organizer,
  }: {
    organizer?: string;
    shouldSendReply?: boolean;
    callback: {
      onYes: (props: { comment: string; sendReply: boolean }) => void;
    };
  }) {
    this.showModal({
      component: ModalCancelPrompt,
      title: translate('CANCEL_APPOINTMENT'),
      props: {
        callback,
        organizer,
        shouldSendReply,
      },
    });
  }

  public editSubject({
    callback,
    initialValue,
  }: {
    initialValue: string;
    callback: {
      onYes: (props: { subject: string }) => void;
      onNo?: () => void;
    };
  }) {
    this.showModal({
      component: ModalEditSubject,
      title: translate('EDIT_SUBJECT'),
      props: { initialValue, callback },
    });
  }

  public activityAddToFolder({
    subject,
    callback,
  }: {
    subject: string;
    callback: {
      onYes: (props: { folderId: number; subject: string }) => void;
    };
  }) {
    this.showModal({
      component: ModalActivityAddToFolder,
      height: '50vh',
      title: translate('ADD_ACT_TO_FOLDER'),
      subtitle: translate('ADD_ACT_TO_FOLDER_TEXT', {
        act: subject,
      }),
      props: { callback, subject },
    });
  }

  public activityDelegate({
    callback,
    subject,
    user,
    context = 'edit',
  }: {
    user?: string;
    subject?: string;
    callback: { onYes: (props: { userName: string; comment: string }) => void };
    context?: 'create' | 'edit';
  }) {
    this.showModal({
      component: ModalActivityDelegate,
      title: translate('DELEGATE'),
      subtitle: subject ? translate('DELEGATE_ACTIVITY', { act: subject }) : '',
      props: { callback, user, context },
    });
  }

  public confirmSubstitute({
    callback,
    user,
  }: {
    callback: { onYes: (props: { userName: string }) => void };
    user: User;
  }) {
    this.showModal({
      component: ModalConfirmSubstitute,
      title: translate('SUBSTITUTION'),
      subtitle: translate('SUBSTITUTE_TEXT', { user: user.fullName }),
      props: { callback, user },
    });
  }

  public activityChooseActType({
    subject,
    callback,
    currentType,
  }: {
    subject: string;
    currentType: ActivityType;
    callback: { onYes: (actType: ActivityType) => void };
  }) {
    this.showModal({
      component: ModalActivityChooseActType,
      height: '60vh',
      title: translate('CHOOSE_ACTIVITY_TYPE', {
        act: subject,
      }),
      props: { callback, currentType },
    });
  }

  public activitySendCC({
    isNew,
    latestInternalCCs,
    initialComment,
    callback,
  }: {
    latestInternalCCs: string[];
    initialComment?: string;
    isNew?: boolean;
    callback: {
      onYes: (props: { receivers: string[]; comment: string }) => Promise<any>;
    };
  }) {
    this.showModal({
      component: ModalActivitySendCCs,
      title: translate('ACT_SEND_CCS'),
      subtitle: isNew ? translate('ICC_SEND_AUTOMATIC') : '',
      props: {
        callback,
        latestInternalCCs,
        initialComment,
        isNew: isNew || false,
      },
    });
  }

  public activityPostpone({
    subject,
    callback,
    dueDate,
  }: {
    subject: string;
    dueDate: string;
    callback: { onYes: (props: { dueDateTimeUtc: string }) => void };
  }) {
    this.showModal({
      component: ModalActivityPostpone,
      title: translate('POSTPONE_ACTIVITY'),
      subtitle: translate('POSTPONE_ACTIVITY_TEXT', {
        act: subject,
      }),
      props: { dueDate, callback },
    });
  }

  public editAdditionalData(props: {
    fields: AdditionalDataEntry[];
    callback: (fields: AdditionalDataEntry[]) => Promise<void>;
  }) {
    this.showModal({
      component: ModalEditAdditionalData,
      title: translate('ADDITIONAL_DATA_EDIT'),
      props: props,
      fullscreen: 'mobile',
    });
  }

  public activityPriority({
    subject,
    callback,
    priorityId,
  }: {
    subject: string;
    priorityId: number;
    callback: { onYes: (props: { priority: number }) => void };
  }) {
    this.showModal({
      component: ModalActivityPriority,
      title: translate('CHANGE_ACTIVITY_PRIORITY'),
      subtitle: translate('CHANGE_ACTIVITY_PRIORITY_TEXT', {
        act: subject,
      }),
      props: { priorityId, callback },
    });
  }

  public activityAlarm({
    subject,
    currentAlarm = '',
    isAppointment = false,
    callback,
  }: {
    subject: string;
    currentAlarm?: string | number;
    isAppointment: boolean;
    callback: {
      onYes: (props: {
        absoluteAlarmTime: string;
        relativeAlarmTime: number;
        removeAlarm: boolean;
      }) => Promise<void>;
    };
  }) {
    this.showModal({
      component: ModalActivityAlarm,
      title: translate('ADD_REMINDER', { act: subject }),
      props: { currentAlarm, isAppointment, callback },
    });
  }

  public activityTodoType({
    subject,
    callback,
    initialTodoType,
  }: {
    initialTodoType: ActivityTodoType;
    subject: string;
    callback: {
      onYes: (props: { todoType: ActivityTodoType }) => void;
    };
  }) {
    this.showModal({
      component: ModalActivityTodoType,
      title: translate('CHOOSE_ACTIVITY_TODO_TYPE'),
      subtitle: translate('CHOOSE_ACTIVITY_TODO_TYPE_TEXT', { act: subject }),
      props: { initialTodoType, callback },
    });
  }

  public activityConfidentiality({
    subject,
    callback,
    initialConfidentiality,
  }: {
    subject: string;
    initialConfidentiality: Confidentiality;
    callback: {
      onYes: (props: { confidentiality: Confidentiality }) => void;
    };
  }) {
    this.showModal({
      component: ModalActivityConfidentiality,
      title: translate('CHANGE_ACTIVITY_CONFIDENTIALITY'),
      subtitle: translate('CHANGE_ACTIVITY_CONFIDENTIALITY_TEXT', {
        act: subject,
      }),
      props: {
        initialConfidentiality,
        callback,
        confidentialityList: ConfidentialitiesService.items,
      },
    });
  }

  public activityProject({
    subject,
    initialProject,
    callback,
  }: {
    subject: string;

    initialProject: ProjectInfo | undefined;
    callback: {
      onYes: (props: { selectedProject: ProjectInfo }) => void;
    };
  }) {
    this.showModal({
      component: ModalActivityProject,
      title: translate('CHOOSE_ACTIVITY_PROJECT'),
      subtitle: translate('CHOOSE_ACTIVITY_PROJECT_TEXT', { act: subject }),
      props: { callback, initialProject },
    });
  }

  public activityStartEndTime({
    subject,
    callback,
    initialStartTime,
    initialEndTime,
    initialIsAllDay,
  }: {
    subject: string;
    initialStartTime: string;
    initialEndTime: string;
    initialIsAllDay: boolean;
    callback: {
      onYes: (props: {
        startTime: string;
        endTime: string;
        isAllDay: boolean;
      }) => void;
    };
  }) {
    this.showModal({
      component: ModalActivityStartEndTime,
      title: translate('CHOOSE_ACTIVITY_START_END_TIME'),
      subtitle: translate('CHOOSE_ACTIVITY_START_END_TIME_TEXT', {
        act: subject,
      }),
      props: { callback, initialStartTime, initialEndTime, initialIsAllDay },
    });
  }

  public calendarFrom(props: {
    callback: {
      onYes: (data: { userName: string }) => void;
    };
  }) {
    this.showModal({
      component: ModalCalendarFrom,
      height: '50vh',
      title: translate('CALENDAR_FROM'),
      props: { ...props },
    });
  }

  public jumpToDate(props: {
    callback: {
      onYes: (data: { date: string }) => void;
    };
  }) {
    this.showModal({
      component: ModalJumpToDate,
      title: translate('CALENDAR_JUMP_TO_DATE'),
      props: { ...props },
    });
  }

  public activityHistory({
    subject,
    actMailText,
  }: {
    subject: string;
    actMailText: string;
  }) {
    this.showModal({
      component: ModalActivityHistory,
      title: translate('HISTORY_OF', {
        subject: subject,
      }),
      fullscreen: 'mobile',
      width: '50%',
      height: '80vh',
      props: { actMailText },
    });
  }

  public activityWorkflowDecision(
    workflow: WorkflowStep,
    callback: (result: { decision: boolean; comment: string }) => Promise<void>
  ) {
    this.showModal({
      component: ModalWorkflowDecision,
      title: translate('CONTINUE_WORKFLOW'),
      props: { workflow, callback },
    });
  }

  public activityWorkflowCC(
    workflow: WorkflowStep,
    callback: (result: { username: string }) => Promise<void>
  ) {
    this.showModal({
      component: ModalWorkflowCC,
      title: translate('CONTINUE_WORKFLOW'),
      props: { workflow, callback },
    });
  }

  public activityWorkflowDelegation(
    workflow: WorkflowStep,
    callback: (result: { username: string }) => Promise<void>
  ) {
    this.showModal({
      component: ModalWorkflowDelegate,
      title: translate('CONTINUE_WORKFLOW'),
      props: { workflow, callback },
    });
  }

  public error(
    text: string,
    callback: (() => void) | undefined = undefined,
    title = translate('ERROR_OCCURRED'),
    preventClose = true
  ) {
    this.showModal({
      component: ModalError,
      title: title,
      props: { text, callback },
      preventClose: preventClose,
    });
  }

  public chooseProcess(
    options: ActivityTypeWorkflow[],
    callback: (selected: ActivityTypeWorkflow) => void
  ) {
    this.showModal({
      component: ModalChooseProcess,
      title: translate('CHOOSE_PROCESS'),
      subtitle: translate('CHOOSE_PROCESS_DESCRIPTION'),
      props: { options, callback },
      preventClose: true,
    });
  }

  public activityDetail(id: number) {
    this.showModal({
      component: ModalActivityDetail,
      title: '',
      props: { id },
      fullscreen: 'mobile',
      width: '65%',
      height: '80vh',
    });
  }

  public advancedSearch({
    callback,
    initialOptions,
  }: {
    callback: {
      onYes: (data: AdvancedSearchOptions) => void;
    };
    initialOptions: AdvancedSearchOptions | undefined;
  }) {
    this.showModal({
      component: ModalAdvancedSearch,
      height: '80vh',
      width: '50%',
      fullscreen: 'mobile',
      title: translate('ADVANCEDSEARCH_TITLE'),
      props: { callback, initialOptions },
    });
  }

  public loginCredentials(
    callback: (username: string, password: string) => Promise<boolean>
  ) {
    this.showModal({
      component: ModalCredentialsLogin,
      title: translate('LOGIN'),
      props: { callback },
    });
  }

  public chooseProvider(callback: (provider: MeetingLinkProvider) => void) {
    this.showModal({
      component: ModalChooseProvider,
      title: translate('ONLINE_MEETING_CHOOSE'),
      props: { callback },
    });
  }
}

export default new ModalService();
