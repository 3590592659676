/* tslint:disable */
/* eslint-disable */
/**
 * Consolidate App API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 0 = Success
 * 1 = OAuthLoginRequired
 * 2 = CredentialsLoginRequired
 * @export
 * @enum {string}
 */
export enum ScheduleOnlineMeetingResult {
    NUMBER_0 = 0,
    NUMBER_1 = 1,
    NUMBER_2 = 2
}

export function ScheduleOnlineMeetingResultFromJSON(json: any): ScheduleOnlineMeetingResult {
    return ScheduleOnlineMeetingResultFromJSONTyped(json, false);
}

export function ScheduleOnlineMeetingResultFromJSONTyped(json: any, ignoreDiscriminator: boolean): ScheduleOnlineMeetingResult {
    return json as ScheduleOnlineMeetingResult;
}

export function ScheduleOnlineMeetingResultToJSON(value?: ScheduleOnlineMeetingResult | null): any {
    return value as any;
}

