/* tslint:disable */
/* eslint-disable */
/**
 * Consolidate App API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Result3,
    Result3FromJSON,
    Result3FromJSONTyped,
    Result3ToJSON,
} from './';

/**
 * 
 * @export
 * @interface GetOnlineMeetingResponse
 */
export interface GetOnlineMeetingResponse {
    /**
     * 
     * @type {Result3}
     * @memberof GetOnlineMeetingResponse
     */
    meeting?: Result3 | null;
}

export function GetOnlineMeetingResponseFromJSON(json: any): GetOnlineMeetingResponse {
    return GetOnlineMeetingResponseFromJSONTyped(json, false);
}

export function GetOnlineMeetingResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetOnlineMeetingResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'meeting': !exists(json, 'meeting') ? undefined : Result3FromJSON(json['meeting']),
    };
}

export function GetOnlineMeetingResponseToJSON(value?: GetOnlineMeetingResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'meeting': Result3ToJSON(value.meeting),
    };
}


