
















import Vue from 'vue';

export default Vue.extend({
  props: {
    label: { type: String, default: '' },
    value: { type: String, default: '' },
    disabled: { type: Boolean, default: false },
    count: { type: [String, Number] },
    icon: { type: String, default: 'Search' },
  },
  computed: {
    appendIcon(): string {
      if (this.value) return '$vuetify.icons.clear';

      return '';
    },
  },
  methods: {
    iconClicked() {
      this.updateValue('');
      this.$emit('clear');
      this.searchClicked();
    },
    searchClicked() {
      if (this.disabled) return;
      (this.$refs.input as any).blur();
      this.$emit('search', this.value);
    },
    updateValue(value: string) {
      this.$emit('input', value);
    },
    focus() {
      (this.$refs.input as any).focus();
    },
  },
});
