import {
  FindActivityResponseItems,
  GetAktsFromIdResponseItems,
  GetFollowAktsResponseItems,
} from '@consolidate/shared/data-access-legacy-api';
import { normalizeColor } from '@consolidate/shared/ui-components';
import { ActivityFactory } from '.';
import { resolveIconId } from '../../utils/activity/icon';
import {
  ActivityListItem,
  ActivityListItemSearchResult,
  ActivityTodoType,
  ActivityType,
} from '../entitites';

function sanitize(val: string): string;
function sanitize(val?: string): string | undefined {
  if (!val) return val;

  return val.replace(/<\/?em>/g, '');
}

export const ActivityListItemFactory = {
  create(item: {
    id: number;
    subject: string;
    dueDateTimeUtc?: string;
    hasAttachments: boolean;
    priorityId: number;
    actType?: Partial<ActivityType>;
    contact?: string;
    todoType?: ActivityTodoType;
    icon?: number | string | null;
    iconColor?: string;
    isNew?: boolean;
    summary?: string;
    body?: string;
    responsible?: string;
    isSpam?: boolean;
    isRecurring?: boolean;
  }): ActivityListItem {
    const icon = resolveIconId(item.icon);

    const color = normalizeColor(item.iconColor);

    return {
      ...item,
      // When the aktid's uniquieness isn't enough (like in inbox)
      uid: Math.random().toString(36).substr(2, 9),
      icon: icon,
      summary: item.summary || this.getSummaryFromBody(item.body),
      iconColor: color,
      actType: {
        ...item.actType,
        icon: icon,
        color: color,
      },
    };
  },

  getSummaryFromBody(body: string | undefined) {
    if (!body) return '';
    if (body.length < 250) return body;
    return body.substr(0, 249);
  },

  getFromIdSearch(item: GetAktsFromIdResponseItems): ActivityListItem {
    return this.create({
      id: item.aktid,
      subject: item.subject,
      dueDateTimeUtc: `${item.date}.000Z`,
      hasAttachments: item.hasattachment,
      priorityId: item.priority,
      icon: item.icon,
      iconColor: item.color,
      actType: {
        id: item.akttype,
        name: item.aktname,
      },
      summary: item.summary,
      responsible: item.resp,
      todoType: ActivityFactory.getTodoTypeFromConsolidateId(item.todotype),
      isRecurring: item.isrecurring,
    });
  },

  getFromConsolidateElasticSearch(
    esItem: FindActivityResponseItems
  ): ActivityListItemSearchResult {
    return {
      ...this.create({
        id: esItem.aktid,
        subject: sanitize(esItem.subject),
        dueDateTimeUtc: `${esItem.date}.000Z`,
        hasAttachments: esItem.hasattachment,
        priorityId: 1, // Not provided by the webservice
        actType: {
          name: esItem.aktname, // No id :(
        },
        contact: sanitize(esItem.adrcontent),
        icon: esItem.icon,
        summary: sanitize(esItem.summary),
        responsible: esItem.resp,
      }),
      highlightedSubject: esItem.subject,
      highlightedSummary: esItem.summary,
      highlightedContact: esItem.adrcontent,
    };
  },

  getFromConsolidateFollowActs(
    followAct: GetFollowAktsResponseItems
  ): ActivityListItem {
    return this.create({
      id: followAct.aktid,
      subject: followAct.subject,
      dueDateTimeUtc: `${followAct.date}.000Z`,
      hasAttachments: followAct.hasattachment,
      priorityId: followAct.priority,
      actType: {
        id: followAct.akttype,
        name: followAct.aktname,
      },
      icon: followAct.icon,
      iconColor: followAct.color,
      summary: followAct.summary,
      responsible: followAct.resp,
      todoType: ActivityFactory.getTodoTypeFromConsolidateId(
        followAct.todotype
      ),
    });
  },
};
