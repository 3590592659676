









































import Vue from 'vue';
import { ActivityTechnology } from '../../domain';
import ModalService from '../../logic/services/ModalService';

export default Vue.extend({
  props: {
    icon: { type: String },
    findIcon: { type: Boolean, default: false },
    label: { type: String },
    href: { type: String },
    action: { type: String, default: '' },
    target: { type: String, default: '_blank' },
    multiRow: { type: Boolean },
    value: { type: [String, Number] },
    disabled: { type: Boolean },
    labelAsPlaceholder: { type: Boolean },
  },
  methods: {
    click(val: string) {
      if (!val || typeof val !== 'string') return;

      if (this.isEmail(val)) {
        this.openCreateNewModalEmail(val);
        return;
      }

      const link = this.link(val);

      if (link != null) {
        window.open(link + val, link === 'tel:' ? '_self' : '_blank');
      }
    },
    openCreateNewModalEmail(email: string) {
      ModalService.createActivity({
        fixedTechnology: ActivityTechnology.Email,
        creationContext: {
          contact: email,
        },
      });
    },
    isEmail(val: string) {
      return val.includes('@') && val.includes('.');
    },
    link(val: string) {
      if (val.startsWith('http')) return '';
      if (val.startsWith('www')) return 'https://';
      if (val.startsWith('+')) return 'tel:';
      if (val.startsWith('maps:')) return 'https://www.google.com/maps/place/';

      return null;
    },
    getIcon(val: string) {
      if (
        val.includes('www') ||
        val.includes('http') ||
        val.includes('https')
      ) {
        return 'Web';
      }
      if (val.charAt(0) === '+') {
        return 'Phone2';
      }
      if (val?.includes('@') && val?.includes('.')) {
        return 'Email';
      }
      return '';
    },
  },
});
