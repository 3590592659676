



























import { translate } from '@consolidate/shared/util-translations';
import Vue from 'vue';
import ActivityList from '../../components/activity/list/ActivityList.vue';
import UserCcFacade from '../../domain/application/UserCcFacade';
import { filterMixin } from '../../mixins/filterMixin';

export default Vue.extend({
  metaInfo: {
    titleTemplate: `${translate('ICC_LIST')}  %s`,
  },
  components: {
    ActivityList,
  },
  mixins: [filterMixin(UserCcFacade)],
  computed: {
    items() {
      return UserCcFacade.filteredItems;
    },
    isLoading() {
      return UserCcFacade.loading;
    },
    loadError(): boolean {
      return UserCcFacade.loadError;
    },
  },
  mounted() {
    UserCcFacade.load();
  },
  methods: {
    reload() {
      UserCcFacade.load(true);
    },
    loadMore() {
      UserCcFacade.loadMore();
    },
  },
});
