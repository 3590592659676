





































import ButtonBar from '../../button/ButtonBar.vue';
import Vue, { PropType } from 'vue';
import EmployeeService from '../../../domain/application/EmployeeService';
import { User, WorkflowStep } from '../../../domain';

export default Vue.extend({
  components: {
    ButtonBar,
  },
  props: {
    workflow: { type: Object as PropType<WorkflowStep>, default: null },
    callback: {
      type: Function,
    },
  },
  data() {
    return {
      selectedUser: '',
      isLoading: false,
    };
  },
  created() {
    if (this.workflow.targetUser) {
      this.selectedUser = this.workflow.targetUser;
    }
  },
  computed: {
    readonlyUser(): string | undefined {
      return EmployeeService.find(this.selectedUser)?.fullName;
    },
    readonly(): boolean {
      return !!this.workflow.targetUser;
    },
    users(): User[] {
      return EmployeeService.items;
    },
  },
  methods: {
    async continueWorkflow() {
      if (!this.selectedUser) return;

      const workflowData = {
        username: this.selectedUser,
      };

      if (this.callback) {
        this.isLoading = true;
        await this.callback(workflowData);
        this.isLoading = false;
      }

      this.close();
    },
    close() {
      this.$emit('close');
    },
  },
});
