/* tslint:disable */
/* eslint-disable */
/**
 * Consolidate App API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ObjectTypeDto
 */
export interface ObjectTypeDto {
    /**
     * 
     * @type {number}
     * @memberof ObjectTypeDto
     */
    id: number;
    /**
     * 
     * @type {number}
     * @memberof ObjectTypeDto
     */
    icon?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ObjectTypeDto
     */
    description?: string | null;
}

export function ObjectTypeDtoFromJSON(json: any): ObjectTypeDto {
    return ObjectTypeDtoFromJSONTyped(json, false);
}

export function ObjectTypeDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ObjectTypeDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'icon': !exists(json, 'icon') ? undefined : json['icon'],
        'description': !exists(json, 'description') ? undefined : json['description'],
    };
}

export function ObjectTypeDtoToJSON(value?: ObjectTypeDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'icon': value.icon,
        'description': value.description,
    };
}


