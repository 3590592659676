import { ActivityModel } from './activityModel';
import { ListModel } from './listModel';

export class ActivitySearchResultModel extends ListModel {
  static entity = 'activitySearchResults';

  id!: number;
  sortOrder!: number;
  highlightedSubject?: string | null;
  highlightedSummary?: string | null;
  highlightedContact?: string | null;
  activity!: ActivityModel;

  static fields() {
    return {
      id: this.number(0),
      sortOrder: this.number(0),
      highlightedSubject: this.string(null).nullable(),
      highlightedSummary: this.string(null).nullable(),
      highlightedContact: this.string(null).nullable(),
      activity: this.belongsTo(ActivityModel, 'id'),
    };
  }

  static state() {
    return {
      ...super.state(),
      page: 0,
      value: 0,
      type: '',
      total: 0,
      hasMore: false,
      advancedOptions: undefined,
    };
  }
}
