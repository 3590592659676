/**
 * Returns the input string with the first letter uppercased
 *
 * @param {string} s The string to capitalize
 * @returns {string}
 *
 * @example
 * capitalize('yO');  // Returns 'Y0'
 * capitalize('dAn'); // Returns 'DAn'
 * capitalize('Dan the man'); // Returns 'Dan the man'
 */
export function capitalize(s: unknown): string {
  if (typeof s !== 'string') return '';
  return s.charAt(0).toUpperCase() + s.slice(1);
}

/**
 * Turns the array into a comma seperated string the webservice can understand
 */
export function getConsiCSV(
  array?: string[] | number[],
  prepend = true
): string {
  if (!array) return '';

  const vals = array.join(';');
  return vals ? (prepend ? `;${vals}` : vals) : ''; // Prepend ';' if we got sth
}
