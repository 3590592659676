






























































































































import Vue from 'vue';
import DetailPage from '../../components/shared/DetailPage.vue';
import OfflineIndicator from '../../components/offline/OfflineIndicator.vue';
import LoadingIndicator from '../../components/loading/LoadingIndicator.vue';
import DetailChip from '../../components/shared/DetailChip.vue';
import ObjectDetailFacade from '../../domain/application/ObjectDetailFacade';
import { ensureMinApiVersion } from '../../utils/versioning';
import ModalService from '../../logic/services/ModalService';
import { AdditionalDataForm } from '../../domain';
import authService from 'libs/shared/util-auth/src/lib/authService';
import { Permission } from 'libs/shared/util-auth/src/lib/models/permission';

export default Vue.extend({
  data() {
    return {
      Permission,
    };
  },
  metaInfo() {
    const description = ObjectDetailFacade.object?.description;
    const path = this.$route.path;

    if (path.includes('activities')) {
      return {
        titleTemplate: `${description ?? '...'} | %s`,
      };
    } else {
      return {
        title: `| ${description ?? '...'}`,
      };
    }
  },
  components: {
    DetailPage,
    OfflineIndicator,
    LoadingIndicator,
    DetailChip,
  },
  computed: {
    id(): number {
      return +this.$route.params.typeId;
    },
    globalAdditionalData(): AdditionalDataForm | undefined {
      return ObjectDetailFacade.globalAdditionalData;
    },
    additionalData(): AdditionalDataForm | undefined {
      return ObjectDetailFacade.additionalData;
    },
    data() {
      return ObjectDetailFacade.object;
    },
    isLoading() {
      return ObjectDetailFacade.loading;
    },
    loadError() {
      return ObjectDetailFacade.loadError;
    },
  },
  watch: {
    id() {
      this.fetchData();
    },
  },
  created(): void {
    this.fetchData();
  },
  methods: {
    hasPermission(permission: Permission) {
      return authService.getUser()?.permissions.includes(permission);
    },
    fetchData() {
      if (!this.id) return;

      if (this.id === ObjectDetailFacade.Id) return;

      ObjectDetailFacade.loadObject(this.id);
    },
    navigateToLocation() {
      if (this.data?.location) {
        this.$router.push({
          name: 'address-detail',
          params: {
            typeId: this.data.location.id,
          },
        });
      }
    },
    navigateToManager() {
      if (this.data?.manager) {
        this.$router.push({
          name: 'contact-detail',
          params: {
            typeId: this.data.manager.id.toString(),
          },
        });
      }
    },
    goToActivities() {
      if (ensureMinApiVersion('5.2014')) {
        this.$router.push({
          name: 'object-detail-activities',
        });
      }
    },
    canEdit(form: AdditionalDataForm) {
      return form.fields.some((x) => x.editable);
    },
    edit(formKey: string) {
      const form = this.data?.additionalData?.find((x) => x.key == formKey);
      if (form) {
        ModalService.editAdditionalData({
          fields: form.fields,
          callback: (fields) =>
            ObjectDetailFacade.editAdditionalData(form.key, fields),
        });
      }
    },
  },
});
