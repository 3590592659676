import {
  AdditionalDataEntry,
  AdditionalDataFactory,
  AdditionalDataForm,
} from '..';
import Client from '../../logic/api/Client';
import { EditObjectAdditionalDataCommand } from '../../logic/commands';
import { isConnectionError } from '../../utils/network';
import { minApiVersion } from '../../utils/versioning';
import { ObjectModel } from '../infrastructure/+state/models';
import store from '../infrastructure/+state/store';

class ObjectDetailFacade {
  public get loading(): boolean {
    return store.state.entities[ObjectModel.entity].loadingDetail;
  }

  public get loadError(): boolean {
    return store.state.entities[ObjectModel.entity].loadDetailError;
  }

  public get Id(): number {
    return store.state.entities[ObjectModel.entity].detailId;
  }

  public get object(): ObjectModel | null {
    return ObjectModel.find(this.Id);
  }

  public get additionalData(): AdditionalDataForm | undefined {
    return this.object?.additionalData.find((x) => x.key !== 'cc4objectglobal');
  }

  public get globalAdditionalData(): AdditionalDataForm | undefined {
    return this.object?.additionalData.find((x) => x.key === 'cc4objectglobal');
  }

  public async loadObject(id: number) {
    ObjectModel.commit((state) => {
      state.loadingDetail = true;
      state.detailId = id;
    });

    let loadError = false;
    try {
      const object = await new Client().objects.objectsGetObject(id);

      ObjectModel.insertOrUpdate({
        data: {
          ...object,
          additionalData: new AdditionalDataFactory().parseNew(
            object.additionalData
          ),
        },
      });
    } catch (error) {
      loadError = isConnectionError(error);
    }

    ObjectModel.commit((state) => {
      state.loadingDetail = false;
      state.loadDetailError = loadError;
    });
  }

  @minApiVersion('5.2023')
  public async editAdditionalData(form: string, fields: AdditionalDataEntry[]) {
    const id = this.Id;
    if (id) {
      await new EditObjectAdditionalDataCommand({
        objectId: id,
        form,
        fields,
      }).execute();

      ObjectModel.update({
        where: id,
        data: {
          additionalData: this.object?.additionalData.map((x) =>
            x.key === form
              ? {
                  ...x,
                  fields: x.fields.map(
                    (f) => fields.find((n) => n.key === f.key) ?? f
                  ),
                }
              : x
          ),
        },
      });
    }
  }
}

export default new ObjectDetailFacade();
