import localforage from 'localforage';
import { CalendarItemModel, InboxItemModel, TaskModel } from '.';
import { ActivityTodoType, ActivityType } from '../../../entitites';
import { ActivityDetailModel } from './activityDetailModel';
import { ListModel } from './listModel';

export class ActivityModel extends ListModel {
  static entity = 'activities';

  type!: 'LIST' | 'DETAIL';
  id!: number;
  subject!: string;
  dueDateTimeUtc!: string;
  hasAttachments!: boolean;
  priorityId!: number;
  actType?: Partial<ActivityType>;
  contact?: string;
  todoType?: ActivityTodoType;
  icon?: string;
  iconColor!: string | null;
  summary!: string | null;
  responsible!: string;
  isRecurring?: boolean;

  static fields() {
    return {
      type: this.string('LIST'),
      id: this.number(0),
      subject: this.string(''),
      dueDateTimeUtc: this.string(''),
      hasAttachments: this.boolean(false),
      priorityId: this.number(0),
      actType: this.attr(null).nullable(),
      contact: this.string(null).nullable(),
      todoType: this.attr(null).nullable(),
      icon: this.string(null).nullable(),
      iconColor: this.string(null).nullable(),
      summary: this.string(null).nullable(),
      responsible: this.string(null).nullable(),
      isRecurring: this.boolean(false).nullable(),
    };
  }

  static types() {
    return {
      LIST: ActivityModel,
      DETAIL: ActivityDetailModel,
    };
  }

  static state() {
    return {
      ...super.state(),
      lastCreated: [],
    };
  }

  static afterDelete(model: ActivityModel) {
    InboxItemModel.delete((x) => x.aktid === model.id);
    CalendarItemModel.delete((x) => x.aktid === model.id);
    TaskModel.delete((x) => x.id === model.id);

    localforage.removeItem(`act_comment_${model.id}`);
  }
}
