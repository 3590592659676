











































































import Vue from 'vue';
import { translate } from '@consolidate/shared/util-translations';
import { Theme } from '../../domain';
import SettingsFacade from '../../domain/application/SettingsFacade';
import { Capacitor } from '@capacitor/core';
import { ensureMinApiVersion } from '../../utils/versioning';

export default Vue.extend({
  name: 'SettingsMaster',
  metaInfo: {
    title: translate('SETTINGS'),
  },
  data: () => ({
    changedSignature: null as string | null,
    themeOptions: [
      {
        value: 'auto',
        text: translate('AUTO'),
        path: 'img/settings_page_auto.svg',
      },
      {
        value: 'light',
        text: translate('LIGHT'),
        path: 'img/settings_page_light.svg',
      },
      {
        value: 'dark',
        text: translate('DARK'),
        path: 'img/settings_page_dark.svg',
      },
    ],
  }),
  computed: {
    apiVersion() {
      return SettingsFacade.settings.apiVersion;
    },
    appVersion() {
      return SettingsFacade.appVersion;
    },
    theme() {
      return this.themeOptions.find(
        (r) => r.value === SettingsFacade.settings.theme
      );
    },
    link() {
      // setting default to be andriod
      let link =
        'https://play.google.com/store/apps/details?id=com.ionicframework.consolidateapp923951&hl=de_AT';

      if (Capacitor.getPlatform() === 'ios') {
        link = 'https://apps.apple.com/at/app/consolidate-5/id1020688754';
      }
      return link;
    },
    signature: {
      get() {
        return this.changedSignature ?? SettingsFacade.settings.signature.text;
      },
      set(val: string) {
        this.changedSignature = val;
      },
    },
    allowEditingSignature() {
      // before this version editing was always allowed
      if (!ensureMinApiVersion('5.2030', true)) return true;
      return SettingsFacade.settings.signature.editappsignature;
    },
  },
  methods: {
    updateThemeSetting(option: { value: Theme }) {
      SettingsFacade.setTheme(option.value);
    },
    updateSignature() {
      if (this.changedSignature !== null) {
        SettingsFacade.setSignature(this.changedSignature);
        this.changedSignature = null;
      }
    },
  },
});
