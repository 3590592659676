import { GetAktMemberDefault } from '@consolidate/shared/data-access-legacy-api';
import {
  AddressListItem,
  Attendee,
  ContactListItem,
  ContactOrAddress,
  User,
} from '../domain';

export function isAttendee(
  item: GetAktMemberDefault | ContactOrAddress | Attendee | User | string
): item is Attendee {
  if (typeof item === 'string') {
    return false;
  }
  return 'name' in item && 'confirmationStatus' in item;
}

export function isUser(
  item: GetAktMemberDefault | ContactOrAddress | Attendee | User
): item is User {
  return 'userName' in item && 'isTeam' in item;
}

export function isContact(
  item: GetAktMemberDefault | ContactOrAddress | Attendee
): item is ContactListItem {
  return 'type' in item && item.type === 'CONTACT';
}

export function isAddress(
  item: GetAktMemberDefault | ContactOrAddress | Attendee
): item is AddressListItem {
  return 'type' in item && item.type === 'ADDRESS';
}

export function isAktMemberDefault(
  item: GetAktMemberDefault | ContactOrAddress | Attendee
): item is GetAktMemberDefault {
  if (isAttendee(item)) return false;

  return 'anr' in item && 'cid' in item;
}
