



























































































import {
  AdditionalDataEntry,
  AdditionalDataType,
  SourceFieldValue,
} from '../../../domain';
import Vue, { PropType } from 'vue';
import Client from '../../../logic/api/Client';

export default Vue.extend({
  props: {
    field: { type: Object as PropType<AdditionalDataEntry>, required: true },
    value: { type: [String, Number, Boolean, Object], required: false },
  },
  data: () => ({
    AdditionalDataType,
    search: '',
    initialOptions: [] as SourceFieldValue[],
  }),
  computed: {
    valueInternal: {
      get(): string | number | boolean | SourceFieldValue | null {
        return this.value;
      },
      set(val: string | number | boolean | SourceFieldValue) {
        this.$emit('input', val);
      },
    },
  },
  async created() {
    if (this.field.type === AdditionalDataType.Source) {
      this.initialOptions = await this.loadSrcValues();
    }
  },
  methods: {
    async loadSrcValues(searchString?: string) {
      const result = await new Client().custom.customGetSourceFieldValues(
        this.field.formName,
        this.field.key,
        searchString
      );

      return result;
    },
  },
});
