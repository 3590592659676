
















import Vue from 'vue';
import DOMPurify from 'dompurify';

const consLinkRegex =
  /(?:hrefinfo.html\?Akt=|hrefinfo.html\?|hrefinfo.htm\?cons\.)([\d]*)/gi;

export default Vue.extend({
  name: 'cs-html',
  props: {
    html: { type: String, default: '' },
  },
  data: () => ({
    shadowRoot: null as ShadowRoot | null,
    prevDist: undefined as number | undefined,
    minZoom: 0.5,
    maxZoom: 4,
    zoom: 1,
  }),
  watch: {
    html() {
      this.setContent();
    },
  },
  mounted() {
    this.addShadow();
    this.setContent();
  },
  methods: {
    setZoom(zoom: number): void {
      this.zoom = Math.max(this.minZoom, Math.min(this.maxZoom, zoom));
    },
    keydown(e: KeyboardEvent) {
      if (e.ctrlKey && e.key === '0') {
        this.setZoom(1);
      }
    },
    wheel(e: WheelEvent): void {
      if (!e.ctrlKey) return;
      e.preventDefault();

      let delta = e.deltaY;
      if (!delta) return;

      const direction = delta > 0 ? -1 : 1;

      this.setZoom(this.zoom + direction * 0.1);
    },
    touchMove(e: TouchEvent): void {
      if (e.touches.length !== 2) return;
      e.preventDefault();

      const newDist = Math.hypot(
        e.touches[0].pageX - e.touches[1].pageX,
        e.touches[0].pageY - e.touches[1].pageY
      );

      const delta = newDist - (this.prevDist ?? newDist);
      this.prevDist = newDist;

      this.setZoom(this.zoom + delta * 0.005);
    },
    touchEnd(e: TouchEvent): void {
      e.stopPropagation();
      this.prevDist = undefined;
    },
    addShadow() {
      const shadowContainer = this.$refs.shadow_container as HTMLElement;
      this.shadowRoot = shadowContainer.attachShadow({ mode: 'open' });
    },
    setContent() {
      const filteredHTML = DOMPurify.sanitize(this.html, {
        IN_PLACE: true,
        FORBID_TAGS: ['meta', 'style'],
      });

      if (this.shadowRoot) {
        this.shadowRoot.innerHTML = filteredHTML;
        this.shadowRoot
          .querySelectorAll('a')
          .forEach(this.attachConsLinkEventListenerIfNeeded.bind(this));
      }
    },
    attachConsLinkEventListenerIfNeeded(element: HTMLAnchorElement) {
      const isConsLink = (element.href.match(consLinkRegex) || []).length;
      if (isConsLink) {
        element.addEventListener('click', this.onConsLinkClick);
      } else {
        element.setAttribute('target', '_blank');
      }
    },
    onConsLinkClick(event: MouseEvent) {
      event.preventDefault();

      const match = consLinkRegex.exec(
        (event.target as HTMLAnchorElement).href
      );

      if (match) {
        const id = match[1]; // Capture group 1 value = consid

        this.$router.push({
          name: 'activity-detail',
          params: { id },
        });
      }
    },
  },
});
