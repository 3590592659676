/* tslint:disable */
/* eslint-disable */
/**
 * Consolidate App API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Provider,
    ProviderFromJSON,
    ProviderFromJSONTyped,
    ProviderToJSON,
} from './';

/**
 * 
 * @export
 * @interface Result3
 */
export interface Result3 {
    /**
     * 
     * @type {string}
     * @memberof Result3
     */
    joinLink: string;
    /**
     * 
     * @type {Provider}
     * @memberof Result3
     */
    provider: Provider;
}

export function Result3FromJSON(json: any): Result3 {
    return Result3FromJSONTyped(json, false);
}

export function Result3FromJSONTyped(json: any, ignoreDiscriminator: boolean): Result3 {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'joinLink': json['joinLink'],
        'provider': ProviderFromJSON(json['provider']),
    };
}

export function Result3ToJSON(value?: Result3 | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'joinLink': value.joinLink,
        'provider': ProviderToJSON(value.provider),
    };
}


