import {
  ApiResponse,
  GetProjectListResponse,
  GetProjectListResponseItems,
} from '@consolidate/shared/data-access-legacy-api';
import { Model, Query } from '@vuex-orm/core';
import Client from '../../logic/api/Client';
import {
  AddProjectToFavoritesCommand,
  RemoveProjectFromFavoritesCommand,
} from '../../logic/commands';
import { ProjectItem } from '../entitites/projects';
import { ProjectDetail } from '../entitites/projects/ProjectDetail';
import { AdditionalDataFactory } from '../factories';
import {
  ProjectModel,
  ProjectSearchResultModel,
} from '../infrastructure/+state/models';
import store from '../infrastructure/+state/store';
import { ListFacade, ListFacadeWithCaching } from './ListFacade';

const mapItem = (x: GetProjectListResponseItems) => ({
  id: x.id,
  // some old projects have no icon, assume the first icon for them and restrict to range of available icons
  icon: `Project${Math.min(Math.max(parseInt(x.icon) || 0, 1), 5)}`,
  name: x.projectname,
  responsible: x.projectowner,
  start: x.startdate && new Date(x.startdate).getTime(),
  end: x.enddate && new Date(x.enddate).getTime(),
  active: x.active,
  isFavorite: x.isfavorite,
});

class Default extends ListFacadeWithCaching<
  ProjectModel,
  ProjectItem,
  GetProjectListResponse
> {
  protected entity: string = ProjectModel.entity;

  protected baseFilter(query: Query<Model>): Query<Model> {
    return query.where('isListedInDefault', true);
  }

  protected map(item: ProjectModel): ProjectItem {
    return {
      ...item,
    };
  }

  public get hideInactive(): boolean {
    return store.state.entities[ProjectModel.entity].hideInactive ?? false;
  }

  protected makeApiRequest(
    hash: number
  ): Promise<ApiResponse<GetProjectListResponse>> {
    return new Client().api.getProjectListRaw({
      hash,
      getProjectListRequest: { onlyactive: this.hideInactive },
    });
  }

  protected mapResponse(
    response: GetProjectListResponse
  ): Record<string, unknown>[] {
    return response.items.map((x) => ({
      ...mapItem(x),
      isListedInDefault: true,
    }));
  }

  protected filterProperties: (keyof ProjectItem)[] = ['name'];
}

class SearchResults extends ListFacade<ProjectSearchResultModel, ProjectItem> {
  protected entity: string = ProjectSearchResultModel.entity;

  public map(item: ProjectSearchResultModel): ProjectItem {
    return {
      ...item.project,
    };
  }

  public get searchTerm(): string | undefined {
    return store.state.entities[ProjectSearchResultModel.entity].searchTerm;
  }

  public get hideInactive(): boolean {
    return store.state.entities[ProjectModel.entity].hideInactive ?? false;
  }

  protected baseFilter(query: Query<Model>): Query<Model> {
    return query.whereHas('project', (query) => {
      query.where('isListedInDefault', false);
    });
  }

  public async getItems(
    searchTerm?: string,
    onlyactive?: boolean
  ): Promise<Record<string, unknown>[] | undefined> {
    const { items } = await new Client().api.getProjectList(undefined, {
      searchstring: searchTerm ?? this.searchTerm,
      onlyactive: onlyactive ?? this.hideInactive,
      limit: 50,
    });

    return items.map((x) => ({
      id: x.id,
      project: {
        ...ProjectModel.find(x.id),
        ...mapItem(x),
      },
    }));
  }

  protected filterProperties: (keyof ProjectItem)[] = [];

  public load(searchTerm?: string) {
    ProjectSearchResultModel.commit((state) => {
      state.searchTerm = searchTerm;
    });

    ProjectSearchResultModel.deleteAll();

    return super.load();
  }

  public async loadMore() {
    // currently the webservice does not support paging
  }
}

class ProjectsFacade {
  public default = new Default();
  public searchResults = new SearchResults();

  public get items(): ProjectItem[] {
    return ProjectModel.query()
      .all()
      .map((x) => ({ ...x }));
  }

  public async load(): Promise<boolean> {
    const defaultSuccess = await this.default.load();

    if (this.searchResults.searchTerm) {
      return (
        defaultSuccess &&
        (await this.searchResults.load(this.searchResults.searchTerm))
      );
    }

    return defaultSuccess;
  }

  public get hideInactive() {
    return store.state.entities[ProjectModel.entity].hideInactive;
  }

  public async setHideInactive(value: boolean) {
    ProjectModel.commit((state) => {
      state.hideInactive = value;
    });

    await this.load();
  }

  public getProject(id: number): ProjectDetail | null {
    return ProjectModel.find(id);
  }

  public get loading(): boolean {
    return store.state.entities[ProjectModel.entity].loadingDetail;
  }

  public get loadError(): boolean {
    return store.state.entities[ProjectModel.entity].loadError;
  }

  public async loadAdditionalData(id: number) {
    const result = await new Client().api.getProjectCustomData(id);

    ProjectModel.insertOrUpdate({
      data: {
        ...result,
        icon: `Project${Math.min(
          Math.max(parseInt(result.icon as string) || 0, 1),
          5
        )}`,
        additionalData: new AdditionalDataFactory().parse(result.custom),
        active: result.active,
        archived: result.archived,
        comment: result.comment,
        confidentialityGroup: result.confidentialityGroup,
        partOf: result.partOf,
      },
    });
  }

  /** Returns the items without persisting them in the store */
  public async findItems(searchTerm: string): Promise<ProjectItem[]> {
    const items = await this.searchResults.getItems(searchTerm, true);
    if (items) {
      return items
        .map((x) => new ProjectSearchResultModel(x))
        .map(this.searchResults.map);
    }
    return [];
  }

  public async setFavorite(id: number, isFavorite: boolean) {
    ProjectModel.update({
      where: id,
      data: {
        isFavorite: isFavorite,
      },
    });

    if (isFavorite) {
      await new AddProjectToFavoritesCommand({ id: id }).execute();
    } else {
      await new RemoveProjectFromFavoritesCommand({
        id: id,
      }).execute();
    }
  }
}

export default new ProjectsFacade();
