











































import Vue from 'vue';
import BaseModal from './BaseModal.vue';
import ButtonBar from '../button/ButtonBar.vue';
import ModalService, { ModalData } from '../../logic/services/ModalService';

interface ExtendedModalData extends ModalData {
  headerKey: string;
}

export default Vue.extend({
  name: 'RootModal',
  components: {
    BaseModal,
    ButtonBar,
  },
  data: () => ({
    isOpen: false,
    confirmCloseOpen: false,
    modals: [] as ExtendedModalData[],
    key: 0,
  }),
  created() {
    ModalService.registerHandler(this.showModal);
  },
  methods: {
    showModal(data: ModalData): void {
      this.modals.push({
        ...data,
        headerKey: 'modal-header' + ++this.key,
      });
      this.isOpen = true;
    },
    close(index: number) {
      this.modals.splice(index, 1);
      this.confirmCloseOpen = false;
    },
    handleClose(index: number) {
      const data = this.modals[index];
      if (data.confirmClose) {
        this.confirmCloseOpen = true;
      } else {
        this.close(index);
      }
    },
  },
});
