/* tslint:disable */
/* eslint-disable */
/**
 * Consolidate Kanban API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum ActivityStatus {
    NUMBER_0 = 0,
    NUMBER_1 = 1
}

export function ActivityStatusFromJSON(json: any): ActivityStatus {
    return ActivityStatusFromJSONTyped(json, false);
}

export function ActivityStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): ActivityStatus {
    return json as ActivityStatus;
}

export function ActivityStatusToJSON(value?: ActivityStatus | null): any {
    return value as any;
}

