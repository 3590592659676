import { ListModel } from './listModel';
import { ProjectModel } from './projectModel';

export class ProjectSearchResultModel extends ListModel {
  static entity = 'projectSearchResults';

  id!: number;
  project!: ProjectModel;

  static fields() {
    return {
      id: this.number(0),
      project: this.belongsTo(ProjectModel, 'id'),
    };
  }

  static state() {
    return {
      ...super.state(),
      searchTerm: '',
    };
  }
}
