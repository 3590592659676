














































































import Vue, { PropType } from 'vue';
import { RRuleSet } from 'rrule';

export interface Instance {
  instance: Date;
  isPast: boolean;
}

export default Vue.extend({
  name: 'ModalChooseInstance',
  components: {},
  props: {
    recurrence: {
      type: Object as PropType<RRuleSet>,
      required: true,
    },
    callback: {
      type: Object as PropType<{
        removeFromChain: (props: {
          exceptions: Date[];
          deleteExceptions: boolean;
        }) => void;
        seperateChainFromHere: (exception: Date) => void;
        deleteChainFromHere: (exception: Date) => void;
      }>,
    },
  },
  data: () => ({
    selectedInstances: [] as Date[],
    allInstances: [] as Instance[],
    hasPast: false,
    collapsed: true,
  }),
  computed: {
    disabled(): boolean {
      return this.selectedInstances.length < 1;
    },
    onlyOne(): boolean {
      return this.selectedInstances.length >= 2;
    },
    instances(): Instance[] {
      if (!this.collapsed) return this.allInstances;

      return [
        ...this.allInstances.filter((x) => x.isPast).slice(-1),
        ...this.allInstances.filter((x) => !x.isPast),
      ];
    },
  },
  created() {
    const today = new Date();

    const allInstances = this.recurrence
      .all()
      .map((x) =>
        x >= today
          ? { instance: x, isPast: false }
          : { instance: x, isPast: x < today }
      );

    this.allInstances = allInstances;
    this.hasPast = allInstances.filter((x) => x.isPast).length >= 2;
  },
  methods: {
    invertSelection() {
      this.selectedInstances = this.instances
        .filter((x) => !this.selectedInstances.includes(x.instance))
        .map((x) => x.instance);
    },
    removeFromChain(deleteExceptions: boolean) {
      if (!this.selectedInstances) return;
      let exceptions = this.selectedInstances;

      const { removeFromChain } = this.callback;
      if (removeFromChain) {
        removeFromChain({ exceptions, deleteExceptions });
      }

      this.$emit('close');
      this.$router.push({ name: 'calendar' });
    },
    seperateChainFromHere() {
      if (!this.selectedInstances || this.selectedInstances.length > 1) return;
      let exception = this.selectedInstances[0];

      const { seperateChainFromHere } = this.callback;
      if (seperateChainFromHere) {
        seperateChainFromHere(exception);
      }
      this.$emit('close');
    },
    deleteChainFromHere() {
      if (!this.selectedInstances || this.selectedInstances.length > 1) return;
      let exception = this.selectedInstances[0];

      const { deleteChainFromHere } = this.callback;
      if (deleteChainFromHere) {
        deleteChainFromHere(exception);
      }
      this.$emit('close');
    },
  },
});
