import { MeetingLinkProvider } from '../../../../logic/api/gen';
import { ListModel } from './listModel';

export class MeetingProviderModel
  extends ListModel
  implements MeetingLinkProvider
{
  static entity = 'meetingProviders';

  id!: number;
  name!: string;

  static fields() {
    return {
      id: this.number(0),
      name: this.string(''),
    };
  }
}
