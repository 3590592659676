


























import { AuthService } from '@consolidate/shared/util-auth';
import { Permission } from 'libs/shared/util-auth/src/lib/models/permission';
import Vue, { PropType } from 'vue';
import { Activity, ActivityTechnology, ActivityType } from '../../../domain';
import { ActivityCreationContext } from '../../../domain/application/ActivityService';
import { AddActivityCommandPayload } from '../../../logic/commands/activity/AddActivityCommand';
import ModalService, {
  ActivityCreateMode,
} from '../../../logic/services/ModalService';
import ActivityTypeChooser from '../../activity/ActivityTypeChooser.vue';

interface technology {
  name: string;
  type: ActivityTechnology | 'STANDARD';
}

interface CreateAction {
  type: string;
  label: any;
  icon: string;
}

export default Vue.extend({
  name: 'CreateNewModal',
  components: {
    ActivityTypeChooser,
  },
  props: {
    fixedTechnology: { type: String as PropType<ActivityTechnology> },
    previousActivity: { type: Object as PropType<Activity> },
    mode: {
      type: String as PropType<ActivityCreateMode>,
      required: true,
    },
    creationContext: {
      type: Object as PropType<ActivityCreationContext>,
    },
  },
  data: () => ({
    searchInput: '',
    technology: 'STANDARD' as ActivityTechnology | 'STANDARD',
    tab: 0,
  }),
  computed: {
    technologies(): technology[] {
      return [
        { name: this.$t('STANDARD') as string, type: 'STANDARD' },
        { name: this.$t('EMAIL') as string, type: ActivityTechnology.Email },
        {
          name: this.$t('APPOINTMENT') as string,
          type: ActivityTechnology.Appointment,
        },
        {
          name: this.$t('DOCUMENT') as string,
          type: ActivityTechnology.Document,
        },
      ];
    },
    items(): CreateAction[] {
      const user = AuthService.getUser();

      const createActions: Partial<Record<Permission, CreateAction>> = {
        [Permission.AddAddress]: {
          type: 'Address',
          label: this.$t('ADDRESS_COMPANY'),
          icon: 'CreateAddress',
        },
        [Permission.AddContact]: {
          type: 'Contact',
          label: this.$t('PERSON'),
          icon: 'CreateContact',
        },
      };

      return Object.entries(createActions)
        .filter(([permission]) =>
          user?.permissions?.includes(+permission as Permission)
        )
        .map(([, action]) => action);
    },
  },
  async created() {
    this.technology = this.fixedTechnology ?? 'STANDARD';

    const hasContactContext = this.$route.name === 'contacts-addresses';
    hasContactContext ? (this.tab = 1) : (this.tab = 0);
  },
  methods: {
    navigate(item: ActivityType) {
      const type =
        this.technology === 'STANDARD' ? item.technology : this.technology;

      ModalService.addActivity(
        this.mode,
        type,
        item,
        this.previousActivity,
        this.getPrefilledValues(type)
      );

      this.closeCreateNewModal();
    },
    getPrefilledValues(
      type: ActivityTechnology
    ): Partial<AddActivityCommandPayload> | undefined {
      let values: Partial<AddActivityCommandPayload> = {};

      const state = this.creationContext;
      if (!state) return values;

      if (state.subject) {
        values = {
          ...values,
          subject: state.subject,
        };
      }

      if (state.text) {
        values = {
          ...values,
          body: state.text,
        };
      }

      if (state.files) {
        values = {
          ...values,
          attachments: state.files,
        };
      }

      if (
        this.$route.path.includes('calendar') &&
        type === ActivityTechnology.Appointment
      ) {
        if (state.startDate && state.endDate) {
          values = {
            ...values,
            appointmentStartDateTimeUtc: state.startDate.toISOString(),
            appointmentEndDateTimeUtc: state.endDate.toISOString(),
          };
        } else if (state.selectedDate) {
          const date = new Date(state.selectedDate);
          const now = new Date();

          // start time is now rounded to the next half hour
          date.setHours(
            now.getHours(),
            Math.ceil(now.getMinutes() / 30) * 30,
            0,
            0
          );

          const start = date.toISOString();
          date.setHours(date.getHours() + 1);
          const end = date.toISOString();

          values = {
            ...values,
            appointmentStartDateTimeUtc: start,
            appointmentEndDateTimeUtc: end,
          };
        }
      }

      if (state.contact) {
        switch (type) {
          case ActivityTechnology.Email:
            values = {
              ...values,
              emailReceivers: [state.contact],
            };
            break;
          case ActivityTechnology.Document:
            if (typeof state.contact !== 'string') {
              values = {
                ...values,
                documentContacts: [state.contact],
              };
            }
            break;
          case ActivityTechnology.Appointment:
            if (typeof state.contact !== 'string') {
              values = {
                ...values,
                appointmentExternalParticipants: [state.contact],
              };
            }
            break;
        }
      }
      return values;
    },
    navigateContactOrAddress(item: any) {
      switch (item.type) {
        case 'Contact':
          ModalService.createContact();
          break;
        case 'Address':
          ModalService.addAddress();
          break;
      }

      this.closeCreateNewModal();
    },
    closeCreateNewModal() {
      this.$emit('close');
      this.searchInput = '';
    },
  },
});
