import Client from '../../logic/api/Client';
import {
  AddAddressToFavoritesCommand,
  RemoveAddressFromFavoritesCommand,
} from '../../logic/commands';
import { isConnectionError } from '../../utils/network';
import { Address } from '../entitites';
import { AdditionalDataFactory } from '../factories';
import { AddressDetailModel } from '../infrastructure/+state/models';
import store from '../infrastructure/+state/store';

class AddressDetailFacade {
  public get id(): number | null {
    return store.state.entities[AddressDetailModel.entity].detailId;
  }

  public get loading(): boolean {
    return store.state.entities[AddressDetailModel.entity].loadingDetail;
  }

  public get loadError(): boolean {
    return store.state.entities[AddressDetailModel.entity].loadError;
  }

  public get canEditAddress(): boolean {
    return store.state.entities[AddressDetailModel.entity].canEditAddress;
  }

  public get address(): Address | undefined {
    if (this.loading) return;

    const address = AddressDetailModel.find(this.id ?? 0);
    if (address) {
      if (address.item_type !== 'ADDRESS_DETAIL') return;

      return { ...address };
    }
  }

  public async getAddress(id: string) {
    const {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      caneditcontact,
      caneditaddress,
      custom,
      ...address
    } = await new Client().api.getAddress(id, true);

    return {
      caneditaddress,
      address: {
        ...address,
        id: address.ANR,
        icon: `Contact${address.icon ?? 1}`,
        additionalData: new AdditionalDataFactory().parse(custom),
        item_type: 'ADDRESS_DETAIL',
      },
    };
  }

  public async loadAddress(id: string) {
    AddressDetailModel.commit((state) => {
      state.loadingDetail = true;
      state.detailId = id;
    });

    let loadError = false;
    try {
      const { caneditaddress, address } = await this.getAddress(id);

      AddressDetailModel.insert({
        data: {
          ...AddressDetailModel.find(id),
          ...address,
        },
      });

      AddressDetailModel.commit((state) => {
        state.canEditAddress = caneditaddress;
      });
    } catch (error) {
      loadError = isConnectionError(error);
    }

    AddressDetailModel.commit((state) => {
      state.loadingDetail = false;
      state.loadError = loadError;
    });
  }

  async toggleFavorite() {
    if (!this.address) return;
    const isFavorite = this.address.isfavorite;

    AddressDetailModel.update({
      where: this.address.id,
      data: {
        isfavorite: !isFavorite,
        isListedInFavorites: !isFavorite,
      },
    });

    if (isFavorite) {
      new RemoveAddressFromFavoritesCommand({
        id: this.address.id,
      }).execute();
    } else {
      new AddAddressToFavoritesCommand({ id: this.address.id }).execute();
    }
  }
}

export default new AddressDetailFacade();
