




import Vue from 'vue';
import MeetingProvidersFacade from '../../../domain/application/MeetingProvidersFacade';
import { MeetingLinkProvider } from '../../../logic/api/gen';

export default Vue.extend({
  name: 'ModalChooseProvider',
  props: {
    callback: { type: Function },
  },
  computed: {
    providers() {
      return MeetingProvidersFacade.items;
    },
  },
  methods: {
    async select(item: MeetingLinkProvider) {
      this.$emit('close');
      this.callback(item);
    },
  },
});
