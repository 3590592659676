





























import Vue, { PropType } from 'vue';
import { RRuleSet } from 'rrule';
export interface Instance {
  instance: Date;
  isPast: boolean;
}
export default Vue.extend({
  name: 'ModalChooseInstance',
  components: {},
  props: {
    recurrence: {
      type: Object as PropType<RRuleSet>,
      required: true,
    },
    callback: {
      type: Object as PropType<{
        onYes: (date: Date) => void;
      }>,
    },
  },
  data: () => ({
    selectedInstance: null as Date | null,
    allInstances: [] as Instance[],
    hasPast: false,
    collapsed: true,
  }),
  computed: {
    instances(): Instance[] {
      if (!this.collapsed) return this.allInstances;
      return [
        ...this.allInstances.filter((x) => x.isPast).slice(-1),
        ...this.allInstances.filter((x) => !x.isPast),
      ];
    },
  },
  created() {
    const today = new Date();
    const allInstances = this.recurrence
      .all()
      .map((x) =>
        x >= today
          ? { instance: x, isPast: false }
          : { instance: x, isPast: true }
      );
    const closestInstance = this.recurrence.after(new Date(), true);
    this.allInstances = allInstances;
    this.selectedInstance = closestInstance;
    this.hasPast = allInstances.filter((x) => x.isPast).length >= 2;
  },
  methods: {
    async handleYesCallback() {
      const { onYes } = this.callback;
      if (onYes) {
        if (!this.selectedInstance) return;
        onYes(this.selectedInstance);
      }
      this.$emit('close');
    },
  },
});
