























import Vue, { PropType } from 'vue';
import { ActivityListItem, ACTIVITY_TODO_TYPE_LIST } from '../../../domain';
import ActivityList from './ActivityList.vue';

export default Vue.extend({
  name: 'TaskList',
  components: {
    ActivityList,
  },
  props: {
    items: { type: Array as PropType<ActivityListItem[]> },
    filteredItems: { type: Array as PropType<ActivityListItem[]> },
    listActionsTarget: { type: String, required: true },
    loadError: { type: Boolean },
    filterActive: { type: Boolean, default: false },
  },
  data: () => ({
    selectedTab: 0,
  }),
  computed: {
    routePath(): string {
      return this.$route.path;
    },
    tabs(): { displayedLabel: string; label: string }[] {
      if (!Object.keys(this.tabData).length) {
        return [
          {
            displayedLabel: `${this.$t(
              `TASKS_${ACTIVITY_TODO_TYPE_LIST[0].identifier}`
            )}`,
            label: ACTIVITY_TODO_TYPE_LIST[0].name,
          },
        ];
      }

      return Object.keys(this.tabData).map((key) => ({
        displayedLabel: `${this.$t(
          `TASKS_${
            ACTIVITY_TODO_TYPE_LIST.find((x) => x.id === +key)?.identifier
          }`
        )} (${this.tabData[key].length})`,
        label: key,
      }));
    },
    tabData(): Record<string, ActivityListItem[]> {
      return this.items.reduce((total: any, item: any) => {
        if (item.todoType) {
          const key = item.todoType.id;

          if (!total[key]) total[key] = [];
          if (this.filteredItems.some((x) => x.id === item.id))
            total[key].push(item);
        }

        return total;
      }, {} as Record<string, ActivityListItem[]>);
    },
  },
  watch: {
    routePath() {
      (this.$refs.tabs as any).callSlider();
    },
  },
});
