/* eslint-disable no-await-in-loop */
import { QueueModel } from '../../domain/infrastructure/+state/models';

class QueueService {
  public add(commandname: string, payload: any) {
    QueueModel.insert({
      data: { command: commandname, payload },
    });
  }

  public delete(id: string) {
    QueueModel.delete(id);
  }

  public deleteAll() {
    QueueModel.deleteAll();
  }

  public get all() {
    return QueueModel.all();
  }

  public get count() {
    return QueueModel.query().count();
  }

  async process() {
    const items = this.all;
    for (const item of items) {
      const command = new (window as any).commands[item.command](item.payload);
      command.persist = false;

      try {
        const { ok } = await command.execute();
        if (ok !== 'queued') {
          this.delete(item.id);
        }
      } catch {
        // ? TODO: NOTIFY?
      }
    }
  }
}

export default new QueueService();
