/* tslint:disable */
/* eslint-disable */
/**
 * Consolidate App API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    Command,
    CommandFromJSON,
    CommandToJSON,
    KeyValuePairOfStringAndString,
    KeyValuePairOfStringAndStringFromJSON,
    KeyValuePairOfStringAndStringToJSON,
} from '../models';

export interface CustomEditAdditionalDataRequest {
    command: Command;
}

export interface CustomGetSourceFieldValuesRequest {
    formName?: string | null;
    fieldName?: string | null;
    searchString?: string | null;
}

/**
 * 
 */
export class CustomApi extends runtime.BaseAPI {

    /**
     */
    async customEditAdditionalDataRaw(requestParameters: CustomEditAdditionalDataRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.command === null || requestParameters.command === undefined) {
            throw new runtime.RequiredError('command','Required parameter requestParameters.command was null or undefined when calling customEditAdditionalData.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("oauth2", []);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/app/v1/Custom/editAdditionalData`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CommandToJSON(requestParameters.command),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async customEditAdditionalData(command: Command): Promise<void> {
        await this.customEditAdditionalDataRaw({ command: command });
    }

    /**
     */
    async customGetSourceFieldValuesRaw(requestParameters: CustomGetSourceFieldValuesRequest): Promise<runtime.ApiResponse<Array<KeyValuePairOfStringAndString>>> {
        const queryParameters: any = {};

        if (requestParameters.formName !== undefined) {
            queryParameters['FormName'] = requestParameters.formName;
        }

        if (requestParameters.fieldName !== undefined) {
            queryParameters['FieldName'] = requestParameters.fieldName;
        }

        if (requestParameters.searchString !== undefined) {
            queryParameters['SearchString'] = requestParameters.searchString;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("oauth2", []);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/app/v1/Custom/sourceValues`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(KeyValuePairOfStringAndStringFromJSON));
    }

    /**
     */
    async customGetSourceFieldValues(formName?: string | null, fieldName?: string | null, searchString?: string | null): Promise<Array<KeyValuePairOfStringAndString>> {
        const response = await this.customGetSourceFieldValuesRaw({ formName: formName, fieldName: fieldName, searchString: searchString });
        return await response.value();
    }

}
