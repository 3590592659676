/* tslint:disable */
/* eslint-disable */
/**
 * Consolidate App API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    Result6,
    Result6FromJSON,
    Result6ToJSON,
} from '../models';

/**
 * 
 */
export class OverviewApi extends runtime.BaseAPI {

    /**
     */
    async overviewGetRaw(): Promise<runtime.ApiResponse<Result6>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("oauth2", []);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/app/v1/Overview`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => Result6FromJSON(jsonValue));
    }

    /**
     */
    async overviewGet(): Promise<Result6> {
        const response = await this.overviewGetRaw();
        return await response.value();
    }

}
