import { AuthService } from '@consolidate/shared/util-auth';
import { Configuration, KanbanApi } from './gen';

export class Client {
  public api: KanbanApi;

  constructor() {
    const url = AuthService.getWebServiceUrl();
    if (!url) throw new Error('no webservice URL');

    this.api = new KanbanApi(
      new Configuration({
        basePath: url,
        accessToken: () => AuthService.getAccessToken() ?? '',
      })
    );
  }
}
