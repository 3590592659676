/* tslint:disable */
/* eslint-disable */
/**
 * Consolidate App API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface OverviewCounts
 */
export interface OverviewCounts {
    /**
     * 
     * @type {number}
     * @memberof OverviewCounts
     */
    inbox: number;
    /**
     * 
     * @type {number}
     * @memberof OverviewCounts
     */
    spam: number;
    /**
     * 
     * @type {number}
     * @memberof OverviewCounts
     */
    tasks: number;
    /**
     * 
     * @type {number}
     * @memberof OverviewCounts
     */
    quarantined: number;
}

export function OverviewCountsFromJSON(json: any): OverviewCounts {
    return OverviewCountsFromJSONTyped(json, false);
}

export function OverviewCountsFromJSONTyped(json: any, ignoreDiscriminator: boolean): OverviewCounts {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'inbox': json['inbox'],
        'spam': json['spam'],
        'tasks': json['tasks'],
        'quarantined': json['quarantined'],
    };
}

export function OverviewCountsToJSON(value?: OverviewCounts | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'inbox': value.inbox,
        'spam': value.spam,
        'tasks': value.tasks,
        'quarantined': value.quarantined,
    };
}


