import { NotificationService } from '@consolidate/shared/ui-components';
import { translate } from '@consolidate/shared/util-translations';
import Client from '../../api/Client';
import { ActivitiesAddObjectsRequest } from '../../api/gen';
import ConsolidateBaseCommand from '../baseCommands/ConsolidateBaseCommand';

export default class AddActivityObjectCommand extends ConsolidateBaseCommand<ActivitiesAddObjectsRequest> {
  constructor(payload: ActivitiesAddObjectsRequest) {
    super('AddActivityObjectCommand', payload);
  }

  async execute() {
    try {
      await new Client().activities.activitiesAddObjects(
        this.payload.activityId,
        this.payload.objectIdsDto
      );

      return this.ok();
    } catch (error) {
      if (this.isRetryable(error)) {
        return this.save();
      }
      return this.notOk();
    }
  }

  protected onError() {
    NotificationService.showError({
      message: translate('OBJECT_ADD_ERROR'),
    });
  }
}
