















































import Vue from 'vue';
import TasksFacade from '../../domain/application/TasksFacade';
import ActivityList from '../../components/activity/list/ActivityList.vue';
import { filterMixin } from '../../mixins/filterMixin';
import { ACTIVITY_TODO_TYPE_LIST } from '../../domain';
import { translate } from '@consolidate/shared/util-translations';

export default Vue.extend({
  name: 'TasksMaster',
  metaInfo: {
    titleTemplate: `${translate('TASKS')}  %s`,
  },
  components: {
    ActivityList,
  },
  mixins: [filterMixin(TasksFacade)],
  computed: {
    showFuture: {
      get() {
        return TasksFacade.showFuture;
      },
      set(val: boolean) {
        return TasksFacade.setShowFutureState(val);
      },
    },
    selectedTab: {
      get() {
        return TasksFacade.todoType.toString();
      },
      set(val: string) {
        TasksFacade.setTodoType(+val);
      },
    },
    counts() {
      return TasksFacade.counts;
    },
    tabs(): { displayedLabel: string; type: string }[] {
      return this.counts
        .filter((c) => c.count > 0 || c.type === 0)
        .map((c) => ({
          displayedLabel: `${this.$t(
            `TASKS_${
              ACTIVITY_TODO_TYPE_LIST.find((x) => x.id === c.type)?.identifier
            }`
          )} (${c.count})`,
          type: c.type.toString(),
        }));
    },
    isLoading() {
      return TasksFacade.loading;
    },
    loadError(): boolean {
      return TasksFacade.loadError;
    },
    filteredItems() {
      return TasksFacade.filteredItems;
    },
  },
  mounted() {
    TasksFacade.load();
  },
  methods: {
    reload() {
      TasksFacade.load(true);
    },
    loadMore() {
      TasksFacade.loadMore();
    },
  },
});
