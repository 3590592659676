


























import { Splitpanes, Pane } from 'splitpanes';
import 'splitpanes/dist/splitpanes.css';

import Vue from 'vue';
export default Vue.extend({
  components: {
    Splitpanes,
    Pane,
  },
  props: {
    name: { type: String },
    component: { required: true },
  },
  computed: {
    show(): boolean {
      if (this.$route.name === this.name) return true;

      if (this.$route.matched.at(-2)?.name === this.name)
        return !this.$vuetify.breakpoint.mobile;

      return false;
    },
    hasChild(): boolean {
      return this.$route.name !== this.name;
    },
  },
});
