import { Model, Query } from '@vuex-orm/core';
import Client from '../../logic/api/Client';
import { JunkmailItem } from '../entitites';
import { JunkmailModel } from '../infrastructure/+state/models';
import { ListFacade } from './ListFacade';
import OverviewFacade from './OverviewFacade';

class JunkmailsFacade extends ListFacade<JunkmailModel, JunkmailItem> {
  protected async getItems(): Promise<Record<string, unknown>[] | undefined> {
    const items = await new Client().junkmail.quarantinedGet();
    return items.map((x) => ({
      id: x.messageId,
      body: x.bodyPreview,
      date: x.receivedDate.toLocaleDateString(),
      from: x.senderMailAddress,
      subject: x.subject,
      recipients: x.recipients,
    }));
  }

  protected entity: string = JunkmailModel.entity;

  protected baseFilter(query: Query<Model>): Query<Model> {
    return query.orderBy('id', 'asc');
  }

  protected map(item: JunkmailModel): JunkmailItem {
    return item;
  }

  public async load() {
    JunkmailModel.deleteAll();

    const result = await super.load();
    OverviewFacade.refreshCounts();
    return result;
  }

  protected filterProperties: (keyof JunkmailItem)[] = ['id'];

  public async deleteJunkmails(ids: string[]) {
    ids.forEach((id) => JunkmailModel.delete(id));

    await new Client().junkmail.quarantinedSetStatus({
      messageIds: ids,
      status: 1,
    });

    OverviewFacade.refreshCounts();
  }

  public async downloadJunkmails(ids: string[]) {
    ids.forEach((id) => JunkmailModel.delete(id));

    await new Client().junkmail.quarantinedSetStatus({
      messageIds: ids,
      status: 2,
    });

    OverviewFacade.refreshCounts();
  }
}

export default new JunkmailsFacade();
