
































import Vue, { PropType } from 'vue';

interface AlarmTimeOption {
  value: number;
  label: string;
}

export default Vue.extend({
  name: 'ModalActivityAlarm',
  props: {
    currentAlarm: { type: [String, Number], default: '' },
    isAppointment: {
      // If predefined values (5, 10, 15... mins) should be selectable or a custom DateTime
      type: Boolean,
    },
    callback: {
      type: Object as PropType<{
        onYes: {
          (props: {
            absoluteAlarmTime: string;
            relativeAlarmTime: number;
            removeAlarm: boolean;
          }): Promise<void>;
        };
      }>,
      default() {
        return { onYes: null };
      },
    },
  },
  data: (): {
    error: string;
    absoluteAlarmTime: string;
    relativeAlarmTime: AlarmTimeOption | null;
    blurButtons: boolean;
  } => ({
    error: '',
    absoluteAlarmTime: '',
    relativeAlarmTime: null,
    blurButtons: false,
  }),
  computed: {
    alarmTimeOptions() {
      // Assemble minutes (5, 10, 15, ... till 55)
      const optionsMinutes = [];
      for (let i = 5; i < 60; i += 5) {
        optionsMinutes.push({
          value: i,
          label: this.$t('X_MINUTES_BEFORE', { minutes: i }),
        });
      }

      const optionsHours = [2, 4, 8, 12, 24].map((o) => ({
        value: o * 60,
        label: this.$t('X_HOURS_BEFORE', { hours: o }),
      }));
      optionsHours.unshift({ value: 60, label: this.$t('ONE_HOUR_BEFORE') });

      return [
        { value: 0, label: this.$t('NO_REMINDER') },
        ...optionsMinutes,
        ...optionsHours,
      ];
    },
  },
  created() {
    if (this.isAppointment) {
      // if alarm exists, set selector to alarm, otherwise use 'No Reminder'
      this.relativeAlarmTime =
        this.alarmTimeOptions.find((o) => o.value === this.currentAlarm) ||
        this.alarmTimeOptions[0];
    } else {
      // if alarm exists, set datepicker to alarm, otherwise use now
      this.absoluteAlarmTime =
        (this.currentAlarm as string) || new Date().toISOString();
    }
  },
  methods: {
    async handleYesCallback(removeAlarm: boolean) {
      const { onYes } = this.callback;
      if (onYes) {
        await onYes({
          absoluteAlarmTime: this.absoluteAlarmTime,
          relativeAlarmTime: this.relativeAlarmTime?.value ?? 0,
          removeAlarm,
        });
      }

      this.$emit('close');
    },
    async save() {
      await this.handleYesCallback(false);
    },
    async removeAlarm() {
      await this.handleYesCallback(true);
    },
    resetError() {
      this.error = '';
    },
  },
});
