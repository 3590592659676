import { AddOrEditAddressRequest } from '@consolidate/shared/data-access-legacy-api';
import AddressDetailFacade from '../../../domain/application/AddressDetailFacade';
import { AddressDetailModel } from '../../../domain/infrastructure/+state/models';
import Client from '../../api/Client';
import ConsolidateBaseCommand from '../baseCommands/ConsolidateBaseCommand';

interface Payload {
  address: AddOrEditAddressRequest;
}

class EditAddressCommand extends ConsolidateBaseCommand<Payload, string> {
  constructor(payload: Payload) {
    super('EditAddressCommand', payload);
  }

  async execute() {
    try {
      const { address } = this.payload;

      if (!address.ANR) {
        throw new Error('ANR is required for editing');
      }

      const result = await new Client().api.addOrEditAddress(address);

      const adrNumber = result?._return;

      return this.ok(adrNumber);
    } catch (error) {
      if (this.isRetryable(error)) {
        return this.save();
      }
      return this.notOk();
    }
  }

  protected async onSuccess(id: string) {
    const { address } = await AddressDetailFacade.getAddress(id);

    AddressDetailModel.update({
      data: {
        ...AddressDetailModel.find(id),
        ...address,
      },
    });
  }
}
export default EditAddressCommand;
