import {
  GetAktMemberDefault,
  GetAktResponseBaseObjects,
} from '@consolidate/shared/data-access-legacy-api';
import { NotificationService } from '@consolidate/shared/ui-components';
import { AuthService } from '@consolidate/shared/util-auth';
import { translate } from '@consolidate/shared/util-translations';
import { RRule } from 'rrule';
import Client from '../../logic/api/Client';
import { Provider, ScheduleOnlineMeetingResult } from '../../logic/api/gen';
import {
  AddActivityParticipantCommand,
  AddActivityToFolderCommand,
  AddAttachmentToActivityCommand,
  AddCommentToActivityCommand,
  CancelAppointmentCommand,
  ChangeActivityRecurrenceCommand,
  ConfirmAppointmentCommand,
  DelegateActivityCommand,
  DeleteActivityCommand,
  EditActivityAdditionalDataCommand,
  MarkActivityAsDoneCommand,
  RemoveActivityParticipantCommand,
  RemoveAttachmentFromActivityCommand,
  SendCcCommand,
  SetActivityAlarmCommand,
  SetActivityConfidentialityCommand,
  SetActivityDueDateCommand,
  SetActivityPriorityCommand,
  SetActivityProjectCommand,
  SetActivityTodoTypeCommand,
  SetActivityTypeCommand,
  SetAppointmentDateTimeCommand,
  SetSubjectCommand,
  WorkflowCancelCommand,
} from '../../logic/commands';
import AddActivityClientCommand from '../../logic/commands/activity/AddActivityClientCommand';
import AddActivityObjectCommand from '../../logic/commands/activity/AddActivityObjectCommand';
import RemoveActivityClientCommand from '../../logic/commands/activity/RemoveActivityClientCommand';
import RemoveActivityObjectCommand from '../../logic/commands/activity/RemoveActivityObjectCommand';
import RemoveRecurrencesFromChainCommand from '../../logic/commands/activity/RemoveRecurrencesFromChainCommand';
import ModalService, { PrimaryAction } from '../../logic/services/ModalService';
import { getConsiDateTimeFromUTCString } from '../../utils/date';
import { isConnectionError } from '../../utils/network';
import { mapToRRule } from '../../utils/recurrence/recurrence';
import { ensureMinApiVersion, minApiVersion } from '../../utils/versioning';
import {
  ACTIVITY_TODO_TYPE_DONE,
  ACTIVITY_TODO_TYPE_LIST,
  Activity,
  ActivityTechnology,
  AdditionalDataEntry,
  AppointmentConfirmationStatus,
  Attendee,
  AttendeeType,
} from '../entitites';
import { ActivityDetailModel } from '../infrastructure/+state/models';
import store from '../infrastructure/+state/store';
import ActivityService from './ActivityService';
import FoldersFacade from './FoldersFacade';
import MeetingProvidersFacade from './MeetingProvidersFacade';

class ActivityDetailFacade {
  public get id(): number | null {
    return store.state.entities[ActivityDetailModel.entity].detailId;
  }

  public get Activity(): Activity | undefined {
    if (this.loading) return;

    const akt = ActivityDetailModel.find(this.id ?? 0);

    if (akt) {
      if (akt.type !== 'DETAIL') return;

      return <Activity>{ ...akt };
    }
  }

  public get loading(): boolean {
    return store.state.entities[ActivityDetailModel.entity].loadingDetail;
  }

  public get loadError(): boolean {
    return store.state.entities[ActivityDetailModel.entity].loadError;
  }

  public async loadActivity(id: number) {
    ActivityDetailModel.commit((state) => {
      state.loadingDetail = true;
      state.detailId = id;
    });

    let loadError = false;
    try {
      await ActivityService.loadActivity(id);
    } catch (error) {
      loadError = isConnectionError(error);
    }

    ActivityDetailModel.commit((state) => {
      state.loadingDetail = false;
      state.loadError = loadError;
    });
  }

  public async confirmAppointment() {
    if (
      this.id &&
      this.Activity?.specializedType === ActivityTechnology.Appointment
    ) {
      ActivityService.updateAktAttendees(this.id, {
        internal: (a) =>
          a.map((x) =>
            x.userName === AuthService.getUser()?.uid
              ? {
                  ...x,
                  confirmationStatus: AppointmentConfirmationStatus.Confirmed,
                  confirmationDate: new Date().toISOString(),
                }
              : x
          ),
      });

      let sendReply = false;
      if (this.Activity.shouldSendReply) {
        sendReply = await ModalService.choiceAsync({
          title: translate('ATTEND_APPOINTMENT_TITLE'),
          subtitle: translate('ATTEND_APPOINTMENT_TEXT', {
            organizer: this.Activity.organizer ?? '',
          }),
          labels: { yes: translate('YES'), no: translate('NO') },
          canCancel: true,
          primaryAction: PrimaryAction.YES,
        });

        if (sendReply) {
          NotificationService.showSuccess({
            message: translate('ATTEND_APPOINTMENT_SENT'),
          });
        } else {
          NotificationService.showWarning({
            message: translate('ATTEND_APPOINTMENT_NOT_SENT'),
          });
        }
      }

      await new ConfirmAppointmentCommand({
        actId: this.id,
        sendReply: sendReply,
      }).execute();
    }
  }

  public cancelAppointment() {
    const id = this.id;
    const act = this.Activity;
    if (id && act?.specializedType === ActivityTechnology.Appointment) {
      ModalService.ModalCancelPrompt({
        shouldSendReply: act.shouldSendReply ? true : false,
        organizer: act.organizer ?? '',
        callback: {
          onYes: async ({ comment = '', sendReply = false }) => {
            ActivityService.updateAktAttendees(id, {
              internal: (a) =>
                a.map((x) =>
                  x.userName === AuthService.getUser()?.uid
                    ? {
                        ...x,
                        confirmationStatus:
                          AppointmentConfirmationStatus.Declined,
                        confirmationDate: new Date().toISOString(),
                      }
                    : x
                ),
            });

            await new CancelAppointmentCommand({
              actId: id,
              reason: comment,
              sendReply: sendReply,
            }).execute();

            if (act.shouldSendReply) {
              if (sendReply) {
                NotificationService.showSuccess({
                  message: translate('CANCEL_APPOINTMENT_SENT'),
                });
              } else {
                NotificationService.showWarning({
                  message: translate('CANCEL_APPOINTMENT_NOT_SENT'),
                });
              }
            }
          },
        },
      });
    }
  }

  public async removeAttendee(item: Attendee) {
    if (item.userName) {
      await this.removeAttendeeInternal(item);
    } else {
      await this.removeAttendeeExternal(item);
    }
  }

  private async removeAttendeeInternal(removedAttendee: Attendee) {
    const id = this.id;
    const act = this.Activity;
    if (id && act?.specializedType === ActivityTechnology.Appointment) {
      ModalService.prompt({
        title: translate('REMOVE_FROM_APPOINTMENT'),
        subtitle: translate('REMOVE_FROM_APPOINTMENT_TEXT', {
          name: removedAttendee.name,
        }),
        buttonLabel: translate('CONFIRM'),
        callback: {
          onYes: async ({ comment = '' }) => {
            const { ok } = await new RemoveActivityParticipantCommand({
              aktid: id,
              uid: removedAttendee.userName,
              reason: comment,
            }).execute();

            if (ok) {
              ActivityService.removeAttendeeInternal(id, removedAttendee);
            }
          },
        },
      });
    }
  }

  private async removeAttendeeExternal(removedAttendee: Attendee) {
    const id = this.id;
    const act = this.Activity;
    if (id && act?.specializedType === ActivityTechnology.Appointment) {
      ModalService.choice({
        title: translate('REMOVE_FROM_APPOINTMENT'),
        subtitle: translate('REMOVE_FROM_APPOINTMENT_TEXT', {
          name: removedAttendee.name,
        }),
        labels: { yes: translate('CONFIRM') },
        canCancel: true,
        callback: {
          onYes: async () => {
            const { ok } = await new RemoveActivityParticipantCommand({
              aktid: id,
              cid: removedAttendee.cid,
              anr: removedAttendee.anr,
            }).execute();

            if (ok) {
              ActivityService.removeAttendeeExternal(id, removedAttendee);
            }
          },
        },
      });
    }
  }

  public async addAttendee(type: AttendeeType) {
    const id = this.id;
    const act = this.Activity;
    if (id && act?.specializedType === ActivityTechnology.Appointment) {
      ModalService.addAttendee(type, {
        onYes: async ({ attendee }) => {
          if ('userName' in attendee) {
            const { ok } = await new AddActivityParticipantCommand({
              aktid: id,
              uid: attendee.userName,
            }).execute();

            if (ok) {
              ActivityService.addAttendeeInternal(id, attendee);
            }
          }
          if ('type' in attendee && attendee.type === 'CONTACT') {
            const { ok } = await new AddActivityParticipantCommand({
              aktid: id,
              cid: attendee.id,
            }).execute();

            if (ok) {
              ActivityService.addAttendeeExternal(id, attendee);
            }
          }
          return true;
        },
      });
    }
  }

  public async addContact() {
    const id = this.id;
    if (id) {
      ModalService.addContact({
        onYes: async ({ contact }) => {
          if (Array.isArray(contact)) return false;

          const { ok } = await new AddActivityParticipantCommand({
            aktid: id,
            cid: contact.type === 'CONTACT' ? contact.id : undefined,
            anr: contact.type === 'ADDRESS' ? contact.id : undefined,
          }).execute();

          if (ok) {
            ActivityService.addContact(id, contact);
          }
          return true;
        },
      });
    }
  }

  public async removeContact(item: GetAktMemberDefault) {
    const id = this.id;
    if (id) {
      ModalService.choice({
        title: translate('REMOVE_CONTACT'),
        subtitle: translate('REMOVE_CONTACT_TEXT', {
          name: item.description ?? '',
        }),
        labels: { yes: translate('CONFIRM') },
        canCancel: true,
        callback: {
          onYes: async () => {
            const { ok } = await new RemoveActivityParticipantCommand({
              aktid: id,
              cid: item.cid,
              anr: item.anr,
            }).execute();

            if (ok) {
              ActivityService.removeContact(id, item);
            }
          },
        },
      });
    }
  }

  public async addClient() {
    const id = this.id;
    if (id) {
      ModalService.addContact(
        {
          onYes: async ({ contact: client }) => {
            if (Array.isArray(client)) return false;

            const { ok } = await new AddActivityClientCommand({
              activityId: id,
              contactId: client.type === 'CONTACT' ? client.id : undefined,
              addressId: client.type === 'ADDRESS' ? client.id : undefined,
            }).execute();

            if (ok) {
              ActivityService.addClient(id, client);
            }
            return true;
          },
        },
        translate('ADD_CLIENT')
      );
    }
  }

  public async removeClient(item: GetAktMemberDefault) {
    const id = this.id;
    if (id) {
      ModalService.choice({
        title: translate('REMOVE_CLIENT'),
        subtitle: translate('REMOVE_CONTACT_TEXT', {
          name: item.description ?? '',
        }),
        labels: { yes: translate('CONFIRM') },
        canCancel: true,
        callback: {
          onYes: async () => {
            const { ok } = await new RemoveActivityClientCommand({
              activityId: id,
              contactId: item.cid,
              addressId: item.anr,
            }).execute();

            if (ok) {
              ActivityService.removeClient(id, item);
            }
          },
        },
      });
    }
  }

  @minApiVersion('5.2012')
  public async addObject() {
    const id = this.id;
    if (id && this.Activity) {
      ModalService.addObject(
        translate('ADD_OBJECTS_TEXT', { act: this.Activity.subject }),

        {
          onYes: async ({ objects }) => {
            const { ok } = await new AddActivityObjectCommand({
              activityId: id,
              objectIdsDto: { ids: [...objects.map((x) => x.id)] },
            }).execute();

            if (ok) {
              ActivityService.addObject(id, objects);
            }

            return true;
          },
        }
      );
    }
  }

  @minApiVersion('5.2012')
  public async removeObject(removedObject: GetAktResponseBaseObjects) {
    const id = this.id;
    if (id) {
      ModalService.choice({
        title: translate('REMOVE_OBJECT'),
        subtitle: translate('REMOVE_OBJECT_TEXT', {
          object: removedObject.description ?? '',
        }),
        labels: { yes: translate('CONFIRM') },
        canCancel: true,

        callback: {
          onYes: async () => {
            const { ok } = await new RemoveActivityObjectCommand({
              activityId: id,
              objectId: removedObject.id,
            }).execute();

            if (ok) {
              ActivityService.removeObject(id, removedObject.id);
            }
          },
        },
      });
    }
  }

  public changeActPriority() {
    const id = this.id;
    if (id && this.Activity) {
      ModalService.activityPriority({
        subject: this.Activity.subject,
        priorityId: this.Activity.priorityId,
        callback: {
          onYes: ({ priority }) => {
            ActivityService.updateAkt(id, {
              priorityId: priority,
            });

            new SetActivityPriorityCommand({
              id: id,
              priority,
            }).execute();
          },
        },
      });
    }
  }

  public async changeActConfidentiality() {
    const id = this.id;
    if (id && this.Activity) {
      ModalService.activityConfidentiality({
        subject: this.Activity.subject,
        initialConfidentiality: this.Activity.confidentiality,
        callback: {
          onYes: ({ confidentiality }) => {
            ActivityService.updateAkt(id, {
              confidentiality: confidentiality,
            });

            new SetActivityConfidentialityCommand({
              actId: id,
              confidentiality: confidentiality.id,
            }).execute();
          },
        },
      });
    }
  }

  public async changeActTodoType() {
    const id = this.id;
    if (id && this.Activity) {
      ModalService.activityTodoType({
        initialTodoType: this.Activity.todoType,
        subject: this.Activity.subject,
        callback: {
          onYes: ({ todoType }) => {
            ActivityService.updateAkt(id, {
              todoType: todoType,
            });

            new SetActivityTodoTypeCommand({
              actId: id,
              todoType: todoType.id,
            }).execute();
          },
        },
      });
    }
  }

  public async changeActProject() {
    const id = this.id;
    if (id && this.Activity) {
      ModalService.activityProject({
        subject: this.Activity.subject,
        initialProject: this.Activity.project,
        callback: {
          onYes: ({ selectedProject }) => {
            ActivityService.updateAkt(id, {
              project: selectedProject,
            });

            new SetActivityProjectCommand({
              actId: id,
              projectId: selectedProject?.id ?? 0,
            }).execute();
          },
        },
      });
    }
  }

  public async changeActStartEndTime() {
    const id = this.id;
    if (
      id &&
      this.Activity &&
      this.Activity.specializedType === ActivityTechnology.Appointment
    ) {
      const hasReservations = this.Activity.reservations?.length > 0;

      ModalService.activityStartEndTime({
        subject: this.Activity.subject,
        initialStartTime: this.Activity.startDateTimeUtc,
        initialEndTime: this.Activity.endDateTimeUtc,
        initialIsAllDay: this.Activity.isAllDay,
        callback: {
          onYes: async ({ startTime, endTime, isAllDay }) => {
            let updateReservations = false;
            if (hasReservations) {
              updateReservations = await ModalService.choiceAsync({
                title: translate('UPDATE_RESERVATIONS_TITLE'),
                subtitle: translate('UPDATE_RESERVATIONS_SUBTITLE'),
                canCancel: true,
                primaryAction: PrimaryAction.YES,
                labels: {
                  yes: translate('YES'),
                  no: translate('NO'),
                },
              });
            }

            // If yes has been selected, show dialog if server does not support it
            if (updateReservations && !ensureMinApiVersion('5.2016')) {
              return;
            }

            ActivityService.updateAkt(id, {
              startDateTimeUtc: startTime,
              endDateTimeUtc: endTime,
              isAllDay: isAllDay,
            });

            new SetAppointmentDateTimeCommand({
              actId: id,
              startDateTimeUtc: startTime,
              endDateTimeUtc: endTime,
              isAllDay: isAllDay,
              updateReservations,
            }).execute();
          },
        },
      });
    }
  }

  public async toggleAktCompleted() {
    if (this.Activity) {
      if (!this.Activity.rights.edit) return;

      if (this.Activity.todoType.id !== ACTIVITY_TODO_TYPE_DONE.id) {
        ActivityService.updateAkt(this.Activity.id, {
          todoType: ACTIVITY_TODO_TYPE_DONE,
        });

        const { ok } = await new MarkActivityAsDoneCommand({
          actId: this.Activity.id,
        }).execute();
        if (ok === true) {
          // we check for true explicitly becasue the "showSuccess" should not be shown if the command was "queued"
          const result = await NotificationService.showSuccess({
            message: translate('ACTIVITY_COMPLETED'),
            canUndo: true,
          }).getResult();

          if (result.buttonClicked) {
            this.toggleAktCompleted();
          }
        }
      } else {
        const todoType = ACTIVITY_TODO_TYPE_LIST[0];
        ActivityService.updateAkt(this.Activity.id, {
          todoType: todoType,
        });

        await new SetActivityTodoTypeCommand({
          actId: this.Activity.id,
          todoType: todoType.id,
        }).execute();
      }
    }
  }

  public delegateAct(): Promise<boolean> | undefined {
    const id = this.id;
    if (id && this.Activity) {
      if (!this.Activity.rights.delegate) return;
      const activity = this.Activity;

      return new Promise<boolean>((resolve) => {
        ModalService.activityDelegate({
          user: activity.responsible,
          subject: activity.subject,
          callback: {
            onYes: async ({ userName, comment }) => {
              ActivityService.updateAkt(id, {
                responsible: userName,
              });

              const { ok } = await new DelegateActivityCommand({
                actId: id,
                userName,
                comment,
              }).execute();

              resolve(!!ok);
            },
          },
        });
      });
    }
  }

  public changeActType() {
    const id = this.id;
    if (id && this.Activity) {
      if (!this.Activity.rights.edit) return;

      ModalService.activityChooseActType({
        subject: this.Activity.subject,
        currentType: this.Activity.actType,
        callback: {
          onYes: async (actType) => {
            ActivityService.updateAkt(id, {
              actType,
              icon: actType.icon,
              iconColor: actType.color,
            });

            await new SetActivityTypeCommand({
              actId: id,
              actType: actType.id,
            }).execute();
          },
        },
      });
    }
  }

  public sendCCs() {
    const id = this.id;
    if (id && this.Activity) {
      ModalService.activitySendCC({
        latestInternalCCs: this.Activity.latestInternalCCs,
        callback: {
          onYes: async ({ receivers, comment }) => {
            if (receivers.length > 0) {
              ActivityService.updateAkt(
                id,
                {
                  latestInternalCCs: receivers
                    .concat(this.Activity?.latestInternalCCs ?? [])
                    .unique(),
                },
                false
              );

              await new SendCcCommand({
                actId: id,
                receivers: receivers,
                comment: comment,
              }).execute();
            }
          },
        },
      });
    }
  }

  public postponeAct() {
    const id = this.id;
    if (id && this.Activity) {
      ModalService.activityPostpone({
        subject: this.Activity.subject,
        dueDate:
          this.Activity.specializedType !== ActivityTechnology.Appointment
            ? this.Activity.dueDateTimeUtc
            : '',
        callback: {
          onYes: ({ dueDateTimeUtc }) => {
            ActivityService.updateAkt(id, {
              dueDateTimeUtc: dueDateTimeUtc,
            });
            new SetActivityDueDateCommand({
              id: id,
              dateTimeUtc: dueDateTimeUtc,
            }).execute();
          },
        },
      });
    }
  }

  public changeRecurrence() {
    if (!ensureMinApiVersion('5.2019')) return;

    const id = this.id;
    if (
      id &&
      this.Activity?.specializedType === ActivityTechnology.Appointment &&
      this.Activity.recurrence
    ) {
      const endDate = new Date(this.Activity.endDateTimeUtc);
      const rule = mapToRRule(
        this.Activity.startDateTimeUtc,
        this.Activity.recurrence
      );

      const futureInstances = rule.all().filter((x) => x > new Date());

      if (futureInstances.length === 0) {
        NotificationService.showError({
          message: translate('NO_FUTURE_INSTANCES'),
        });
        return;
      }

      ModalService.chooseRecurrenceInstance(futureInstances, {
        onSelected: (instance) => {
          ModalService.editRecurrence(
            new RRule({
              ...rule.rrules()[0].options,
              count: undefined,
              dtstart: instance,
              until: endDate,
            }),
            {
              onYes: async (rrule) => {
                if (!rrule) return;

                await new ChangeActivityRecurrenceCommand({
                  actId: id,
                  recurrence: rrule,
                  splitDate: instance,
                }).execute();
              },
            }
          );
        },
      });
    }
  }

  public async addAttachment(attachment: {
    name: string;
    size: number;
    mimeType: string;
    base64Data: string;
  }) {
    if (this.id && this.Activity) {
      await new AddAttachmentToActivityCommand({
        actId: this.id,
        attachment,
      }).execute();
    }
  }

  public async removeAttachment(attId: number) {
    if (this.id && this.Activity) {
      const result = await new RemoveAttachmentFromActivityCommand({
        actId: this.id,
        attId: attId,
      }).execute();

      if (result.ok) {
        const attachments = this.Activity.attachments.filter(
          (x) => x.id !== attId
        );
        ActivityService.updateAkt(this.id, {
          hasAttachments: attachments.length > 0,
          attachments,
        });
      }
    }
  }

  public async commentActivity() {
    const id = this.id;
    if (id && this.Activity) {
      if (!this.Activity.rights.edit) return;

      ModalService.prompt({
        title: translate('COMMENT_ACTIVITY'),
        buttonLabel: translate('ACTION_COMMENT_CONFIRM'),
        subtitle: translate('COMMENT_ADD', { act: this.Activity.subject }),
        callback: {
          onYes: async ({ comment }) => {
            await new AddCommentToActivityCommand({
              actId: id,
              comment,
            }).execute();
          },
        },
      });
    }
  }

  public async addActToFolder() {
    const id = this.id;
    if (id && this.Activity) {
      ModalService.activityAddToFolder({
        subject: this.Activity.subject,
        callback: {
          onYes: async ({ folderId, subject }) => {
            await new AddActivityToFolderCommand({
              activityIDs: [id],
              folderId,
              subject,
            }).execute();
            FoldersFacade.load();
          },
        },
      });
    }
  }

  public hasAlarm(): boolean {
    if (this.Activity?.specializedType === ActivityTechnology.Appointment) {
      return this.Activity.alarmTime > 0;
    }

    return !!this.Activity?.alarmDateTimeUtc;
  }

  public async setAlarm() {
    const id = this.id;
    if (!(id && this.Activity)) return;
    if (!this.Activity.rights.edit) return;

    const isAppointment =
      this.Activity.specializedType === ActivityTechnology.Appointment;

    ModalService.activityAlarm({
      subject: this.Activity.subject,
      currentAlarm:
        this.Activity.specializedType === ActivityTechnology.Appointment
          ? this.Activity.alarmTime
          : this.Activity.alarmDateTimeUtc,
      isAppointment,
      callback: {
        onYes: async ({
          absoluteAlarmTime,
          relativeAlarmTime,
          removeAlarm,
        }) => {
          /**
           * If the actType is an appointment, we need to send a relative time to the appointment (X minutes before),
           * in any other case we need to send a UTC time string.
           * To delete the appointment we send an alarmtime of 0.
           */
          const alarmTime = isAppointment
            ? relativeAlarmTime
            : getConsiDateTimeFromUTCString(absoluteAlarmTime);

          const { ok } = await new SetActivityAlarmCommand({
            id,
            alarmTime: removeAlarm ? '0' : alarmTime,
          }).execute();

          if (ok) {
            if (isAppointment) {
              ActivityService.updateAkt(id, {
                alarmTime: removeAlarm ? undefined : relativeAlarmTime,
              });
            } else {
              ActivityService.updateAkt(id, {
                alarmDateTimeUtc: removeAlarm ? undefined : absoluteAlarmTime,
              });
            }
          }
        },
      },
    });
  }

  public deleteAct(): Promise<boolean> | undefined {
    const id = this.id;
    if (id && this.Activity) {
      if (!this.Activity.rights.delete) return;
      const activity = this.Activity;

      return new Promise<boolean>((resolve) => {
        ModalService.choice({
          title: translate('DELETE_ACTIVITY'),
          subtitle: translate('DELETE_ACTIVITY_TEXT', {
            act: activity.subject,
          }),
          labels: { yes: translate('DELETE') },
          canCancel: true,
          callback: {
            onYes: async () => {
              const { ok } = await new DeleteActivityCommand({
                actId: id,
              }).execute();

              if (ok) {
                resolve(true);
                ActivityDetailModel.delete(id);
              } else {
                resolve(false);
              }
            },
          },
        });
      });
    }
  }

  public editSubject(): Promise<boolean> | undefined {
    const id = this.id;
    if (id && this.Activity) {
      if (!this.Activity.rights.edit) return;

      ModalService.editSubject({
        initialValue: this.Activity.subject,
        callback: {
          onYes: async ({ subject }) => {
            const result = await new SetSubjectCommand({
              actId: id,
              subject: subject,
            }).execute();

            if (result.ok) {
              ActivityService.updateAkt(id, {
                subject: subject,
              });
            }
          },
        },
      });
    }
  }

  public async continueWorkflow() {
    if (this.id) {
      ActivityService.continueWorkflow(this.id);
    }
  }

  public cancelWorkflow() {
    const id = this.id;
    if (id && this.Activity?.workflow) {
      ModalService.prompt({
        title: translate('CANCEL_WORKFLOW'),
        buttonLabel: translate('CANCEL_WORKFLOW'),
        callback: {
          async onYes({ comment }) {
            const result = await new WorkflowCancelCommand({
              aktid: id,
              reasoncomment: comment,
            }).execute();

            if (result.ok) {
              ActivityService.updateAkt(id, {
                workflow: null,
              });
            }
          },
        },
      });
    }
  }

  public showAcceptionsAndCancellationsOfInstance() {
    if (!ensureMinApiVersion('5.2019')) return;

    const id = this.id;
    if (
      id &&
      this.Activity &&
      this.Activity.specializedType === ActivityTechnology.Appointment &&
      this.Activity.recurrence
    ) {
      const rule = mapToRRule(
        this.Activity.startDateTimeUtc,
        this.Activity.recurrence
      );
      const attendees = this.Activity.attendeesInternal;

      ModalService.chooseInstance({
        recurrence: rule,
        callback: {
          onYes: (date) => {
            ModalService.showAttendeeConfirmationStatus({
              date: date,
              attendeesInternal: attendees,
              callback: {
                onBack: () => {
                  this.showAcceptionsAndCancellationsOfInstance();
                },
              },
            });
          },
        },
      });
    }
  }

  public editChain() {
    if (!ensureMinApiVersion('5.2020')) return;

    const id = this.id;
    if (
      id &&
      this.Activity &&
      this.Activity.specializedType === ActivityTechnology.Appointment &&
      this.Activity.recurrence
    ) {
      const rule = mapToRRule(
        this.Activity.startDateTimeUtc,
        this.Activity.recurrence
      );

      ModalService.chooseInstanceEditChain({
        recurrence: rule,
        callback: {
          removeFromChain: async ({ exceptions, deleteExceptions }) => {
            const result = await new RemoveRecurrencesFromChainCommand({
              actId: id,
              exceptions: exceptions,
              deleteExceptions: deleteExceptions,
            }).execute();

            if (result.ok) {
              if (exceptions.length === rule.all().length) {
                NotificationService.showInfo({
                  message: deleteExceptions
                    ? translate('CHAIN_REMOVE_DELETE_ALL_TEXT')
                    : translate('CHAIN_REMOVE_ALL_TEXT', {
                        count: exceptions.length,
                      }),
                });
              } else {
                NotificationService.showInfo({
                  message: deleteExceptions
                    ? translate('CHAIN_REMOVE_DELETE_TEXT')
                    : translate('CHAIN_REMOVE_TEXT'),
                });
              }
            }
          },
          seperateChainFromHere: async (exception) => {
            const newRule = new RRule({
              ...rule.rrules()[0].options,
              count: undefined,
              dtstart: exception,
              until: rule.all().at(-1),
            });

            const result = await new ChangeActivityRecurrenceCommand({
              actId: id,
              recurrence: newRule,
              splitDate: exception,
              skipNotification: true,
            }).execute();

            if (result.ok) {
              NotificationService.showInfo({
                message: translate('CHAIN_SEPERATE_BEFORE_APPOINTMENT_TEXT', {
                  date: exception.toLocaleDateStringLS(),
                }),
              });
            }
          },

          deleteChainFromHere: async (exception) => {
            const newRule = new RRule({
              ...rule.rrules()[0].options,
              until: exception,
            });

            const result = await new ChangeActivityRecurrenceCommand({
              actId: id,
              recurrence: newRule,
              splitDate: rule.all().at(0) as Date,
              skipNotification: true,
            }).execute();

            if (result.ok) {
              NotificationService.showInfo({
                message: translate('CHAIN_DELETE_FROM_HERE_TEXT', {
                  date: exception.toLocaleDateStringLS(),
                }),
              });
            }
          },
        },
      });
    }
  }

  @minApiVersion('5.2022')
  public async editAdditionalData(form: string, fields: AdditionalDataEntry[]) {
    const id = this.id;
    if (id) {
      await new EditActivityAdditionalDataCommand({
        activityId: id,
        form,
        fields,
      }).execute();

      ActivityService.updateAkt(id, {
        additionalData: this.Activity?.additionalData?.map((x) =>
          x.key === form
            ? {
                ...x,
                fields: x.fields.map(
                  (f) => fields.find((n) => n.key === f.key) ?? f
                ),
              }
            : x
        ),
      });
    }
  }

  @minApiVersion('5.2100')
  public async createOnlineMeeting(
    provider: Provider | null = null,
    isRetry = false
  ) {
    if (!provider) {
      if (MeetingProvidersFacade.items.length === 1) {
        provider = MeetingProvidersFacade.items[0].id;
      } else {
        ModalService.chooseProvider((provider) =>
          this.createOnlineMeeting(provider.id, isRetry)
        );
        return;
      }
    }

    const id = this.id;
    if (id) {
      try {
        ActivityDetailModel.commit((state) => {
          state.loadingDetail = true;
        });
        const { result } =
          await new Client().activities.activitiesScheduleOnlineMeeting(id, {
            provider: provider,
          });

        if (result === ScheduleOnlineMeetingResult.NUMBER_0) {
          await this.loadActivity(id);
        } else {
          if (isRetry) return;
          // oauth
          if (result === ScheduleOnlineMeetingResult.NUMBER_1) {
            await MeetingProvidersFacade.loginOAuth(provider, () => {
              this.createOnlineMeeting(provider, true);
            });
          } else if (result === ScheduleOnlineMeetingResult.NUMBER_2) {
            await MeetingProvidersFacade.loginCredentials(provider, () => {
              this.createOnlineMeeting(provider, true);
            });
          }
        }
      } catch {
        NotificationService.showError({
          message: translate('ONLINE_MEETING_CREATE_ERROR'),
        });
      } finally {
        ActivityDetailModel.commit((state) => {
          state.loadingDetail = false;
        });
      }
    }
  }

  @minApiVersion('5.2100')
  public async removeOnlineMeeting() {
    const id = this.id;
    if (id) {
      try {
        ActivityDetailModel.commit((state) => {
          state.loadingDetail = true;
        });

        await new Client().activities.activitiesRemoveOnlineMeeting(id);
        await this.loadActivity(id);
      } finally {
        ActivityDetailModel.commit((state) => {
          state.loadingDetail = false;
        });
      }
    }
  }
}

export default new ActivityDetailFacade();
