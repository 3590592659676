




































































import ProjectsList from './ProjectsList.vue';
import ProjectsFacade from '../../domain/application/ProjectsFacade';
import Vue from 'vue';
import { searchMixin } from '../../mixins/searchMixin';
import { translate } from '@consolidate/shared/util-translations';
import { ensureMinApiVersion } from '../../utils/versioning';

export default Vue.extend({
  metaInfo: {
    titleTemplate: `${translate('PROJECTS')}  %s`,
  },
  components: {
    ProjectsList,
  },
  mixins: [searchMixin(ProjectsFacade.default, ProjectsFacade.searchResults)],
  data: () => ({
    showFilters: false,
  }),
  computed: {
    hideInactive: {
      get() {
        return ProjectsFacade.hideInactive;
      },
      set(value: boolean) {
        ProjectsFacade.setHideInactive(value);
      },
    },
    supportsFiltering() {
      return ensureMinApiVersion('5.2023', true);
    },
    items() {
      return ProjectsFacade.default.filteredItems;
    },
    isLoading(): boolean {
      return ProjectsFacade.default.loading;
    },
    loadError(): boolean {
      return ProjectsFacade.default.loadError;
    },
  },
  created(): void {
    if (!this.items.length) {
      (this as any).filterOpen = true;
    }
  },
  methods: {
    reload() {
      ProjectsFacade.default.load(true);
    },
    loadMore() {
      ProjectsFacade.searchResults.loadMore();
    },
  },
});
