

































































































































import Vue, { PropType } from 'vue';
import { AuthService } from '../../../../../../../libs/shared/util-auth/src';
import {
  Activity,
  ActivityTechnology,
  AppointmentConfirmationStatus,
} from '../../../../domain';
import ActivityDetailFacade from '../../../../domain/application/ActivityDetailFacade';
import {
  formatDateDuration,
  formatDateTimeDuration,
} from '../../../../utils/date';
import { capitalize } from '../../../../utils/string';

export default Vue.extend({
  components: {},
  props: {
    data: { type: Object as PropType<Activity>, required: true },
  },
  data: () => ({
    collapsed: false,
  }),
  computed: {
    activityDisplayTime(): string {
      if (this.data.specializedType === ActivityTechnology.Appointment) {
        return this.data.isAllDay
          ? formatDateDuration(
              this.data.startDateTimeUtc,
              this.data.endDateTimeUtc
            )
          : formatDateTimeDuration(
              this.data.startDateTimeUtc,
              this.data.endDateTimeUtc
            );
      }
      return new Date(this.data.dueDateTimeUtc).toLongDateString();
    },
    attendeesString(): string {
      if (this.data.specializedType === ActivityTechnology.Appointment) {
        const attendees = this.data.attendeesInternal.concat(
          this.data.attendeesExternal
        );
        let val = attendees
          .slice(0, 5)
          .map((r) => r.name.trim())
          .join(', ');

        if (attendees.length > 5) {
          val += ` (${this.$t('ADDITIONAL_ITEMS', {
            count: attendees.length - 5,
          })})`;
        }

        return val;
      }
      return '';
    },
    myConfirmationStatus(): AppointmentConfirmationStatus {
      if (this.data.specializedType === ActivityTechnology.Appointment) {
        const myUserName = AuthService.getUser()?.uid;
        const myIndex = this.data?.attendeesInternal?.findIndex(
          (a) => a.userName === myUserName
        );

        if (myIndex < 0) return -1;
        return this.data?.attendeesInternal[myIndex]?.confirmationStatus;
      }
      return 0;
    },
    isAppointmentAttendable(): boolean {
      return [
        AppointmentConfirmationStatus.Outstanding,
        AppointmentConfirmationStatus.Declined,
      ].includes(this.myConfirmationStatus);
    },
    isAppointmentCancellable(): boolean {
      return [
        AppointmentConfirmationStatus.NoConfirmationNeeded,
        AppointmentConfirmationStatus.Outstanding,
        AppointmentConfirmationStatus.Confirmed,
      ].includes(this.myConfirmationStatus);
    },
    canSetDone(): boolean {
      return this.data.rights.edit && !this.data.workflow;
    },
  },
  methods: {
    capitalize: (s: string) => capitalize(s),
    toggleAktCompleted() {
      ActivityDetailFacade.toggleAktCompleted();
    },
    async delegateAct() {
      if (await ActivityDetailFacade.delegateAct())
        this.redirectToListViewIfNeeded();
    },
    chooseActType() {
      ActivityDetailFacade.changeActType();
    },
    sendCCs() {
      ActivityDetailFacade.sendCCs();
    },
    redirectToListViewIfNeeded() {
      if (+this.$route?.params?.id === this.data.id) {
        const listPath = this.$route.path.replace(`/${this.data.id}`, '');
        this.$router.push(listPath);
      }
    },
    postponeAct() {
      ActivityDetailFacade.postponeAct();
    },
    confirmAppointment() {
      ActivityDetailFacade.confirmAppointment();
    },
    cancelAppointment() {
      ActivityDetailFacade.cancelAppointment();
    },
    joinMeeting() {
      if (this.data.specializedType !== ActivityTechnology.Appointment) return;
      window.open(this.data.onlineMeeting?.joinLink, '_blank');
    },
  },
});
