import {
  GetAktMemberDefault,
  GetAktResponseBaseObjects,
  WorkflowDoNextStepRequest,
} from '@consolidate/shared/data-access-legacy-api';
import { NotificationService } from '@consolidate/shared/ui-components';
import { AuthService } from '@consolidate/shared/util-auth';
import { translate } from '@consolidate/shared/util-translations';
import localforage from 'localforage';
import Client from '../../logic/api/Client';
import {
  ActivityStartProcessCommand,
  DelegateActivityCommand,
  DeleteActivityCommand,
  MarkActivityAsDoneCommand,
  MarkActivityAsReadCommand,
  RemoveActivitiesFromFolderCommand,
  WorkflowContinueCommand,
} from '../../logic/commands';
import AddActivityCommand, {
  AddActivityCommandPayload,
} from '../../logic/commands/activity/AddActivityCommand';
import SendAppointmentInvitationCommand from '../../logic/commands/activity/SendAppointmentInvitationCommand';
import { GetActivityQuery } from '../../logic/queries';
import ModalService from '../../logic/services/ModalService';
import { ensureMinApiVersion } from '../../utils/versioning';
import {
  ACTIVITY_TODO_TYPE_DONE,
  Activity,
  ActivityTechnology,
  ActivityType,
  ActivityTypeWorkflow,
  AppointmentConfirmationStatus,
  Attendee,
  ContactListItem,
  ContactOrAddress,
  User,
  WorkFlowStepType,
  WorkflowStepFireType,
} from '../entitites';
import {
  ActivityDetailModel,
  ActivityModel,
  ActivitySearchResultModel,
} from '../infrastructure/+state/models';
import ActivityEvents from './ActivityEvents';
import ActivityTypesService from './ActivityTypesService';
import FoldersFacade from './FoldersFacade';
import InboxFacade from './InboxFacade';
import TasksFacade from './TasksFacade';

export interface ActivityCreationContext {
  startDate?: Date;
  endDate?: Date;
  selectedDate?: string;
  contact?: string | ContactOrAddress;
  subject?: string;
  text?: string;
  files?: { name: string; size: number; base64: string }[];
}

class ActivityService {
  constructor() {
    ActivityEvents.on(
      'activityCreated',
      this.addActivityToLastCreated.bind(this)
    );
    ActivityEvents.on('activityCreated', (act) => {
      this.sendAppointmentInvitation(act);
    });
    ActivityEvents.on('appointmentDateChanged', (id) => {
      const act = ActivityDetailModel.find(id);
      if (!act) return;

      this.sendAppointmentInvitation(act, true);
    });
    ActivityEvents.on('activityDeleted', (id) => {
      const act = ActivityDetailModel.find(id);
      if (!act) return;

      this.sendAppointmentInvitation(act, false, true);
    });
  }

  public creationContext: ActivityCreationContext = {};

  public async createActivity(payload: AddActivityCommandPayload) {
    ActivityTypesService.activityCreated(payload.actType);
    const result = await new AddActivityCommand(payload).execute();

    return result.ok;
  }

  private async sendAppointmentInvitationOrCancelationOnChange(
    act: ActivityDetailModel,
    attendee: Attendee | ContactListItem,
    cancel = false
  ): Promise<void> {
    this.sendAppointmentInvitation(
      act,
      true,
      false,
      cancel ? 'Cancel' : 'REQUEST',
      attendee
    );
  }

  private sendAppointmentInvitation(
    activity: ActivityDetailModel | Activity,
    changed = false,
    activityDeleted = false,
    method = 'REQUEST',
    attendee?: Attendee | ContactListItem | undefined
  ): void {
    if (
      !ensureMinApiVersion('5.2030', true) ||
      (activityDeleted && !ensureMinApiVersion('5.2061', true)) ||
      activity.specializedType !== ActivityTechnology.Appointment
    )
      return;

    if (!attendee && (activity.attendeesExternal?.length ?? 0) <= 0) return;

    let anr: string | undefined = undefined;
    let cid: string | undefined = undefined;
    let email: string | undefined = undefined;

    if (attendee) {
      anr = 'anr' in attendee ? attendee.anr : undefined;
      cid =
        'cid' in attendee
          ? attendee.cid?.toString()
          : (attendee as ContactListItem).id.toString();
      email = attendee.email;
    }

    ModalService.sendAppointmentInvitation({
      callback: {
        async onYes({ withcomment }) {
          await new SendAppointmentInvitationCommand({
            aktid: activity.id,
            withcomment: withcomment,
            activitydeleted: activityDeleted,
            method: activityDeleted ? 'CANCEL' : method,
            aNR: anr,
            cID: cid,
            email: email,
          }).execute();
        },
      },
      changed,
      deleted: activityDeleted,
    });
  }

  public addActivityToLastCreated(activity: Activity): void {
    ActivityModel.commit((state) => {
      state.lastCreated.unshift(activity.id);
      if (state.lastCreated.length > 10) {
        state.lastCreated.pop();
      }
    });

    const isRecurring =
      'recurrence' in activity && activity.recurrence !== null;

    ActivityModel.insert({ data: { ...activity, isRecurring } });
  }

  async markAllAsRead(ids: number[]) {
    // make sure we execute the action only for the supplied ids
    // even if the array changes after the function was called
    ids = [...ids];

    InboxFacade.deleteForActivities(ids);

    for (const id of ids) {
      await new MarkActivityAsReadCommand({ actId: id }).execute();
    }
  }

  async markAllAsDone(ids: number[]) {
    // make sure we execute the action only for the supplied ids
    // even if the array changes after the function was called
    ids = [...ids];

    for (const id of ids) {
      this.updateAkt(id, {
        todoType: ACTIVITY_TODO_TYPE_DONE,
      });
    }

    const result = await NotificationService.showSuccess({
      message:
        ids.length === 1
          ? translate('ACTIVITY_COMPLETING')
          : translate('ACTIVITIES_COMPLETING', {
              activites: ids.length.toString(),
            }),
      canUndo: true,
    }).getResult();

    if (!result.buttonClicked) {
      for (const id of ids) {
        await new MarkActivityAsDoneCommand({ actId: id }).execute();
      }
    } else {
      // if the user clicked undo just reload
      TasksFacade.load(true);
      InboxFacade.load(true);
    }
  }

  public delegateAll(ids: number[]): Promise<void> {
    // make sure we execute the action only for the supplied ids
    // even if the array changes after the function was called
    ids = [...ids];

    return new Promise((resolve) => {
      ModalService.activityDelegate({
        callback: {
          onYes: async ({ userName = '', comment = '' }) => {
            for (const id of ids) {
              this.updateAkt(id, {
                responsible: userName,
              });
            }

            resolve();

            for (const id of ids) {
              await new DelegateActivityCommand({
                actId: id,
                userName,
                comment,
              }).execute();
            }
          },
        },
      });
    });
  }

  public async removeAllFromFolder(ids: number[], folderId: number) {
    // make sure we execute the action only for the supplied ids
    // even if the array changes after the function was called
    ids = [...ids];

    const { ok } = await new RemoveActivitiesFromFolderCommand({
      aktids: ids,
      folderid: folderId,
    }).execute();
    if (ok) {
      for (const id of ids) {
        ActivitySearchResultModel.delete(id);
      }
      FoldersFacade.load();
    }
  }

  deleteAll(ids: number[]): Promise<boolean> | undefined {
    // make sure we execute the action only for the supplied ids
    // even if the array changes after the function was called
    ids = [...ids];

    return new Promise<boolean>((resolve) => {
      ModalService.choice({
        title: translate('DELETE_ACTIVITIES'),
        subtitle: translate('DELETE_ACTIVITIES_TEXT'),
        labels: { yes: translate('DELETE') },
        canCancel: true,
        callback: {
          onYes: async () => {
            // we have two loops, the first one is so that all the items get removed from the list immediately.
            // the second one is to delete them in the background.
            for (const id of ids) {
              ActivityModel.delete(id);
            }

            for (const id of ids) {
              const { ok } = await new DeleteActivityCommand({
                actId: id,
                surpressSuccessMessage: true,
              }).execute();

              if (!ok) {
                resolve(false);
                return;
              }
            }
            NotificationService.showSuccess({
              message:
                ids.length > 1
                  ? translate('DELETE_ACTIVITIES_SUCCESS', {
                      activities: ids.length.toString(),
                    })
                  : translate('DELETE_ACTIVITY_SUCCESS'),
            });
            resolve(true);
          },
        },
      });
    });
  }

  public addAttendeeInternal(id: number, attendee: User) {
    const act = ActivityDetailModel.find(id);
    if (!act?.attendeesInternal) return;

    if (act.attendeesInternal.find((x) => x.userName === attendee.userName)) {
      this.updateAktAttendees(id, {
        internal: (a) =>
          a.map((x) =>
            x.userName === attendee.userName
              ? {
                  ...x,
                  confirmationStatus:
                    AppointmentConfirmationStatus.NoConfirmationNeeded,
                  confirmationDate: undefined,
                }
              : x
          ),
      });
    } else {
      this.updateAktAttendees(id, {
        internal: (a) =>
          a.concat({
            userName: attendee.userName,
            name: attendee.fullName,
            confirmationStatus:
              AppointmentConfirmationStatus.NoConfirmationNeeded,
          }),
      });
    }
  }

  public addAttendeeExternal(id: number, attendee: ContactListItem) {
    const act = ActivityDetailModel.find(id);
    if (!act?.attendeesExternal) return;

    if (act.attendeesExternal.find((x) => x.cid === attendee.id)) {
      this.updateAkt(id, {
        attendeesExternal: act.attendeesExternal.map((x) =>
          x.cid === attendee.id
            ? {
                ...x,
                confirmationStatus:
                  AppointmentConfirmationStatus.NoConfirmationNeeded,
                confirmationDate: undefined,
              }
            : x
        ),
      });
    } else {
      this.updateAkt(id, {
        attendeesExternal: act.attendeesExternal.concat({
          cid: attendee.id,
          name: `${attendee.firstname} ${attendee.lastname}`,
          email: attendee.email,
          confirmationStatus:
            AppointmentConfirmationStatus.NoConfirmationNeeded,
        }),
      });
    }

    this.sendAppointmentInvitationOrCancelationOnChange(act, attendee);
  }

  public removeAttendeeExternal(id: number, attendee: Attendee) {
    const act = ActivityDetailModel.find(id);
    if (!act?.attendeesExternal) return;

    this.updateAktAttendees(id, {
      external: (a) => a.filter((x) => x.cid !== attendee.cid),
    });

    if (attendee.cid) {
      this.sendAppointmentInvitationOrCancelationOnChange(act, attendee, true);
    }
  }

  public removeAttendeeInternal(id: number, attendee: Attendee) {
    const act = ActivityDetailModel.find(id);
    if (!act?.attendeesInternal) return;

    this.updateAktAttendees(id, {
      internal: (a) =>
        a.map((x) =>
          x.userName === attendee.userName
            ? {
                ...x,
                confirmationStatus: AppointmentConfirmationStatus.Declined,
                confirmationDate: new Date().toISOString(),
              }
            : x
        ),
    });
  }

  public addContact(id: number, item: ContactOrAddress) {
    const act = ActivityDetailModel.find(id);
    if (!act) return;

    let member: GetAktMemberDefault;
    if (item.type === 'CONTACT') {
      member = {
        cid: item.id,
        description: `${item.lastname} ${item.firstname}`,
      };
    } else {
      member = {
        anr: item.id,
        description: item.companyname,
      };
    }

    this.updateAkt(id, {
      contacts: [...(act.contacts ?? []), member],
    });
  }

  public addClient(id: number, item: ContactOrAddress) {
    const act = ActivityDetailModel.find(id);
    if (!act) return;

    let member: GetAktMemberDefault;
    if (item.type === 'CONTACT') {
      member = {
        cid: item.id,
        description: `${item.lastname} ${item.firstname}`,
      };
    } else {
      member = {
        anr: item.id,
        description: item.companyname,
      };
    }

    this.updateAkt(id, {
      clients: [...(act.clients ?? []), member],
    });
  }

  public removeContact(id: number, item: GetAktMemberDefault) {
    const act = ActivityDetailModel.find(id);
    if (!act?.contacts) return;

    this.updateAkt(id, {
      contacts: act.contacts.filter(
        (x) => !(x.cid === item.cid && x.anr === item.anr)
      ),
    });
  }

  public removeClient(id: number, item: GetAktMemberDefault) {
    const act = ActivityDetailModel.find(id);
    if (!act?.clients) return;

    this.updateAkt(id, {
      clients: act.clients.filter(
        (x) => !(x.cid === item.cid && x.anr === item.anr)
      ),
    });
  }

  public addObject(id: number, objects: GetAktResponseBaseObjects[]) {
    const act = ActivityDetailModel.find(id);

    const filteredObjects = objects.filter(
      (item) => !act?.objects?.find((x) => x.id === item.id)
    );

    this.updateAkt(id, {
      objects: [...(act?.objects ?? []), ...filteredObjects],
    });
  }

  public removeObject(id: number, objectId: number) {
    const act = ActivityDetailModel.find(id);
    if (!act?.objects) return;

    this.updateAkt(id, {
      objects: act.objects.filter((x) => x.id !== objectId),
    });
  }

  public async continueWorkflow(id: number) {
    const activity = ActivityDetailModel.find(id);

    if (activity?.workflow) {
      const workflow = activity.workflow;

      if (workflow.type === WorkFlowStepType.Decision) {
        ModalService.activityWorkflowDecision(workflow, async (result) => {
          await this.executeWorkflowContinuation({
            aktid: id,
            newcomment: result.comment,
            decision: result.decision,
          });
        });
      } else if (workflow.type === WorkFlowStepType.CC) {
        if (workflow.targetList) {
          await this.executeWorkflowContinuation({
            aktid: id,
            targetuserid: workflow.targetList,
          });
        } else {
          ModalService.activityWorkflowCC(workflow, async (result) => {
            await this.executeWorkflowContinuation({
              aktid: id,
              targetuserid: result.username,
            });
          });
        }
      } else if (workflow.type === WorkFlowStepType.Delegation) {
        ModalService.activityWorkflowDelegation(workflow, async (result) => {
          await this.executeWorkflowContinuation({
            aktid: id,
            targetuserid: result.username,
          });
        });
      }
    }
  }

  private async executeWorkflowContinuation(
    request: WorkflowDoNextStepRequest
  ) {
    await new WorkflowContinueCommand(request).execute();

    const activity = await new GetActivityQuery({
      id: request.aktid,
    }).execute();

    this.updateAkt(request.aktid, {
      ...activity,
    });

    if (
      activity.workflow &&
      activity.workflow.nextStepFiredBy === WorkflowStepFireType.Automatic &&
      activity.responsible === AuthService.getUser()?.uid
    ) {
      this.continueWorkflow(request.aktid);
    }
  }

  public async loadActivity(id: number): Promise<Activity> {
    const [activity, comment] = await Promise.all([
      new GetActivityQuery({ id }).execute(),
      new Client().api.getComment(id),
    ]);

    ActivityDetailModel.insert({
      data: {
        ...ActivityDetailModel.find(id),
        ...activity,
        type: 'DETAIL',
      },
    });
    await this.storeActivityComment(id, comment);

    return activity;
  }

  public async storeActivityComment(
    id: number,
    comment: string
  ): Promise<string> {
    await localforage.setItem(`act_comment_${id}`, comment);

    ActivityDetailModel.update({
      where: id,
      data: {},
    }); // trigger change detection

    return comment;
  }

  public async getActivityComment(id: number): Promise<string | null> {
    return await localforage.getItem<string>(`act_comment_${id}`);
  }

  public async activityTypeChanged(id: number, actType: ActivityType) {
    const activity = ActivityDetailModel.find(id);
    if (activity?.workflow) return;

    const workflow = await new Promise<ActivityTypeWorkflow | undefined>(
      (resolve) => {
        if (!actType.workflows?.length) return;

        if (actType.workflows.length === 1) {
          resolve(actType.workflows[0]);
        } else {
          ModalService.chooseProcess(actType.workflows, async (workflow) => {
            resolve(workflow);
          });
        }
      }
    );

    if (!workflow) return;

    await new ActivityStartProcessCommand({
      aktId: id,
      processId: workflow.id,
    }).execute();
  }

  public updateAktAttendees(
    id: number,
    update: {
      internal?: (items: Attendee[]) => Attendee[];
      external?: (items: Attendee[]) => Attendee[];
    }
  ) {
    const akt = ActivityDetailModel.find(id);
    if (!akt || akt.specializedType !== ActivityTechnology.Appointment) {
      throw new Error('Invalid Activity');
    }

    ActivityModel.update({
      where: id,
      data: {
        attendeesInternal: update.internal
          ? update.internal(akt.attendeesInternal ?? [])
          : akt.attendeesInternal,

        attendeesExternal: update.external
          ? update.external(akt.attendeesExternal ?? [])
          : akt.attendeesExternal,
      },
    });
  }

  public updateAkt(
    id: number,
    data: Partial<ActivityDetailModel>,
    deleteFromInbox = true
  ) {
    ActivityModel.update({
      where: id,
      data,
    });

    ActivityEvents.emit('activityRead', id);

    if (deleteFromInbox) {
      InboxFacade.deleteForActivity(id);
    }
  }
}

export default new ActivityService();
