
























































import Vue, { PropType } from 'vue';
import { GetAktMemberDefault } from '@consolidate/shared/data-access-legacy-api';
import { Attendee, ContactOrAddress, User } from '../../../domain';
import {
  isAttendee,
  isUser,
  isContact,
  isAddress,
  isAktMemberDefault,
} from '../../../utils/checkType';
import { Permission } from 'libs/shared/util-auth/src/lib/models/permission';
import { AuthService } from '@consolidate/shared/util-auth';

export default Vue.extend({
  props: {
    value: { type: Array as PropType<unknown[]>, default: () => [] },
    label: { type: String },
    subtitle: { type: String },
    display: { type: [String, Function] },
    displaySubtitle: { type: [String, Function] },
    displayCaption: { type: [String, Function] },
    displayCaptionError: { type: [Boolean, String, Function] },
    link: { type: Function },
    editable: { type: Boolean, default: true },
    canAdd: { type: Boolean, default: false },
    canRemove: { type: Function },
    count: { type: Number },
    showLabel: { type: Boolean, default: true },
  },
  methods: {
    hasPermission(item: Record<string, unknown>) {
      if (item.cid) {
        return AuthService.getUser()?.permissions.includes(
          Permission.ViewPersonList
        );
      } else {
        return AuthService.getUser()?.permissions.includes(
          Permission.ViewAddressList
        );
      }
    },
    getDisplay(item: Record<string, unknown>) {
      if (this.display) {
        if (typeof this.display === 'string') {
          return item[this.display];
        }
        return this.display(item);
      }

      return this.getDisplayDefault(item);
    },
    getDisplayDefault(
      item: GetAktMemberDefault | ContactOrAddress | User | Attendee | string
    ): string {
      if (typeof item === 'string') return item;

      if (isAttendee(item)) {
        return item.name;
      }

      if (isUser(item)) {
        return `${item.lastName} ${item.firstName}`;
      }

      if (isContact(item)) {
        return `${item.lastname} ${item.firstname}`;
      }

      if (isAddress(item)) {
        return item.companyname;
      }

      if (isAktMemberDefault(item)) {
        return item.description ?? '';
      }

      return '';
    },
    getDisplaySubtitle(item: Record<string, unknown>) {
      if (typeof this.displaySubtitle === 'string') {
        return item[this.displaySubtitle];
      }
      return this.displaySubtitle(item);
    },
    getDisplayCaption(item: Record<string, unknown>) {
      if (typeof this.displayCaption === 'string') {
        return item[this.displayCaption];
      }
      return this.displayCaption(item);
    },
    getDisplayCaptionError(item: Record<string, unknown>) {
      if (!this.displayCaptionError) return false;

      if (typeof this.displayCaptionError === 'boolean') {
        return this.displayCaptionError;
      }

      if (typeof this.displayCaptionError === 'string') {
        return item[this.displayCaptionError];
      }

      return this.displayCaptionError(item);
    },
    add() {
      this.$emit('add');
    },
    remove(index: number) {
      this.$emit('remove', index);
      this.$emit('input', [
        ...this.value.slice(0, index),
        ...this.value.slice(index + 1),
      ]);
    },
  },
});
