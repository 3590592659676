import {
  ApiResponse,
  GetSentMailsResponse,
} from '@consolidate/shared/data-access-legacy-api';
import Client from '../../logic/api/Client';
import { ActivityListItem } from '../entitites';
import { ActivityFactory, ActivityListItemFactory } from '../factories';
import { ActivityModel, SentItemModel } from '../infrastructure/+state/models';
import store from '../infrastructure/+state/store';
import { ListFacadeWithCaching } from './ListFacade';

class SentFacade extends ListFacadeWithCaching<
  SentItemModel,
  ActivityListItem,
  GetSentMailsResponse
> {
  protected entity = SentItemModel.entity;

  protected map(item: SentItemModel): ActivityListItem {
    return {
      uid: item.id,
      ...item.activity,
    };
  }

  protected makeApiRequest(
    hash: number,
    offset = 0
  ): Promise<ApiResponse<GetSentMailsResponse>> {
    return new Client().api.getSentMailsRaw({
      getSentMailsRequest: {
        offset: offset,
        limit: 30,
      },
      hash,
    });
  }

  protected mapResponse(
    response: GetSentMailsResponse
  ): Record<string, unknown>[] {
    if (response.items) {
      SentItemModel.commit((state) => {
        const count = response.items?.length ?? 0;
        state.hasMore = count > 0;
      });

      return response.items.map((x) => ({
        id: x.id,
        activity: {
          ...ActivityModel.find(x.aktid),
          ...ActivityListItemFactory.create({
            id: x.aktid,
            subject: x.subject,
            dueDateTimeUtc: `${x.date}.000Z`,
            hasAttachments: x.hasattachment,
            priorityId: x.priority,
            actType: {
              ...ActivityModel.find(x.aktid)?.actType,
              id: x.akttype,
              name: x.aktname,
            },
            contact: x.sender,
            icon: x.icon,
            summary: x.summary,
            responsible: x.resp,
            todoType: ActivityFactory.getTodoTypeFromConsolidateId(x.todotype),
          }),
        },
      }));
    }

    return [];
  }

  protected filterProperties: (keyof ActivityListItem)[] = [
    'subject',
    'contact',
    'summary',
  ];

  public get filteredItems() {
    return super.filteredItems.sortDesc('dueDateTimeUtc');
  }

  public get hasMore(): boolean {
    return store.state.entities[SentItemModel.entity].hasMore;
  }

  public async loadMore() {
    SentItemModel.commit((state) => {
      state.loading = true;
    });

    try {
      const resp = await this.makeApiRequest(
        this.hash,
        SentItemModel.all().length
      );
      const items = this.mapResponse(await resp.value());
      if (items) {
        SentItemModel.insertOrUpdate({
          data: items,
        });
      }
    } catch {
      // when offline, dont load more items
    }

    SentItemModel.commit((state) => {
      state.loading = false;
    });
  }
}

export default new SentFacade();
