import Client from '../../api/Client';
import ConsolidateBaseCommand from '../baseCommands/ConsolidateBaseCommand';

interface Payload {
  actId: number;
  internalParticipants: string[];
  externalParticipants: string[];
}

class SetAppointmentParticipantsCommand extends ConsolidateBaseCommand<Payload> {
  constructor(payload: Payload) {
    super('SetAppointmentParticipantsCommand', payload);
  }

  async execute() {
    try {
      const {
        actId,
        internalParticipants,
        externalParticipants,
      } = this.payload;

      const dto = {
        aktid: actId,
        externalParticipants: this.toConsolidateCSV(externalParticipants),
        internalParticipants: this.toConsolidateCSV(internalParticipants),
      };
      const data = await new Client().api.changeRecipient(dto);
      const success = data?._return === 'TRUE';

      return this.result(success);
    } catch (error) {
      if (this.isRetryable(error)) {
        return this.save();
      }
      return this.notOk();
    }
  }
}

export default SetAppointmentParticipantsCommand;
