import { AdditionalData } from '../../..';
import {
  AddressDto,
  ContactDto,
  ObjectTypeDto,
  WorkflowDto,
} from '../../../../logic/api/gen';
import { ListModel } from './listModel';

export class ObjectModel extends ListModel {
  static entity = 'objects';

  id!: number;
  type!: ObjectTypeDto;
  active?: boolean | null;
  description?: string | null | undefined;
  manager?: ContactDto | null | undefined;
  location?: AddressDto | null | undefined;
  responsible?: string | null | undefined;
  comment?: string | null | undefined;
  workflow?: WorkflowDto | null | undefined;
  base64Image?: string | null | undefined;
  additionalData!: AdditionalData;

  static fields() {
    return {
      id: this.number(0),
      type: this.attr({}),
      active: this.boolean(null).nullable(),
      description: this.string(null).nullable(),
      manager: this.attr(null).nullable(),
      location: this.attr(null).nullable(),
      responsible: this.string(null).nullable(),
      comment: this.string(null).nullable(),
      workflow: this.attr(null).nullable(),
      base64Image: this.string(null).nullable(),
      additionalData: this.attr([]),
    };
  }

  static state() {
    return {
      ...super.state(),
      hasMore: false,
      total: 0,
      loadingDetail: false,
      detailId: 0,
      loadDetailError: false,
    };
  }
}
