<template>
  <form class="modal-prompt" @submit.prevent="handleYesCallback">
    <cs-datetime-picker v-model="date" :label="$t('DATE')" type="date" />

    <BaseModalControls class="margin-top" @no="$emit('close')">
      <template v-if="labels && labels.yes" #yes>
        <span v-text="labels.yes" />
      </template>

      <template v-if="labels && labels.no" #no>
        <span v-text="labels.no" />
      </template>
    </BaseModalControls>
  </form>
</template>

<script>
import BaseModalControls from '../partials/BaseModalControls.vue';

export default {
  name: 'ModalJumpToDate',
  components: {
    BaseModalControls,
  },
  props: {
    callback: {
      type: Object,
      default() {
        return {
          onYes: null,
          onNo: null,
        };
      },
    },
    labels: {
      type: Object,
      default() {
        return {
          yes: '',
          no: '',
        };
      },
    },
  },
  data() {
    return {
      date: new Date().toISOString(),
    };
  },
  methods: {
    async handleYesCallback() {
      const { onYes } = this.callback;
      if (onYes) {
        await onYes({ date: this.date.substring(0, 10) });
      }

      this.$emit('close');
    },
  },
};
</script>
