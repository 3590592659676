/* tslint:disable */
/* eslint-disable */
/**
 * Consolidate App API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ActivityRecurrenceType,
    ActivityRecurrenceTypeFromJSON,
    ActivityRecurrenceTypeFromJSONTyped,
    ActivityRecurrenceTypeToJSON,
    DayOfWeek,
    DayOfWeekFromJSON,
    DayOfWeekFromJSONTyped,
    DayOfWeekToJSON,
    WeekOfMonth,
    WeekOfMonthFromJSON,
    WeekOfMonthFromJSONTyped,
    WeekOfMonthToJSON,
} from './';

/**
 * 
 * @export
 * @interface AppRecurrencePatternDto
 */
export interface AppRecurrencePatternDto {
    /**
     * 
     * @type {ActivityRecurrenceType}
     * @memberof AppRecurrencePatternDto
     */
    type: ActivityRecurrenceType;
    /**
     * 
     * @type {number}
     * @memberof AppRecurrencePatternDto
     */
    day?: number | null;
    /**
     * 
     * @type {number}
     * @memberof AppRecurrencePatternDto
     */
    month?: number | null;
    /**
     * 
     * @type {number}
     * @memberof AppRecurrencePatternDto
     */
    frequency?: number | null;
    /**
     * 
     * @type {number}
     * @memberof AppRecurrencePatternDto
     */
    dayOfWeek?: number | null;
    /**
     * 
     * @type {WeekOfMonth}
     * @memberof AppRecurrencePatternDto
     */
    weekOfMonth?: WeekOfMonth | null;
    /**
     * 
     * @type {Array<DayOfWeek>}
     * @memberof AppRecurrencePatternDto
     */
    days?: Array<DayOfWeek> | null;
    /**
     * 
     * @type {number}
     * @memberof AppRecurrencePatternDto
     */
    duration?: number | null;
}

export function AppRecurrencePatternDtoFromJSON(json: any): AppRecurrencePatternDto {
    return AppRecurrencePatternDtoFromJSONTyped(json, false);
}

export function AppRecurrencePatternDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): AppRecurrencePatternDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'type': ActivityRecurrenceTypeFromJSON(json['type']),
        'day': !exists(json, 'day') ? undefined : json['day'],
        'month': !exists(json, 'month') ? undefined : json['month'],
        'frequency': !exists(json, 'frequency') ? undefined : json['frequency'],
        'dayOfWeek': !exists(json, 'dayOfWeek') ? undefined : json['dayOfWeek'],
        'weekOfMonth': !exists(json, 'weekOfMonth') ? undefined : WeekOfMonthFromJSON(json['weekOfMonth']),
        'days': !exists(json, 'days') ? undefined : (json['days'] === null ? null : (json['days'] as Array<any>).map(DayOfWeekFromJSON)),
        'duration': !exists(json, 'duration') ? undefined : json['duration'],
    };
}

export function AppRecurrencePatternDtoToJSON(value?: AppRecurrencePatternDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'type': ActivityRecurrenceTypeToJSON(value.type),
        'day': value.day,
        'month': value.month,
        'frequency': value.frequency,
        'dayOfWeek': value.dayOfWeek,
        'weekOfMonth': WeekOfMonthToJSON(value.weekOfMonth),
        'days': value.days === undefined ? undefined : (value.days === null ? null : (value.days as Array<any>).map(DayOfWeekToJSON)),
        'duration': value.duration,
    };
}


