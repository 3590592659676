import { Model } from '@vuex-orm/core';
import { EditAdditionalDataCommand } from '../../logic/commands';
import ModalService from '../../logic/services/ModalService';
import { minApiVersion } from '../../utils/versioning';
import { AdditionalData } from '../entitites';

class AdditionalDataModel extends Model {
  additionalData?: AdditionalData;
}

class AdditionalDataFacade {
  @minApiVersion('5.2053')
  public async editAdditionalData<T extends typeof AdditionalDataModel>(
    id: number | string,
    formKey: string,
    model: T
  ) {
    const entity = model.find(id);

    const form = entity?.additionalData?.find((x) => x.key === formKey);
    if (!form) return;

    ModalService.editAdditionalData({
      fields: form.fields,
      callback: async (fields) => {
        await new EditAdditionalDataCommand({
          id,
          form: formKey,
          fields,
        }).execute();

        model.update({
          where: id,
          data: {
            additionalData: entity?.additionalData?.map((x) =>
              x.key === formKey
                ? {
                    ...x,
                    fields: x.fields.map(
                      (f) => fields.find((n) => n.key === f.key) ?? f
                    ),
                  }
                : x
            ),
          },
        });
      },
    });
  }
}

export default new AdditionalDataFacade();
