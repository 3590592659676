/* tslint:disable */
/* eslint-disable */
/**
 * Consolidate App API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface LoginOAuthRequest
 */
export interface LoginOAuthRequest {
    /**
     * 
     * @type {string}
     * @memberof LoginOAuthRequest
     */
    authorizationCode: string;
    /**
     * 
     * @type {string}
     * @memberof LoginOAuthRequest
     */
    redirectUrl: string;
}

export function LoginOAuthRequestFromJSON(json: any): LoginOAuthRequest {
    return LoginOAuthRequestFromJSONTyped(json, false);
}

export function LoginOAuthRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): LoginOAuthRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'authorizationCode': json['authorizationCode'],
        'redirectUrl': json['redirectUrl'],
    };
}

export function LoginOAuthRequestToJSON(value?: LoginOAuthRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'authorizationCode': value.authorizationCode,
        'redirectUrl': value.redirectUrl,
    };
}


