export const kanbanRoutes = [
  {
    path: '/kanban',
    name: 'kanban',
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    component: () =>
      import(/* webpackChunkName: "kanban" */ './pages/BoardList.vue'),
  },
  {
    path: '/kanban/:id',
    name: 'kanban-board',
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    component: () =>
      import(/* webpackChunkName: "kanban" */ './pages/Board.vue'),
  },
];
