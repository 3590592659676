





































































import { translate } from '@consolidate/shared/util-translations';
import ActivitySearchFacade from 'apps/web/src/domain/application/ActivitySearchFacade';
import ActivityTypesService from 'apps/web/src/domain/application/ActivityTypesService';
import {
  AdvancedSearchOptions,
  CreationPeriod,
} from 'apps/web/src/logic/services/ModalService';
import moment from 'moment-timezone';
import Vue, { PropType } from 'vue';
import ContactAutoComplete from '../../../components/input/ContactAutoComplete.vue';
import {
  ActivityType,
  ContactOrAddress,
  ProjectInfo,
  User,
} from '../../../domain';
import EmployeeService from '../../../domain/application/EmployeeService';
import ProjectAutoComplete from '../../project/ProjectAutoComplete.vue';

interface CheckboxOption {
  text: string;
  value: boolean;
}

export default Vue.extend({
  name: 'ModalAdvancedSearchForm',
  components: {
    ContactAutoComplete,
    ProjectAutoComplete,
  },
  props: {
    headerKey: { type: String },
    callback: {
      type: Object,
      default() {
        return {
          onYes: null,
        };
      },
    },
    initialOptions: {} as PropType<AdvancedSearchOptions | undefined>,
  },
  data: () => ({
    responsible: undefined as User[] | undefined,
    fromDate: {
      text: translate('CREATION_PERIOD_YEAR'),
      value: moment().add(-1, 'years').toDate(),
    } as CreationPeriod,
    activityType: undefined as ActivityType[] | undefined,
    contacts: undefined as User[] | ContactOrAddress[] | undefined,
    projects: undefined as ProjectInfo[] | undefined,
    showAll: true,
  }),
  created() {
    if (this.initialOptions) {
      this.responsible = this.initialOptions.responsibles;
      this.activityType = this.initialOptions.activityType;
      this.contacts = this.initialOptions.contacts;
      this.projects = this.initialOptions.project;
      this.showAll = this.initialOptions.showAll;
      this.fromDate = this.initialOptions.fromDate;
    }
  },
  computed: {
    searchTerm: {
      get() {
        return ActivitySearchFacade.value
          ? ActivitySearchFacade.value.toLocaleString()
          : '';
      },
      set(newTerm: string) {
        ActivitySearchFacade.value = newTerm;
      },
    },
    statusCheckboxOptions(): CheckboxOption[] {
      return [
        { value: false, text: this.$t('ADVANCEDSEARCH_ONLY_UNDONE') },
        { value: true, text: this.$t('ADVANCEDSEARCH_ALL') },
      ];
    },
    creationPeriods(): CreationPeriod[] {
      return [
        { text: this.$t('TODAY'), value: moment().toDate() },
        {
          text: this.$t('CREATION_PERIOD_WEEK'),
          value: moment().add(-7, 'days').toDate(),
        },
        {
          text: this.$t('CREATION_PERIOD_MONTH'),
          value: moment().add(-1, 'months').toDate(),
        },
        {
          text: this.$t('CREATION_PERIOD_QUARTER'),
          value: moment().add(-1, 'quarters').toDate(),
        },
        {
          text: this.$t('CREATION_PERIOD_YEAR'),
          value: moment().add(-1, 'years').toDate(),
        },
        {
          text: this.$t('CREATION_PERIOD_THREE_YEARS'),
          value: moment().add(-3, 'years').toDate(),
        },
        {
          text: this.$t('CREATION_PERIOD_FIVE_YEARS'),
          value: moment().add(-5, 'years').toDate(),
        },
        {
          text: this.$t('CREATION_PERIOD_ALL'),
          value: null,
        },
      ];
    },
    allUsers(): User[] {
      return EmployeeService.items;
    },
    allActTypes(): ActivityType[] {
      return ActivityTypesService.allTypes.sortAsc('name');
    },
  },
  methods: {
    async handleYesCallback() {
      const { onYes } = this.callback;

      if (onYes) {
        onYes({
          responsibles: this.responsible,
          fromDate: this.fromDate,
          contacts: this.contacts,
          activityType: this.activityType,
          project: this.projects,
          showAll: this.showAll,
        } as AdvancedSearchOptions);

        this.$emit('close');
      }
    },
  },
});
