import { Capacitor } from '@capacitor/core';
import { Filesystem } from '@capacitor/filesystem';
import * as Sentry from '@sentry/capacitor';
import { ActivityTechnology } from '../../domain';
import ModalService from './ModalService';

declare const cordova: any;

class ShareService {
  public init(): void {
    if (!Capacitor.isNativePlatform() || !cordova.openwith) return;

    cordova.openwith.init(this.initSuccess, this.initError);
    cordova.openwith.addHandler(this.handleShare);
  }

  private initSuccess() {
    // do nothing
  }

  private initError(err: any) {
    console.error('init of ShareService failed: ' + err);
    Sentry.captureException(err);
  }

  private async handleShare(intent: any) {
    const fileUrls = [];
    let text;

    for (let i = 0; i < intent.items.length; ++i) {
      const item = intent.items[i];

      if (Capacitor.getPlatform() === 'ios') {
        if (item.uti === 'public.text' || item.uti === 'public.url') {
          text = item.data;
        }

        if (item.fileUrl) {
          fileUrls.push({
            name: item.name,
            url: item.fileUrl,
          });
        }
      } else {
        if (item.type === 'text/plain' || item.type === 'url') {
          text = item.data;
        }

        if (item.fileUrl) {
          fileUrls.push({
            name: item.name,
            url: item.fileUrl,
          });
        }
      }
    }

    const files = [];
    for (const file of fileUrls) {
      const { data } = await Filesystem.readFile({
        path: file.url,
      });

      files.push({
        name: file.name,
        size: 3 * ((data as string).length / 4),
        base64: data as string,
      });
    }

    ModalService.createActivity({
      creationContext: {
        text: text,
        files: files,
      },
    });
  }

  public async handleMailto(mail: string) {
    const [to, parameters] = mail.split('?', 2);
    const params = new URLSearchParams(parameters);

    ModalService.createActivity({
      fixedTechnology: ActivityTechnology.Email,
      creationContext: {
        contact: to,
        subject: params.get('subject') ?? undefined,
        text: params.get('body') ?? undefined,
      },
    });
  }
}

export default new ShareService();
