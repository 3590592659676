/* tslint:disable */
/* eslint-disable */
/**
 * Consolidate Kanban API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ColumnDto,
    ColumnDtoFromJSON,
    ColumnDtoFromJSONTyped,
    ColumnDtoToJSON,
} from './';

/**
 * 
 * @export
 * @interface BoardDto
 */
export interface BoardDto {
    /**
     * 
     * @type {number}
     * @memberof BoardDto
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof BoardDto
     */
    title: string;
    /**
     * 
     * @type {Array<ColumnDto>}
     * @memberof BoardDto
     */
    columns: Array<ColumnDto>;
}

export function BoardDtoFromJSON(json: any): BoardDto {
    return BoardDtoFromJSONTyped(json, false);
}

export function BoardDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): BoardDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'title': json['title'],
        'columns': ((json['columns'] as Array<any>).map(ColumnDtoFromJSON)),
    };
}

export function BoardDtoToJSON(value?: BoardDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'title': value.title,
        'columns': ((value.columns as Array<any>).map(ColumnDtoToJSON)),
    };
}


