<template>
  <div class="cs-page">
    <cs-toolbar>
      <v-toolbar-title>Development test page</v-toolbar-title>
    </cs-toolbar>

    <div class="cs-content">
      <v-container>
        <ButtonBar>
          <cs-button
            color="primary"
            @click.native="decisionMade(true)"
            v-text="$t('YES')"
          />
          <cs-button
            color="primary"
            @click.native="decisionMade(false)"
            v-text="$t('NO')"
          />
          <cs-button
            color="primary"
            @click.native="cancelWorkflow"
            v-text="$t('CANCEL_WORKFLOW')"
          />
          <cs-button
            color="primary"
            @click.native="$emit('input', false)"
            v-text="$t('CANCEL')"
          />
        </ButtonBar>
        <HoursToWork />

        <h3>Normal</h3>
        <v-divider />
        <cs-button> Normal </cs-button>
        <cs-button outlined> outlined </cs-button>

        <h3>Disabled</h3>
        <v-divider />
        <cs-button disabled> disabled </cs-button>
        <cs-button outlined disabled> outlined disabled </cs-button>

        <h3>Primary</h3>
        <v-divider />
        <cs-button color="primary"> primary </cs-button>
        <cs-button color="primary" outlined> primary outlined </cs-button>

        <h3>Success</h3>
        <v-divider />
        <cs-button color="success"> Success </cs-button>
        <cs-button outlined color="success"> outlined Success </cs-button>

        <h3>Danger</h3>
        <v-divider />
        <cs-button color="error"> Error </cs-button>
        <cs-button outlined color="error"> outlined error </cs-button>

        <h3>Loading</h3>
        <v-divider />
        <cs-button color="primary" loading> primary </cs-button>
        <cs-button outlined loading> outlined </cs-button>
      </v-container>
    </div>
  </div>
</template>

<script>
import HoursToWork from '../../components/freakyfriday/HoursToWork.vue';
import ButtonBar from '../../components/button/ButtonBar.vue';

export default {
  components: {
    HoursToWork,
    ButtonBar,
  },
  computed: {},
};
</script>

<style scoped>
.freaky-friday-page {
  overflow: scroll;
  height: 100vh;
}
h3 {
  margin-top: 0.5rem;
  margin-bottom: 0.375rem;
}

hr {
  margin-bottom: 0.5rem;
}
.icons {
  display: flex;
  flex-wrap: wrap;
}
.icon {
  display: flex;
  align-items: center;
  margin-right: 2rem;
}
</style>
