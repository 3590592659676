/* tslint:disable */
/* eslint-disable */
/**
 * Consolidate App API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AppAdditionalDataDto,
    AppAdditionalDataDtoFromJSON,
    AppAdditionalDataDtoFromJSONTyped,
    AppAdditionalDataDtoToJSON,
} from './';

/**
 * 
 * @export
 * @interface AppAdditionalDataForm
 */
export interface AppAdditionalDataForm {
    /**
     * 
     * @type {string}
     * @memberof AppAdditionalDataForm
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof AppAdditionalDataForm
     */
    title: string;
    /**
     * 
     * @type {{ [key: string]: AppAdditionalDataDto; }}
     * @memberof AppAdditionalDataForm
     */
    fields: { [key: string]: AppAdditionalDataDto; };
}

export function AppAdditionalDataFormFromJSON(json: any): AppAdditionalDataForm {
    return AppAdditionalDataFormFromJSONTyped(json, false);
}

export function AppAdditionalDataFormFromJSONTyped(json: any, ignoreDiscriminator: boolean): AppAdditionalDataForm {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        'title': json['title'],
        'fields': (mapValues(json['fields'], AppAdditionalDataDtoFromJSON)),
    };
}

export function AppAdditionalDataFormToJSON(value?: AppAdditionalDataForm | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'title': value.title,
        'fields': (mapValues(value.fields, AppAdditionalDataDtoToJSON)),
    };
}


