import { NotificationService } from '@consolidate/shared/ui-components';
import { translate } from '@consolidate/shared/util-translations';
import { ACTIVITY_TODO_TYPE_LIST } from '../../../domain';
import OverviewFacade from '../../../domain/application/OverviewFacade';
import TasksFacade from '../../../domain/application/TasksFacade';
import Client from '../../api/Client';
import ConsolidateBaseCommand from '../baseCommands/ConsolidateBaseCommand';

interface Payload {
  actId: number;
  todoType: number;
}

class SetActivityTodoTypeCommand extends ConsolidateBaseCommand<Payload> {
  constructor(payload: Payload) {
    super('SetActivityTodoTypeCommand', payload);
  }

  async execute() {
    try {
      const { actId, todoType } = this.payload;

      const fullTodoType = ACTIVITY_TODO_TYPE_LIST.find(
        (i) => i.id === todoType
      );
      if (!fullTodoType) {
        throw new Error('Invalid todo type');
      }

      const dto = {
        aktid: actId,
        statusid: todoType,
      };
      const data = await new Client().api.setStatus(dto);
      const success = data?._return === 'TRUE';

      return this.result(success);
    } catch (error) {
      if (this.isRetryable(error)) {
        return this.save();
      }
      return this.notOk();
    }
  }

  protected onSuccess() {
    OverviewFacade.refreshCounts();
    TasksFacade.getCounts();

    const { todoType } = this.payload;

    const fullTodoType = ACTIVITY_TODO_TYPE_LIST.find((i) => i.id === todoType);
    if (!fullTodoType) {
      throw new Error('Invalid todo type');
    }

    NotificationService.showInfo({
      message: translate('ACTIVITY_STATUS_SET', {
        status: fullTodoType.name,
      }),
    });
  }
}

export default SetActivityTodoTypeCommand;
