import { Model } from '@vuex-orm/core';
import { Country } from '../../../entitites';

export class CountryModel extends Model implements Country {
  static entity = 'countries';

  id!: string;
  name!: string;

  static fields() {
    return {
      id: this.string(''),
      name: this.string(''),
    };
  }
}
