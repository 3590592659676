// Models
import {
  GetAktMemberDefault,
  GetAktResponseBaseObjects,
} from '@consolidate/shared/data-access-legacy-api';
import {
  ActivityTodoType,
  ActivityType,
  AdditionalData,
  Attachment,
  Confidentiality,
  WorkflowStep,
} from '../../../domain';
import { ActivityAppointment } from './ActivityAppointment';
import { ActivityDocument } from './ActivityDocument';
import { ActivityEmail } from './ActivityEmail';
// Helpers

export interface ProjectInfo {
  id: number;
  name: string;
}

export interface RightsObject {
  edit: boolean;
  delegate: boolean;
  delete: boolean;
}

export interface DelegationInfo {
  by: string;
  dateTime: string;
  comment: string;
}

export interface ActivityBase {
  id: number;
  subject: string;
  responsible: string;
  actType: ActivityType;
  latestInternalCCs: string[];
  icon: string;
  iconColor: string;
  previousActivity?: number;
  hasFollowingActivities: number;
  todoType: ActivityTodoType;
  specializedType: ActivityTechnology;
  attachments: Attachment[];
  alarmDateTimeUtc?: string;
  project?: ProjectInfo;
  priorityId: number;
  confidentiality: Confidentiality;
  rights: RightsObject;
  delegated: false | DelegationInfo;
  workflow: WorkflowStep | null;
  createdBy: string;
  creationDate: string;
  additionalData?: AdditionalData;
  clients?: GetAktMemberDefault[];
  addressList?: GetAktMemberDefault[];
  allowobjects?: boolean;
  objects?: GetAktResponseBaseObjects[];
}

export enum ActivityTechnology {
  Email = 'EMAIL',
  Appointment = 'APPOINTMENT',
  Document = 'DOCUMENT',
}

export type Activity = ActivityEmail | ActivityAppointment | ActivityDocument;
