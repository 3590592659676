import { Model, Query } from '@vuex-orm/core';
import Client from '../../logic/api/Client';
import { ActivityListItem, ACTIVITY_TODO_TYPE_DONE } from '../entitites';
import { ActivityListItemFactory } from '../factories';
import {
  ActivityModel,
  TeamModel,
  TeamTaskModel,
} from '../infrastructure/+state/models';
import store from '../infrastructure/+state/store';
import { ListFacade } from './ListFacade';

class TeamTasksFacade extends ListFacade<TeamTaskModel, ActivityListItem> {
  protected entity = TeamTaskModel.entity;

  protected filterProperties: (keyof ActivityListItem)[] = [
    'subject',
    'contact',
    'summary',
  ];

  protected map(item: TeamTaskModel): ActivityListItem {
    return {
      uid: item.id,
      ...item.activity,
    };
  }

  protected baseFilter(query: Query<Model>): Query<Model> {
    return query
      .whereHas('activity', (query) => {
        query
          .where(
            (x: ActivityModel) => x.todoType?.id !== ACTIVITY_TODO_TYPE_DONE.id
          )
          .where((x: ActivityModel) => x.responsible === this.teamId);
      })
      .orderBy(
        (x) => ActivityModel.find((x as TeamTaskModel).id)?.dueDateTimeUtc,
        'desc'
      );
  }

  protected async getItems(): Promise<Record<string, unknown>[] | undefined> {
    if (!this.teamId) return [];

    const response = await new Client().api.getAktsFromId({
      type: 5,
      id: this.teamId,
      showFuture: this.showFuture,
      limit: Number.MAX_SAFE_INTEGER,
    });

    return response.items.map((x) => ({
      id: x.id,
      activity: {
        ...ActivityModel.find(x.id),
        ...ActivityListItemFactory.getFromIdSearch(x),
      },
    }));
  }

  public get showFuture(): boolean {
    return store.state.entities[TeamTaskModel.entity].showFuture;
  }

  public get teamId(): string | undefined {
    return store.state.entities[TeamTaskModel.entity].team;
  }

  public get teamName(): string | undefined {
    if (!this.teamId) return undefined;

    return TeamModel.find(this.teamId)
      ?.name.replace(/\(\d*\)$/g, '')
      .trim();
  }

  public setTeam(val: string) {
    TeamTaskModel.commit((state) => {
      state.team = val;
      state.showFuture = false;
    });
    this.load();
  }

  public setShowFutureState(val: boolean) {
    TeamTaskModel.commit((state) => {
      state.showFuture = val;
    });
    this.load();
  }
}

export default new TeamTasksFacade();
