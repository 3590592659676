<template>
  <v-btn
    fab
    large
    dark
    color="primary"
    v-bind="$attrs"
    :style="center ? { left: '50%', transform: 'translateX(-50%)' } : null"
    @click="showCreateNewModal"
  >
    <cs-icon>Plus</cs-icon>
  </v-btn>
</template>

<script>
import ModalService from '../../logic/services/ModalService';

export default {
  name: 'CreateButton',
  props: {
    center: { type: Boolean, default: false },
  },
  methods: {
    showCreateNewModal() {
      ModalService.createActivity();
    },
  },
};
</script>

<style lang="scss" scoped>
.center {
  transform: translateX(calc(-50% + 32px));
}
</style>
