import { Model } from '@vuex-orm/core';
import ConsolidateBaseCommand from '../../../../logic/commands/baseCommands/ConsolidateBaseCommand';

export class QueueModel extends Model {
  static entity = 'queue';

  id!: string;
  command!: string;
  payload!: ConsolidateBaseCommand<any, any>;

  static fields() {
    return {
      id: this.uid(),
      command: this.string(''),
      payload: this.attr(null).nullable(),
    };
  }
}
