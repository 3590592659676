import { EventEmitter } from 'events';
import Vue from 'vue';

declare interface ConnectivityService {
  on(event: 'onlineStateChanged', listener: (online: boolean) => void): this;

  emit(event: 'onlineStateChanged', online: boolean): boolean;
}

class ConnectivityService extends EventEmitter {
  private state = Vue.observable({
    online: window.navigator.onLine,
  });

  public get online() {
    return this.state.online;
  }

  constructor() {
    window.addEventListener('offline', () => this.setOnline(false));
    super();
  }

  public setOnline(online: boolean) {
    this.state.online = online;
    this.emit('onlineStateChanged', online);
  }
}

export default new ConnectivityService();
