





































import Vue from 'vue';
import BaseModalControls from '../partials/BaseModalControls.vue';

export default Vue.extend({
  name: 'ModalActivityStartEndTime',
  components: {
    BaseModalControls,
  },
  props: {
    initialStartTime: String,
    initialEndTime: String,
    initialIsAllDay: Boolean,
    callback: {
      type: Object,
      default: () => ({
        onYes: { type: Function, default: null },
      }),
    },
  },
  data: () => ({
    isFormValid: false,
    selectedStartTime: '',
    selectedEndTime: '',
    isAllDay: false,
    error: '',
    isSubmitting: false,
  }),
  created() {
    this.selectedStartTime = this.initialStartTime;
    this.selectedEndTime = this.initialEndTime;
    this.isAllDay = this.initialIsAllDay;
  },
  methods: {
    async validate() {
      (this.$refs.submitFormRef as any).validate();
      await this.$nextTick();
    },
    validateEndDate(startDate: string, endDate: string, allDay: boolean) {
      const start = new Date(startDate);
      const end = new Date(endDate);
      return (
        (allDay ? end >= start : end > start) || this.$t('END_BEFORE_START')
      );
    },
    async handleYesCallback() {
      await this.validate();
      if (!this.isFormValid) return;
      // if nothing changed, show error message to the user
      if (
        this.selectedStartTime === this.initialStartTime &&
        this.selectedEndTime === this.initialEndTime &&
        this.isAllDay === this.initialIsAllDay
      ) {
        this.error = `${this.$t('NO_CHANGES_WERE_MADE')}.`;
        return;
      }

      const { onYes } = this.callback;
      if (onYes) {
        this.isSubmitting = true;

        let startDate = new Date(this.selectedStartTime);
        let endDate = new Date(this.selectedEndTime);

        if (this.isAllDay) {
          startDate.setHours(0, 0, 0, 0);
          endDate.setHours(0, 0, 0, 0);
        }

        await onYes({
          startTime: startDate.toISOString(),
          endTime: endDate.toISOString(),
          isAllDay: this.isAllDay,
        });

        this.isSubmitting = false;
      }
      this.$emit('close');
    },
    resetError() {
      this.error = '';
    },
  },
});
