

























import Vue, { PropType } from 'vue';
import ActivityList from '../../components/activity/list/ActivityList.vue';
import FoldersFacade from '../../domain/application/FoldersFacade';
import ProjectsFacade from '../../domain/application/ProjectsFacade';
import ActivitySearchFacade, {
  SearchType,
} from '../../domain/application/ActivitySearchFacade';
import ContactsFacade from '../../domain/application/ContactsFacade';
import ObjectsFacade from '../../domain/application/ObjectsFacade';
import { translate } from '@consolidate/shared/util-translations';

export default Vue.extend({
  name: 'ActivitiesMaster',
  metaInfo() {
    const path = this.$route.path;
    const regex = /folders\/.*\//g;
    const title: string = this.title ?? '';

    if (regex.test(path)) {
      return {
        titleTemplate: `${title}  %s`,
      };
    } else if (path.includes('/activities/')) {
      return {
        titleTemplate: `${title}  %s`,
      };
    } else if (path.includes('/activities')) {
      return {
        title: translate('ACTIVITIES'),
      };
    } else {
      return {
        title: `| ${title}`,
      };
    }
  },
  components: {
    ActivityList,
  },
  props: {
    type: { type: String as PropType<SearchType> },
  },
  computed: {
    activityItems() {
      return ActivitySearchFacade.filteredItems;
    },
    title(): string | undefined {
      switch (this.type) {
        case SearchType.Folder:
          return FoldersFacade.items.find((x) => x.id === +this.typeId)?.name;

        case SearchType.Project:
          return (
            ProjectsFacade.items.find((x) => x.id === +this.typeId)?.name +
            ` (${ActivitySearchFacade.total})`
          );

        case SearchType.Object:
          return (
            ObjectsFacade.items.find((x) => x.id === +this.typeId)
              ?.description + ` (${ActivitySearchFacade.total})`
          );

        case SearchType.Address:
          const address = ContactsFacade.find(this.typeId);

          if (
            address &&
            address.isDivider === false &&
            address.type === 'ADDRESS'
          ) {
            return address.companyname;
          }
          return this.$t('ACTIVITIES');

        case SearchType.Contact:
          const contact = ContactsFacade.find(this.typeId);

          if (
            contact &&
            contact.isDivider === false &&
            contact.type === 'CONTACT'
          ) {
            let fullName;

            if (contact.firstname && contact.lastname)
              fullName = contact.firstname + ' ' + contact.lastname;
            if (!contact.lastname) fullName = contact.firstname;
            if (!contact.firstname) fullName = contact.lastname;

            return `${fullName} ${this.$t('ACTIVITIES')} (${
              ActivitySearchFacade.total
            }) `;
          }
          return this.$t('ACTIVITIES');

        case SearchType.Followers:
          return this.$t('FOLLOWING_ACTIVITIES');

        default:
          return this.$t('ACTIVITIES');
      }
    },
    isLoading(): boolean {
      return ActivitySearchFacade.loading;
    },
    loadError(): boolean {
      return ActivitySearchFacade.loadError;
    },
    typeId(): string {
      return this.$route.params.typeId;
    },
  },
  watch: {
    typeId: {
      handler() {
        this.load();
      },
      immediate: true,
    },
  },
  methods: {
    load() {
      if (!this.typeId) return;

      if (
        ActivitySearchFacade.type !== this.type ||
        ActivitySearchFacade.value !== this.typeId
      ) {
        ActivitySearchFacade.loadFor(this.type as SearchType, this.typeId);
      }
    },
    loadMore() {
      ActivitySearchFacade.loadMore();
    },
    reload() {
      ActivitySearchFacade.loadFor(this.type as SearchType, this.typeId);
    },
  },
});
