/* tslint:disable */
/* eslint-disable */
/**
 * Consolidate App API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AppAdditionalDataDto
 */
export interface AppAdditionalDataDto {
    /**
     * 
     * @type {string}
     * @memberof AppAdditionalDataDto
     */
    caption: string;
    /**
     * 
     * @type {number}
     * @memberof AppAdditionalDataDto
     */
    pos: number;
    /**
     * 
     * @type {any}
     * @memberof AppAdditionalDataDto
     */
    value?: any | null;
    /**
     * 
     * @type {string}
     * @memberof AppAdditionalDataDto
     */
    description?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof AppAdditionalDataDto
     */
    editable: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AppAdditionalDataDto
     */
    required: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof AppAdditionalDataDto
     */
    options?: Array<string> | null;
}

export function AppAdditionalDataDtoFromJSON(json: any): AppAdditionalDataDto {
    return AppAdditionalDataDtoFromJSONTyped(json, false);
}

export function AppAdditionalDataDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): AppAdditionalDataDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'caption': json['caption'],
        'pos': json['pos'],
        'value': !exists(json, 'value') ? undefined : json['value'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'editable': json['editable'],
        'required': json['required'],
        'options': !exists(json, 'options') ? undefined : json['options'],
    };
}

export function AppAdditionalDataDtoToJSON(value?: AppAdditionalDataDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'caption': value.caption,
        'pos': value.pos,
        'value': value.value,
        'description': value.description,
        'editable': value.editable,
        'required': value.required,
        'options': value.options,
    };
}


