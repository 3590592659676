



































import Vue from 'vue';
import BaseModalControls from '../partials/BaseModalControls.vue';

export default Vue.extend({
  name: 'ModalSendAppointmentInvitationCommand',
  components: {
    BaseModalControls,
  },
  props: {
    callback: {
      type: Object,
      default() {
        return {
          onYes: null,
        };
      },
    },
    changed: {
      type: Boolean,
      default: false,
    },
    deleted: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    isLoading: false,
    withComment: true,
  }),
  mounted() {
    (this.$refs.form as HTMLElement)?.focus();
  },
  methods: {
    close() {
      this.$emit('close');
    },
    async submit() {
      this.isLoading = true;
      await this.callback.onYes?.(this.withComment);
      this.isLoading = false;

      this.close();
    },
  },
});
