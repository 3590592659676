const amount = 50;
export default abstract class ConsolidateBaseQuery<T, TPayload = null> {
  constructor(protected _payload: TPayload) {}

  /**
   * Triggers the Query to do its work. Override this method.
   * @returns the result of the query
   */
  abstract execute(): Promise<T | { error: string } | null>;

  _addPaging(pageNumber: number) {
    return {
      limit: amount - 1,
      offset: pageNumber * amount,
    };
  }

  _pagedObject(
    found: number,
    pageNumber: number,
    items: ItemType<T>[]
  ): Paged<ItemType<T>> {
    return {
      total: found,
      hasMore: found - (pageNumber + 1) * amount > 0,
      page: pageNumber,
      items,
    };
  }

  _error(error: string) {
    return { error };
  }
}

export interface Paged<T> {
  total: number;
  hasMore: boolean;
  page: number;
  items: T[];
}

type ItemType<Type> = Type extends Paged<infer X> ? X : never;
