<template>
  <div class="single-file-input">
    <cs-button :loading="loading" @click.native="click">
      <input
        ref="uploadElement"
        type="file"
        :accept="accept"
        @change="fileChanged"
      />
      <slot />
    </cs-button>
  </div>
</template>

<script>
export default {
  props: {
    accept: { type: String, default: '', required: true },
  },
  data: () => ({
    loading: false,
  }),
  methods: {
    click() {
      this.$refs.uploadElement.click();
    },
    fileChanged(event) {
      try {
        this.loading = true;
        const { files } = event.target;
        const reader = new FileReader();
        reader.readAsBinaryString(files[0]);

        reader.onload = () => {
          const base64Url = `data:${files[0].type};base64,${btoa(
            reader.result
          )}`;
          this.$emit('change', { base64: base64Url });
        };
        reader.onerror = () => {
          // dunno man?! 🤷
        };
      } catch (error) {
        //
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style scoped>
input {
  display: none;
}

label {
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
}
</style>
