



























import { AdditionalDataEntry, AdditionalDataType } from '../../domain';
import Vue, { PropType } from 'vue';
import InfoRow from './InfoRow.vue';

export default Vue.extend({
  components: { InfoRow },
  props: {
    rows: { type: Array as PropType<AdditionalDataEntry[]>, default: () => [] },
  },
  data() {
    return {
      AdditionalDataType,
    };
  },
  methods: {
    value(row: AdditionalDataEntry) {
      if (row.description) return row.description;

      if (row.type === AdditionalDataType.Boolean) {
        return row.value ? this.$t('YES') : this.$t('NO');
      } else if (row.type === AdditionalDataType.Date) {
        return row.value ? new Date(row.value).toLocaleDateStringLS() : '';
      } else if (
        (row.type === AdditionalDataType.Radio ||
          row.type === AdditionalDataType.Select) &&
        row.value === -1
      ) {
        return undefined;
      }

      return row.value;
    },
  },
});
