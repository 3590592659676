













import BaseModalControls from '../partials/BaseModalControls.vue';
import Vue from 'vue';

export default Vue.extend({
  name: 'ModalEditSubject',
  components: {
    BaseModalControls,
  },
  props: {
    callback: {
      type: Object,
      default() {
        return {
          onYes: null,
          onNo: null,
        };
      },
    },
    initialValue: { type: String },
  },
  data: () => ({
    error: '',
    blurButtons: false,
    subject: '',
    isLoading: false,
  }),
  created() {
    this.subject = this.initialValue;
  },
  methods: {
    async handleYesCallback(): Promise<void> {
      // Check if user input is enough
      if (this.subject.length === 0) {
        this.error = `${this.$t('MISSING_REQUIRED_FIELD', {
          field: this.$t('SUBJECT'),
        })}.`;
        this.blurButtons = true;
        return;
      }

      const { onYes } = this.callback;
      if (onYes) {
        this.isLoading = true;
        await onYes({ subject: this.subject });
        this.isLoading = false;
      }

      this.$emit('close');
    },
  },
});
