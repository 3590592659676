import { StatusBar, Style } from '@capacitor/status-bar';
import semver, { SemVer } from 'semver';
import Client from '../../logic/api/Client';
import { SetSignatureCommand } from '../../logic/commands';
import { getAppVersion } from '../../utils/versioning';
import { Settings, Theme } from '../entitites';
import store from '../infrastructure/+state/store';

class SettingsFacade {
  async load() {
    // set theme initially to trigger vuetify update
    this.setTheme(this.settings.theme);

    try {
      const [settings, apiInfo, signature] = await Promise.all([
        new Client().api.getSettings(),
        new Client().api.getApiInfo(),
        new Client().api.getSignature(),
      ]);

      store.commit('setApiInfo', apiInfo);
      store.commit('setSettings', settings);
      store.commit('setSignature', signature);
    } catch (error) {
      // if we have previously loaded data ignore the error and work with offline data
      // otherwise throw an error
      if (!this.settings.apiStatus) {
        throw error;
      }
    }
  }

  async setTheme(newValue: Theme) {
    store.commit('setTheme', newValue);

    const statusBarStyle: Record<string, Style> = {
      dark: Style.Dark,
      light: Style.Light,
    };

    await StatusBar.setStyle({
      style: statusBarStyle[newValue] ?? Style.Default,
    });
  }

  async setSignature(text: string) {
    const signature = { ...store.state.settings.signature, text };
    store.commit('setSignature', signature);

    await new SetSignatureCommand({
      signature: text,
    }).execute();
  }

  public get settings(): Settings {
    return store.state.settings;
  }

  public get apiVersion(): SemVer {
    const version = semver.coerce(this.settings.apiVersion);
    if (!version) throw new Error('Invalid API version');

    return version;
  }

  public get appVersion(): string {
    const version = getAppVersion();
    return `${version?.version} (${version?.build})`;
  }
}

export default new SettingsFacade();
