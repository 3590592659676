/* tslint:disable */
/* eslint-disable */
/**
 * Consolidate App API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    Command2,
    Command2FromJSON,
    Command2ToJSON,
    QuarantinedMailDto,
    QuarantinedMailDtoFromJSON,
    QuarantinedMailDtoToJSON,
} from '../models';

export interface QuarantinedSetStatusRequest {
    command2: Command2;
}

/**
 * 
 */
export class QuarantinedApi extends runtime.BaseAPI {

    /**
     */
    async quarantinedGetRaw(): Promise<runtime.ApiResponse<Array<QuarantinedMailDto>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("oauth2", []);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/app/v1/Quarantined`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(QuarantinedMailDtoFromJSON));
    }

    /**
     */
    async quarantinedGet(): Promise<Array<QuarantinedMailDto>> {
        const response = await this.quarantinedGetRaw();
        return await response.value();
    }

    /**
     */
    async quarantinedSetStatusRaw(requestParameters: QuarantinedSetStatusRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.command2 === null || requestParameters.command2 === undefined) {
            throw new runtime.RequiredError('command2','Required parameter requestParameters.command2 was null or undefined when calling quarantinedSetStatus.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("oauth2", []);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/app/v1/Quarantined`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: Command2ToJSON(requestParameters.command2),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async quarantinedSetStatus(command2: Command2): Promise<void> {
        await this.quarantinedSetStatusRaw({ command2: command2 });
    }

}
