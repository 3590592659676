















import { GetAktMemberDefault } from '@consolidate/shared/data-access-legacy-api';
import {
  isAttendee,
  isUser,
  isContact,
  isAddress,
  isAktMemberDefault,
} from '../../../../utils/checkType';
import Vue from 'vue';
import { Attendee, ContactOrAddress, User } from '../../../../domain';
import ContactsFacade from '../../../../domain/application/ContactsFacade';
import EmployeeService from '../../../../domain/application/EmployeeService';
import { getContactSubtitle } from '../../../../utils/contact';

export default Vue.extend({
  name: 'AttendeesAutoComplete',
  props: {
    label: { type: String },
    value: { type: [Object, Array] },
    multiple: { type: Boolean, default: true },
    allowInternal: { type: Boolean, default: true },
    allowExternal: { type: Boolean, default: true },
  },
  data: () => ({
    initialOptions: [] as (ContactOrAddress | User)[],
  }),
  created() {
    const options: (ContactOrAddress | User)[] = [];

    if (this.allowExternal) {
      options.push(...ContactsFacade.default.withoutDividers);
    }

    if (this.allowInternal) {
      options.push(...EmployeeService.users);
    }

    this.initialOptions = this.sort(options);
  },
  methods: {
    async findItems(val: string) {
      const options: (ContactOrAddress | User)[] = [];

      if (this.allowExternal) {
        options.push(...(await ContactsFacade.findItems(val)));
      }

      if (this.allowInternal) {
        options.push(
          ...EmployeeService.users.filter((x) =>
            `${x.firstName} ${x.lastName}`
              .toLowerCase()
              .includes(val.toLowerCase())
          )
        );
      }

      return this.sort(options);
    },
    sort(val: (ContactOrAddress | User)[]) {
      return val.sort((a, b) => {
        const nameA = this.getDisplayText(a);
        const nameB = this.getDisplayText(b);
        if (nameA < nameB)
          //sort string ascending
          return -1;
        if (nameA > nameB) return 1;
        return 0; //default return value (no sorting)
      });
    },
    getDisplayText(
      item: GetAktMemberDefault | ContactOrAddress | User | Attendee
    ): string {
      if (isAttendee(item)) {
        if (item.userName) {
          return `${item.name} (${this.$t('INTERNAL')})`;
        }

        return item.name;
      }

      if (isUser(item)) {
        return `${item.firstName} ${item.lastName} (${this.$t('INTERNAL')})`;
      }

      if (isContact(item)) {
        return `${item.firstname} ${item.lastname}`;
      }

      if (isAddress(item)) {
        return item.companyname;
      }

      if (isAktMemberDefault(item)) {
        return item.description ?? '';
      }

      return '';
    },
    getItemValue(
      item: GetAktMemberDefault | ContactOrAddress | User | Attendee
    ): string {
      if (isAttendee(item)) {
        if (item.userName && item.userName !== '') {
          return item.userName;
        }
        return item.cid?.toString() ?? item.anr ?? '';
      }

      if (isUser(item)) {
        return item.userName;
      }

      if (isContact(item)) {
        return item.id.toString();
      }

      if (isAddress(item)) {
        return item.id;
      }

      if (isAktMemberDefault(item)) {
        return item.cid?.toString() ?? item.anr ?? item.uid ?? '';
      }

      return '';
    },
    getItemSubtitle(
      item: GetAktMemberDefault | ContactOrAddress | User | Attendee
    ): string | undefined {
      if (isAttendee(item)) {
        if (item.userName) {
          return item.userName;
        }

        return;
      }

      if (isUser(item)) {
        return item.userName;
      }

      if (isAktMemberDefault(item)) return;

      return getContactSubtitle(item);
    },
    updateValue(val: unknown) {
      this.$emit('input', val);
    },
    focus() {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (this.$refs.input as any).focus();
    },
  },
});
