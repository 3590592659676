

























import Vue, { PropType } from 'vue';

export default Vue.extend({
  components: {},
  props: {
    callback: {
      type: Object as PropType<{
        onSelected: (instace: Date) => Promise<void>;
      }>,
    },
    instances: {
      type: Array as PropType<Date[]>,
    },
  },
  data() {
    return {
      selectedInstance: this.instances[0],
      isLoading: false,
    };
  },
  methods: {
    async onSave() {
      if (!this.selectedInstance) return;
      const { onSelected } = this.callback;

      this.isLoading = true;
      await onSelected(this.selectedInstance);
      this.isLoading = false;
      this.$emit('close');
    },
  },
});
