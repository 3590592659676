






import Vue, { PropType } from 'vue';
import { ActivityTypeWorkflow } from '../../../domain';

export default Vue.extend({
  props: {
    options: { type: Array as PropType<ActivityTypeWorkflow[]> },
    callback: {
      type: Function as PropType<(selected: ActivityTypeWorkflow) => void>,
    },
  },
  methods: {
    select(item: ActivityTypeWorkflow) {
      this.$emit('close');
      this.callback(item);
    },
  },
});
