import { NotificationService } from '@consolidate/shared/ui-components';
import { translate } from '@consolidate/shared/util-translations';
import { AdditionalDataEntry } from '../../../domain';
import ObjectDetailFacade from '../../../domain/application/ObjectDetailFacade';
import { preprocessFields } from '../../../utils/additionalData';
import Client from '../../api/Client';
import ConsolidateBaseCommand from '../baseCommands/ConsolidateBaseCommand';

interface Payload {
  objectId: number;
  form: string;
  fields: AdditionalDataEntry[];
}

class EditObjectAdditionalDataCommand extends ConsolidateBaseCommand<Payload> {
  constructor(payload: Payload) {
    super('EditObjectAdditionalDataCommand', payload);
  }

  async execute() {
    try {
      const { objectId, form, fields } = this.payload;
      await new Client().objects.objectsEditAdditionalData(objectId, {
        form: form,
        fields: preprocessFields(fields),
      });
      return this.ok();
    } catch (error) {
      if (this.isRetryable(error)) {
        return this.save();
      }
      return this.notOk();
    }
  }

  protected onError() {
    NotificationService.showError({
      message: translate('ERROR_OCCURRED'),
    });

    ObjectDetailFacade.loadObject(this.payload.objectId);
  }
}

export default EditObjectAdditionalDataCommand;
