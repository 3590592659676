import {
  ApiResponse,
  GetUserFoldersResponse,
} from '@consolidate/shared/data-access-legacy-api';
import { Model, Query } from '@vuex-orm/core';
import Client from '../../logic/api/Client';
import { FolderItem } from '../entitites';
import { FolderModel } from '../infrastructure/+state/models';
import { ListFacadeWithCaching } from './ListFacade';

class FoldersFacade extends ListFacadeWithCaching<
  FolderModel,
  FolderItem,
  GetUserFoldersResponse
> {
  protected entity: string = FolderModel.entity;

  protected baseFilter(query: Query<Model>): Query<Model> {
    return query.orderBy(
      (x) =>
        // remove special characters (umlauts, accents, etc)
        (x as FolderModel).name
          .normalize('NFD')
          .replace(/\p{Diacritic}/gu, '')
          .toLowerCase(),
      'asc'
    );
  }

  protected map(item: FolderModel): FolderItem {
    return {
      id: item.id,
      icon: 'Folder' + item.icon,
      name: item.name,
    };
  }

  protected makeApiRequest(
    hash: number
  ): Promise<ApiResponse<GetUserFoldersResponse>> {
    return new Client().api.getUserFoldersRaw({ hash });
  }

  protected mapResponse(
    response: GetUserFoldersResponse
  ): Record<string, unknown>[] {
    return response.items.map((x) => ({
      id: x.id,
      icon: x.icon,
      name: x.foldername,
    }));
  }

  protected filterProperties: (keyof FolderItem)[] = ['name'];
}

export default new FoldersFacade();
