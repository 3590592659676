






import Vue from 'vue';

export default Vue.extend({
  inject: {
    canGoBack: { default: false },
  },
  props: {
    forceDisplay: Boolean,
  },
  computed: {
    parent(): string | undefined {
      return this.$route.meta?.parentRoute;
    },
  },
  methods: {
    back() {
      this.$router.back();
      if (this.parent) {
        this.$router.replace({ name: this.parent });
      }
    },
  },
});
