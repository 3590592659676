




































































import Vue, { PropType } from 'vue';
import { CalendarItem } from '../../domain';

export default Vue.extend({
  props: {
    date: { type: String },
    items: { type: Array as PropType<CalendarItem[]>, default: () => [] },
    displayAll: { type: Boolean },
  },
  data: () => ({
    showBirthdays: false,
    showAnniversaries: false,
  }),
  computed: {
    displayedItems() {
      const types = ['A', 'H'];

      const birthdays = this.items.filter((item) => item.type === 'B');

      const anniversaries = this.items.filter((item) => item.type === 'J');

      let items: CalendarItem[] = this.items
        .filter((item) => types.includes(item.type))
        .sortAsc('start');

      if (this.showAnniversaries || this.displayAll)
        items = [...anniversaries, ...items];

      if (this.showBirthdays || this.displayAll)
        items = [...birthdays, ...items];

      return items;
    },
    birthdayCount() {
      return this.items.filter((x) => x.type === 'B').length;
    },
    anniversaryCount() {
      return this.items.filter((x) => x.type === 'J').length;
    },
  },
  methods: {
    getCalendarWeek(date: string): number {
      return new Date(date).getWeek();
    },
    displayTime(event: CalendarItem): string {
      if (event.allDay) return this.$t('ALL_DAY');
      return `${event.start.toShortTimeString()} - ${event.end.toShortTimeString()}`;
    },
    isToday(date: string): boolean {
      return new Date(date).isToday();
    },
  },
});
