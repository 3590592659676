import { NotificationService } from '@consolidate/shared/ui-components';
import { translate } from '@consolidate/shared/util-translations';
import { AdditionalDataEntry } from '../../../domain';
import { preprocessFields } from '../../../utils/additionalData';
import Client from '../../api/Client';
import ConsolidateBaseCommand from '../baseCommands/ConsolidateBaseCommand';

interface Payload {
  id: number | string;
  form: string;
  fields: AdditionalDataEntry[];
}

class EditAdditionalDataCommand extends ConsolidateBaseCommand<Payload> {
  constructor(payload: Payload) {
    super('EditAdditionalDataCommand', payload);
  }

  async execute() {
    try {
      const { id, form, fields } = this.payload;
      await new Client().custom.customEditAdditionalData({
        id: id.toString(),
        form: form,
        fields: preprocessFields(fields),
      });
      return this.ok();
    } catch (error) {
      if (this.isRetryable(error)) {
        return this.save();
      }
      return this.notOk();
    }
  }

  protected onError() {
    NotificationService.showError({
      message: translate('ERROR_OCCURRED'),
    });
  }
}

export default EditAdditionalDataCommand;
