/* eslint-disable no-useless-constructor */
import Client from '../../api/Client';
import ConsolidateBaseCommand from './ConsolidateBaseCommand';

interface Payload {
  type: number;
  id: number | string;
}

abstract class AddToFavoritesBaseCommand extends ConsolidateBaseCommand<Payload> {
  constructor(type: string, payload: Payload) {
    super(type, payload);
  }

  async executeInternal() {
    try {
      const data = await await new Client().api.add2Favorite({
        id: this.payload.id.toString(),
        type: this.payload.type,
      });
      const result = data?._return === 'TRUE';

      return this.result(result);
    } catch (error) {
      if (this.isRetryable(error)) {
        return this.save();
      }
      return this.notOk();
    }
  }
}

export default AddToFavoritesBaseCommand;
