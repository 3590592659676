import { kanbanRoutes } from '@consolidate/feature-kanban';
import { authGuard, loginRoutes } from '@consolidate/shared/feature-login';
import Vue, { VueConstructor } from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import NavigationLayout from './NavigationLayout.vue';
import animationData from './assets/18-teamwork.json';
import ActivityDetail from './components/activity/detail/ActivityDetail.vue';
import AddressDetail from './components/address/AddressDetail.vue';
import ContactDetail from './components/contact/ContactDetail.vue';
import ProjectActivities from './components/project/ProjectActivities.vue';
import ProjectDetail from './components/project/ProjectDetail.vue';
import { SearchType } from './domain/application/ActivitySearchFacade';
import PageNotFound from './routes/404/PageNotFound.vue';
import ActivitiesMaster from './routes/activity/ActivitiesMaster.vue';
import CalendarMaster from './routes/calendar/CalendarMaster.vue';
import UserCcMaster from './routes/ccs/UserCcMaster.vue';
import ContactsAndAddressesPage from './routes/contactsAndAddresses/ContactsAndAddressesPage.vue';
import FoldersMaster from './routes/folders/FoldersMaster.vue';
import FreakyFridayPage from './routes/freakyfriday/FreakyFridayPage.vue';
import InboxMaster from './routes/inbox/InboxMaster.vue';
import JunkmailsMaster from './routes/junkmails/JunkmailsMaster.vue';
import MeetingLoginCallback from './routes/meeting/MeetingLoginCallback.vue';
import ObjectDetail from './routes/objects/ObjectDetail.vue';
import ObjectsMaster from './routes/objects/ObjectsMaster.vue';
import OverviewPage from './routes/overview/OverviewPage.vue';
import PoolMaster from './routes/pool/PoolMaster.vue';
import ProjectsMaster from './routes/projects/ProjectsMaster.vue';
import QueuePage from './routes/queue/QueuePage.vue';
import SentMaster from './routes/sent/SentMaster.vue';
import SettingsMaster from './routes/settings/SettingsMaster.vue';
import TasksMaster from './routes/tasks/TasksMaster.vue';
import TeamTasksMaster from './routes/teams/TeamTasksMaster.vue';
import TeamsMaster from './routes/teams/TeamsMaster.vue';

interface NavigationLevel {
  path: string;
  name: string;
  component: VueConstructor<Vue>;
  props?: any;
  dontSetParentRoute?: boolean;
}

const addNavigationLevels = (
  levels: NavigationLevel[],
  parent?: NavigationLevel
): RouteConfig[] => {
  if (levels.length === 0) return [];

  const item = levels[0];

  const path = parent ? `${parent.path}/${item.path}` : item.path;

  return [
    {
      path: `/${path}`,
      name: item.name,
      component: NavigationLayout,
      props: {
        component: item.component,
        name: item.name,
        ...item.props,
      },

      meta: parent && {
        parentRoute: !item.dontSetParentRoute && parent.name,
      },

      children: addNavigationLevels(levels.slice(1), { ...item, path }),
    },
  ];
};

const routes: RouteConfig[] = [
  ...loginRoutes('App', animationData, '5.2000'),
  {
    path: '/meeting-provider-callback',
    name: 'meeting-provider-callback',
    component: MeetingLoginCallback,
  },
  {
    path: '/',
    name: '',
    redirect: 'overview',
  },
  {
    path: '/overview',
    name: 'overview',
    component: OverviewPage,
  },
  ...addNavigationLevels([
    {
      path: 'inbox',
      name: 'inbox',
      component: InboxMaster,
    },
    {
      path: ':id',
      name: 'inbox-detail',
      component: ActivityDetail,
    },
  ]),
  ...addNavigationLevels([
    {
      path: 'junkmail',
      name: 'junkmail',
      component: JunkmailsMaster,
    },
  ]),
  ...addNavigationLevels([
    {
      path: 'tasks',
      name: 'tasks',
      component: TasksMaster,
    },
    {
      path: ':id',
      name: 'tasks-detail',
      component: ActivityDetail,
    },
  ]),
  ...addNavigationLevels([
    {
      path: 'projects',
      name: 'projects',
      component: ProjectsMaster,
    },
    {
      path: ':typeId',
      name: 'projects-detail',
      component: ProjectDetail,
    },
    {
      path: 'activities',
      name: 'projects-detail-activities',
      component: ProjectActivities,
    },
    {
      path: ':id',
      name: 'projects-detail-activities-detail',
      component: ActivityDetail,
    },
  ]),
  ...addNavigationLevels([
    {
      path: 'teams',
      name: 'teams',
      component: TeamsMaster,
    },
    {
      path: ':typeId',
      name: 'teams-activities',
      component: TeamTasksMaster,
    },
    {
      path: ':id',
      name: 'teams-activities-detail',
      component: ActivityDetail,
    },
  ]),
  ...addNavigationLevels([
    {
      path: 'folders',
      name: 'folders',
      component: FoldersMaster,
    },
    {
      path: ':typeId',
      name: 'folders-activities',
      component: ActivitiesMaster,
      props: { type: SearchType.Folder },
    },
    {
      path: ':id',
      name: 'folders-activities-detail',
      component: ActivityDetail,
    },
  ]),
  ...addNavigationLevels([
    {
      path: 'sent',
      name: 'sent',
      component: SentMaster,
    },
    {
      path: ':id',
      name: 'sent-detail',
      component: ActivityDetail,
    },
  ]),
  ...addNavigationLevels([
    {
      path: 'icc',
      name: 'icc',
      component: UserCcMaster,
    },
    {
      path: ':id',
      name: 'icc-detail',
      component: ActivityDetail,
    },
  ]),
  ...addNavigationLevels([
    {
      path: 'pool',
      name: 'pool',
      component: PoolMaster,
    },
    {
      path: ':id',
      name: 'pool-detail',
      component: ActivityDetail,
    },
  ]),
  ...addNavigationLevels([
    {
      path: 'calendar',
      name: 'calendar',
      component: CalendarMaster,
    },
    {
      path: ':id',
      name: 'calendar-detail',
      component: ActivityDetail,
    },
  ]),
  {
    path: '/contacts',
    name: 'contacts-addresses',
    component: NavigationLayout,
    props: {
      component: ContactsAndAddressesPage,
      name: 'contacts-addresses',
    },
    children: [
      ...addNavigationLevels(
        [
          {
            path: 'contact/:typeId',
            name: 'contact-detail',
            component: ContactDetail,
          },
          {
            path: 'activities',
            name: 'contact-detail-activities',
            component: ActivitiesMaster,
            props: { type: SearchType.Contact },
          },
          {
            path: ':id',
            name: 'contact-detail-activities-detail',
            component: ActivityDetail,
          },
        ],
        {
          path: 'contacts',
          name: 'contacts-addresses',
          component: ContactsAndAddressesPage,
        }
      ),
      ...addNavigationLevels(
        [
          {
            path: 'address/:typeId',
            name: 'address-detail',
            component: AddressDetail,
          },
          {
            path: 'activities',
            name: 'address-detail-activities',
            component: ActivitiesMaster,
            props: { type: SearchType.Address },
          },
          {
            path: ':id',
            name: 'address-detail-activities-detail',
            component: ActivityDetail,
          },
        ],
        {
          path: 'contacts',
          name: 'contacts-addresses',
          component: ContactsAndAddressesPage,
        }
      ),
    ],
  },

  ...addNavigationLevels([
    {
      path: 'objects',
      name: 'objects',
      component: ObjectsMaster,
    },
    {
      path: ':typeId',
      name: 'object-detail',
      component: ObjectDetail,
    },
    {
      path: 'activities',
      name: 'object-detail-activities',
      component: ActivitiesMaster,
      props: { type: SearchType.Object },
    },
    {
      path: ':id',
      name: 'object-detail-activities-detail',
      component: ActivityDetail,
    },
  ]),

  ...addNavigationLevels([
    {
      path: 'activity/:typeId',
      name: 'activity-detail',
      component: ActivityDetail,
    },
    {
      path: 'followers',
      name: 'activity-followers',
      component: ActivitiesMaster,
      props: { type: SearchType.Followers },
    },
    {
      path: ':id',
      name: 'activity-followers-detail',
      component: ActivityDetail,
      props: { type: SearchType.Followers },
    },
  ]),
  {
    path: '/settings',
    name: 'settings',
    component: SettingsMaster,
  },
  {
    path: '/queue',
    name: 'queue',
    component: QueuePage,
  },
  {
    path: '/freakyfriday',
    name: 'freakyfriday',
    component: FreakyFridayPage,
  },
  ...kanbanRoutes,
  {
    path: '*',
    name: 'PageNotFound',
    component: PageNotFound,
  },
];

const router = new VueRouter({
  mode: 'history',
  routes,
});

router.beforeEach(authGuard);

export default router;

export { routes };
