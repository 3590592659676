



















































































import Vue from 'vue';
import { Attendee, ContactOrAddress } from '../../../domain';
import ContactAutoComplete from '../../input/ContactAutoComplete.vue';
import ActivityFormBase from './partials/ActivityFormBase.vue';
import ContactGroup from '../shared/ContactGroup.vue';
import {
  isAddress,
  isAktMemberDefault,
  isAttendee,
  isContact,
} from '../../../utils/checkType';
import { GetAktMemberDefault } from '@consolidate/shared/data-access-legacy-api';
import { isEmail } from '../../../utils/validate';
import SettingsFacade from 'apps/web/src/domain/application/SettingsFacade';

export default Vue.extend({
  name: 'ActivityFormEmail',
  components: {
    ActivityFormBase,
    ContactAutoComplete,
    ContactGroup,
  },
  data: () => ({
    ccVisible: false,
  }),
  computed: {
    mailFromAddresses(): { email: string; value: string | null }[] {
      const mailAddresses = SettingsFacade.settings.mailFromAddresses;

      if (!mailAddresses) {
        return [];
      }

      const result: { email: string; value: string | null }[] =
        mailAddresses.aliases.map((x) => ({ email: x, value: x }));
      result.push({ email: mailAddresses.primary, value: null });

      return result;
    },
  },
  methods: {
    validateEmails(v: string[] | ContactOrAddress[]) {
      for (let item of v) {
        if (typeof item === 'string') {
          if (!isEmail(item)) {
            return this.$t('EMAIL_NOT_VALID', { email: item });
          }
        }
      }

      return true;
    },

    getContactDisplay(
      item: GetAktMemberDefault | ContactOrAddress | string
    ): string {
      if (isAttendee(item)) {
        return item.name;
      }

      if (typeof item === 'string') return item;

      if (isContact(item)) {
        return `${item.lastname} ${item.firstname}`;
      }

      if (isAddress(item)) {
        return item.companyname;
      }

      if (isAktMemberDefault(item)) {
        return item.description ?? '';
      }

      return '';
    },
    async focusReceivers() {
      (this.$refs.base as any).expand();
      await this.$nextTick();
      (this.$refs.receivers as any).focus();
    },
    async focusCC() {
      this.ccVisible = true;
      (this.$refs.base as any).expand();
      await this.$nextTick();
      (this.$refs.externalCC as any).focus();
    },
    getMemberIcon(item: ContactOrAddress | Attendee | string) {
      if (typeof item === 'string') return 'UnknownEmailAddress';
      if ('cid' in item) {
        if (item.cid) return 'GenderNeutral';
        else return 'Address';
      }
      if ('type' in item) {
        if (item.type === 'CONTACT') return 'GenderNeutral';
        if (item.type === 'ADDRESS') return 'Address';
      }
    },
  },
});
