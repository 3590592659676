import { GetProjectCustomDataResponsePartOf } from '@consolidate/shared/data-access-legacy-api';
import { AdditionalData } from '../../../entitites';
import { ListModel } from './listModel';

export class ProjectModel extends ListModel {
  static entity = 'projects';

  active!: boolean;
  archived?: boolean;
  comment?: string;
  confidentialityGroup!: string;
  partOf?: GetProjectCustomDataResponsePartOf;
  id!: number;
  icon!: string;
  name!: string;
  responsible!: string;
  start?: number;
  end?: number;
  isFavorite!: boolean;
  additionalData?: AdditionalData;

  static fields() {
    return {
      active: this.boolean(true),
      archived: this.boolean(null).nullable(),
      comment: this.string(null).nullable(),
      confidentialityGroup: this.string(''),
      partOf: this.attr(null).nullable(),
      id: this.number(0),
      isFavorite: this.boolean(false),
      name: this.string(''),
      responsible: this.string(''),
      start: this.number(null).nullable(),
      end: this.number(null).nullable(),
      icon: this.string(''),
      additionalData: this.attr(null).nullable(),
      isListedInDefault: this.boolean(false),
    };
  }

  static state() {
    return {
      ...super.state(),
      loadingDetail: false,
      loadDetailError: false,
      hideInactive: false,
    };
  }
}
