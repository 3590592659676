import { CalendarItem } from '../domain';

export function getCalendarItemTarget(
  item: CalendarItem
): { name: string; params: any } | undefined {
  if (item.activityId) {
    return {
      name: 'calendar-detail',
      params: { id: item.activityId.toString() },
    };
  }
  if (item.type === 'B') {
    const contactId = +item.uid.split('_')[0];
    // if the id is bigger than 1000000000 it is an employee and we can't show anything
    if (contactId < 1000000000) {
      return {
        name: 'contact-detail',
        params: { typeId: contactId.toString() },
      };
    }
  }
}
