












import { areTheSameDay } from '../../../utils/date';
import Vue, { PropType } from 'vue';
import { Attendee } from '../../../domain';
import AttendeeContactGroup from '../../activity/detail/partials/AttendeeContactGroup.vue';

export default Vue.extend({
  name: 'ModalAttendeeConfirmationStatus',
  components: {
    AttendeeContactGroup,
  },
  props: {
    date: { type: Date, required: true },
    attendeesInternal: { type: Array as PropType<Attendee[]>, required: true },
    callback: { type: Object as PropType<{ onBack: () => void }> },
  },
  computed: {
    attendees(): Attendee[] {
      let attendees = this.attendeesInternal.map((x) => {
        if (!x.exclusionDates) return x;

        let exclusionDates = x.exclusionDates?.split(';');

        let hasRejected = exclusionDates.some((x) =>
          areTheSameDay(x, this.date)
        );

        return hasRejected ? { ...x, confirmationStatus: 3 } : x;
      });

      return attendees;
    },
  },
  methods: {
    async onBack() {
      const { onBack } = this.callback;
      onBack();

      this.$emit('close');
    },
  },
});
