import { App as AppPlugin } from '@capacitor/app';
import { Capacitor } from '@capacitor/core';
import { setActivityHandler } from '@consolidate/feature-kanban';
import { ComponentsPlugin } from '@consolidate/shared/ui-components';
import { AuthService } from '@consolidate/shared/util-auth';
import { i18n } from '@consolidate/shared/util-translations';
import * as Sentry from '@sentry/capacitor';
import * as SentryVue from '@sentry/vue';
import PortalVue from 'portal-vue';
import Vue from 'vue';
import 'vue-virtual-scroller/dist/vue-virtual-scroller.css';
import Vue2TouchEvents from 'vue2-touch-events';
import App from './App.vue';
import * as components from './components';
import QueueService from './domain/application/QueueService';
import store from './domain/infrastructure/+state/store';
// Register HTML5 Component
import VueMeta from 'vue-meta';
import * as commands from './logic/commands';
import InitializationService from './logic/services/InitializationService';
import LicensingService from './logic/services/LicensingService';
import MigrationService from './logic/services/MigrationService';
import ModalService from './logic/services/ModalService';
import ShareService from './logic/services/ShareService';
import plugins from './plugins';
import './registerServiceWorker';
import router from './router';
import { getAppVersion } from './utils/versioning';

if (process.env.NODE_ENV === 'production') {
  const version = getAppVersion();

  // workaround because sentry capacitor does not support vue
  // https://github.com/getsentry/sentry-capacitor/issues/91
  type VueOptions = Omit<
    NonNullable<Parameters<typeof SentryVue.init>[0]>,
    'Vue'
  >;
  const initSentryVue = (options?: VueOptions) =>
    SentryVue.init({ ...options, Vue });

  Sentry.init(
    {
      dsn: 'https://485c1bd7d15745b6bbc77567ec29cc46@o908775.ingest.sentry.io/5844381',
      environment: Capacitor.isNativePlatform() ? 'native' : 'web',
      release: `app@${version.version}+${version.build}`,
    },
    initSentryVue
  );
}

window.commands = window.commands || {};

for (const command in commands) {
  window.commands[command] = (commands as any)[command];
}

for (const key in components) {
  //eslint-disable-next-line @typescript-eslint/no-explicit-any
  Vue.component(key, (components as any)[key]);
}

plugins(Vue);
Vue.use(ComponentsPlugin);

Vue.use(PortalVue);

Vue.use(Vue2TouchEvents, { namespace: 'touchevents' });

Vue.use(VueMeta);

Vue.config.productionTip = false;
Vue.config.performance = true;

const mountApp = () => {
  const vue = new Vue({
    router,
    i18n,
    render: (h) => h(App),
    store,
  });
  vue.$mount('#app');

  AppPlugin.addListener('appUrlOpen', function (data: any) {
    // this is how sharing works on ios, ignore here and don't try to route
    if (data.url.startsWith('eu.consolidate.app-share')) {
      return;
    }

    if (data.url.startsWith('mailto:')) {
      ShareService.handleMailto(data.url.substr(7));
      return;
    }

    // Example url: eu.consolidate.app:/login-callback
    const slug = data.url.split(':/').pop();

    if (slug) {
      router.push({
        path: slug,
      });
    }
  });

  AppPlugin.addListener('backButton', function ({ canGoBack }) {
    if (canGoBack) {
      window.history.back();
    } else {
      AppPlugin.exitApp();
    }
  });

  ShareService.init();
  setActivityHandler((id) => {
    ModalService.activityDetail(id);
  });
};

window.addEventListener('online', () => {
  if (AuthService.isLoggedIn()) {
    QueueService.process();
  }
});

(async () => {
  await MigrationService.migrate();

  await AuthService.init('app', [
    'cons.app',
    'cons.kanban',
    'openid',
    'profile',
    'offline_access',
    'consid.licenses',
    'consid.permissions',
  ]);

  mountApp();

  if (AuthService.isLoggedIn() && LicensingService.checkLicense()) {
    Sentry.setTag('webservice.url', AuthService.getWebServiceUrl());
    Sentry.setUser({
      username: AuthService.getUser()?.uid,
      webservice: AuthService.getWebServiceUrl(),
    });

    QueueService.process();
    await InitializationService.loadData();
  }
})();
