








































































import { AuthService } from '@consolidate/shared/util-auth';
import { Permission } from 'libs/shared/util-auth/src/lib/models/permission';
import Vue from 'vue';
import OverviewFacade from '../../domain/application/OverviewFacade';
import QueueService from '../../domain/application/QueueService';
import InitializationService from '../../logic/services/InitializationService';
import LogoutService from '../../logic/services/LogoutService';
import ModalService from '../../logic/services/ModalService';
import Logo from '../logo/logo.svg';

export default Vue.extend({
  name: 'SidebarMenu',
  components: {
    Logo,
  },
  computed: {
    amountOfCommandsInQueue() {
      return QueueService.count;
    },
    isLoading() {
      return InitializationService.state.isLoading;
    },
    me() {
      return AuthService.getUser();
    },
    notificationsCount() {
      return OverviewFacade.notificationsCount;
    },
    junkmailCount() {
      return OverviewFacade.junkmailCount;
    },
    totalTaskItems() {
      return OverviewFacade.tasksCount;
    },
    items(): any[] {
      const permissions = AuthService.getUser()?.permissions;
      return [
        {
          target: { name: 'overview' },
          label: 'OVERVIEW',
          icon: 'Overview',
        },
        {
          target: { name: 'inbox' },
          label: 'INBOX',
          icon: 'Notifications',
          newItems: this.notificationsCount,
        },
        ...(this.junkmailCount && this.junkmailCount >= 1
          ? [
              {
                target: { name: 'junkmail' },
                label: 'JUNKMAIL',
                icon: 'FolderDisable',
                newItems: this.junkmailCount,
              },
            ]
          : []),
        {
          target: { name: 'icc' },
          label: 'ICC_LIST',
          icon: 'ICCs',
        },
        {
          target: { name: 'sent' },
          label: 'SENT',
          icon: 'EmailSent',
        },
        {
          target: { name: 'tasks' },
          label: 'TASKS',
          icon: 'Task',
          newItems: this.totalTaskItems,
        },
        {
          target: { name: 'teams' },
          label: 'TEAM_TASKS',
          icon: 'Team',
        },
        {
          target: { name: 'pool' },
          label: 'ACTIVITIES',
          icon: 'Search',
        },
        {
          target: { name: 'folders' },
          label: 'FOLDERS',
          icon: 'Folder',
        },
        {
          target: { name: 'calendar' },
          label: 'CALENDAR',
          icon: 'Calendar',
        },
        ...(permissions?.includes(Permission.ViewPersonList) ||
        permissions?.includes(Permission.ViewAddressList)
          ? [
              {
                target: { name: 'contacts-addresses' },
                label: 'CONTACTS',
                icon: 'Contact',
              },
            ]
          : []),
        {
          target: { name: 'projects' },
          label: 'PROJECTS',
          icon: 'Projects',
        },
        {
          target: { name: 'objects' },
          label: 'OBJECTS',
          icon: 'Objects',
        },
        ...(AuthService.getScopes().includes('cons.kanban')
          ? [
              {
                target: { name: 'kanban' },
                label: 'KANBAN',
                icon: 'Kanban',
              },
            ]
          : []),
        {
          target: { name: 'settings' },
          label: 'SETTINGS',
          icon: 'Settings',
        },
      ];
    },
  },
  methods: {
    async logout() {
      const amountInQueue = QueueService.count;
      if (amountInQueue === 0) return this.performLogout();

      return ModalService.choice({
        title: this.$t('LOGOUT_TITLE'),
        subtitle: this.$t('ARE_YOU_SURE_YOU_WANT_TO_LOGOUT'),
        labels: { yes: this.$t('LOGOUT') },
        callback: {
          onYes: () => this.performLogout(),
        },
      });
    },
    async performLogout() {
      LogoutService.logout();
    },
  },
});
