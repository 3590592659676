import { translate } from '@consolidate/shared/util-translations';
import { Model, Query } from '@vuex-orm/core';
import {
  FindActivitiesQuery,
  GetActivitiesFromQuery,
  GetFollowActsQuery,
} from '../../logic/queries';
import { AdvancedSearchOptions } from '../../logic/services/ModalService';
import { isConnectionError } from '../../utils/network';
import { ActivityListItem } from '../entitites';
import {
  ActivityModel,
  ActivitySearchResultModel,
} from '../infrastructure/+state/models';
import store from '../infrastructure/+state/store';
import { ListFacade } from './ListFacade';

export enum SearchType {
  Folder = 'folder',
  Project = 'project',
  Address = 'address',
  Contact = 'contact',
  Team = 'team',
  Followers = 'followers',
  Search = 'search',
  Object = 'object',
}

class ActivitySearchFacade extends ListFacade<
  ActivitySearchResultModel,
  ActivityListItem
> {
  protected entity: string = ActivitySearchResultModel.entity;

  protected filterProperties: (keyof ActivityListItem)[] = [
    'subject',
    'contact',
    'summary',
  ];

  protected map(item: ActivitySearchResultModel): ActivityListItem {
    return {
      uid: item.id,
      ...item.activity,
      summary: item.highlightedSummary ?? item.activity.summary,
      subject: item.highlightedSubject ?? item.activity.subject,
      contact: item.highlightedContact ?? item.activity.contact,
    };
  }

  public get lastCreated(): ActivityListItem[] {
    const items = store.state.entities[ActivityModel.entity].lastCreated.map(
      (x: number) => {
        const activity = ActivityModel.find(x);

        if (!activity) {
          return {
            uid: x,
            id: x,
            subject: translate('DELETED'),
            summary: x,
            icon: 'Trash',
          };
        }

        return {
          ...activity,
          uid: x,
          summary: x,
          dueDateTimeUtc: null,
          contact: null,
        };
      }
    );

    return items;
  }

  protected baseFilter(query: Query<Model>): Query<Model> {
    return query.orderBy('sortOrder', 'asc');
  }

  public get page(): number {
    return store.state.entities[ActivitySearchResultModel.entity].page;
  }

  public get value(): string | number {
    return store.state.entities[ActivitySearchResultModel.entity].value;
  }

  public set value(val: string | number) {
    this.model.commit((state) => {
      state.value = val;
    });
  }

  public get type(): SearchType {
    return store.state.entities[ActivitySearchResultModel.entity].type;
  }

  public get advancedOptions(): AdvancedSearchOptions {
    return store.state.entities[ActivitySearchResultModel.entity]
      .advancedOptions;
  }

  public get total(): string {
    return store.state.entities[ActivitySearchResultModel.entity].total;
  }

  public get hasMore(): string {
    return store.state.entities[ActivitySearchResultModel.entity].hasMore;
  }

  protected async getItems(): Promise<Record<string, unknown>[] | undefined> {
    throw new Error('Not Implemented');
  }

  public clear() {
    super.clear();

    ActivitySearchResultModel.commit((state) => {
      state.loading = false;
      state.loadError = false;
      state.type = undefined;
      state.value = undefined;
      state.total = undefined;
      state.advancedOptions = undefined;
    });
  }

  public async loadFor(
    type: SearchType,
    value: string | number,
    advancedOptions?: AdvancedSearchOptions
  ) {
    ActivitySearchResultModel.commit((state) => {
      state.loading = true;
      state.loadError = false;
      state.total = undefined;
      state.advancedOptions = undefined;
    });
    ActivitySearchResultModel.deleteAll();

    let loadError = false;
    try {
      const items = await this.loadItems(type, value, 0, advancedOptions);
      if (items) {
        ActivitySearchResultModel.insert({
          data: items,
        });
      }
    } catch (error) {
      loadError = isConnectionError(error);
    }

    ActivitySearchResultModel.commit((state) => {
      state.loading = false;
      state.loadError = loadError;
    });
  }

  private async loadItems(
    type: SearchType,
    value: string | number,
    page: number,
    advancedOptions?: AdvancedSearchOptions
  ) {
    ActivitySearchResultModel.commit((state) => {
      state.type = type;
      state.value = value;
      state.page = page;
      state.advancedOptions = advancedOptions;
    });

    let command;

    let advancedSearchOptions;

    if (advancedOptions) {
      let addresses;
      let contacts;

      if (advancedOptions.contacts) {
        addresses = advancedOptions.contacts.filter(
          (x) => x.type === 'ADDRESS'
        );

        contacts = advancedOptions.contacts.filter((x) => x.type === 'CONTACT');
      }

      advancedSearchOptions = {
        ...(advancedOptions.project && {
          project: advancedOptions.project.map((x) => x.id).toString(),
        }),

        ...(advancedOptions.activityType && {
          akttype: advancedOptions.activityType.map((x) => x.id).toString(),
        }),

        ...(advancedOptions.responsibles && {
          responsible: advancedOptions.responsibles
            .map((x) => x.userName)
            .toString(),
        }),

        ...(addresses && {
          anrlist: addresses.map((x) => x.id as string).toString(),
        }),

        ...(contacts && {
          cidlist: contacts.map((x) => x.id as number).toString(),
        }),

        ...(advancedOptions.fromDate && {
          createdfrom: advancedOptions.fromDate.value?.toDateString(),
        }),

        ...(advancedOptions.showAll !== undefined &&
          !advancedOptions.showAll && {
            status: advancedOptions.showAll,
          }),
      };
    }

    switch (type) {
      case SearchType.Followers:
        command = new GetFollowActsQuery({ id: +value });
        break;
      case SearchType.Search:
        command = new FindActivitiesQuery({
          page: page,
          searchTerm: value.toString(),
          ...advancedSearchOptions,
        });
        break;
      default:
        command = new GetActivitiesFromQuery({
          type,
          id: value,
          page,
        });
    }

    const result = await command.execute();

    if (result) {
      ActivitySearchResultModel.commit((state) => {
        state.total = result.total;
        state.hasMore = result.hasMore;
      });

      let sortOrder = ActivitySearchResultModel.all().length;
      return result.items.map((x) => ({
        id: x.id,
        sortOrder: ++sortOrder,
        highlightedSubject:
          'highlightedSubject' in x ? x.highlightedSubject : null,
        highlightedSummary:
          'highlightedSummary' in x ? x.highlightedSummary : null,
        highlightedContact:
          'highlightedContact' in x ? x.highlightedContact : null,
        activity: {
          ...ActivityModel.find(x.id),
          ...x,
        },
      }));
    }
  }

  public async loadMore() {
    if (!this.hasMore || this.loading) return;

    ActivitySearchResultModel.commit((state) => {
      state.loading = true;
    });

    try {
      const items = await this.loadItems(
        this.type,
        this.value,
        this.page + 1,
        this.advancedOptions
      );
      if (items) {
        ActivitySearchResultModel.insertOrUpdate({
          data: items,
        });
      }
    } catch {
      // when offline, dont load more items
    }

    ActivitySearchResultModel.commit((state) => {
      state.loading = false;
    });
  }
}

export default new ActivitySearchFacade();
