




































import { PrimaryAction } from '../../../logic/services/ModalService';
import Vue, { PropType } from 'vue';
import ButtonBar from '../../button/ButtonBar.vue';

export default Vue.extend({
  name: 'BaseModalControls',
  components: {
    ButtonBar,
  },
  props: {
    blur: { type: Boolean, default: false },
    loading: { type: Boolean, default: false },
    canCancel: { type: Boolean, default: false },
    primaryAction: {
      type: String as PropType<PrimaryAction>,
      default: () => PrimaryAction.NO,
    },
  },
  watch: {
    blur(hasChanged: Event) {
      if (hasChanged) {
        this.clearButtonFocus();
      }
    },
  },
  methods: {
    clearButtonFocus() {
      this.$el.querySelectorAll('button').forEach((button) => button.blur());
    },
  },
});
