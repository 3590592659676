/* tslint:disable */
/* eslint-disable */
/**
 * Consolidate App API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 0 = Quarantined
 * 1 = Delete
 * 2 = Sync
 * @export
 * @enum {string}
 */
export enum QuarantinedMailStatus {
    NUMBER_0 = 0,
    NUMBER_1 = 1,
    NUMBER_2 = 2
}

export function QuarantinedMailStatusFromJSON(json: any): QuarantinedMailStatus {
    return QuarantinedMailStatusFromJSONTyped(json, false);
}

export function QuarantinedMailStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): QuarantinedMailStatus {
    return json as QuarantinedMailStatus;
}

export function QuarantinedMailStatusToJSON(value?: QuarantinedMailStatus | null): any {
    return value as any;
}

