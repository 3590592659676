import { ActivityListItem, ActivityListItemFactory } from '../../../domain';
import Client from '../../api/Client';
import ConsolidateBaseQuery, { Paged } from '../ConsolidateBaseQuery';

interface Payload {
  aktid: number;
}

class GetFollowActsQuery extends ConsolidateBaseQuery<
  Paged<ActivityListItem>,
  Payload
> {
  constructor({ id }: { id: number }) {
    super({ aktid: id });
  }

  async execute() {
    const response = await new Client().api.getFollowAkts({
      aktid: this._payload.aktid,
    });

    const items: ActivityListItem[] = response.items.map((x) =>
      ActivityListItemFactory.getFromConsolidateFollowActs(x)
    );

    const result = this._pagedObject(items.length, 0, items);
    return result;
  }
}
export default GetFollowActsQuery;
