import VuexORM from '@vuex-orm/core';
import localforage from 'localforage';
import * as shvl from 'shvl';
import Vue from 'vue';
import Vuex from 'vuex';
import VuexPersistence from 'vuex-persist';
import LogoutService from '../../../logic/services/LogoutService';
import { Settings } from '../../entitites';
import {
  ActivityDetailModel,
  ActivityModel,
  ActivitySearchResultModel,
  ActivityTypeModel,
  AddressDetailModel,
  CalendarItemModel,
  ConfidentialityModel,
  ContactDetailModel,
  ContactModel,
  ContactSearchResultModel,
  CountryModel,
  EmployeeModel,
  FolderModel,
  InboxItemModel,
  JunkmailModel,
  MeetingProviderModel,
  ObjectModel,
  ProjectActivitiesModel,
  ProjectModel,
  ProjectSearchResultModel,
  QueueModel,
  SentItemModel,
  TaskModel,
  TeamModel,
  TeamTaskModel,
  UserCcModel,
} from './models';
import { settingsModule } from './modules';

const debug = process.env.NODE_ENV !== 'production';

Vue.use(Vuex);

const database = new VuexORM.Database();

database.register(ActivityModel);
database.register(ActivityDetailModel);
database.register(ActivitySearchResultModel);
database.register(InboxItemModel);
database.register(TeamModel);
database.register(FolderModel);
database.register(JunkmailModel);
database.register(SentItemModel);
database.register(CalendarItemModel);
database.register(ProjectModel);
database.register(ProjectSearchResultModel);
database.register(ContactModel);
database.register(ContactSearchResultModel);
database.register(ContactDetailModel);
database.register(AddressDetailModel);
database.register(ActivityTypeModel);
database.register(EmployeeModel);
database.register(CountryModel);
database.register(ConfidentialityModel);
database.register(QueueModel);
database.register(TaskModel);
database.register(TeamTaskModel);
database.register(ProjectActivitiesModel);
database.register(UserCcModel);
database.register(ObjectModel);
database.register(MeetingProviderModel);

interface RootState {
  settings: Settings;
  entities: any;
}

const persistedEntities = [
  QueueModel.entity,
  ActivityTypeModel.entity,
  CountryModel.entity,
  ConfidentialityModel.entity,
  EmployeeModel.entity,
  TeamModel.entity,
  InboxItemModel.entity,
  SentItemModel.entity,
  TaskModel.entity,
  ActivityModel.entity,
  ActivityDetailModel.entity,
  FolderModel.entity,
  CalendarItemModel.entity,
  ProjectModel.entity,
  ContactModel.entity,
  ContactDetailModel.entity,
  AddressDetailModel.entity,
  TeamTaskModel.entity,
  ProjectActivitiesModel.entity,
  UserCcModel.entity,
  ObjectModel.entity,
  MeetingProviderModel.entity,
];

const paths = [
  'settings',
  ...persistedEntities.map((x) => `entities.${x}.data`),
  ...persistedEntities.map((x) => `entities.${x}.hash`),
  ...persistedEntities.map((x) => `entities.${x}.hasMore`),
  `entities.${TaskModel.entity}.showFuture`,
  `entities.${TaskModel.entity}.counts`,
  `entities.${ActivityTypeModel.entity}.latestFive`,
  `entities.${ActivityModel.entity}.lastCreated`,
  `entities.${ProjectModel.entity}.hideInactive`,
];

const vuexLocal = new VuexPersistence<RootState>({
  storage: localforage as any,
  asyncStorage: true,
  reducer: (state) => {
    return paths.reduce(function (substate, path) {
      return shvl.set(
        substate,
        path,
        shvl.get(state, path, undefined),
        undefined
      );
    }, {});
  },
});

LogoutService.registerPreLogoutTask(
  async () => await vuexLocal.storage?.clear()
);

const store = new Vuex.Store<RootState>({
  strict: debug,
  plugins: [VuexORM.install(database), vuexLocal.plugin],
  modules: {
    settings: settingsModule,
  },
});

export default store as typeof store & { restored: Promise<RootState> };
