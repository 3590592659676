import { NotificationService } from '@consolidate/shared/ui-components';
import { translate } from '@consolidate/shared/util-translations';
import { AttachmentFactory } from '../../../domain';
import ActivityService from '../../../domain/application/ActivityService';
import { ActivityDetailModel } from '../../../domain/infrastructure/+state/models';
import { formatBytes } from '../../../utils/file';
import Client from '../../api/Client';
import ConsolidateBaseCommand from '../baseCommands/ConsolidateBaseCommand';

interface Payload {
  actId: number;
  attachment: {
    name: string;
    size: number;
    mimeType: string;
    base64Data: string;
  };
}

class AddAttachmentToActivityCommand extends ConsolidateBaseCommand<Payload> {
  constructor(payload: Payload) {
    super('AddAttachmentToActivityCommand', payload);
  }

  async execute() {
    try {
      const { actId, attachment } = this.payload;

      const dto = {
        aktid: actId,
        base64: attachment.base64Data,
        fileName: attachment.name,
        fileSize: attachment.size,
        fileType: attachment.mimeType,
      };

      const data = await new Client().api.addAttachment(dto);
      const success = data?._return === 'TRUE';

      if (success) {
        ActivityService.updateAkt(actId, {
          hasAttachments: true,
          attachments: [
            ...(ActivityDetailModel.find(actId)?.attachments ?? []),
            AttachmentFactory.create({
              ...attachment,
              aktid: actId,
              id: data.attid,
            }),
          ],
        });
      }

      return this.result(success);
    } catch (error) {
      if (this.isRetryable(error)) {
        return this.save();
      }

      return this.notOk();
    }
  }

  protected onError() {
    NotificationService.showError({
      message: translate('ATTACHMENT_UPLOAD_TOO_BIG', {
        size: formatBytes(30 * 1024 * 1024),
      }),
    });
  }
}

export default AddAttachmentToActivityCommand;
