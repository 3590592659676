













import Vue, { PropType } from 'vue';
import { ProjectItem } from '../../domain';
import ProjectsFacade from '../../domain/application/ProjectsFacade';

export default Vue.extend({
  name: 'ProjectAutoComplete',
  props: {
    label: { type: String },
    value: { type: [Object, Array] as PropType<ProjectItem> },
    multiple: { type: Boolean, default: false },
  },
  computed: {
    initialOptions() {
      return ProjectsFacade.items.filter((x) => x.active);
    },
  },
  methods: {
    findItems(val: string) {
      return ProjectsFacade.findItems(val);
    },
    updateValue(val: ProjectItem | null) {
      this.$emit('input', val);
    },
  },
});
