import Client from '../../api/Client';
import ConsolidateBaseCommand from '../baseCommands/ConsolidateBaseCommand';

interface Payload {
  actId: number;
  sendReply?: boolean;
}

class ConfirmAppointmentCommand extends ConsolidateBaseCommand<Payload> {
  constructor(payload: Payload) {
    super('ConfirmAppointmentCommand', payload);
  }

  async execute() {
    try {
      const { actId, sendReply } = this.payload;

      const dto = {
        aktid: actId,
        sendreply: sendReply,
      };
      const data = await new Client().api.confirmAppointment(dto);
      const success = data?._return === 'TRUE';

      return this.result(success);
    } catch (error) {
      if (this.isRetryable(error)) {
        return this.save();
      }
      return this.notOk();
    }
  }
}

export default ConfirmAppointmentCommand;
