
















import Vue from 'vue';
import ActivityToolbar from '../../activity/detail/partials/ActivityToolbar.vue';
import LoadingIndicator from '../../loading/LoadingIndicator.vue';
import ActivityDetailTemplate from '../../activity/detail/ActivityDetailTemplate.vue';
import ActivityDetailFacade from '../../../domain/application/ActivityDetailFacade';

export default Vue.extend({
  components: {
    LoadingIndicator,
    ActivityToolbar,
    ActivityDetailTemplate,
  },
  props: {
    id: { type: Number, required: true },
    headerKey: { type: String },
  },
  computed: {
    isLoading() {
      return ActivityDetailFacade.loading;
    },
    loadError() {
      return ActivityDetailFacade.loadError;
    },
    data() {
      return ActivityDetailFacade.Activity;
    },
  },
  created(): void {
    ActivityDetailFacade.loadActivity(this.id);
  },
});
