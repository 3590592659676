/* tslint:disable */
/* eslint-disable */
/**
 * Consolidate App API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Result2
 */
export interface Result2 {
    /**
     * 
     * @type {Array<number>}
     * @memberof Result2
     */
    activityIds: Array<number>;
}

export function Result2FromJSON(json: any): Result2 {
    return Result2FromJSONTyped(json, false);
}

export function Result2FromJSONTyped(json: any, ignoreDiscriminator: boolean): Result2 {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'activityIds': json['activityIds'],
    };
}

export function Result2ToJSON(value?: Result2 | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'activityIds': value.activityIds,
    };
}


