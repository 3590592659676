import { NotificationService } from '@consolidate/shared/ui-components';
import { translate } from '@consolidate/shared/util-translations';
import ActivityDetailFacade from '../../../domain/application/ActivityDetailFacade';
import ActivityEventEmitter from '../../../domain/application/ActivityEvents';
import InboxFacade from '../../../domain/application/InboxFacade';
import OverviewFacade from '../../../domain/application/OverviewFacade';
import TasksFacade from '../../../domain/application/TasksFacade';
import Client from '../../api/Client';
import ConsolidateBaseCommand from '../baseCommands/ConsolidateBaseCommand';

interface Payload {
  actId: number;
  surpressSuccessMessage?: boolean;
}

class DeleteActivityCommand extends ConsolidateBaseCommand<Payload> {
  constructor(payload: Payload) {
    super('DeleteActivityCommand', payload);
  }

  async execute() {
    try {
      const { actId } = this.payload;

      const data = await new Client().api.deleteAkt(actId);
      const success = data._return === 'True';

      return this.result(success);
    } catch (error) {
      if (this.isRetryable(error)) {
        return this.save();
      }
      return this.notOk();
    }
  }

  protected onSuccess() {
    OverviewFacade.refreshCounts();
    TasksFacade.getCounts();

    ActivityEventEmitter.emit('activityDeleted', this.payload.actId);

    if (this.payload.surpressSuccessMessage) return;

    NotificationService.showSuccess({
      message: translate('DELETE_ACTIVITY_SUCCESS'),
    });
  }

  protected onError() {
    const { actId } = this.payload;
    NotificationService.showError({
      message: translate('ACT_DELETE_ERROR'),
    });

    TasksFacade.load(true);
    InboxFacade.load(true);
    ActivityDetailFacade.loadActivity(actId);
  }
}

export default DeleteActivityCommand;
