export const activityColors = [
  '#638980',
  '#7FBA9A',
  '#B4C676',
  '#E8B558',
  '#E09B62',
  '#B16A6A',
  '#AF7E9E',
  '#8797CE',
  '#97CFD6',
  '#69A7AF',
];

export function normalizeColor(color?: string | null): string | null {
  if (!color || color.replace('#', '') === '000000') {
    return null;
  }

  const sortedColors = activityColors
    .map((c) => ({
      diff: getColorDistance(c, color),
      color: c,
    }))
    .sort((x, y) => (x.diff > y.diff ? 1 : -1));

  return sortedColors[0].color;
}

function getColorDistance(first: string, second: string): number {
  const firstVal = getColorValues(first);
  const secondVal = getColorValues(second);

  return Math.sqrt(
    Math.pow(firstVal.red - secondVal.red, 2) +
      Math.pow(firstVal.green - secondVal.green, 2) +
      Math.pow(firstVal.blue - secondVal.blue, 2)
  );
}

function getColorValues(color: string): {
  red: number;
  green: number;
  blue: number;
} {
  const normalized = color.replace('#', '').substring(0);
  return {
    red: parseInt(normalized.substring(0, 2), 16),
    green: parseInt(normalized.substring(2, 4), 16),
    blue: parseInt(normalized.substring(4, 6), 16),
  };
}

export function getContrastTextColor(color: string): string {
  const L = getLuminance(color);
  return L > 0.179 ? '#000000' : '#ffffff';
}

function getLuminance(color: string) {
  const values = getColorValues(color);

  const uicolors = [values.red / 255, values.green / 255, values.blue / 255];
  const c = uicolors.map((col) => {
    if (col <= 0.03928) {
      return col / 12.92;
    }
    return Math.pow((col + 0.055) / 1.055, 2.4);
  });
  const L = 0.2126 * c[0] + 0.7152 * c[1] + 0.0722 * c[2];
  return L;
}
