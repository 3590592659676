




























import Vue from 'vue';
import animationData from './14-loading.json';

export default Vue.extend({
  name: 'PageNotFound',
  data: () => ({
    animationData,
  }),
});
