import AddToFavoritesBaseCommand from '../baseCommands/AddToFavoritesBaseCommand';

class AddContactToFavoritesCommand extends AddToFavoritesBaseCommand {
  constructor({ id }: { id: number }) {
    super('AddContactToFavoritesCommand', { type: 1, id });
  }

  async execute() {
    return super.executeInternal();
  }
}
export default AddContactToFavoritesCommand;
