



























import { translate } from '@consolidate/shared/util-translations';
import Vue from 'vue';
import ActivityList from '../../components/activity/list/ActivityList.vue';
import SentFacade from '../../domain/application/SentFacade';
import { filterMixin } from '../../mixins/filterMixin';

export default Vue.extend({
  name: 'SentPage',
  metaInfo: {
    titleTemplate: `${translate('SENT')}  %s`,
  },
  components: {
    ActivityList,
  },
  mixins: [filterMixin(SentFacade)],
  computed: {
    items() {
      return SentFacade.filteredItems;
    },
    isLoading() {
      return SentFacade.loading;
    },
    loadError(): boolean {
      return SentFacade.loadError;
    },
  },
  methods: {
    reload() {
      SentFacade.load();
    },
    loadMore() {
      SentFacade.loadMore();
    },
  },
});
