
























import Vue from 'vue';
import { translate } from '@consolidate/shared/util-translations';
import { RRule } from 'rrule';

export default Vue.extend({
  props: {
    multiple: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    value: { type: [Number, Array] },
  },
  data: () => ({
    daysOfWeek: [
      { text: translate('MONDAY'), value: RRule.MO.weekday },
      { text: translate('TUESDAY'), value: RRule.TU.weekday },
      { text: translate('WEDNESDAY'), value: RRule.WE.weekday },
      { text: translate('THURSDAY'), value: RRule.TH.weekday },
      { text: translate('FRIDAY'), value: RRule.FR.weekday },
      { text: translate('SATURDAY'), value: RRule.SA.weekday },
      { text: translate('SUNDAY'), value: RRule.SU.weekday },
    ],
  }),
  methods: {
    onInput(value: unknown) {
      this.$emit('input', value);
    },
  },
});
