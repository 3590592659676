



















import ActivityDetailFacade from 'apps/web/src/domain/application/ActivityDetailFacade';
import Vue from 'vue';

export default Vue.extend({
  name: 'ModalEditChainOrShowAcceptionsCanncellations',

  data: () => ({
    isLoading: false,
  }),
  methods: {
    onView() {
      ActivityDetailFacade.showAcceptionsAndCancellationsOfInstance();
      this.$emit('close');
    },
    onEdit() {
      ActivityDetailFacade.editChain();

      this.$emit('close');
    },
  },
});
