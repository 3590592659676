var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"cs-page"},[_c('cs-toolbar',[_c('v-toolbar-title',[_vm._v(_vm._s(_vm.$t('TEAM_TASKS')))]),_c('v-spacer'),_c('v-btn',{attrs:{"icon":""},on:{"click":_vm.reload}},[_c('cs-icon',{attrs:{"icon":"Refresh","animate":_vm.isLoading,"active":_vm.isLoading}})],1)],1),(_vm.isLoading)?_c('LoadingIndicator'):_vm._e(),(_vm.loadError && _vm.data.length === 0)?_c('offline-indicator'):_c('cs-list',{attrs:{"items":_vm.data,"keyField":"id"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var item = ref.item;
return [_c('v-list-item',{attrs:{"to":{
          name: 'teams-activities',
          params: {
            typeId: item.id,
          },
        }}},[_c('v-list-item-icon',[_c('cs-icon',[_vm._v("Team")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(item.name))])],1)],1),_c('v-divider')]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }