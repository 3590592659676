import ActivityEventEmitter from '../../../domain/application/ActivityEvents';
import { getConsiDateTimeFromUTCString } from '../../../utils/date';
import Client from '../../api/Client';
import ConsolidateBaseCommand from '../baseCommands/ConsolidateBaseCommand';

interface Payload {
  actId: number;
  startDateTimeUtc: string;
  endDateTimeUtc: string;
  isAllDay: boolean;
  updateReservations: boolean;
}

class SetAppointmentDateTimeCommand extends ConsolidateBaseCommand<Payload> {
  constructor(payload: Payload) {
    super('SetAppointmentDateTimeCommand', payload);
  }

  async execute() {
    try {
      const {
        actId,
        startDateTimeUtc,
        endDateTimeUtc,
        isAllDay,
        updateReservations,
      } = this.payload;

      const startDateTime = getConsiDateTimeFromUTCString(startDateTimeUtc);
      const endDateTime = getConsiDateTimeFromUTCString(endDateTimeUtc);
      const dto = {
        aktid: actId,
        starttime: startDateTime,
        startdate: startDateTime,
        endtime: endDateTime,
        enddate: endDateTime,
        wholeday: isAllDay,
        updatereservations: updateReservations,
      };

      const data = await new Client().api.setAppointmentDate(dto);
      const success = data?._return === 'TRUE';

      return this.result(success);
    } catch (error) {
      if (this.isRetryable(error)) {
        return this.save();
      }
      return this.notOk();
    }
  }

  protected onSuccess() {
    ActivityEventEmitter.emit('appointmentDateChanged', this.payload.actId);
  }
}

export default SetAppointmentDateTimeCommand;
