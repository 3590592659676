
import Vue from 'vue';

export default Vue.extend({
  provide: {
    canGoBack: true,
  },
  render(createElement) {
    if (this.$slots.default) {
      return this.$slots.default[0];
    }

    return createElement('div');
  },
});
