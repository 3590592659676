



















































import Vue, { PropType } from 'vue';
import { ActivityType } from '../../domain';
import ActivityTypesService from '../../domain/application/ActivityTypesService';

export default Vue.extend({
  props: {
    value: { type: Object as PropType<ActivityType> },
  },
  data: () => ({
    searchInput: '',
  }),
  computed: {
    allActTypes(): ActivityType[] {
      return ActivityTypesService.types.sortAsc('name');
    },
    filteredActTypes(): ActivityType[] {
      return this.allActTypes.filter((x) =>
        x.name.toLowerCase().includes(this.searchInput.toLowerCase())
      );
    },

    topFive(): ActivityType[] {
      const ltFive = ActivityTypesService.latestFive;
      let topFiveArray = this.allActTypes.filter((item: ActivityType) =>
        ltFive.includes(item.id)
      );

      return topFiveArray;
    },
  },
  methods: {
    select(item: ActivityType) {
      this.$emit('input', item);
    },
  },
});
