























import Vue, { PropType } from 'vue';
import { ActivityTodoType, ACTIVITY_TODO_TYPE_LIST } from '../../../domain';
import BaseModalControls from '../partials/BaseModalControls.vue';

export default Vue.extend({
  name: 'ModalActivityTodoType',
  components: {
    BaseModalControls,
  },
  props: {
    initialTodoType: {} as PropType<ActivityTodoType>, // Normal
    callback: {
      type: Object,
      default: () => ({
        onYes: { type: Function, default: null },
      }),
    },
  },
  data: () => ({
    error: '',
    selectedTodoType: {} as ActivityTodoType,
    todoTypeList: ACTIVITY_TODO_TYPE_LIST, // Normal
    isSubmitting: false,
  }),
  created() {
    this.selectedTodoType = this.initialTodoType;
  },
  methods: {
    async handleYesCallback() {
      // Check if we have a different priority id
      if (this.selectedTodoType.id === this.initialTodoType.id) {
        this.error = `${this.$t('NO_CHANGES_WERE_MADE')}.`;
        return;
      }
      const { onYes } = this.callback;
      if (onYes) {
        this.isSubmitting = true;
        await onYes({ todoType: this.selectedTodoType });
        this.isSubmitting = false;
      }
      this.$emit('close');
    },
    resetError() {
      this.error = '';
    },
  },
});
