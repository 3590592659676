








































import Vue from 'vue';

export default Vue.extend({
  name: 'ModalCancelPrompt',
  props: {
    callback: {
      type: Object,
      default() {
        return {
          onYes: null,
        };
      },
    },
    shouldSendReply: { type: Boolean, default: false },
    organizer: { type: String },
  },
  data: () => ({
    comment: '',
    isLoading: false,
    isFormValid: false,
    sendReply: true,
  }),
  methods: {
    validate() {
      (this.$refs.form as any).validate();
    },
    async handleYesCallback() {
      this.validate();
      if (!this.isFormValid) return;

      const { onYes } = this.callback;
      if (onYes) {
        this.isLoading = true;
        await onYes({ comment: this.comment, sendReply: this.sendReply });
        this.isLoading = false;
      }

      this.$emit('close');
    },
  },
});
