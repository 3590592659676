














import Vue, { PropType } from 'vue';
import { ProjectInfo } from '../../../domain';
import BaseModalControls from '../partials/BaseModalControls.vue';
import ProjectAutoComplete from '../../project/ProjectAutoComplete.vue';

export default Vue.extend({
  name: 'ModalActivityProject',
  components: {
    BaseModalControls,
    ProjectAutoComplete,
  },
  props: {
    initialProject: {} as PropType<ProjectInfo>,
    callback: {
      type: Object,
      default: () => ({
        onYes: { type: Function, default: null },
      }),
    },
  },
  data: () => ({
    error: '',
    selectedProject: {} as ProjectInfo,
    isSubmitting: false,
  }),
  created() {
    this.selectedProject = this.initialProject;
  },
  methods: {
    async handleYesCallback() {
      // If there wasn't a initial project we dont need to check the ids.
      if (this.initialProject !== null && this.selectedProject) {
        if (this.selectedProject.id === this.initialProject.id) {
          this.error = `${this.$t('NO_CHANGES_WERE_MADE')}.`;
          return;
        }
      }
      // If there was no initial project, we need to check if the user selected a new one
      if (this.selectedProject === this.initialProject) {
        this.error = `${this.$t('NO_CHANGES_WERE_MADE')}.`;
        return;
      }
      const { onYes } = this.callback;
      if (onYes) {
        this.isSubmitting = true;
        await onYes({ selectedProject: this.selectedProject });
        this.isSubmitting = false;
      }
      this.$emit('close');
    },
    resetError() {
      this.error = '';
    },
  },
});
