






































































































































































































import { Address } from '../../domain';
import AddressDetailFacade from '../../domain/application/AddressDetailFacade';
import ModalService from '../../logic/services/ModalService';
import LoadingIndicator from '../loading/LoadingIndicator.vue';

import Vue from 'vue';
import DetailChip from '../../components/shared/DetailChip.vue';
import DetailPage from '../../components/shared/DetailPage.vue';
import AdditionalDataFacade from '../../domain/application/AdditionalDataFacade';
import { AddressDetailModel } from '../../domain/infrastructure/+state/models';

interface KeyValuePair {
  key: string;
  value: string;
  externalLink?: string;
}

const getKeyValuePairs = (obj: Address, props: (keyof Address)[]) => {
  const details: KeyValuePair[] = [];
  props.forEach((prop) => {
    const val = obj[prop]?.toString();
    if (val) {
      details.push({ key: prop, value: val });
    }
  });
  return details;
};

export default Vue.extend({
  name: 'AddressDetail',
  metaInfo() {
    const address = AddressDetailFacade.address;
    const path = this.$route.path;

    if (path.includes('/activities')) {
      return {
        titleTemplate: address ? `${address.name} | %s` : '... | %s',
      };
    }
    return {
      title: address ? `| ${address.name}` : '| ...',
    };
  },
  components: {
    LoadingIndicator,
    DetailPage,
    DetailChip,
  },
  data: () => ({
    tabs: [
      { label: 'DETAILS' },
      { label: 'CONTACT_PERSONS' },
      { label: 'NOTES' },
      { label: 'ADDITIONAL_DATA' },
    ],
    selectedTab: { label: 'DETAILS' },
  }),
  computed: {
    subtitle(): string {
      if (!this.address) return '';
      let subtitle = this.address.name2 as string;

      if (this.address.name3) {
        subtitle += ' ' + this.address.name3;
      }

      return subtitle;
    },
    isLoading() {
      return AddressDetailFacade.loading;
    },
    loadError() {
      return AddressDetailFacade.loadError;
    },
    address() {
      return AddressDetailFacade.address;
    },
    canEditAddress() {
      return AddressDetailFacade.canEditAddress;
    },
    companyAddress(): string {
      if (!this.address) return '';
      return this.getAddressString(this.address);
    },
    companyPobox(): string {
      if (!this.address?.pobox) return '';
      return [
        this.address.pobox,
        [this.$t('ZIP'), this.address.poboxzip].join(': '),
      ]
        .filter(Boolean)
        .join('\n');
    },
    communications(): KeyValuePair[] {
      if (!this.address?.communications) return [];

      return this.address.communications.map((comm) => ({
        key: comm.conntype,
        value: comm.connvalue,
      }));
    },
    addressDetails(): KeyValuePair[] {
      if (!this.address) return [];

      return getKeyValuePairs(this.address, [
        'companyname',
        'name',
        'name2',
        'name3',
        'pobox',
        'street',
        'zip',
        'city',
        'country',
        'telcomplete',
        'fax',
      ]);
    },
    id(): string {
      return this.$route.params.typeId;
    },
  },
  watch: {
    id() {
      this.fetchaddress();
    },
  },
  created() {
    this.fetchaddress();
  },
  methods: {
    editAdditionalData() {
      if (this.address?.additionalData) {
        AdditionalDataFacade.editAdditionalData(
          this.address.id,
          this.address.additionalData[0].key,
          AddressDetailModel
        );
      }
    },
    getContactIcon(icon?: string) {
      if (icon === 'man') {
        return 'GenderMan';
      }

      if (icon === 'woman') {
        return 'GenderWoman';
      }

      if (icon === 'other') {
        return 'GenderOther';
      }

      return 'GenderNone';
    },
    editAddressClick() {
      ModalService.editAddress(this.address);
    },
    async toggleFavorite() {
      AddressDetailFacade.toggleFavorite();
    },
    showActivitiesOfThisAddress() {
      this.$router.push({
        name: 'address-detail-activities',
      });
    },
    goToContact(id?: number) {
      if (!id) return;
      this.$router.push({
        name: 'contact-detail',
        params: {
          typeId: id.toString(),
        },
      });
    },
    goToObject(id: number) {
      this.$router.push({
        name: 'object-detail',
        params: {
          typeId: id.toString(),
        },
      });
    },
    getAddressString(address: {
      street?: string;
      street2?: string;
      addressline2?: string;
      addressline3?: string;
      city?: string;
      zip?: string;
      country?: string;
    }): string {
      return [
        address.street,
        address.street2,
        address.addressline2,
        address.addressline3,
        [address.zip, address.city].join(' '),
        address.country,
      ]
        .filter(Boolean)
        .join('\n');
    },
    fetchaddress() {
      if (!this.id) return;

      AddressDetailFacade.loadAddress(this.id);
    },
    getGoogleMapsLink(address: string) {
      return `https://www.google.com/maps/place/${encodeURIComponent(address)}`;
    },
  },
});
