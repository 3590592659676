/* tslint:disable */
/* eslint-disable */
/**
 * Consolidate App API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ObjectListItemDto,
    ObjectListItemDtoFromJSON,
    ObjectListItemDtoFromJSONTyped,
    ObjectListItemDtoToJSON,
} from './';

/**
 * 
 * @export
 * @interface PaginatedListResultOfObjectListItemDto
 */
export interface PaginatedListResultOfObjectListItemDto {
    /**
     * 
     * @type {number}
     * @memberof PaginatedListResultOfObjectListItemDto
     */
    totalItems: number;
    /**
     * 
     * @type {boolean}
     * @memberof PaginatedListResultOfObjectListItemDto
     */
    hasMoreItems: boolean;
    /**
     * 
     * @type {Array<ObjectListItemDto>}
     * @memberof PaginatedListResultOfObjectListItemDto
     */
    items: Array<ObjectListItemDto>;
}

export function PaginatedListResultOfObjectListItemDtoFromJSON(json: any): PaginatedListResultOfObjectListItemDto {
    return PaginatedListResultOfObjectListItemDtoFromJSONTyped(json, false);
}

export function PaginatedListResultOfObjectListItemDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): PaginatedListResultOfObjectListItemDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'totalItems': json['totalItems'],
        'hasMoreItems': json['hasMoreItems'],
        'items': ((json['items'] as Array<any>).map(ObjectListItemDtoFromJSON)),
    };
}

export function PaginatedListResultOfObjectListItemDtoToJSON(value?: PaginatedListResultOfObjectListItemDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'totalItems': value.totalItems,
        'hasMoreItems': value.hasMoreItems,
        'items': ((value.items as Array<any>).map(ObjectListItemDtoToJSON)),
    };
}


