/**
 * Maps the Consolidate system iconId (coming from our API/Database)
 * to a corresponding key of an Icon in our lovely frontend.
 *
 * @param {number} id - The id coming from Consolidate (0)
 * @returns {string} Key - The key of the Icon (Smiley)
 */
export function resolveIconId(id: string | number | null): string;
export function resolveIconId(id?: string | number | null): string | undefined;
export function resolveIconId(id?: string | number | null) {
  if (id === undefined) {
    return undefined;
  }

  // null id's are deleted
  if (id === null) {
    return 'Trash';
  }

  return `Activity${id}`;
}
