import { ActivityModel } from './activityModel';
import { ListModel } from './listModel';

export class ProjectActivitiesModel extends ListModel {
  static entity = 'projectActivities';

  id!: number;
  activity!: ActivityModel;
  sortOrder!: number;

  static fields() {
    return {
      id: this.number(0),
      activity: this.belongsTo(ActivityModel, 'id'),
      sortOrder: this.number(0),
    };
  }

  static state() {
    return {
      ...super.state(),
      search: '',
      project: undefined,
      total: 0,
      hasMore: false,
      page: 0,
    };
  }
}
