
















import Vue from 'vue';
export default Vue.extend({
  props: {
    text: { type: String },
    callback: {
      type: Function,
      required: false,
    },
    preventClose: { type: Boolean },
  },
  methods: {
    ok() {
      this.callback?.();
      if (!this.preventClose) {
        this.$emit('close');
      }
    },
  },
});
