
























import Vue from 'vue';
import { PropType } from 'vue';
import { AttendeeType, ContactOrAddress, User } from '../../../domain';
import AttendeesAutoComplete from '../../activity/form/partials/AttendeesAutoComplete.vue';

export default Vue.extend({
  name: 'ModalActivityAddAttendee',
  components: {
    AttendeesAutoComplete,
  },
  props: {
    callback: {
      type: Object as PropType<{
        onYes?: (props: {
          attendee: User | ContactOrAddress;
        }) => Promise<boolean>;
      }>,
    },
    type: { type: Number as PropType<AttendeeType>, required: true },
  },
  computed: {
    allowInternal(): boolean {
      return this.type === AttendeeType.Internal;
    },
    allowExternal(): boolean {
      return this.type === AttendeeType.External;
    },
    label(): string {
      if (this.type === AttendeeType.Internal) {
        return this.$t('ATTENDEES_INTERNAL');
      }
      return this.$t('ATTENDEES_EXTERNAL');
    },
  },
  data() {
    return {
      isLoading: false,
      selected: undefined as User | ContactOrAddress | undefined,
    };
  },
  methods: {
    async handleYesCallback() {
      if (!this.selected) return;
      const { onYes } = this.callback;

      let success = false;
      if (onYes) {
        this.isLoading = true;
        success = await onYes({
          attendee: this.selected,
        });
        this.isLoading = false;
      }

      if (success) {
        this.$emit('close');
      }
    },
  },
});
