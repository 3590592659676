import { NotificationService } from '@consolidate/shared/ui-components';
import { translate } from '@consolidate/shared/util-translations';
import ActivityEvents from '../../../domain/application/ActivityEvents';
import OverviewFacade from '../../../domain/application/OverviewFacade';
import Client from '../../api/Client';
import ConsolidateBaseCommand from '../baseCommands/ConsolidateBaseCommand';

interface Payload {
  actId: number;
}

class MarkActivityAsReadCommand extends ConsolidateBaseCommand<Payload> {
  constructor(payload: Payload) {
    super('MarkActivityAsReadCommand', payload);
  }

  async execute() {
    try {
      const { actId } = this.payload;
      const data = await new Client().api.setAktRead(actId);
      const success = data?._return === 'TRUE';

      return this.result(success);
    } catch (error) {
      if (this.isRetryable(error)) {
        return this.save();
      }
      return this.notOk();
    }
  }

  protected onSuccess() {
    OverviewFacade.refreshCounts();
    ActivityEvents.emit('activityRead', this.payload.actId);
  }

  protected onError() {
    NotificationService.showError({
      message: translate('MARK_ACT_AS_READ_ERROR'),
    });
  }
}

export default MarkActivityAsReadCommand;
