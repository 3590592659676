import {
  ApiResponse,
  GetInboxResponseCounts,
  GetTodoItem,
  GetTodoItemTodo,
  GetTodoResponse,
} from '@consolidate/shared/data-access-legacy-api';
import { NotificationService } from '@consolidate/shared/ui-components';
import { AuthService } from '@consolidate/shared/util-auth';
import { translate } from '@consolidate/shared/util-translations';
import { Model, Query } from '@vuex-orm/core';
import {
  Activity,
  ActivityFactory,
  ActivityListItem,
  ACTIVITY_TODO_TYPE_DONE,
} from '../../domain';
import Client from '../../logic/api/Client';
import { ActivityListItemFactory } from '../factories';
import { ActivityModel, TaskModel } from '../infrastructure/+state/models';
import store from '../infrastructure/+state/store';
import ActivityEvents from './ActivityEvents';
import { ListFacadeWithCaching } from './ListFacade';
import OverviewFacade from './OverviewFacade';

class TasksFacade extends ListFacadeWithCaching<
  TaskModel,
  ActivityListItem,
  GetTodoResponse
> {
  constructor() {
    super();

    ActivityEvents.on('activityCreated', this.activityCreated);
  }

  protected entity = TaskModel.entity;

  protected filterProperties: (keyof ActivityListItem)[] = [
    'subject',
    'contact',
    'summary',
  ];

  protected map(item: TaskModel): ActivityListItem {
    return {
      uid: item.id,
      ...item.activity,
    };
  }

  protected baseFilter(query: Query<Model>): Query<Model> {
    let filter = query
      .whereHas('activity', (query) => {
        query
          .where((x: ActivityModel) => x.todoType?.id === this.todoType)
          .where(
            (x: ActivityModel) => x.responsible === AuthService.getUser()?.uid
          );
      })
      .orderBy(
        (x) => ActivityModel.find((x as TaskModel).id)?.dueDateTimeUtc,
        'desc'
      )
      .orderBy((x) => (x as TaskModel).id, 'desc');

    if (!this.showFuture) {
      filter = filter.whereHas('activity', (query) => {
        query.where((x: ActivityModel) => !this.isFuture(x.dueDateTimeUtc));
      });
    }

    return filter;
  }

  protected isFuture(date: string): boolean {
    const today = new Date();
    const dueDate = new Date(date);
    return dueDate > today;
  }

  public get showFuture(): boolean {
    return store.state.entities[TaskModel.entity].showFuture;
  }

  public get counts(): GetInboxResponseCounts[] {
    return store.state.entities[TaskModel.entity].counts;
  }

  public get todoType(): number {
    return store.state.entities[TaskModel.entity].todoType;
  }

  public get hasMore(): boolean {
    return (
      (this.counts.find((x) => x.type === this.todoType)?.count ?? 0) >
      this.items.length
    );
  }

  public setTodoType(val: number) {
    TaskModel.commit((state) => {
      state.todoType = val;
    });

    // if we switch to this todotype for the first time we need to load the items
    if (this.items.length === 0) {
      super.load();
    }
  }

  public async load(force = false) {
    const result = await super.load(force);
    OverviewFacade.refreshCounts();
    this.getCounts();
    return result;
  }

  public async loadMore() {
    if (!this.hasMore) return;

    TaskModel.commit((state) => {
      state.loading = true;
    });

    try {
      const resp = await this.makeApiRequest(this.hash, this.items.length);
      const items = this.mapResponse(await resp.value());
      if (items) {
        TaskModel.insertOrUpdate({
          data: items,
        });
      }
    } catch {
      // when offline, dont load more items
    }

    TaskModel.commit((state) => {
      state.loading = false;
    });
  }

  public async setShowFutureState(val: boolean) {
    TaskModel.commit((state) => {
      state.showFuture = val;
    });

    if (await this.load()) {
      NotificationService.showInfo({
        message: translate(
          this.showFuture ? 'SHOW_FUTURE_INFO' : 'DONT_SHOW_FUTURE_INFO'
        ),
      });
    } else {
      NotificationService.showWarning({
        message: translate('SHOW_FUTURE_INFO_OFFLINE'),
      });

      TaskModel.commit((state) => {
        state.showFuture = !val;
      });
    }
  }

  public async getCounts() {
    const { counts } = await new Client().api.getTodo(undefined, {
      limit: 1,
      divider: false,
      showFuture: +this.showFuture,
      getcount: true,
    });

    TaskModel.commit((state) => {
      state.counts = counts;
    });
  }

  protected makeApiRequest(
    hash: number,
    offset = 0
  ): Promise<ApiResponse<GetTodoResponse>> {
    return new Client().api.getTodoRaw({
      getTodoRequest: {
        limit: 30,
        offset,
        divider: false,
        showFuture: +this.showFuture,
        getcount: true,
        todotype: this.todoType,
      },
      hash,
    });
  }

  protected mapResponse(response: GetTodoResponse): Record<string, unknown>[] {
    if (response.items) {
      TaskModel.commit((state) => {
        const count = response.counts?.find((x) => x.type === this.todoType);
        (state.counts as GetInboxResponseCounts[]).find(
          (x) => x.type === this.todoType
        )?.count == count?.count;
      });

      return response.items.filter(this.isTodoItem).map((x) => ({
        id: x.aktid,
        activity: {
          ...ActivityModel.find(x.aktid),
          ...ActivityListItemFactory.create({
            id: x.aktid,
            subject: x.subject,
            dueDateTimeUtc: `${x.duedate}.000Z`,
            hasAttachments: x.hasattachment,
            priorityId: x.priority,
            actType: {
              ...ActivityModel.find(x.aktid)?.actType,
              id: x.akttype,
              name: x.aktname,
            },
            contact: x.contact,
            icon: x.icon,
            iconColor: x.color,
            todoType: ActivityFactory.getTodoTypeFromConsolidateId(x.todotype),
            summary: x.summary,
            responsible: AuthService.getUser()?.uid,
            isRecurring: x.isrecurring,
          }),
        },
      }));
    }

    return [];
  }

  private isTodoItem(
    arg: GetTodoItem
  ): arg is { isdivider: false } & GetTodoItemTodo {
    return !arg.isdivider;
  }

  public activityCreated(newActivity: Activity) {
    if (
      newActivity.responsible === AuthService.getUser()?.uid &&
      newActivity.todoType.id !== ACTIVITY_TODO_TYPE_DONE.id
    ) {
      TaskModel.insert({
        data: {
          id: newActivity.id,
        },
      });
    }
  }
}

export default new TasksFacade();
