/* tslint:disable */
/* eslint-disable */
/**
 * Consolidate Kanban API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Command
 */
export interface Command {
    /**
     * 
     * @type {string}
     * @memberof Command
     */
    title: string;
}

export function CommandFromJSON(json: any): Command {
    return CommandFromJSONTyped(json, false);
}

export function CommandFromJSONTyped(json: any, ignoreDiscriminator: boolean): Command {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'title': json['title'],
    };
}

export function CommandToJSON(value?: Command | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'title': value.title,
    };
}


