import { AdditionalDataEntry, AdditionalDataType } from '../domain';

export const preprocessFields = (
  fields: AdditionalDataEntry[]
): { key: string; value: string | null | undefined }[] => {
  return fields.map((x) => {
    let value = x.value?.toString();

    if (x.type === AdditionalDataType.Color && value) {
      const rgb = hexToRgb(value);
      if (rgb) {
        value = (rgb.r | (rgb.g * 0x100) | (rgb.b * 0x10000)).toString();
      }
    }

    if (x.type === AdditionalDataType.Boolean) {
      value = x.value ? '1' : '0';
    }

    if (x.type === AdditionalDataType.Source) {
      value = x.value?.key;
    }

    if (x.type === AdditionalDataType.Time) {
      value = x.value?.replace(':', '');
    }

    if (x.type === AdditionalDataType.MultilineText) {
      value = x.value?.replace('\r', '').replace('\n', '\r\n');
    }

    return {
      key: x.key,
      value: value,
    };
  });
};

const hexToRgb = (hex: string) => {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
      }
    : null;
};
