/* tslint:disable */
/* eslint-disable */
/**
 * Consolidate Kanban API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CardDto,
    CardDtoFromJSON,
    CardDtoFromJSONTyped,
    CardDtoToJSON,
} from './';

/**
 * 
 * @export
 * @interface ColumnDto
 */
export interface ColumnDto {
    /**
     * 
     * @type {number}
     * @memberof ColumnDto
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof ColumnDto
     */
    title: string;
    /**
     * 
     * @type {Array<CardDto>}
     * @memberof ColumnDto
     */
    cards: Array<CardDto>;
}

export function ColumnDtoFromJSON(json: any): ColumnDto {
    return ColumnDtoFromJSONTyped(json, false);
}

export function ColumnDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ColumnDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'title': json['title'],
        'cards': ((json['cards'] as Array<any>).map(CardDtoFromJSON)),
    };
}

export function ColumnDtoToJSON(value?: ColumnDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'title': value.title,
        'cards': ((value.cards as Array<any>).map(CardDtoToJSON)),
    };
}


