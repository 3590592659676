import { NotificationService } from '@consolidate/shared/ui-components';
import { translate } from '@consolidate/shared/util-translations';
import InboxFacade from '../../../domain/application/InboxFacade';
import OverviewFacade from '../../../domain/application/OverviewFacade';
import TasksFacade from '../../../domain/application/TasksFacade';
import Client from '../../api/Client';
import ConsolidateBaseCommand from '../baseCommands/ConsolidateBaseCommand';

interface Payload {
  actId: number;
}

class MarkActivityAsDoneCommand extends ConsolidateBaseCommand<Payload> {
  constructor(payload: Payload) {
    super('MarkActivityAsDoneCommand', payload);
  }

  async execute() {
    try {
      const { actId } = this.payload;
      const data = await new Client().api.setAktDone(actId);
      const success = data?._return === 'TRUE';

      return this.result(success);
    } catch (error) {
      if (this.isRetryable(error)) {
        return this.save();
      }
      return this.notOk();
    }
  }

  protected onSuccess() {
    OverviewFacade.refreshCounts();
    TasksFacade.getCounts();
  }

  protected onError() {
    NotificationService.showError({
      message: translate('ACT_COMPLETE_ERROR'),
    });

    TasksFacade.load(true);
    InboxFacade.load(true);
  }
}

export default MarkActivityAsDoneCommand;
