import { AddOrEditContactRequest } from '@consolidate/shared/data-access-legacy-api';
import ContactDetailFacade from '../../../domain/application/ContactDetailFacade';
import { ContactDetailModel } from '../../../domain/infrastructure/+state/models';
import Client from '../../api/Client';
import ConsolidateBaseCommand from '../baseCommands/ConsolidateBaseCommand';

interface Payload {
  contact: AddOrEditContactRequest;
}

class EditContactCommand extends ConsolidateBaseCommand<Payload, number> {
  constructor(payload: Payload) {
    super('EditContactCommand', payload);
  }

  async execute() {
    try {
      const { contact } = this.payload;

      const data = await new Client().api.addOrEditContact(contact);
      const contactId = data?._return;
      return this.ok(+contactId);
    } catch (error) {
      if (this.isRetryable(error)) {
        return this.save();
      }
      return this.notOk();
    }
  }

  protected async onSuccess(id: number) {
    const { contact } = await ContactDetailFacade.getContact(id);

    ContactDetailModel.update({
      data: {
        ...ContactDetailModel.find(id),
        ...contact,
      },
    });
  }
}
export default EditContactCommand;
