import { GetAktResponse } from '@consolidate/shared/data-access-legacy-api';
import { WorkflowStep, WorkflowStepFireType } from '../entitites';

export const WorkflowStepFactory = {
  create(activity: GetAktResponse): WorkflowStep | null {
    if (activity.processid) {
      return {
        type: activity.nextsteptype ?? 0,
        stepName: activity.nextstepname ?? '',
        nextStepId: activity.nextstepid ?? 0,
        nextStepFiredBy:
          activity.nextstepfiredby ?? WorkflowStepFireType.Manual,
        onYesInfo: activity.yesInfo ?? '',
        onNoInfo: activity.noInfo ?? '',
        infomail: activity.infomail ?? false,
        comment: activity.stepcomment,
        targetUser: activity.steptargetuser,
        targetList:
          activity.steptargetlist === '*' ? undefined : activity.steptargetlist, // star means we should select the user
      };
    }

    return null;
  },
};
