import Client from '../../logic/api/Client';
import {
  AddContactToFavoritesCommand,
  RemoveContactFromFavoritesCommand,
} from '../../logic/commands';
import { isConnectionError } from '../../utils/network';
import { capitalize } from '../../utils/string';
import { Contact } from '../entitites';
import { AdditionalDataFactory } from '../factories';
import { ContactDetailModel } from '../infrastructure/+state/models';
import store from '../infrastructure/+state/store';
import ActivityService from './ActivityService';

class ContactDetailFacade {
  public get id(): number | null {
    return store.state.entities[ContactDetailModel.entity].detailId;
  }

  public get loading(): boolean {
    return store.state.entities[ContactDetailModel.entity].loadingDetail;
  }

  public get loadError(): boolean {
    return store.state.entities[ContactDetailModel.entity].loadError;
  }

  public get canEditContact(): boolean {
    return store.state.entities[ContactDetailModel.entity].canEditContact;
  }

  public get contact(): Contact | undefined {
    if (this.loading) return;

    const contact = ContactDetailModel.find(this.id ?? 0);
    if (contact) {
      if (contact.item_type !== 'CONTACT_DETAIL') return;

      return { ...contact };
    }
  }

  public async getContact(id: number) {
    const {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      caneditaddress,
      caneditcontact,
      custom,
      ...contact
    } = await new Client().api.getContact(id, true);

    return {
      caneditcontact,
      contact: {
        ...contact,
        id: contact.CID,
        icon: 'Gender' + capitalize(contact.icon ?? 'Neutral'),
        additionalData: new AdditionalDataFactory().parse(custom),
        item_type: 'CONTACT_DETAIL',
      },
    };
  }

  public async loadContact(id: number) {
    ContactDetailModel.commit((state) => {
      state.loadingDetail = true;
      state.detailId = id;
    });

    let loadError = false;
    try {
      const { caneditcontact, contact } = await this.getContact(id);

      ContactDetailModel.insert({
        data: {
          ...ContactDetailModel.find(id),
          ...contact,
        },
      });

      ContactDetailModel.commit((state) => {
        state.canEditContact = caneditcontact;
      });

      ActivityService.creationContext.contact =
        ContactDetailModel.find(id) ?? undefined;
    } catch (error) {
      loadError = isConnectionError(error);
    }

    ContactDetailModel.commit((state) => {
      state.loadingDetail = false;
      state.loadError = loadError;
    });
  }

  async toggleFavorite() {
    if (!this.contact) return;
    const isFavorite = this.contact.isfavorite;

    ContactDetailModel.update({
      where: this.contact.id,
      data: {
        isfavorite: !isFavorite,
      },
    });

    if (isFavorite) {
      new RemoveContactFromFavoritesCommand({
        id: this.contact.id,
      }).execute();
    } else {
      new AddContactToFavoritesCommand({ id: this.contact.id }).execute();
    }
  }
}

export default new ContactDetailFacade();
