













import Vue from 'vue';
import FoldersFacade from '../../../domain/application/FoldersFacade';
import { FolderItem } from '../../../domain';

export default Vue.extend({
  name: 'ModalActivityAddToFolder',
  props: {
    subject: { type: String },
    callback: {
      type: Object,
      default: () => ({
        onYes: { type: Function, default: null },
      }),
    },
  },
  data: () => ({
    error: '',
    isSubmitting: false,
    blurButtons: false,
  }),
  computed: {
    searchInput: {
      get() {
        return FoldersFacade.filterString;
      },
      set(val: string) {
        FoldersFacade.setFilter(val);
      },
    },
    myFolders() {
      return FoldersFacade.filteredItems;
    },
  },
  created() {
    FoldersFacade.setFilter('');

    // maybe they have changed, refresh in the background
    FoldersFacade.load(false);
  },
  methods: {
    async handleYesCallback(item: FolderItem) {
      // Check if we have a folder id
      if (!item?.id) {
        this.error = `${this.$t('NO_FOLDER_CHOSEN')}.`;
        return;
      }

      const { onYes } = this.callback;
      if (onYes) {
        this.isSubmitting = true;
        await onYes({ folderId: item.id, subject: this.subject });
        this.isSubmitting = false;
      }

      this.$emit('close');
    },
    resetError() {
      this.error = '';
    },
  },
});
