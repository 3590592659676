import { WorkflowStepFireType } from '../../../domain';
import ActivityService from '../../../domain/application/ActivityService';
import Client from '../../api/Client';
import ConsolidateBaseCommand from '../baseCommands/ConsolidateBaseCommand';

interface Payload {
  aktId: number;
  processId: number;
}

export default class ActivityStartProcessCommand extends ConsolidateBaseCommand<Payload> {
  constructor(payload: Payload) {
    super('ActivityStartProcessCommand', payload);
  }

  async execute() {
    try {
      const result = await new Client().api.workflowStartProcess({
        aktid: this.payload.aktId,
        processid: this.payload.processId,
        startstepid: 0,
      });

      return this.result(+result._return === 1);
    } catch (error) {
      if (this.isRetryable(error)) {
        return this.save();
      }
      return this.notOk();
    }
  }

  protected async onSuccess() {
    const newActivity = await ActivityService.loadActivity(this.payload.aktId);

    if (
      newActivity.workflow?.nextStepFiredBy === WorkflowStepFireType.Automatic
    ) {
      await ActivityService.continueWorkflow(this.payload.aktId);
    }
  }
}
