import { getIconFromMimeType } from '../../utils/attachment';
import { formatBytes } from '../../utils/file';
import { Attachment } from '../entitites';

export const AttachmentFactory = {
  create(item: {
    id?: number;
    aktid: number;
    versionId?: number;
    name: string;
    size: number;
    mimeType: string;
    thumbnail?: string;
    base64Data: string;
  }): Attachment {
    return {
      ...item,
      id: item.id ?? 0,
      thumbnail: item.thumbnail ?? '',
      sizeString: formatBytes(item.size),
      icon: getIconFromMimeType(item.mimeType),
    };
  },
};
