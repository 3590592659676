



























import Vue from 'vue';
import TaskList from '../../components/activity/list/TaskList.vue';
import TeamTasksFacade from '../../domain/application/TeamTasksFacade';
import { filterMixin } from '../../mixins/filterMixin';

export default Vue.extend({
  name: 'TeamTaskMaster',
  metaInfo() {
    const path = this.$route.path;
    const regex = /teams\/.*\//g;
    const title: string = this.title ?? '';

    if (regex.test(path)) {
      return {
        titleTemplate: `${title}  %s`,
      };
    } else {
      return {
        title: `| ${title}`,
      };
    }
  },
  components: {
    TaskList,
  },
  mixins: [filterMixin(TeamTasksFacade)],
  computed: {
    showFuture: {
      get() {
        return TeamTasksFacade.showFuture;
      },
      set(val: boolean) {
        return TeamTasksFacade.setShowFutureState(val);
      },
    },
    title(): string | undefined {
      return TeamTasksFacade.teamName;
    },
    isLoading() {
      return TeamTasksFacade.loading;
    },
    loadError(): boolean {
      return TeamTasksFacade.loadError;
    },
    items() {
      return TeamTasksFacade.items;
    },
    filteredItems() {
      return TeamTasksFacade.filteredItems;
    },
  },
  watch: {
    $route: {
      handler: function (to) {
        const { typeId } = to.params;
        TeamTasksFacade.setTeam(typeId);
      },
      immediate: true,
    },
  },
  methods: {
    reload() {
      TeamTasksFacade.load();
    },
  },
});
