



























import Vue, { PropType } from 'vue';
import { CalendarItem } from '../../domain';
import LoadingIndicator from '../loading/LoadingIndicator.vue';
import AgendaItem from './AgendaItem.vue';

export default Vue.extend({
  components: {
    AgendaItem,
    LoadingIndicator,
  },
  props: {
    events: {
      type: Array as PropType<CalendarItem[]>,
      default: () => [],
    },
    selectedDate: { type: Date as PropType<Date> },
    loading: { type: Boolean },
  },
  computed: {
    groupedEvents(): { date: string; items: CalendarItem[]; size: number }[] {
      const events = this.events.filter(
        (x) =>
          x.start.getFullYear() === this.selectedDate.getFullYear() &&
          x.start.getMonth() === this.selectedDate.getMonth()
      );

      const data = events.groupBy((x) => x.start.toISODateString());

      const entries = Object.entries(data);

      // Sort the events per day
      entries.forEach(
        (entry) =>
          (entry[1] = entry[1].sort((a: CalendarItem, b: CalendarItem) =>
            a.start < b.start ? -1 : 1
          ))
      );

      // Sort the days
      entries.sort((a, b) => (Date.parse(a[0]) < Date.parse(b[0]) ? -1 : 1));

      return entries.map((x) => ({
        date: x[0],
        items: x[1],
        size: x[1].length * 65 + 48 + 1, // Buffer
      }));
    },
  },
  watch: {
    selectedDate: {
      handler() {
        if (this.selfUpdate) {
          this.selfUpdate = false;
          return;
        }
        this.scrollToSelectedDate();
        this.reload();
      },
      immediate: true,
    },
    events: {
      handler() {
        this.scrollToSelectedDate();
      },
      immediate: true,
    },
  },
  data: () => ({
    oldStartIndex: -1,
    selfUpdate: false,
    scrolling: false,
  }),
  methods: {
    async onUpdate(startIndex: number) {
      if (
        this.oldStartIndex === startIndex ||
        this.groupedEvents.length === 0 ||
        this.scrolling
      ) {
        return;
      }
      this.oldStartIndex = startIndex;

      const startDate = new Date(
        this.groupedEvents[startIndex ? startIndex + 1 : startIndex]?.date
      );

      if (startDate.toISODateString() !== this.selectedDate.toISODateString()) {
        this.selfUpdate = true;
        this.$emit('dateSelected', startDate);
      }
    },
    prev(): void {
      this.$emit(
        'dateSelected',
        new Date(
          this.selectedDate.getFullYear(),
          this.selectedDate.getMonth() - 1,
          1
        )
      );
    },
    next(): void {
      this.$emit(
        'dateSelected',
        new Date(
          this.selectedDate.getFullYear(),
          this.selectedDate.getMonth() + 1,
          1
        )
      );
    },
    reload() {
      const from = new Date(
        this.selectedDate.getFullYear(),
        this.selectedDate.getMonth(),
        1
      );

      const to = new Date(
        this.selectedDate.getFullYear(),
        this.selectedDate.getMonth() + 1,
        0,
        23,
        59,
        59
      );

      this.$emit('reload', from, to);
    },
    scrollToSelectedDate() {
      this.scrolling = true;
      window.requestAnimationFrame(() => {
        const index = this.groupedEvents.findIndex(
          (x) =>
            new Date(x.date) >= new Date(this.selectedDate.toISODateString())
        );

        if (index >= -1) {
          this.oldStartIndex = index;
          (this.$refs.scroller as any)?.scrollToItem(index);
        }
        this.scrolling = false;
      });
    },
  },
});
