/* tslint:disable */
/* eslint-disable */
/**
 * Consolidate Kanban API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ActivityStatus,
    ActivityStatusFromJSON,
    ActivityStatusFromJSONTyped,
    ActivityStatusToJSON,
    ActivityTypeDto,
    ActivityTypeDtoFromJSON,
    ActivityTypeDtoFromJSONTyped,
    ActivityTypeDtoToJSON,
} from './';

/**
 * 
 * @export
 * @interface CardDto
 */
export interface CardDto {
    /**
     * 
     * @type {number}
     * @memberof CardDto
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof CardDto
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof CardDto
     */
    responsible: string;
    /**
     * 
     * @type {ActivityStatus}
     * @memberof CardDto
     */
    status: ActivityStatus;
    /**
     * 
     * @type {ActivityTypeDto}
     * @memberof CardDto
     */
    type: ActivityTypeDto;
}

export function CardDtoFromJSON(json: any): CardDto {
    return CardDtoFromJSONTyped(json, false);
}

export function CardDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CardDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'title': json['title'],
        'responsible': json['responsible'],
        'status': ActivityStatusFromJSON(json['status']),
        'type': ActivityTypeDtoFromJSON(json['type']),
    };
}

export function CardDtoToJSON(value?: CardDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'title': value.title,
        'responsible': value.responsible,
        'status': ActivityStatusToJSON(value.status),
        'type': ActivityTypeDtoToJSON(value.type),
    };
}


