




































































import ContactAndAddressList from '../../components/contactAndAddress/ContactAndAddressList.vue';
import ContactsFacade from '../../domain/application/ContactsFacade';
import Vue from 'vue';
import { searchMixin } from '../../mixins/searchMixin';
import { ContactOrAddressOrDivider } from '../../domain';
import { translate } from '@consolidate/shared/util-translations';

export default Vue.extend({
  name: 'ContactsAndAddressesPage',
  metaInfo: {
    titleTemplate: `${translate('CONTACTS')}  %s`,
  },
  components: {
    ContactAndAddressList,
  },
  mixins: [searchMixin(ContactsFacade.default, ContactsFacade.searchResults)],
  computed: {
    items(): ContactOrAddressOrDivider[] {
      return ContactsFacade.default.filteredItems;
    },
    isLoadingFavorites(): boolean {
      return ContactsFacade.default.loading;
    },
    favoritesLoadError(): boolean {
      return ContactsFacade.default.loadError;
    },
    canAddAddress(): boolean {
      return ContactsFacade.canAddAddress;
    },
    canAddContact(): boolean {
      return ContactsFacade.canAddContact;
    },
  },
  created(): void {
    if (!this.items.length) {
      (this as any).filterOpen = true;
    }
  },
  methods: {
    loadMore(): void {
      ContactsFacade.searchResults.loadMore();
    },
    reload(): void {
      ContactsFacade.default.load(true);
    },
  },
});
