import { NotificationService } from '@consolidate/shared/ui-components';
import { translate } from '@consolidate/shared/util-translations';
import { RRule } from 'rrule';
import ActivityEvents from '../../../domain/application/ActivityEvents';
import { mapFromRRule } from '../../../utils/recurrence/recurrence';
import Client from '../../api/Client';
import ConsolidateBaseCommand from '../baseCommands/ConsolidateBaseCommand';

interface Payload {
  actId: number;
  splitDate: Date;
  recurrence: RRule;
  skipNotification?: boolean;
}

class ChangeActivityRecurrenceCommand extends ConsolidateBaseCommand<
  Payload,
  number
> {
  constructor(payload: Payload) {
    super('ChangeActivityRecurrenceCommand', payload);
  }

  async execute() {
    try {
      const dto = mapFromRRule(this.payload.recurrence);

      const result = await new Client().activities.activitiesEditRecurrence(
        this.payload.actId,
        {
          splitDate: this.payload.splitDate,
          recurrence: {
            ...dto,
            days: this.payload.recurrence.options.byweekday?.map(
              (x) => (x + 1) % 7
            ),
          },
        }
      );

      return this.ok(result.activityId);
    } catch (error) {
      console.error(error);
      if (this.isRetryable(error)) {
        return this.save();
      }
      return this.notOk();
    }
  }

  protected async onSuccess(newAktId: number) {
    const newAkt = newAktId !== this.payload.actId;

    if (!this.payload.skipNotification) {
      NotificationService.showInfo({
        message: translate(
          newAkt ? 'RECURRENCE_CHANGED_NEW' : 'RECURRENCE_CHANGED_EXISTING',
          {
            date: this.payload.splitDate.toLocaleDateStringLS(),
          }
        ),
      });
    }

    ActivityEvents.emit('appointmentDateChanged', this.payload.actId);
    ActivityEvents.emit('activityChanged', this.payload.actId);
  }
}

export default ChangeActivityRecurrenceCommand;
