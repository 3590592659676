import { AddOrEditAddressRequest } from '@consolidate/shared/data-access-legacy-api';
import Client from '../../api/Client';
import ConsolidateBaseCommand from '../baseCommands/ConsolidateBaseCommand';

interface Payload {
  address: Omit<AddOrEditAddressRequest, 'ANR'>;
}

class AddAddressCommand extends ConsolidateBaseCommand<Payload, string> {
  constructor(payload: Payload) {
    super('AddAddressCommand', payload);
  }

  async execute() {
    try {
      const { address } = this.payload;

      const result = await new Client().api.addOrEditAddress({
        ...address,
        ANR: undefined,
        addfavorite: true,
      });

      const adrNumber = result?._return;
      return this.ok(adrNumber);
    } catch (error) {
      if (this.isRetryable(error)) {
        return this.save();
      }
      return this.notOk();
    }
  }
}

export default AddAddressCommand;
