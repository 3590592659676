/* tslint:disable */
/* eslint-disable */
/**
 * Consolidate App API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AddressDto,
    AddressDtoFromJSON,
    AddressDtoFromJSONTyped,
    AddressDtoToJSON,
    ObjectTypeDto,
    ObjectTypeDtoFromJSON,
    ObjectTypeDtoFromJSONTyped,
    ObjectTypeDtoToJSON,
} from './';

/**
 * 
 * @export
 * @interface ObjectListItemDto
 */
export interface ObjectListItemDto {
    /**
     * 
     * @type {number}
     * @memberof ObjectListItemDto
     */
    id: number;
    /**
     * 
     * @type {ObjectTypeDto}
     * @memberof ObjectListItemDto
     */
    type: ObjectTypeDto;
    /**
     * 
     * @type {string}
     * @memberof ObjectListItemDto
     */
    description?: string | null;
    /**
     * 
     * @type {AddressDto}
     * @memberof ObjectListItemDto
     */
    location?: AddressDto | null;
    /**
     * 
     * @type {string}
     * @memberof ObjectListItemDto
     */
    responsible?: string | null;
}

export function ObjectListItemDtoFromJSON(json: any): ObjectListItemDto {
    return ObjectListItemDtoFromJSONTyped(json, false);
}

export function ObjectListItemDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ObjectListItemDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'type': ObjectTypeDtoFromJSON(json['type']),
        'description': !exists(json, 'description') ? undefined : json['description'],
        'location': !exists(json, 'location') ? undefined : AddressDtoFromJSON(json['location']),
        'responsible': !exists(json, 'responsible') ? undefined : json['responsible'],
    };
}

export function ObjectListItemDtoToJSON(value?: ObjectListItemDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'type': ObjectTypeDtoToJSON(value.type),
        'description': value.description,
        'location': AddressDtoToJSON(value.location),
        'responsible': value.responsible,
    };
}


