


















import { GetAktResponseBaseObjects } from '@consolidate/shared/data-access-legacy-api';
import Vue from 'vue';
import { PropType } from 'vue';
import ObjectAutoComplete from '../../object/ObjectAutoComplete.vue';

export default Vue.extend({
  name: 'ModalActivityAddAttendee',
  components: {
    ObjectAutoComplete,
  },
  props: {
    callback: {
      type: Object as PropType<{
        onYes?: (props: {
          objects: GetAktResponseBaseObjects[];
        }) => Promise<boolean>;
      }>,
    },
  },
  computed: {},
  data() {
    return {
      isLoading: false,
      selected: undefined as GetAktResponseBaseObjects[] | undefined,
    };
  },
  methods: {
    async handleYesCallback() {
      if (!this.selected) return;
      const { onYes } = this.callback;

      let success = false;
      if (onYes) {
        this.isLoading = true;
        success = await onYes({
          objects: this.selected,
        });
        this.isLoading = false;
      }

      if (success) {
        this.$emit('close');
      }
    },
  },
});
