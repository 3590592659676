/* tslint:disable */
/* eslint-disable */
/**
 * Consolidate App API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface RemoveRecurrencesFromChainDto
 */
export interface RemoveRecurrencesFromChainDto {
    /**
     * 
     * @type {Array<Date>}
     * @memberof RemoveRecurrencesFromChainDto
     */
    exceptions: Array<Date>;
    /**
     * 
     * @type {boolean}
     * @memberof RemoveRecurrencesFromChainDto
     */
    deleteExceptions: boolean;
}

export function RemoveRecurrencesFromChainDtoFromJSON(json: any): RemoveRecurrencesFromChainDto {
    return RemoveRecurrencesFromChainDtoFromJSONTyped(json, false);
}

export function RemoveRecurrencesFromChainDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): RemoveRecurrencesFromChainDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'exceptions': json['exceptions'],
        'deleteExceptions': json['deleteExceptions'],
    };
}

export function RemoveRecurrencesFromChainDtoToJSON(value?: RemoveRecurrencesFromChainDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'exceptions': value.exceptions,
        'deleteExceptions': value.deleteExceptions,
    };
}


