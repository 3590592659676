export enum WorkFlowStepType {
  Delegation = 1,
  Decision = 2,
  CC = 3,
}

export enum WorkflowStepFireType {
  Automatic = 0,
  AutomaticWhenClosed = 1, // todo: Offene abklärung: Wir soll das im consolidate funktionieren. Akt kann nicht erledigt werden während ein prozess läuft.
  Manual = 2,
}

export interface WorkflowStep {
  type: WorkFlowStepType;
  stepName: string;
  nextStepId: number;
  nextStepFiredBy: WorkflowStepFireType;
  onYesInfo: string;
  onNoInfo: string;
  infomail: boolean;
  comment?: string;
  targetUser?: string;
  targetList?: string;
}
