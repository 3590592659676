



















import Vue, { PropType } from 'vue';
import { PrimaryAction } from '../../../logic/services/ModalService';
import BaseModalControls from '../partials/BaseModalControls.vue';

export default Vue.extend({
  name: 'ModalChoice',
  components: {
    BaseModalControls,
  },
  props: {
    canCancel: { type: Boolean, default: false },
    primaryAction: {
      type: String as PropType<PrimaryAction>,
      default: () => PrimaryAction.NO,
    },
    callback: {
      type: Object,
      default() {
        return {
          onYes: null,
        };
      },
    },
    labels: {
      type: Object,
      default() {
        return {
          yes: '',
          no: '',
        };
      },
    },
  },
  data: () => ({
    isLoading: false,
  }),
  mounted() {
    (this.$refs.form as HTMLElement)?.focus();
  },
  methods: {
    async handle(callbackName: string) {
      const callback = this.callback[callbackName];
      this.$emit('close');
      if (callback) {
        // this.isLoading = true;
        callback();
        // this.isLoading = false;
      }
    },
    async handleNo() {
      this.handle('onNo');
    },
    async handleYesCallback() {
      this.handle('onYes');
    },
  },
});
