import { translate } from '@consolidate/shared/util-translations';
import semver from 'semver';
import SettingsFacade from '../domain/application/SettingsFacade';
import ModalService from '../logic/services/ModalService';

// gets set by webpack define plugin
declare const APP_VERSION: string;
declare const APP_BUILD: string;

export function getAppVersion(): {
  version: string;
  build: string;
} {
  return {
    version: APP_VERSION,
    build: APP_BUILD,
  };
}

export function minApiVersion(version: string, throwOnError = false) {
  return function (
    // eslint-disable-next-line @typescript-eslint/ban-types
    target: Object,
    propertyKey: string | symbol,
    descriptor: PropertyDescriptor
  ) {
    const originalMethod = descriptor.value;

    descriptor.value = function (...args: any[]) {
      if (!ensureMinApiVersion(version, throwOnError)) {
        if (throwOnError) {
          throw new OutdatedApiError(version);
        }

        return null;
      }

      return originalMethod.apply(this, args);
    };
  };
}

export function ensureMinApiVersion(
  version: string,
  surpressDialog = false
): boolean {
  const minVersion = semver.coerce(version);
  if (!minVersion) throw new Error('Invalid minVersion');

  if (semver.lt(SettingsFacade.apiVersion, minVersion)) {
    if (!surpressDialog) {
      ModalService.error(
        translate('OUTDATED_NOT_AVAILABLE_DESCRIPTION', { version }),
        undefined,
        translate('OUTDATED_NOT_AVAILABLE'),
        false
      );
    }
    return false;
  }
  return true;
}

export class OutdatedApiError extends Error {
  constructor(version: string) {
    super('api is outdated. Minimum version: ' + version);
    this.name = 'OutdatedApiError';
  }
}

export function isOutdatedVersionError(
  error: unknown
): error is OutdatedApiError {
  return error instanceof OutdatedApiError;
}
