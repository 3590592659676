

















































































































































































































































import PrintContactGroup from '../shared/PrintContactGroup.vue';
import {
  Activity,
  ActivityTechnology,
  ACTIVITY_PRIORITY_LIST,
  Attendee,
} from '../../../domain';
import { areTheSameDay } from '../../../utils/date';
import Vue, { PropType } from 'vue';
import EmployeeService from 'apps/web/src/domain/application/EmployeeService';
import { GetAktMemberDefault } from '@consolidate/shared/data-access-legacy-api';
import { nestItems } from './partials/nestingHelper';
import ActivityService from 'apps/web/src/domain/application/ActivityService';
import { getLang } from '@consolidate/shared/util-translations';
import { Capacitor } from '@capacitor/core';
import { Printer } from '@awesome-cordova-plugins/printer';
import DOMPurify from 'dompurify';

export default Vue.extend({
  components: {
    PrintContactGroup,
  },
  props: {
    data: {
      type: Object as PropType<Activity>,
      required: true,
    },
  },
  data: () => ({
    body: null as string | null,
  }),
  async mounted() {
    const app = document.getElementById('app');
    app?.prepend(this.$el);

    const body = await ActivityService.getActivityComment(this.data.id);

    this.body = DOMPurify.sanitize(body, {
      IN_PLACE: true,
      FORBID_TAGS: ['meta', 'style'],
    });
  },
  destroyed() {
    // remove the element from the DOM
    // if we dont do this we get multiple copies of the element
    this.$el.parentNode?.removeChild(this.$el);
  },
  computed: {
    responsibleFullName() {
      return EmployeeService.find(this.data.responsible)?.fullName;
    },
    activityDisplayTime(): string {
      if (this.data.specializedType === ActivityTechnology.Appointment) {
        return this.data.isAllDay
          ? this.formatDateDurationPrint(
              this.data.startDateTimeUtc,
              this.data.endDateTimeUtc
            )
          : this.formatDateTimeDurationPrint(
              this.data.startDateTimeUtc,
              this.data.endDateTimeUtc
            );
      }
      return new Date(this.data.creationDate).toLongDateString();
    },
    dateInternal(): string | null {
      if (this.data.specializedType === ActivityTechnology.Appointment) {
        return null;
      }
      return new Date(this.data.dueDateTimeUtc).toLocaleDateStringLS();
    },
    priority(): string | undefined {
      return ACTIVITY_PRIORITY_LIST.find((x) => x.id === this.data?.priorityId)
        ?.name;
    },
  },
  methods: {
    async print() {
      if (Capacitor.isNativePlatform()) {
        if (await Printer.isAvailable()) {
          await Printer.print(document.body.parentElement?.outerHTML);
        }
      } else {
        window.print();
      }
    },
    formatDateDurationPrint(start: string, end: string): string {
      const startDate = new Date(start);
      const endDate = new Date(end);

      return `${startDate.toLocaleDateStringLS()} - ${endDate.toLocaleDateStringLS()}`;
    },
    formatDateTimeDurationPrint(start: string, end: string): string {
      const startDate = new Date(start);
      const endDate = new Date(end);

      const locale = getLang();

      if (areTheSameDay(start, end)) {
        return `${startDate.toLocaleDateTimeStringLS()} - ${endDate.toLocaleTimeString(
          locale,
          {
            hour: '2-digit',
            minute: '2-digit',
          }
        )}`;
      }
      return `${startDate.toLocaleDateTimeStringLS()} - ${endDate.toLocaleDateTimeStringLS()}`;
    },
    nestedData(items: GetAktMemberDefault[]) {
      return nestItems(items, this.data.addressList);
    },
    count(items: GetAktMemberDefault[]): number {
      return this.nestedData(items).filter((x) => !x.skipCount).length ?? 0;
    },
    getUserDisplay(username: string): string {
      const user = EmployeeService.find(username);

      if (user) {
        return `${user.lastName} ${user.firstName} (${user.userName})`;
      }

      return username;
    },
    getMemberDisplay(item: GetAktMemberDefault) {
      if (item.mailaddress && item.description !== item.mailaddress) {
        return `${item.description} (${item.mailaddress})`;
      }
      return item.description;
    },
    getAttendeeDisplay(item: Attendee): string {
      if (item.userName) {
        return `${item.name} (${item.userName})`;
      }

      return item.name;
    },
    activityCreationTime(): string {
      return new Date(this.data.creationDate).toLocaleDateTimeStringLS();
    },
  },
});
