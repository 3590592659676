









































































import Vue, { PropType } from 'vue';

import ModalService, {
  ActivityCreateMode,
} from '../../../../logic/services/ModalService';
import BackButton from '../../../button/BackButton.vue';
import ActivityPrintPreview from '../ActivityPrintPreview.vue';

import { Activity, ActivityTechnology } from '../../../../domain';
import ActivityDetailFacade from '../../../../domain/application/ActivityDetailFacade';
import InboxFacade from '../../../../domain/application/InboxFacade';
import SettingsFacade from '../../../../domain/application/SettingsFacade';

export default Vue.extend({
  name: 'ActivityToolbar',
  components: {
    BackButton,
    ActivityPrintPreview,
  },
  props: {
    data: { type: Object as PropType<Activity>, default: null }, // ActivityAppointment, ActEmail, ActDoc
  },
  data: () => ({
    id: null as number | null,
    EMAIL_AUTHENTICITY: true,
    showWorkflowModal: false,
  }),
  computed: {
    sidebarActions(): any {
      return [
        ...(this.data.workflow
          ? [
              {
                icon: 'ContinueWorkflow',
                name: this.$t('CONTINUE_WORKFLOW'),
                allowed: this.data.workflow,
                handler: () => this.continueWorkflow(),
              },
              {
                icon: 'RemoveCircle',
                name: this.$t('CANCEL_WORKFLOW'),
                allowed: this.data.workflow,
                handler: () => ActivityDetailFacade.cancelWorkflow(),
              },
            ]
          : []),
        {
          icon: 'Comment',
          name: this.$t('ACTION_COMMENT').toLowerCase(),
          allowed: this.isCommentingAllowed,
          handler: () => this.comment(),
        },
        ...(this.data.specializedType === ActivityTechnology.Appointment
          ? [
              this.data.onlineMeeting
                ? {
                    icon: 'OnlineMeetings',
                    name: this.$t('ONLINE_MEETING_REMOVE'),
                    allowed: true,
                    handler: () => this.removeMeeting(),
                  }
                : {
                    icon: 'OnlineMeetings',
                    name: this.$t('ONLINE_MEETING_CREATE'),
                    allowed: true,
                    handler: () => this.createMeeting(),
                  },
            ]
          : []),
        {
          icon: 'Trash',
          name: this.$t('DELETE').toLowerCase(),
          allowed: this.data.rights.delete,
          handler: () => this.deleteAct(),
        },
        ...(this.data.specializedType === ActivityTechnology.Email
          ? [
              {
                icon: 'Reply',
                name: this.$t('REPLY'),
                allowed: true,
                handler: () => this.reply('reply'),
              },
              {
                icon: 'ReplyAll',
                name: this.$t('REPLY_ALL'),
                allowed: true,
                handler: () => this.reply('reply-all'),
              },
            ]
          : []),
        {
          icon: 'Forward',
          name: this.$t('FORWARD'),
          allowed: true,
          handler: () => this.reply('forward'),
        },
        {
          icon: 'Print',
          name: this.$t('PRINT'),
          allowed: true,
          handler: () => this.printActivity(),
        },
        {
          icon: 'Edit',
          name: this.$t('EDIT_SUBJECT'),
          allowed: this.data.rights.edit,
          handler: () => ActivityDetailFacade.editSubject(),
        },
        {
          icon: 'Alarm',
          name: this.$t(
            ActivityDetailFacade.hasAlarm() ? 'EDIT_REMINDER' : 'ADD_REMINDER'
          ),
          allowed: this.data.rights.edit,
          handler: () => ActivityDetailFacade.setAlarm(),
        },
        {
          icon: 'Folder',
          name: this.$t('ADD_TO_FOLDER'),
          allowed: this.data.rights.edit,
          handler: () => ActivityDetailFacade.addActToFolder(),
        },
        {
          icon: 'FollowUp',
          name: this.$t('FOLLOW_UP_ACTIVITY'),
          allowed: true,
          handler: () =>
            ModalService.createActivity({
              previousActivity: this.data,
            }),
        },
      ];
    },
    isInbox(): boolean {
      return this.$route.name?.includes('inbox') ?? false;
    },
    isCommentingAllowed(): boolean {
      // For Appointments and Documents data.rights.edit is sufficent
      if (this.data.specializedType !== 'EMAIL') return this.data.rights.edit;

      /**
       * For E-Mail however we have to check if the system setting
       * for authenticity is disabled (commenting allowed)
       */
      return this.EMAIL_AUTHENTICITY === false && this.data.rights.edit;
    },
  },
  async created() {
    if (this.data) this.id = this.data.id;
    if (!this.data) this.id = +this.$route.params.id;
    // Get settings (email authenticity enabled?)
    this.EMAIL_AUTHENTICITY = SettingsFacade.settings.EMAIL_AUTHENTICITY;
  },
  methods: {
    printActivity() {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (this.$refs.printPreview as any).print();
    },
    continueWorkflow() {
      ActivityDetailFacade.continueWorkflow();
    },
    async setActAsRead() {
      if (await InboxFacade.setActAsRead(this.data.id)) {
        this.redirectToListViewIfNeeded();
      }
    },
    async deleteAct() {
      if (await ActivityDetailFacade.deleteAct()) {
        this.redirectToListViewIfNeeded();
      }
    },
    reply(mode: string) {
      ModalService.replyActivity(
        mode.toUpperCase()?.replace(/-/g, '_') as ActivityCreateMode,
        this.data
      );
    },
    redirectToListViewIfNeeded() {
      this.$router.back();
    },
    comment() {
      ActivityDetailFacade.commentActivity();
    },
    createMeeting() {
      if (this.data.specializedType !== ActivityTechnology.Appointment) return;
      ActivityDetailFacade.createOnlineMeeting();
    },
    removeMeeting() {
      if (this.data.specializedType !== ActivityTechnology.Appointment) return;
      ActivityDetailFacade.removeOnlineMeeting();
    },
  },
});
