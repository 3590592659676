











































import Vue from 'vue';
import { PropType } from 'vue';
import { User } from '../../../domain';
import EmployeeService from '../../../domain/application/EmployeeService';

export default Vue.extend({
  name: 'ModalActivitySendCCs',
  props: {
    latestInternalCCs: { type: Array as PropType<string[]> },
    initialComment: { type: String },
    isNew: { type: Boolean },
    callback: {
      type: Object as PropType<{
        onYes?: (props: {
          receivers: string[];
          comment: string;
        }) => Promise<any>;
      }>,
    },
  },
  data() {
    return {
      selectedUsers: [] as User[],
      comment: this.initialComment,
      isLoading: false,
    };
  },
  computed: {
    users(): User[] {
      return EmployeeService.users;
    },
  },
  created() {
    this.selectedUsers = EmployeeService.items.filter((item) =>
      this.latestInternalCCs.includes(item.userName)
    );
  },
  methods: {
    filter(item: User, queryText: string) {
      const query = queryText.toLocaleLowerCase();
      return (
        item.fullName.toLocaleLowerCase().indexOf(query) > -1 ||
        item.userName.toLocaleLowerCase().indexOf(query) > -1
      );
    },
    async handleYesCallback() {
      const { onYes } = this.callback;

      if (onYes) {
        this.isLoading = true;
        await onYes({
          receivers: this.selectedUsers.map((x) => x.userName),
          comment: this.comment,
        });
        this.isLoading = false;
      }

      this.$emit('close');
      if (this.isNew) return;
    },
  },
});
