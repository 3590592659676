import { NotificationService } from '@consolidate/shared/ui-components';
import Vue from 'vue';

export default Vue.extend({
  created() {
    document.addEventListener('swUpdated', this.updateAvailable, {
      once: true,
    });

    if (navigator.serviceWorker) {
      navigator.serviceWorker.addEventListener('controllerchange', () => {
        window.location.reload();
      });
    }
  },
  methods: {
    async updateAvailable(event: Event) {
      const result = await NotificationService.showSuccess({
        message: this.$t('UPDATE_NEW_VERSION'),
        buttonText: this.$t('UPDATE_RELOAD'),
        timeout: -1,
      }).getResult();

      if (result.buttonClicked) {
        const registration = (event as CustomEvent<ServiceWorkerRegistration>)
          .detail;

        if (!registration || !registration.waiting) return;
        registration.waiting.postMessage({ type: 'SKIP_WAITING' });
      }
    },
  },
});
