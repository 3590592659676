var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"overflow-hidden",attrs:{"fluid":""}},[_c('v-calendar',{ref:"calendar",attrs:{"locale":_vm.locale,"show-week":"","event-overlap-mode":"column","type":_vm.type,"events":_vm.displayedEvents,"weekdays":[1, 2, 3, 4, 5, 6, 0],"locale-first-day-of-year":4,"weekday-format":_vm.type !== 'month' ? function () { return ''; } : undefined,"interval-height":_vm.scaleIntervals ? 48 * 2 : 48,"event-overlap-threshold":59},on:{"click:day":_vm.dayClicked,"change":_vm.dateChanged,"touchstart:time":_vm.touchStart,"mousedown:event":_vm.startEvent,"mousedown:time":_vm.dragStart,"mousemove:time":_vm.dragMove,"mouseup:time":_vm.dragEnd},nativeOn:{"mouseleave":function($event){return _vm.dragCancel.apply(null, arguments)}},scopedSlots:_vm._u([{key:"day-body",fn:function(interval){return [_c('div',{class:_vm.getClassForInterval(interval),staticStyle:{"position":"absolute","top":"0","left":"0","right":"0","bottom":"0"}}),(interval.present)?_c('div',{staticClass:"v-current-time",style:({ top: _vm.nowY })}):_vm._e()]}},{key:"day",fn:function(interval){return [_c('div',{class:_vm.getClassForInterval(interval),staticStyle:{"position":"absolute","top":"0","left":"0","right":"0","bottom":"0"}})]}},{key:"day-label",fn:function(ref){
var day = ref.day;
var month = ref.month;
var present = ref.present;
return [_c('span',{staticClass:"overflow-hidden text-no-wrap text-truncate",class:{ 'text-decoration-underline': present },staticStyle:{"font-size":"14px","text-decoration-thickness":"2px !important"}},[(day === 1)?_c('span',{staticClass:"text-truncate",domProps:{"textContent":_vm._s(_vm.getMonth(month))}}):_vm._e(),_vm._v(" "+_vm._s(day)+" ")])]}},{key:"day-label-header",fn:function(ref){
var day = ref.day;
var weekday = ref.weekday;
var present = ref.present;
return [_c('span',{staticClass:"mb-8",class:{ 'text-decoration-underline': present }},[_vm._v(" "+_vm._s(_vm.translateDayOfWeek(weekday))+" "+_vm._s(day)+" ")])]}},{key:"event",fn:function(ref){
var event = ref.event;
var timeSummary = ref.timeSummary;
var singline = ref.singline;
var day = ref.day;
return [_c('div',{staticClass:"overflow-hidden text-truncate pr-1",class:{
          open: event.isAppointment && event.status === 0,
          'pl-2': event.isAppointment,
          'mx-1': !event.isAppointment,
        },style:({
          height: '100%',
          position: 'relative',
          color: _vm.getContrastColor(event.color),
          'font-size': _vm.type === 'month' && '12px',
          'pointer-events': _vm.type === 'month' ? 'none' : 'auto',
        }),on:{"click":function($event){$event.stopPropagation();return _vm.eventClicked({ event: event, day: day })}}},[(event.blocktype === 1)?_c('div',{staticClass:"non-blocking-event-indicator"}):_vm._e(),_c('div',{staticClass:"d-inline-flex align-center",class:{
            'wrap-text mt-1':
              _vm.scaleIntervals || (!singline && _vm.type !== 'month'),
          }},[_c('span',[(_vm.type !== 'month' && event.icon)?_c('cs-icon',{staticClass:"mr-1",staticStyle:{"padding-bottom":"2px"},attrs:{"x-small":"","icon":event.icon,"color":_vm.getContrastColor(event.color)}}):(event.isRecurring)?_c('cs-icon',{staticClass:"mr-1",attrs:{"x-small":"","icon":"Repetitive","color":_vm.getContrastColor(event.color)}}):_vm._e(),_c('span',{staticClass:"mr-1",domProps:{"textContent":_vm._s(event.name)}}),(event.isRecurring && _vm.type !== 'month')?_c('cs-icon',{staticClass:"mr-1",attrs:{"x-small":"","icon":"Repetitive","color":_vm.getContrastColor(event.color)}}):_vm._e(),(event.timed && _vm.type === 'month')?_c('span',{domProps:{"textContent":_vm._s(timeSummary())}}):_vm._e()],1)])])]}}]),model:{value:(_vm.internalSelectedDate),callback:function ($$v) {_vm.internalSelectedDate=$$v},expression:"internalSelectedDate"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }