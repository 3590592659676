import { translate } from '@consolidate/shared/util-translations';
import { Frequency, RRule, RRuleSet } from 'rrule';

export class RRuleSerializer {
  private rule: RRule;

  private dayOptions = ['MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT', 'SUN'];
  private monthOptions = [
    'JANUARY',
    'FEBRUARY',
    'MARCH',
    'APRIL',
    'MAY',
    'JUNE',
    'JULY',
    'AUGUST',
    'SEPTEMBER',
    'OCTOBER',
    'NOVEMBER',
    'DECEMBER',
  ];

  constructor(private ruleSet: RRuleSet) {
    this.rule = ruleSet.rrules()[0];
  }

  public serialize(): string {
    let text = this.serializeRule();

    if (this.ruleSet.exdates().length > 0) {
      text += ` | ${translate('MANIPULATED')}`;
    }

    return text;
  }

  private serializeRule(): string {
    switch (this.rule.options.freq) {
      case Frequency.DAILY:
        return this.daily();
      case Frequency.WEEKLY:
        return this.weekly();
      case Frequency.MONTHLY:
        return this.monthly();
      case Frequency.YEARLY:
        return this.yearly();
      default:
        return translate('UNKNOWN_RECURRENCE');
    }
  }

  private days(): string | undefined {
    if (this.rule.options.byweekday) {
      const days = [...this.rule.options.byweekday]
        ?.sort() // Consolidate requires the days to be sorted. Otherwise the recurrance is not processed correctly.
        .map((weekday) => translate(this.dayOptions[weekday]))
        .join(' ');

      return days;
    }
    return undefined;
  }

  private month(): string[] | undefined {
    if (this.rule.options.bymonth) {
      const month = this.rule.options.bymonth.map((month) =>
        translate(this.monthOptions[month - 1])
      );
      return month;
    }
    return undefined;
  }

  private setPosition(): string | number[] | undefined {
    if (this.rule.options.bysetpos) {
      const setPosition =
        this.rule.options.bysetpos[0] === -1
          ? `${translate('LAST')}.`
          : `${this.rule.options.bysetpos}.`;

      return setPosition;
    }
    return undefined;
  }

  private daily(): string {
    if (this.rule.options.interval > 1) {
      return translate('EVERY_X_DAYS', { days: this.rule.options.interval });
    }
    return translate('DAILY');
  }

  private weekly(): string {
    if (this.rule.options.interval > 1) {
      return `${translate('EVERY_X_WEEKS', {
        weeks: this.rule.options.interval,
      })} ${this.days()}`;
    }
    return `${translate('WEEKLY')} ${this.days()}`;
  }

  private monthly(): string {
    if (this.rule.options.bymonthday.length > 0) {
      if (this.rule.options.interval > 1) {
        return `${translate('EVERY_X_MONTHS', {
          months: this.rule.options.interval,
        })} ${translate('ON')} ${this.rule.options.bymonthday}.`;
      }
      return `${translate('MONTHLY')} ${translate('ON')} ${
        this.rule.options.bymonthday
      }.`;
    } else if (this.rule.options.byweekday.length > 0) {
      if (this.rule.options.interval > 1) {
        return `${translate('EVERY_X_MONTHS', {
          months: this.rule.options.interval,
        })} ${translate('ON')} ${this.setPosition()} ${this.days()}`;
      }
      return `${translate('MONTHLY')} ${translate(
        'ON'
      )} ${this.setPosition()} ${this.days()}`;
    }
    return translate('UNKNOWN_RECURRENCE');
  }

  private yearly(): string {
    if (this.rule.options.bymonthday.length > 0) {
      if (this.rule.options.interval > 1) {
        return `${translate('EVERY_X_YEARS', {
          years: this.rule.options.interval,
        })} ${this.rule.options.bymonthday}. ${this.month()}.`;
      }
      return `${translate('YEARLY')} ${
        this.rule.options.bymonthday
      }. ${this.month()}`;
    } else if (this.rule.options.byweekday.length > 0) {
      if (this.rule.options.interval > 1) {
        return `${translate('EVERY_X_YEARS', {
          years: this.rule.options.interval,
        })} ${translate(
          'ON'
        )} ${this.setPosition()} ${this.days()} ${this.month()}`;
      }
      return `${translate('YEARLY')} ${translate('ON')} ${this.setPosition()} 
        ${this.days()} ${this.month()}`;
    }
    return translate('UNKNOWN_RECURRENCE');
  }
}
