import { AddOrRemoveClientRequest } from '@consolidate/shared/data-access-legacy-api';
import { NotificationService } from '@consolidate/shared/ui-components';
import { translate } from '@consolidate/shared/util-translations';
import Client from '../../api/Client';
import ConsolidateBaseCommand from '../baseCommands/ConsolidateBaseCommand';

export default class RemoveActivityClientCommand extends ConsolidateBaseCommand<AddOrRemoveClientRequest> {
  constructor(payload: AddOrRemoveClientRequest) {
    super('RemoveActivityClientCommand', payload);
  }

  async execute() {
    try {
      await new Client().api.removeClient(this.payload);

      return this.ok();
    } catch (error) {
      if (this.isRetryable(error)) {
        return this.save();
      }
      return this.notOk();
    }
  }

  protected onError() {
    NotificationService.showError({
      message: translate('COULDNT_CHANGE_ACTIVITY'),
    });
  }
}
