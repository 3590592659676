









import Vue from 'vue';
export default Vue.extend({
  props: {
    active: { type: Boolean },
    value: { type: Boolean },
    icon: { type: String, default: 'Filter' },
  },
  methods: {
    setValue(val: boolean) {
      this.$emit('input', val);
    },
  },
});
