import { Model, Query } from '@vuex-orm/core';
import {
  FindActivitiesQuery,
  GetActivitiesFromQuery,
} from '../../logic/queries';
import { ActivityListItem } from '../entitites';
import {
  ActivityModel,
  ProjectActivitiesModel,
  ProjectModel,
} from '../infrastructure/+state/models';
import store from '../infrastructure/+state/store';
import { SearchType } from './ActivitySearchFacade';
import { ListFacade } from './ListFacade';

class ProjectActivitiesFacade extends ListFacade<
  ProjectActivitiesModel,
  ActivityListItem
> {
  protected entity = ProjectActivitiesModel.entity;

  protected filterProperties: (keyof ActivityListItem)[] = [
    'subject',
    'contact',
    'summary',
  ];

  protected map(item: ProjectActivitiesModel): ActivityListItem {
    return {
      uid: item.id,
      ...item.activity,
    };
  }

  protected baseFilter(query: Query<Model>): Query<Model> {
    return query.orderBy('sortOrder');
  }

  protected async getItems(
    page = 0
  ): Promise<Record<string, unknown>[] | undefined> {
    if (!this.projectId) return [];

    let query;
    if (!this.search) {
      query = new GetActivitiesFromQuery({
        type: SearchType.Project,
        id: this.projectId,
        page: page,
      });
    } else {
      query = new FindActivitiesQuery({
        searchTerm: this.search,
        project: this.projectId.toString(),
        page: page,
      });
    }

    const response = await query.execute();

    ProjectActivitiesModel.commit((state) => {
      state.total = response.total;
      state.hasMore = response.hasMore;
      state.page = page;
    });

    let sortOrder = ProjectActivitiesModel.all().length;
    return response.items.map((x) => ({
      id: x.id,
      sortOrder: ++sortOrder,
      activity: {
        ...ActivityModel.find(x.id),
        ...x,
      },
    }));
  }

  public async loadMore() {
    if (!this.hasMore || this.loading) return;

    ProjectActivitiesModel.commit((state) => {
      state.loading = true;
    });

    try {
      const items = await this.getItems(this.page + 1);
      if (items) {
        ProjectActivitiesModel.insertOrUpdate({
          data: items,
        });
      }
    } catch {
      // when offline, dont load more items
    }

    ProjectActivitiesModel.commit((state) => {
      state.loading = false;
    });
  }

  public get search(): string {
    return store.state.entities[ProjectActivitiesModel.entity].search;
  }

  public get projectId(): number | undefined {
    return store.state.entities[ProjectActivitiesModel.entity].project;
  }

  public get total(): string {
    return store.state.entities[ProjectActivitiesModel.entity].total;
  }

  public get page(): number {
    return store.state.entities[ProjectActivitiesModel.entity].page;
  }

  public get hasMore(): string {
    return store.state.entities[ProjectActivitiesModel.entity].hasMore;
  }

  public get projectName(): string | undefined {
    if (!this.projectId) return undefined;

    return ProjectModel.find(this.projectId)?.name;
  }

  public setProject(id: number) {
    ProjectActivitiesModel.deleteAll();

    ProjectActivitiesModel.commit((state) => {
      state.project = id;
      state.search = '';
    });
    this.load();
  }

  public setSearch(val: string) {
    ProjectActivitiesModel.commit((state) => {
      state.search = val;
    });
    this.load();
  }
}

export default new ProjectActivitiesFacade();
