import { translate } from '@consolidate/shared/util-translations';

export interface ActivityTodoType {
  id: number;
  identifier:
    | 'TODO'
    | 'WAITING'
    | 'PRIVATES'
    | 'LITERATURE'
    | 'PERMANENT'
    | 'DONE';
  name: string;
}

export const ACTIVITY_TODO_TYPE_DONE: ActivityTodoType = {
  id: 5,
  identifier: 'DONE',
  name: translate('ACTIVITY_STATUS_DONE'),
};

export const ACTIVITY_TODO_TYPE_LIST: ActivityTodoType[] = [
  {
    id: 0,
    identifier: 'TODO',
    name: translate('TASKS_TODO'),
  },
  {
    id: 1,
    identifier: 'WAITING',
    name: translate('TASKS_WAITING'),
  },
  {
    id: 2,
    identifier: 'LITERATURE',
    name: translate('TASKS_LITERATURE'),
  }, // Reading material
  {
    id: 3,
    identifier: 'PERMANENT',
    name: translate('TASKS_PERMANENT'),
  }, // Permanent document
  {
    id: 4,
    identifier: 'PRIVATES',
    name: translate('TASKS_PRIVATES'),
  },
  ACTIVITY_TODO_TYPE_DONE,
];
