import { render, staticRenderFns } from "./ProjectDetail.vue?vue&type=template&id=02b6bf14&scoped=true&"
import script from "./ProjectDetail.vue?vue&type=script&lang=ts&"
export * from "./ProjectDetail.vue?vue&type=script&lang=ts&"
import style0 from "./ProjectDetail.vue?vue&type=style&index=0&id=02b6bf14&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "02b6bf14",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabItem } from 'vuetify/lib/components/VTabs';
installComponents(component, {VBtn,VCol,VTab,VTabItem})
