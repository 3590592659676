import {
  GetAktMemberDefault,
  GetAktResponse,
  GetAktResponseAppointment,
  GetAktResponseDocument,
  GetAktResponseEmail,
  GetthumbpicturesResponse,
} from '@consolidate/shared/data-access-legacy-api';
import { normalizeColor } from '@consolidate/shared/ui-components';
import * as Sentry from '@sentry/capacitor';
import { AdditionalDataFactory, WorkflowStepFactory } from '.';
import { GetOnlineMeetingResponse } from '../../logic/api/gen';
import { resolveIconId } from '../../utils/activity/icon';
import ConfidentialitiesService from '../application/ConfidentialitiesService';
import {
  ACTIVITY_TODO_TYPE_LIST,
  Activity,
  ActivityTechnology,
} from '../entitites';
import { ActivityAppointment } from '../entitites/activity/ActivityAppointment';
import { ActivityDocument } from '../entitites/activity/ActivityDocument';
import { ActivityEmail } from '../entitites/activity/ActivityEmail';

/**
 * Exposes helper functions to map Consolidate API responses to internal models
 */
export const ActivityFactory = {
  getFromConsolidate(
    consAct: GetAktResponse,
    consAtts: GetthumbpicturesResponse,
    meeting?: GetOnlineMeetingResponse
  ): Activity {
    const mappedData = {
      id: consAct.aktid,
      subject: consAct.subject,
      responsible: consAct.resp,
      actType: {
        id: consAct.akttype,
        name: consAct.aktname,
        technology: consAct.type,
        allowdocs: consAct.addAttachment,
        allowobjects: consAct.allowobjects,
        color: normalizeColor(consAct.color),
        icon: resolveIconId(consAct.icon),
      },
      latestInternalCCs: (consAct.internalCCs || [])?.map((cc: any) => cc.uid),
      icon: resolveIconId(consAct.icon),
      iconColor: normalizeColor(consAct.color),
      previousActivity: consAct.prevakt !== 0 ? consAct.prevakt : null,
      hasFollowingActivities: consAct.hasfollowakt,
      todoType: this.getTodoTypeFromConsolidateId(consAct.statusid),
      specializedType: consAct.type,
      attachments: (consAct.attachments || []).map((a: any) => ({
        id: a.attid,
        aktid: a.aktid,
        versionId: a.versionid,
        name: a.attname,
        size: a.size,
        mimeType: a.mimetype,
        thumbnail: '', // Gets overriden if a thumbnail/picture is present
        icon: a.icon,
        sizeString: a.sizeString,
      })),
      alarmDateTimeUtc:
        consAct.alarmtimeutc && consAct.alarmtimeutc.replace('Z', '.000Z'),
      rights: {
        edit: consAct.rights.edit,
        delegate: consAct.rights.dispo,
        delete: consAct.rights._delete,
      },
      priorityId: +consAct.priority,
      confidentiality: ConfidentialitiesService.find(consAct.secret),
      createdBy: consAct.createdby,
      creationDate: consAct.creationdate,
      clients: consAct.members.clients?.map(this.mapMember),
      addressList: consAct.members.addresslist?.map(this.mapMember),
    } as Partial<Activity>;

    mappedData.workflow = WorkflowStepFactory.create(consAct);

    // add objects
    mappedData.allowobjects = consAct.allowobjects;
    mappedData.objects = consAct.objects;

    // Additional data / Zusatszdaten
    try {
      mappedData.additionalData = new AdditionalDataFactory().parse(
        consAct.custom
      );
    } catch (error) {
      console.error('Additional data error', error);
      Sentry.captureException(error);
    }

    // Add project if I am allowed to see it and if it has data
    const hasProject = consAct.projectvisible && consAct.projectname;
    if (hasProject) {
      mappedData.project = {
        id: consAct.projectid,
        name: consAct.projectname ?? '',
      };
    }

    // Check if activity was delegated
    if (consAct.workflowInfo) {
      // Structure: 'xx: Check out this text!'
      const { workflowInfo } = consAct;
      const indexOfNameSeparator = workflowInfo.indexOf(':');
      const by = workflowInfo.substring(0, indexOfNameSeparator);
      const comment = workflowInfo.substring(
        by.length + 2,
        workflowInfo.length
      );
      mappedData.delegated = {
        by,
        dateTime: `${consAct.workflowinfotimestamp}Z`, // UTC will come one day!
        comment: `${by.toUpperCase()}: ${comment}`,
      };
    }

    // Do we have attachments?
    if (
      consAtts &&
      consAtts.items &&
      consAtts.items.length &&
      mappedData.attachments
    ) {
      const allAttIds = mappedData.attachments.map((a) => a.id);

      // Reattach thumbnails to their matching attIds
      consAtts.items.forEach((att: any) => {
        const allAttIndex = allAttIds.indexOf(att.id);

        /**
         * Check if the item is present and if it has data present. 23 is the length
         * of the encoding string's start ('data:image/jpeg;base64,')
         */
        if (
          typeof allAttIndex !== 'undefined' &&
          att.picture.length > 23 &&
          mappedData.attachments
        ) {
          mappedData.attachments[allAttIndex].thumbnail = att.picture;
        }
      });
    }

    if (
      this.isDocument(consAct) &&
      mappedData.specializedType === ActivityTechnology.Document
    ) {
      mappedData.dueDateTimeUtc = new Date(consAct.date).toISOString();

      // Assemble contact / address info
      mappedData.contacts = consAct.members.participants?.map(this.mapMember);

      return mappedData as ActivityDocument;
    }

    if (
      this.isAppointment(consAct) &&
      mappedData.specializedType === ActivityTechnology.Appointment
    ) {
      mappedData.startDateTimeUtc = consAct.startdatetimeutc;
      mappedData.endDateTimeUtc = consAct.enddatetimeutc;
      mappedData.alarmTime = parseInt(consAct.alarmtime ?? '-1');

      const attendees = (consAct.members.attendees || []).map((a) => ({
        userName: a.uid,
        cid: a.cid,
        anr: a.anr,
        name: a.description ?? '',
        email: a.mailaddress,
        exclusionDates: a.exclusiondates,
        confirmationStatus: a.confirmationstatus ?? 0,
        confirmationDate:
          typeof a.confirmation === 'string' ? `${a.confirmation}Z` : undefined,
      }));

      mappedData.attendeesInternal = attendees.filter((x) => !!x.userName);
      mappedData.attendeesExternal = attendees.filter((x) => !x.userName);

      mappedData.reservations = consAct.members.reservations;

      mappedData.isAllDay = consAct.wholeday;

      mappedData.shouldSendReply = consAct.shouldsendreply;

      mappedData.organizer = consAct.organizer;

      mappedData.recurrence = consAct.recurrence;

      mappedData.onlineMeeting = meeting?.meeting;

      return mappedData as ActivityAppointment;
    }

    if (
      this.isEmail(consAct) &&
      mappedData.specializedType === ActivityTechnology.Email
    ) {
      mappedData.dueDateTimeUtc = new Date(consAct.date).toISOString();

      mappedData.from = (consAct.members.from?.map(this.mapMember) ?? [])[0];
      mappedData.to = consAct.members.to?.map(this.mapMember) ?? [];
      mappedData.cc = consAct.members.cc?.map(this.mapMember) ?? [];
      mappedData.bcc = consAct.members.bcc?.map(this.mapMember) ?? [];

      return mappedData as ActivityEmail;
    }

    return mappedData as Activity;
  },

  mapMember(mem: GetAktMemberDefault) {
    return {
      ...mem,
      mailaddress: mem.mailaddress?.replace(/'/g, ''), // Sometimes includes quotes in quotes
      description: mem.description?.replace(/'/g, ''), // Can be a name, can be an address, everything
    };
  },

  getTechnologyFromAktSAkt(aktsakt: number): ActivityTechnology {
    switch (aktsakt) {
      case 12: // Outgoing mail
      case 13: // Incoming mail
        return ActivityTechnology.Email;
      case 4:
        return ActivityTechnology.Appointment;
      default:
        return ActivityTechnology.Document; // 9 for 'Interne Mitteilung'; Default in current App
    }
  },

  getAktSAktFromTechnology(technology: ActivityTechnology) {
    switch (technology) {
      case ActivityTechnology.Email:
        return 12; // As we only send outgoing mails from the client
      case ActivityTechnology.Appointment:
        return 4;
      default:
        return 9; // Document in our case, 'Interne Mitteilung' in Consi
    }
  },

  getTodoTypeFromConsolidateId(id = 0) {
    return ACTIVITY_TODO_TYPE_LIST.find((t) => t.id === +id);
  },

  isEmail(arg: GetAktResponse): arg is { type: 'EMAIL' } & GetAktResponseEmail {
    return arg.type === 'EMAIL';
  },

  isAppointment(
    arg: GetAktResponse
  ): arg is { type: 'APPOINTMENT' } & GetAktResponseAppointment {
    return arg.type === 'APPOINTMENT';
  },

  isDocument(
    arg: GetAktResponse
  ): arg is { type: 'DOCUMENT' } & GetAktResponseDocument {
    return arg.type === 'DOCUMENT';
  },
};
