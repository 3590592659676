






















import Vue, { PropType } from 'vue';

export default Vue.extend({
  props: {
    value: { type: Array as PropType<unknown[]>, default: () => [] },
    label: { type: String },
    subtitle: { type: String },
    display: { type: [String, Function] },
    displaySubtitle: { type: [String, Function] },
    count: { type: Number },
  },
  methods: {
    getDisplay(item: Record<string, unknown>) {
      if (typeof this.display === 'string') {
        return item[this.display];
      }
      return this.display(item);
    },
    getDisplaySubtitle(item: Record<string, unknown>) {
      if (typeof this.displaySubtitle === 'string') {
        return item[this.displaySubtitle];
      }
      return this.displaySubtitle(item);
    },
  },
});
