import { ListModel } from './listModel';

export class TeamModel extends ListModel {
  static entity = 'teams';

  id!: string;
  name!: string;

  static fields() {
    return {
      id: this.string(0),
      name: this.string(''),
    };
  }
}
