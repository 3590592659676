import { ListModel } from './listModel';

export class FolderModel extends ListModel {
  static entity = 'folders';

  id!: number;
  name!: string;
  icon!: number;

  static fields() {
    return {
      id: this.number(0),
      name: this.string(''),
      icon: this.string(''),
    };
  }
}
