import { NotificationService } from '@consolidate/shared/ui-components';
import { translate } from '@consolidate/shared/util-translations';
import Client from '../../api/Client';
import ConsolidateBaseCommand from '../baseCommands/ConsolidateBaseCommand';

interface Payload {
  actId: number;
  subject: string;
}

class SetSubjectCommand extends ConsolidateBaseCommand<Payload> {
  constructor(payload: Payload) {
    super('SetSubjectCommand', payload);
  }

  async execute() {
    try {
      const { actId, subject } = this.payload;

      const data = await new Client().api.setSubject({
        aktid: actId,
        subject,
      });
      const success = data._return === 'TRUE';

      return this.result(success);
    } catch (error) {
      if (this.isRetryable(error)) {
        return this.save();
      }
      return this.notOk();
    }
  }

  protected onError() {
    NotificationService.showError({
      message: translate('COULDNT_CHANGE_ACTIVITY'),
    });
  }
}

export default SetSubjectCommand;
