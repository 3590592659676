





















import Vue, { PropType } from 'vue';
import { GetAktMemberDefault } from '@consolidate/shared/data-access-legacy-api';
import ContactGroup from '../../shared/ContactGroup.vue';
import { Nested, nestItems } from './nestingHelper';
import { ensureMinApiVersion } from 'apps/web/src/utils/versioning';
import { translate } from '@consolidate/shared/util-translations';
import { AuthService } from '@consolidate/shared/util-auth';
import { Permission } from 'libs/shared/util-auth/src/lib/models/permission';

export default Vue.extend({
  components: { ContactGroup },
  props: {
    value: { type: Array as PropType<GetAktMemberDefault[]> },
    addressList: { type: Array as PropType<GetAktMemberDefault[]> },
    label: { type: String, required: true },
    editable: { type: Boolean, default: false },
    email: { type: Boolean, default: false },
  },
  computed: {
    nestedData() {
      return nestItems(this.value, this.addressList);
    },
    count(): number {
      return this.nestedData?.filter((x) => !x.skipCount).length ?? 0;
    },
  },
  methods: {
    getMemberDisplay(item: GetAktMemberDefault) {
      return item.description;
    },
    getMemberSubtitle(item: GetAktMemberDefault) {
      if (item.mailaddress && item.mailaddress !== item.description) {
        return item.mailaddress;
      }

      return '';
    },
    getMemberCaption(item: GetAktMemberDefault) {
      if (
        !this.email ||
        !ensureMinApiVersion('5.2022', true) ||
        !item.mailsenddatetime
      )
        return undefined;

      switch (item.mailstatus) {
        case 2:
          return translate('CONTACTS_EMAIL_SENT', {
            date: new Date(item.mailsenddatetime ?? '').toLocaleString(),
          });

        case 0:
        case 1:
        case 4:
        case 5:
        case 55:
          return translate('CONTACTS_EMAIL_WILL_BE_SENT', {
            date: new Date(item.mailsenddatetime ?? '').toLocaleString(),
          });

        case 3:
        case 10:
        case 11:
          return translate('CONTACTS_EMAIL_DRAFT');

        case 90:
        case 91:
        case 92:
          return translate('CONTACTS_EMAIL_WONT_BE_SENT');

        case 93:
        case 94:
        case 95:
          return translate('CONTACTS_EMAIL_DRAFT_DISCARDED');

        case 96:
        case 97:
        case 98:
        case 99:
          return translate('CONTACTS_EMAIL_ERROR', { error: item.mailstatus });

        default:
          return undefined;
      }
    },
    getMemberCaptionError(item: GetAktMemberDefault) {
      return item.mailstatus && item.mailstatus >= 96;
    },
    getMemberLink(item: GetAktMemberDefault) {
      const user = AuthService.getUser();
      if (item.cid && user?.permissions.includes(Permission.ViewPersonList)) {
        return {
          name: 'contact-detail',
          params: { typeId: item.cid },
        };
      }

      if (item.anr && user?.permissions.includes(Permission.ViewAddressList)) {
        return {
          name: 'address-detail',
          params: { typeId: item.anr },
        };
      }
    },
    getMemberIcon(item: GetAktMemberDefault) {
      if (item.cid) {
        return 'GenderNeutral';
      }

      if (item.anr) {
        return 'Address';
      }

      if (item.uid) {
        return 'User';
      }

      return 'UnknownEmailAddress';
    },
    canRemove(item: Nested<GetAktMemberDefault>) {
      if (item.skipCount) {
        return false;
      }
      return true;
    },
    add() {
      this.$emit('add');
    },
    remove(index: number) {
      this.$emit('remove', this.nestedData[index]);
      this.$emit('input', [
        ...this.nestedData.slice(0, index),
        ...this.nestedData.slice(index + 1),
      ]);
    },
  },
});
