<template>
  <div>
    <v-list-item class="pr-0" @click="open">
      <v-list-item-icon>
        <img
          v-if="attachment.thumbnail"
          :src="attachment.thumbnail"
          :style="{
            maxHeight: '80px',
            maxWidth: '80px',
          }"
        />
        <cs-icon v-else>File</cs-icon>
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title>{{ attachment.name }}</v-list-item-title>
        <v-list-item-subtitle>{{
          formatSize(attachment.size)
        }}</v-list-item-subtitle>
      </v-list-item-content>
      <v-list-item-action>
        <v-row dense>
          <v-col>
            <v-btn icon @click="download" v-if="!isLoading">
              <cs-icon>Download</cs-icon>
            </v-btn>
            <v-progress-circular
              v-else
              :value="progress"
              :indeterminate="indeterminate"
            />
          </v-col>
          <v-col v-if="canEdit">
            <v-btn icon @click="remove" v-if="!isRemoving">
              <cs-icon>RemoveCircle</cs-icon>
            </v-btn>
            <v-progress-circular v-else indeterminate />
          </v-col>
        </v-row>
      </v-list-item-action>
    </v-list-item>
  </div>
</template>

<script>
import { GetAttachmentQuery } from '../../logic/queries';
import ModalService from '../../logic/services/ModalService';
import FileService from '../../logic/services/FileService';
import ActivityDetailFacade from '../../domain/application/ActivityDetailFacade';
import { formatBytes } from '../../utils/file';
import { NotificationService } from '../../../../../libs/shared/ui-components/src';
import { translate } from '../../../../../libs/shared/util-translations/src';

export default {
  props: {
    canEdit: { type: Boolean, default: false },
    attachment: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      progress: -1,
      indeterminate: false,
      isLoading: false,
      isRemoving: false,
    };
  },
  methods: {
    async remove(e) {
      const activity = ActivityDetailFacade.Activity;
      e.stopPropagation();
      ModalService.choice({
        title: this.$t('REMOVE_FILE_TITLE'),
        subtitle: this.$t('REMOVE_FILE', {
          name: this.attachment.name,
          act: activity.subject,
        }),
        labels: { yes: this.$t('REMOVE') },
        callback: {
          onYes: this.removeConfirmed,
        },
      });
    },
    async removeConfirmed() {
      try {
        this.isRemoving = true;
        await ActivityDetailFacade.removeAttachment(this.attachment.id);
      } finally {
        this.isRemoving = false;
      }
    },
    async open(e) {
      await this.getFile(e, 'open');
    },
    async download(e) {
      await this.getFile(e, 'download');
    },
    async getFile(e, mode) {
      if (e) e.stopPropagation();

      this.progress = -1;
      this.indeterminate = true;
      this.isLoading = true;

      try {
        const data = await new GetAttachmentQuery({
          id: this.attachment.id,
          aktid: this.attachment.aktid,
          versionId: this.attachment.versionId,
        }).execute((progress) => {
          this.indeterminate = false;
          this.progress = progress;
        });

        this.progress = 0;
        if (!data) return;

        FileService.saveFile(data, this.attachment.name, mode);
      } catch (e) {
        NotificationService.showError({ message: translate('ERROR_OCCURRED') });
      } finally {
        this.isLoading = false;
      }
    },
    formatSize(size) {
      return formatBytes(size);
    },
  },
};
</script>

<style scoped></style>
